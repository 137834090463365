import React from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { useStyles } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

// 결제 통계 컴포넌트
const PaymentCharts = () => {
  const classes = useStyles();

  // 차트 데이터
  const barChartData = {
    labels: ['수기', '단말기', '온라인'],
    datasets: [
      {
        label: '결제금액',
        backgroundColor: [
          'rgba(87, 117, 144, 0.7)',
          'rgba(252, 141, 89, 0.7)',
          'rgba(145, 204, 117, 0.7)'
        ],
        borderColor: [
          'rgba(87, 117, 144, 1)',
          'rgba(252, 141, 89, 1)',
          'rgba(145, 204, 117, 1)'
        ],
        borderWidth: 0,
        borderRadius: 4,
        data: [28450000, 35527050, 0],
        barPercentage: 0.6,
        categoryPercentage: 0.8
      }
    ]
  };

  // 차트 데이터(도넛)
  const pieChartData = {
    labels: ['수기', '단말기', '온라인'],
    datasets: [{
      data: [28450000, 35527050, 0],
      backgroundColor: [
        'rgba(87, 117, 144, 0.7)',
        'rgba(252, 141, 89, 0.7)',
        'rgba(145, 204, 117, 0.7)'
      ],
      borderWidth: 0,
    }]
  };

  // 차트 옵션
  const commonOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          padding: 15,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 11,
            family: "'Pretendard', sans-serif",
            weight: '500'
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#666',
        bodyColor: '#666',
        borderColor: 'rgba(0,0,0,0.1)',
        borderWidth: 1,
        padding: 10,
        boxPadding: 5,
        usePointStyle: true,
        titleFont: {
          size: 13,
          family: "'Pretendard', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 12,
          family: "'Pretendard', sans-serif"
        }
      }
    }
  };

  const barOptions = {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      tooltip: {
        ...commonOptions.plugins.tooltip,
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `결제금액: ${value.toLocaleString()}원`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif"
          },
          padding: 10,
          callback: value => value.toLocaleString() + '원'
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif"
          }
        }
      }
    }
  };

  const pieOptions = {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      legend: {
        ...commonOptions.plugins.legend,
        position: 'right',
        align: 'center',
        labels: {
          ...commonOptions.plugins.legend.labels,
          padding: 20,
          generateLabels: (chart) => {
            const data = chart.data;
            const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
            return data.labels.map((label, i) => ({
              text: `${label}: ${((data.datasets[0].data[i] / total) * 100).toFixed(1)}%`,
              fillStyle: data.datasets[0].backgroundColor[i]
            }));
          }
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true
  };

  // 메인 코드
  return (
    <Box className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography className={classes.chartTitle}>
                일 결제금액
              </Typography>
              <Typography className={classes.chartSubtitle}>
                수단별 결제 현황
              </Typography>
            </Box>
            <Box className={classes.chartBody}>
              <div className={classes.chartContainer}>
                <Bar data={barChartData} options={barOptions} />
              </div>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                일 수단별 결제금액
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.chartSubtitle}>
                비율
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Pie data={pieChartData} options={pieOptions} />
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentCharts;