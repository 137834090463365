import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Typography, 
  Box, 
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

// 모달 스타일
const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '80%',
    maxWidth: '1200px',
    borderRadius: '8px'
  }
});

// 모달 헤더 스타일
const DialogHeader = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  borderBottom: '1px solid #e0e0e0',
  '& .MuiTypography-root': {
    fontSize: '1.25rem',
    fontWeight: 600
  }
});

// 테이블 스타일
const StyledTable = styled(Table)({
  '& th': {
    backgroundColor: '#f5f5f5',
    fontWeight: 600,
    borderBottom: '2px solid #e0e0e0',
    padding: '12px 16px'
  },
  '& td': {
    padding: '12px 16px',
    borderBottom: '1px solid #e0e0e0'
  }
});

//메인 함수
function CancelModal({ open, onClose, data, modalType = 'cancel' }) {
  const renderContent = () => {
    switch (modalType) {
      case 'account':
        return (
          <>
            <DialogHeader sx={{ mb: 2 }}>
              계정별 정보
              <IconButton
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogHeader>
            <DialogContent sx={{ p: 3 }}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>구분</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>영업자명</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>수수료율(VAT포함)</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>지급금액(VAT포함)</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>수수료율(VAT별도)</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>지급금액(VAT별도)</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>원천공제</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>은행명</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>계좌번호</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">본사</TableCell>
                    <TableCell align="center">홍길동</TableCell>
                    <TableCell align="center">3.3%</TableCell>
                    <TableCell align="right">55,000</TableCell>
                    <TableCell align="center">3.0%</TableCell>
                    <TableCell align="right">50,000</TableCell>
                    <TableCell align="right">3,000</TableCell>
                    <TableCell align="center">신한은행</TableCell>
                    <TableCell align="center">110-123-456789</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">지사</TableCell>
                    <TableCell align="center">김철수</TableCell>
                    <TableCell align="center">2.2%</TableCell>
                    <TableCell align="right">35,000</TableCell>
                    <TableCell align="center">2.0%</TableCell>
                    <TableCell align="right">32,000</TableCell>
                    <TableCell align="right">2,000</TableCell>
                    <TableCell align="center">국민은행</TableCell>
                    <TableCell align="center">123-456-789012</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">대리점</TableCell>
                    <TableCell align="center">이영희</TableCell>
                    <TableCell align="center">1.1%</TableCell>
                    <TableCell align="right">25,000</TableCell>
                    <TableCell align="center">1.0%</TableCell>
                    <TableCell align="right">23,000</TableCell>
                    <TableCell align="right">1,000</TableCell>
                    <TableCell align="center">우리은행</TableCell>
                    <TableCell align="center">456-789-123456</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">영업자</TableCell>
                    <TableCell align="center">박상철</TableCell>
                    <TableCell align="center">2.75%</TableCell>
                    <TableCell align="right">45,000</TableCell>
                    <TableCell align="center">2.5%</TableCell>
                    <TableCell align="right">41,000</TableCell>
                    <TableCell align="right">2,500</TableCell>
                    <TableCell align="center">하나은행</TableCell>
                    <TableCell align="center">789-456-123012</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">부본사</TableCell>
                    <TableCell align="center">정민우</TableCell>
                    <TableCell align="center">3.0%</TableCell>
                    <TableCell align="right">48,000</TableCell>
                    <TableCell align="center">2.7%</TableCell>
                    <TableCell align="right">44,000</TableCell>
                    <TableCell align="right">2,700</TableCell>
                    <TableCell align="center">기업은행</TableCell>
                    <TableCell align="center">345-678-901234</TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </DialogContent>
          </>
        );
      case 'cancel':
        return (
          <>
            <DialogHeader>
              취소 상세 정보
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogHeader>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                취소 처리하시겠습니까?
              </Typography>
              {/* 취소 모달 내용 */}
            </DialogContent>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      {renderContent()}
    </StyledDialog>
  );
}

export default CancelModal;
