import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Drawer, 
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Tooltip
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import QuickMenu from '../../Common/QuickMenu';
import SearchOptions from '../../Common/SearchOptions';
import Notice from '../../Notice/Notice';
import CancelModal from '../../Common/PayModal/CancelModal';
import CurrentLocation from '../../Common/CurrentLocation';
import styled from '@emotion/styled';
import UserInfoTwo from '../../Common/PayModal/UserInfoTwo';
import { format, parseISO, startOfDay, endOfDay } from 'date-fns';
import dayjs from 'dayjs';

// 검색 조건에 따른 필터링 함수
const useFilteredData = (data, searchConditions) => {
  return React.useMemo(() => {
    if (!searchConditions) return data;

    return data.filter(item => {
      let isMatched = true;

      // 텍스트 검색 (가맹점명, MID, TID, 승인번호)
      if (searchConditions.searchText) {
        const searchLower = searchConditions.searchText.toLowerCase();
        const matchMerchant = item.merchantName?.toLowerCase().includes(searchLower);
        const matchMID = item.merchantMID?.toLowerCase().includes(searchLower);
        const matchTID = item.merchantTID?.toLowerCase().includes(searchLower);
        const matchApprovalNo = item.approvalNo?.toLowerCase().includes(searchLower);
        
        isMatched = matchMerchant || matchMID || matchTID || matchApprovalNo;
      }

      // 날짜 범위 필터링
      if (searchConditions.startDate && searchConditions.endDate) {
        const itemDate = dayjs(item.cancelDate).format('YYYY-MM-DD');
        const startDate = dayjs(searchConditions.startDate).format('YYYY-MM-DD');
        const endDate = dayjs(searchConditions.endDate).format('YYYY-MM-DD');
        
        isMatched = isMatched && (itemDate >= startDate && itemDate <= endDate);
      }

      // 선택된 토글 옵션 필터링
      if (searchConditions.selectedToggleOptions?.length > 0 && isMatched) {
        isMatched = searchConditions.selectedToggleOptions.some(option => {
          switch (option.parentOption) {
            case 'installment':
              return item.installment === option.label;
            case 'pgCompany':
              return item.pg === option.label;
            case 'merchantMID':
              return item.merchantMID === option.label;
            case 'tid':
              return item.merchantTID === option.label;
            default:
              return true;
          }
        });
      }

      return isMatched;
    });
  }, [data, searchConditions]);
};

function CancelCompletionDetails() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [userInfoTwoOpen, setUserInfoTwoOpen] = useState(false);
  const [selectedMerchantName, setSelectedMerchantName] = useState('');
  const [searchParams, setSearchParams] = useState({
    dateRange: {
      startDate: '',
      endDate: ''
    },
    searchText: '',
    installment: '',
    pg: ''
  });

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (checked, approvalNo) => {
    if (checked) {
      setSelectedApprovals(prev => [...prev, approvalNo]);
    } else {
      setSelectedApprovals(prev => prev.filter(no => no !== approvalNo));
    }
  };

  // 검색 조건 처리
  const handleSearch = (searchConditions) => {
    console.log('Received search conditions:', searchConditions);
    setSearchParams({
      startDate: searchConditions.startDate,
      endDate: searchConditions.endDate,
      searchText: searchConditions.searchText || '',
      selectedToggleOptions: searchConditions.selectedToggleOptions || []
    });
  };

  // 계정 클릭 핸들러
  const handleAccountClick = (row) => {
    setSelectedAccount(row);
    setAccountModalOpen(true);
  };

  // 사용자 상세정보 클릭 핸들러 추가
  const handleUserDetailClick = (row) => {
    setSelectedMerchantName(row.merchantName);
    setUserInfoTwoOpen(true);
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: '5px',
    ml: '40px',  // QuickMenu 기준
    mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
    height: 'auto',
    overflowY: 'auto',
    position: 'relative',
    transition: 'margin 0.2s',
    '@media (max-width: 960px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  };

  // 테이블 헤더 정의 수정
  const tableHeaders = [
    // 파란색 헤더 (#1976d2)
    { id: 'no', label: 'No', color: '#1976d2' },
    { id: 'accountDetail', label: '상세', color: '#1976d2' },
    { id: 'status', label: '상태', color: '#1976d2' },
    { id: 'pg', label: 'PG', color: '#1976d2' },
    { id: 'cancelDate', label: '취소일시', color: '#1976d2' },
    { id: 'requestDate', label: '요청일시', color: '#1976d2' },
    
    // 하늘색 헤더 (#90caf9)
    { id: 'merchantMID', label: '가맹점 MID', color: '#90caf9' },
    { id: 'merchantTID', label: '가맹점 TID', color: '#90caf9' },
    { id: 'accountStatus', label: '계정상태', color: '#90caf9' },
    { id: 'refundAmount', label: '환입금액', color: '#90caf9' },
    { id: 'walletUse', label: '월렛사용여부', color: '#90caf9' },
    { id: 'amount', label: '거래금액', color: '#90caf9' },
    { id: 'approvalNo', label: '승인번호', color: '#90caf9' },
    { id: 'merchantName', label: '가맹점명', color: '#90caf9' },
    { id: 'receipt', label: '영수증', color: '#90caf9' },
    
    // 연한 녹색 헤더 (#a5d6a7)
    { id: 'issuer', label: '발급사', color: '#a5d6a7' },
    { id: 'cardNumber', label: '카드번호', color: '#a5d6a7' },
    { id: 'installment', label: '할부', color: '#a5d6a7' },
    { id: 'feeRate', label: '수수료율', color: '#a5d6a7' },
    { id: 'feeAmount', label: '수수료금액', color: '#a5d6a7' },
    { id: 'deposit', label: '보증금', color: '#a5d6a7' },
    { id: 'depositAmount', label: '입금액', color: '#a5d6a7' },
    { id: 'accountBalance', label: '계정잔액', color: '#a5d6a7' },
    { id: 'deductionAmount', label: '차감금액', color: '#a5d6a7' },
    { id: 'vanTrxId', label: 'vanTrxidtrxid', color: '#a5d6a7' },
    
  ];

  // 더미데이터 수정 (no, cancelDate, feeAmount 추가)
  const dummyData = [
    {
      no: 1,
      id: 1,
      cancelDate: '2025-02-06 15:39:44',
      requestDate: '2025-02-06 15:30:00',
      merchantName: '네오알파시스템즈',
      merchantMID: 'ksnet_175',
      merchantTID: 'TR678901234',
      approvalNo: '65934468',
      status: '취소완료',
      pg: 'KSNET',
      accountStatus: '정상',
      refundAmount: '50,000',
      walletUse: '사용중',
      amount: '176,000',
      receipt: 'Y',
      issuer: '삼성카드',
      cardNumber: '5137-92**-****-4575',
      installment: '일시불',
      feeRate: '3.3%',
      feeAmount: '5,808',
      deposit: '1,000',
      depositAmount: '169,192',
      accountBalance: '587,777',
      deductionAmount: '2,000',
      vanTrxId: 'ksnet_auto_175_20241227000001'
    },
    {
      no: 2,
      id: 2,
      cancelDate: '2025-02-05 13:25:00',
      requestDate: '2025-02-05 13:20:00',
      merchantName: '일일구세탁',
      merchantMID: 'nice_176',
      merchantTID: 'TR567890123',
      approvalNo: '65934469',
      status: '취소완료',
      pg: 'NICE',
      accountStatus: '정상',
      refundAmount: '45,000',
      walletUse: '조회용',
      amount: '45,000',
      receipt: 'Y',
      issuer: 'KB국민카드',
      cardNumber: '9411-23**-****-1234',
      installment: '3개월',
      feeRate: '2.8%',
      feeAmount: '1,260',
      deposit: '1,500',
      depositAmount: '42,240',
      accountBalance: '657,890',
      deductionAmount: '1,500',
      vanTrxId: 'nice_auto_176_20241227000001'
    },
    {
      no: 3,
      id: 3,
      cancelDate: '2025-02-04 11:15:30',
      requestDate: '2025-02-04 11:10:00',
      merchantName: '고기진심정육점',
      merchantMID: 'nice_177',
      merchantTID: 'TR567890124',
      approvalNo: '65934470',
      status: '취소완료',
      pg: 'NICE',
      accountStatus: '정상',
      refundAmount: '150,000',
      walletUse: '미사용',
      amount: '150,000',
      receipt: 'Y',
      issuer: 'KB국민카드',
      cardNumber: '9411-23**-****-1234',
      installment: '일시불',
      feeRate: '3.2%',
      feeAmount: '4,800',
      deposit: '3,000',
      depositAmount: '142,200',
      accountBalance: '892,300',
      deductionAmount: '5,000',
      vanTrxId: 'nice_auto_177_20241227000003'
    },
    {
      no: 4,
      id: 4,
      cancelDate: '2025-02-03 09:45:20',
      requestDate: '2025-02-03 09:40:00',
      merchantName: '골드스파사우나',
      merchantMID: 'kopay_178',
      merchantTID: 'TR567890125',
      approvalNo: '65934471',
      status: '취소완료',
      pg: 'Kopay',
      accountStatus: '정상',
      refundAmount: '80,000',
      walletUse: '사용중',
      amount: '80,000',
      receipt: 'Y',
      issuer: '현대카드',
      cardNumber: '5137-92**-****-4576',
      installment: '3개월',
      feeRate: '2.8%',
      feeAmount: '2,240',
      deposit: '1,500',
      depositAmount: '76,260',
      accountBalance: '445,800',
      deductionAmount: '3,000',
      vanTrxId: 'kopay_auto_178_20241227000004'
    },
    {
      no: 5,
      id: 5,
      cancelDate: '2025-02-02 16:20:10',
      requestDate: '2025-02-02 16:15:00',
      merchantName: '일일구세탁',
      merchantMID: 'aynil_179',
      merchantTID: 'TR567890126',
      approvalNo: '65934472',
      status: '취소완료',
      pg: 'AYNIL',
      accountStatus: '정상',
      refundAmount: '95,000',
      walletUse: '조회용',
      amount: '95,000',
      receipt: 'Y',
      issuer: '롯데카드',
      cardNumber: '4009-78**-****-8207',
      installment: '2개월',
      feeRate: '3.0%',
      feeAmount: '2,850',
      deposit: '2,000',
      depositAmount: '90,150',
      accountBalance: '678,900',
      deductionAmount: '4,000',
      vanTrxId: 'aynil_auto_179_20241227000005'
    }
  ];

  const styles = {
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  // 스타일드 컴포넌트 추가
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '4px 8px',
    fontSize: '0.813rem',
    // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
    letterSpacing: '-0.03em',
    borderBottom: '1px solid #e9ecef',
    borderRight: '1px solid #e9ecef',
    whiteSpace: 'nowrap',
    color: '#495057',
    
    '&.MuiTableCell-head': {
      fontWeight: 600,
      borderBottom: '2px solid #e9ecef',
      textAlign: 'center',
      height: '28px',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      padding: '4px 8px',
      
      '&.primary-header': {
        backgroundColor: '#1976d2',
        color: '#ffffff',
        whiteSpace: 'nowrap',
        minWidth: 'max-content',
        padding: '4px 8px'
      }
    }
  }));

  // 테이블 행 스타일
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    height: '28px',
    backgroundColor: '#ffffff',
    
    '&:hover': {
      '& td': {
        color: '#000000',
      },
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }));

  // 테이블 래퍼 스타일
  const StyledTableWrapper = styled(Box)({
    overflow: 'auto',
    position: 'relative',
    maxHeight: 'calc(100vh - 300px)',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px'
    }
  });

  // useFilteredData 훅 사용
  const filteredData = useFilteredData(dummyData, searchParams);

  // 메인 코드
  return (
    <Box component="main" sx={mainBoxStyle}>
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            zIndex: 1199
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Notice />
      <CurrentLocation mainTitle="결제" subTitle="취소완료 내역" />
      
      <Box sx={{ mb: '20px' }} />
      
      <TableContainer component={StyledTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.id}
                  align="center"
                  className="primary-header"
                  style={{ backgroundColor: header.color }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">{row.no}</StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="계정 정보 보기">
                    <IconButton
                      size="small"
                      onClick={() => handleUserDetailClick(row)}
                      sx={{
                        padding: '4px',
                        '&:hover': {
                          backgroundColor: '#e8f5e9'
                        }
                      }}
                    >
                      <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="center">{row.status}</StyledTableCell>
                <StyledTableCell align="center">{row.pg}</StyledTableCell>
                <StyledTableCell align="center">{row.cancelDate}</StyledTableCell>
                <StyledTableCell align="center">{row.requestDate}</StyledTableCell>
                <StyledTableCell align="center">{row.merchantMID}</StyledTableCell>
                <StyledTableCell align="center">{row.merchantTID}</StyledTableCell>
                <StyledTableCell align="center">{row.accountStatus}</StyledTableCell>
                <StyledTableCell align="right">{row.refundAmount}</StyledTableCell>
                <StyledTableCell align="center">{row.walletUse}</StyledTableCell>
                <StyledTableCell align="right">{row.amount}</StyledTableCell>
                <StyledTableCell align="center">{row.approvalNo}</StyledTableCell>
                <StyledTableCell align="center">{row.merchantName}</StyledTableCell>
                <StyledTableCell align="center">{row.receipt}</StyledTableCell>
                <StyledTableCell align="center">{row.issuer}</StyledTableCell>
                <StyledTableCell align="center">{row.cardNumber}</StyledTableCell>
                <StyledTableCell align="center">{row.installment}</StyledTableCell>
                <StyledTableCell align="right">{row.feeRate}</StyledTableCell>
                <StyledTableCell align="right">{row.feeAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.deposit}</StyledTableCell>
                <StyledTableCell align="right">{row.depositAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.accountBalance}</StyledTableCell>
                <StyledTableCell align="right">{row.deductionAmount}</StyledTableCell>
                <StyledTableCell align="center">{row.vanTrxId}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mb: '20px' }} />

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>

      <CancelModal
        open={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        data={selectedAccount}
        modalType="account"
      />

      <UserInfoTwo
        open={userInfoTwoOpen}
        onClose={() => setUserInfoTwoOpen(false)}
        businessName={selectedMerchantName}
      />
    </Box>
  );
}

export default CancelCompletionDetails;
