import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// 로컬 컴포넌트 import
import Notice from '../../Notice/Notice';
import BunseokGamengTables from './bunseokGamengTables';
import SummaryTable from '../../Common/PaymentResultData/PayHisSummaryTable';
import QuickMenu from '../../Common/QuickMenu';
import SearchOptions from '../../Common/SearchOptions';
import ResultText from '../../ResultText';
// import NewResultTables from '../../NewResultTables';
import CurrentLocation from '../../Common/CurrentLocation';

// 스타일
const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '50px',
    pr: '280px',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100%)', 
    margin: '0', 
    mb: 2,
    padding: '0'
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '320px',
      boxSizing: 'border-box',
      mt: '64px',
      height: 'calc(100% - 64px)',
      border: 'none',
      backgroundColor: 'transparent',
      '@media (max-width: 768px)': {
        width: '100%',
        mt: '56px',
        height: 'calc(100% - 56px)'
      }
    }
  }
};

// 메인 함수
function BunseokGameng() {
  // 상태값 정의
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [searchParams, setSearchParams] = useState({
    startDate: '',
    endDate: '',
    limit: 30
  });

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
    setSearchParams(searchParams);
  };

  // 데이터 업데이트 핸들러
  const handleDataUpdate = (newData) => {
    console.log('데이터 업데이트:', newData);
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: 2,
    transition: 'padding 0.2s',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '10px'
    },
    ml: {
      xs: '10px',
      md: '50px'
    },
    '@media (max-width: 960px)': {
      ml: '10px',
      pr: '10px'
    }
  };

  // 메인 코드
  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* Notice 영역 */}
      <Box sx={styles.noticeBox}>
        <Notice />
      </Box>

      <CurrentLocation mainTitle="통계" subTitle="가맹점별 분석" />
      
      <Box sx={{ mb: '20px' }} />

      {/* ResultText */}
      <ResultText />

      {/* 메인 테이블 컴포넌트 */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        {/* <Typography variant="h6" sx={{ mb: 2 }}>가맹점별 결제금액</Typography> */}
        <BunseokGamengTables 
          searchParams={searchParams}
          searchPanelOpen={searchPanelOpen}
          onDataUpdate={handleDataUpdate}
        />
      </Paper>
      
      {/* 하단 합계 표 */}
      <Box sx={{ mt: 3 }}>
        <SummaryTable searchPanelOpen={searchPanelOpen} />
      </Box>

      {/* QuickMenu */}
      <QuickMenu />

      {/* SearchOptions Drawer */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch} 
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>

      {/* 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            borderRadius: '12px 0 0 12px',
            boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
            '&:hover': {
              backgroundColor: '#f5f5f5'
            },
            zIndex: 900
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default BunseokGameng;

