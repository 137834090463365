import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material';

// 더미 데이터를 export 해줍니다
export const settlementData = {
  basicStats: {
    totalSettlementAmount: 15780000,
    settlementCount: 342,
    cancelSettlementAmount: 580000,
    cancelCount: 12,
    netSettlementAmount: 15200000,
    settlementRate: 96.5,
    avgSettlementAmount: 46140,
    totalTransactions: 354,
    totalFee: 500000,
    totalDeposit: 1000000,
    offsetAmount: 112246,
    settlementReflectedAmount: 5777671662
  }
};

// 테이블 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 6px',
  fontSize: '0.813rem',
  // fontFamilly: 'inherit',
  textAlign: 'center',
  border: '1px solid #e3f2fd',
  backgroundColor: '#f8fbff',
  '&.header': {
    backgroundColor: '#e3f2fd',
    color: '#0d47a1',
    fontWeight: 600,
  },
  '&.subheader': {
    backgroundColor: '#f5f5f5',
    color: '#333',
    fontWeight: 500,
  }
}));

// 테이블 스타일
const StyledTable = styled(Table)({
  minWidth: 650,
  borderCollapse: 'collapse',
  '& th, & td': {
    border: '1px solid #e3f2fd',
  }
});

const SetSummaryTables = () => {
  const { basicStats = {} } = settlementData || {};
  
  const formatNumber = (number) => {
    return (number || 0).toLocaleString();
  };

  // 메인 코드(정산요약 상단 테이블)
  return (
    <>
      <Box sx={{ width: '100%', mb: 4 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ color: '#1976d2', fontWeight: 600, mb: 1 }}>
            정산 요약
          </Typography>
          <Typography variant="body2" sx={{ color: '#666' }}>
            전체 정산 현황에 대한 요약 정보입니다.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TableContainer component={Paper} sx={{ boxShadow: '0 2px 12px 0 rgba(0,0,0,0.05)' }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell className="header" colSpan={2}>정산 승인</StyledTableCell>
                  <StyledTableCell className="header" colSpan={2}>정산 취소</StyledTableCell>
                  <StyledTableCell className="header" colSpan={4}>정산 합계</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="subheader">금액</StyledTableCell>
                  <StyledTableCell className="subheader">건수</StyledTableCell>
                  <StyledTableCell className="subheader">금액</StyledTableCell>
                  <StyledTableCell className="subheader">건수</StyledTableCell>
                  <StyledTableCell className="subheader">순정산금액</StyledTableCell>
                  <StyledTableCell className="subheader">정산율</StyledTableCell>
                  <StyledTableCell className="subheader">평균금액</StyledTableCell>
                  <StyledTableCell className="subheader">전체건수</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell>{formatNumber(basicStats.totalSettlementAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.settlementCount)}건</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.cancelSettlementAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.cancelCount)}건</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.netSettlementAmount)}원</StyledTableCell>
                  <StyledTableCell>{basicStats.settlementRate}%</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.avgSettlementAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.totalTransactions)}건</StyledTableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>

          <TableContainer component={Paper} sx={{ boxShadow: '0 2px 12px 0 rgba(0,0,0,0.05)' }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell className="header">수수료(VAT포함)</StyledTableCell>
                  <StyledTableCell className="header">보증금(VAT포함)</StyledTableCell>
                  <StyledTableCell className="header">상계금액</StyledTableCell>
                  <StyledTableCell className="header">정산반영 합계액</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell>{formatNumber(basicStats.totalFee)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.totalDeposit)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.offsetAmount)}원</StyledTableCell>
                  <StyledTableCell>{formatNumber(basicStats.settlementReflectedAmount)}원</StyledTableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default SetSummaryTables;
