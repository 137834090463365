import React, { useMemo, useEffect } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper
} from '@mui/material';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { PAYMENT_TYPE_OPTIONS } from '../../../Constants/settlementOptions';
dayjs.extend(isBetween);

// 스타일드 컴포넌트 정의
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
  },

  '&.amount': {
    color: '#2196f3',
    textAlign: 'right'
  },
  '&.negative': {
    color: '#dc3545',
    textAlign: 'right'
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

// 테이블 컨테이너 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

// 테이블 헤더 데이터
const tableHeaders = [
  // 파란색 그룹
  { id: 'no', label: 'NO', color: '#bbdefb' },
  { id: 'date', label: '날짜', color: '#bbdefb' },
  { id: 'approvalNo', label: '승인번호', color: '#bbdefb' },
  
  // 하늘색 그룹
  { id: 'paymentAmount', label: '결제액', color: '#e3f2fd' },
  { id: 'commission', label: '수수료', color: '#e3f2fd' },
  { id: 'inOutAmount', label: '입출금액', color: '#e3f2fd' },
  { id: 'chargeAmount', label: '충전액', color: '#e3f2fd' },
  { id: 'accountBalance', label: '계정잔액', color: '#e3f2fd' },
  { id: 'fixedRate', label: '정액정률', color: '#e3f2fd' },
  { id: 'tid', label: 'TID', color: '#e3f2fd' },
  
  // 초록색 그룹
  { id: 'companyName', label: '상호', color: '#c8e6c9' },
  { id: 'userId', label: '아이디', color: '#c8e6c9' },
  { id: 'bankName', label: '은행명', color: '#c8e6c9' },
  
  // 연두색 그룹
  { id: 'accountHolder', label: '예금주', color: '#f1f8e9' },
  { id: 'accountNumber', label: '계좌번호', color: '#f1f8e9' },
  { id: 'paymentMethod', label: '결제방식', color: '#f1f8e9' },
  { id: 'withdrawalLimit', label: '출금제한', color: '#f1f8e9' },
  { id: 'status', label: '상태(지연시간)', color: '#f1f8e9' },
  { id: 'success', label: '성공실패', color: '#f1f8e9' },
];

// 더미 데이터
const dummyData = [
  {
    no: 1,
    date: '2025-02-07 14:28:15',
    approvalNo: 'AP123456789',
    paymentAmount: 1000000,
    commission: -3000,
    inOutAmount: 997000,
    chargeAmount: 0,
    accountBalance: 2500000,
    fixedRate: '3.3%',
    tid: 'TR678901234',
    companyName: '농부고을2',
    userId: 'good777',
    bankName: '농협은행',
    accountHolder: '홍길동',
    accountNumber: '123-4567-8901',
    paymentMethod: '카드결제',
    withdrawalLimit: '출금가능',
    status: '처리중',
    success: '성공'
  },
  {
    no: 2,
    date: '2025-02-06 14:28:15',
    approvalNo: 'AP123457',
    paymentAmount: 250000,
    commission: 2500,
    inOutAmount: 247500,
    chargeAmount: 0,
    accountBalance: 747500,
    fixedRate: '1.0%',
    tid: 'TID123457',
    companyName: '행복마트',
    userId: 'happy777',
    bankName: '국민은행',
    accountHolder: '김행복',
    accountNumber: '456-7890-1234',
    paymentMethod: '현금결제',
    withdrawalLimit: '출금불가',
    status: '정상(1s)',
    success: '성공'
  },
  {
    no: 3,
    date: '2025-02-05 14:25:30',
    approvalNo: 'AP123458',
    paymentAmount: 50000,
    commission: 500,
    inOutAmount: 49500,
    chargeAmount: 10000,
    accountBalance: 59500,
    fixedRate: '1.0%',
    tid: 'TID123458',
    companyName: '스마일샵',
    userId: 'smile123',
    bankName: '우리은행',
    accountHolder: '이스마일',
    accountNumber: '789-1234-5678',
    paymentMethod: '상품권',
    withdrawalLimit: '출금가능',
    status: '지연(5s)',
    success: '대기'
  },
  {
    no: 4,
    date: '2025-02-04 14:20:45',
    approvalNo: 'AP123459',
    paymentAmount: 150000,
    commission: 1500,
    inOutAmount: 148500,
    chargeAmount: 0,
    accountBalance: 208000,
    fixedRate: '1.0%',
    tid: 'TID123459',
    companyName: '드림스토어',
    userId: 'dream999',
    bankName: '하나은행',
    accountHolder: '박드림',
    accountNumber: '321-6543-9876',
    paymentMethod: '예비1',
    withdrawalLimit: '출금불가',
    status: '정상(0s)',
    success: '성공'
  },
  {
    no: 5,
    date: '2025-02-03 14:15:10',
    approvalNo: 'AP123460',
    paymentAmount: 75000,
    commission: 750,
    inOutAmount: 74250,
    chargeAmount: 5000,
    accountBalance: 79250,
    fixedRate: '1.0%',
    tid: 'TID123460',
    companyName: '굿모닝마트',
    userId: 'good777',
    bankName: '농협은행',
    accountHolder: '최굿모닝',
    accountNumber: '147-2583-6789',
    paymentMethod: '카드결제',
    withdrawalLimit: '출금가능',
    status: '오류(10s)',
    success: '실패'
  }
];

// 메인 함수
function WalletInOutTable({ searchConditions, isInitialLoad }) {
  const filteredData = useMemo(() => {
    // 초기 로딩 시에는 모든 데이터 표시
    if (isInitialLoad) {
      console.log('Initial load - showing all data');
      return dummyData.map((row, index) => ({
        ...row,
        no: index + 1
      }));
    }

    if (!searchConditions) return dummyData;

    console.log('Filtering with conditions:', searchConditions);
    
    return dummyData.filter(row => {
      // 날짜 범위 체크
      if (searchConditions.startDate && searchConditions.endDate) {
        const rowDate = dayjs(row.date);
        const start = dayjs(searchConditions.startDate).startOf('day');
        const end = dayjs(searchConditions.endDate).endOf('day');
        
        if (!rowDate.isBetween(start, end, 'day', '[]')) {
          return false;
        }
      }

      // 출금제한 필터 (전체 선택 시 필터링 하지 않음)
      if (searchConditions.withdrawalLimit !== '전체' && 
          row.withdrawalLimit !== searchConditions.withdrawalLimit) {
        return false;
      }

      // 결제방식 필터 (전체 선택 시 필터링 하지 않음)
      if (searchConditions.paymentType !== 'all') {
        const selectedPaymentType = PAYMENT_TYPE_OPTIONS.find(
          opt => opt.value === searchConditions.paymentType
        );
        if (row.paymentMethod !== selectedPaymentType?.label) {
          return false;
        }
      }

      // 검색어 체크
      if (searchConditions.searchText) {
        const searchLower = searchConditions.searchText.toLowerCase();
        const isMatch = 
          row.approvalNo?.toLowerCase().includes(searchLower) ||
          row.tid?.toLowerCase().includes(searchLower) ||
          row.companyName?.toLowerCase().includes(searchLower) ||
          row.userId?.toLowerCase().includes(searchLower) ||
          row.accountNumber?.includes(searchConditions.searchText);
        
        if (!isMatch) return false;
      }

      return true;
    }).map((row, index) => ({
      ...row,
      no: index + 1
    }));
  }, [searchConditions, isInitialLoad]);

  useEffect(() => {
    console.log('Filtered data:', filteredData);
  }, [filteredData]);

  return (
    <Box mb={2}>
      <TableContainer component={StyledTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.id}
                  style={{
                    backgroundColor: header.color,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <StyledTableRow key={row.no}>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={`${row.no}-${header.id}`}
                    align={
                      ['paymentAmount', 'commission', 'inOutAmount', 'chargeAmount', 'accountBalance'].includes(header.id)
                        ? 'right'
                        : ['no', 'date', 'approvalNo', 'tid', 'userId', 'status', 'success'].includes(header.id)
                          ? 'center'
                          : 'left'
                    }
                    className={
                      ['paymentAmount', 'inOutAmount', 'chargeAmount', 'accountBalance'].includes(header.id)
                        ? 'amount'
                        : header.id === 'commission'
                          ? 'negative'
                          : ''
                    }
                  >
                    {typeof row[header.id] === 'number' 
                      ? row[header.id].toLocaleString() 
                      : row[header.id]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default WalletInOutTable;

