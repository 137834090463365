import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  IconButton,
  Tooltip,
  TablePagination,
  Typography,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import OptionButton from '../../Common/ManagementData/OptionButton';
import MerchantStatusIcons from '../../Common/MerchantStatusIcons';
import BlockIcon from '@mui/icons-material/Block';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccountNoButton from '../../Common/ManagementData/AccountNoButton';
import MerchantNoModal from '../../Common/ManagementData/MerchantNoModal';
import MerchantInput from './MerchantInput/MerchantInput';

// 스타일 컴포넌트
const StyledTableWrapper = styled(Box)({
  overflowX: 'auto',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 350px)',
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
  }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: '#1976d2',
    color: '#ffffff',
    textAlign: 'center',
    height: '32px',
    padding: '4px 8px',

    // 헤더 체크박스 셀 스타일
    '& .MuiCheckbox-root': {
      color: '#ffffff',
      padding: '4px',
      '&.Mui-checked': {
        color: '#ffffff',
      },
      '&.MuiCheckbox-indeterminate': {
        color: '#ffffff',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
      }
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(even)': {
    backgroundColor: '#f8f9fa',
  },
  '&:hover': {
    backgroundColor: '#e3f2fd',
  },
  '& td': {
    height: '32px',
  }
});

const StyledTablePagination = styled(TablePagination)({
  '.MuiTablePagination-select': {
    fontSize: '0.813rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.813rem'
  }
});

// 더미 데이터 생성 함수 수정
const generateDummyData = (count) => {
    return Array.from({ length: count }, (_, index) => ({
      id: index + 1,
      no: index + 1,
      detail: null, // 상세보기 버튼용
      pg: ['나이스', 'KICC', 'KCP'][Math.floor(Math.random() * 3)],
      loginId: `user${index + 1}@example.com`,
      merchantName: `가맹점${index + 1}`,
      mid: `MID${String(index + 1).padStart(6, '0')}`,
      tid: `TID${String(index + 1).padStart(6, '0')}`,
      businessType: ['사업자', '개인'][Math.floor(Math.random() * 2)],
      taxType: ['세금', '원천'][Math.floor(Math.random() * 2)],
      paymentMethod: ['수기', '단말기', '온라인'][Math.floor(Math.random() * 3)],
      authMethod: ['SMS', 'ARS', '카드'][Math.floor(Math.random() * 3)],
      cancelAuth: ['허용', '차단'][Math.floor(Math.random() * 2)],
      settlement: ['즉시', 'D+1', 'D+2', 'D+3'][Math.floor(Math.random() * 4)],
      wallet: ['사용', '미사용'][Math.floor(Math.random() * 2)],
      settlementStatus: ['정상', '보류', '중지'][Math.floor(Math.random() * 3)],
      feeRate: (Math.random() * 3 + 1).toFixed(2) + '%',
      depositRate: (Math.random() * 10).toFixed(2) + '%',
      point: ['적용', '미적용'][Math.floor(Math.random() * 2)],
      transactionLimit: Math.floor(Math.random() * 10 + 1) + '만원',
      installment: ['가능', '불가'][Math.floor(Math.random() * 2)],
      walletFixed: Math.floor(Math.random() * 1000) * 100 + '원',
      walletRate: (Math.random() * 2).toFixed(2) + '%',
      walletLimit: Math.floor(Math.random() * 100) + '만원',
      sales1: `영업${Math.floor(Math.random() * 10) + 1}팀`,
      sales2: `영업${Math.floor(Math.random() * 10) + 1}팀`,
      sales3: `영업${Math.floor(Math.random() * 10) + 1}팀`,
      agency: `대리점${Math.floor(Math.random() * 20) + 1}`,
      retail: `리테일${Math.floor(Math.random() * 15) + 1}`,
      branch: `지사${Math.floor(Math.random() * 10) + 1}`,
      distributor: `총판${Math.floor(Math.random() * 5) + 1}`,
      seniorMaster: `시니어${Math.floor(Math.random() * 3) + 1}`,
      master: `마스터${Math.floor(Math.random() * 2) + 1}`,
      registDate: new Date(Date.now() - Math.random() * 10000000000).toLocaleDateString(),
      modifyDate: new Date(Date.now() - Math.random() * 1000000000).toLocaleDateString(),
      workerId: `admin${Math.floor(Math.random() * 100) + 1}`
    }));
  };

// 테이블 헤더 정의
const columns = [
  { id: 'select', width: 50 },
  { id: 'no', label: 'No', width: 60 },
  { id: 'detail', label: '상세보기', width: 80 },
  { id: 'pg', label: 'PG', width: 100 },
  { id: 'loginId', label: '로그인 아이디', width: 120 },
  { id: 'merchantName', label: '가맹점명', width: 150 },
  { id: 'mid', label: 'MID', width: 120 },
  { id: 'tid', label: 'TID', width: 120 },
  { id: 'businessType', label: '사업자/개인', width: 100 },
  { id: 'taxType', label: '세금/원천', width: 100 },
  { id: 'paymentMethod', label: '결제수단', width: 100 },
  { id: 'authMethod', label: '인증방식', width: 100 },
  { id: 'cancelAuth', label: '취소권한', width: 100 },
  { id: 'settlement', label: '정산', width: 100 },
  { id: 'wallet', label: '월렛', width: 80 },
  { id: 'settlementStatus', label: '정산상태', width: 100 },
  { id: 'feeRate', label: '수수료율(별)', width: 110 },
  { id: 'depositRate', label: '보증금율', width: 100 },
  { id: 'point', label: '포인트', width: 80 },
  { id: 'transactionLimit', label: '건한도', width: 80 },
  { id: 'installment', label: '할부', width: 80 },
  { id: 'walletFixed', label: '월렛 정액', width: 100 },
  { id: 'walletRate', label: '월렛 정률', width: 100 },
  { id: 'walletLimit', label: '월렛 제한', width: 100 },
  { id: 'sales1', label: '영업1', width: 100 },
  { id: 'sales2', label: '영업2', width: 100 },
  { id: 'sales3', label: '영업3', width: 100 },
  { id: 'agency', label: '대리점', width: 100 },
  { id: 'retail', label: '리테일', width: 100 },
  { id: 'branch', label: '지사', width: 100 },
  { id: 'distributor', label: '총판', width: 100 },
  { id: 'seniorMaster', label: '시니어 마스터', width: 120 },
  { id: 'master', label: '마스터', width: 100 },
  { id: 'registDate', label: '등록일', width: 120 },
  { id: 'modifyDate', label: '수정일', width: 120 },
  { id: 'workerId', label: '작업자 아이디', width: 120 }
];

function MerchantNoTables({ onDataUpdate }) {
  // 상태값 관리
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  // 더미 데이터 생성
  useEffect(() => {
    setMerchants(generateDummyData(100)); // 더미 데이터 100개 생성
  }, []);

  // 전체 선택 함수
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedMerchants(merchants.map(merchant => merchant.id));
    } else {
      setSelectedMerchants([]);
    }
  };

  // 선택 함수
  const handleSelect = (id) => {
    setSelectedMerchants(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  // 페이지 변경 함수
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // 옵션 클릭 함수
  const handleOptionClick = (optionType, selectedItems) => {
    switch(optionType) {
      case '수정':
        if (selectedItems.length === 1) {
          const selectedMerchant = merchants.find(m => m.id === selectedItems[0]);
          setSelectedMerchant(selectedMerchant);
          setEditModalOpen(true);
        }
        break;
      case '삭제':
        console.log('삭제 처리:', selectedItems);
        break;
      case '복사':
        if (selectedItems.length === 1) {
          console.log('복사 처리:', selectedItems);
        }
        break;
      case '신청':
      case '반려':
      case '삭제요청':
      case '임시저장':
        console.log(`${optionType} 처리:`, selectedItems);
        break;
    }
  };

  // 모든 데이터 가져오기 함수
  const getAllMerchantData = async () => {
    try {
      return merchants;
    } catch (error) {
      console.error('Error fetching all data:', error);
      return null;
    }
  };

  // 데이터 업데이트 함수
  useEffect(() => {
    onDataUpdate?.({
      data: merchants,
      getAllData: getAllMerchantData
    });
  }, [merchants]);

  // 상세 클릭 함수
  const handleOpenModal = (merchant) => {
    setSelectedMerchant(merchant);
    setModalOpen(true);
  };

  // 상세 모달 닫기 함수
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedMerchant(null);
  };

  // 메인 코드
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ 
        display: 'flex',
        gap: 4,
        backgroundColor: '#f8f9fa',
        padding: '24px',
        borderRadius: '8px',
        mb: 3,
        width: '100%'
      }}>
        <Box sx={{ 
          display: 'flex',
          gap: 3,
          alignItems: 'flex-start'
        }}>
          <AccountNoButton 
            onOptionClick={handleOptionClick}
            selectedItems={selectedMerchants}
          />
          <OptionButton 
            onOptionClick={handleOptionClick}
            selectedItems={selectedMerchants}
          />
        </Box>
      </Box>

      <StyledTableWrapper>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  style={{ minWidth: column.width }}
                >
                  {column.id === 'select' ? (
                    <Checkbox
                      checked={selectedMerchants.length === merchants.length}
                      indeterminate={selectedMerchants.length > 0 && selectedMerchants.length < merchants.length}
                      onChange={handleSelectAll}
                    />
                  ) : (
                    column.label
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {merchants
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((merchant) => (
                <StyledTableRow key={merchant.id}>
                  <StyledTableCell>
                    <Checkbox
                      checked={selectedMerchants.includes(merchant.id)}
                      onChange={() => handleSelect(merchant.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{merchant.no}</StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="상세보기">
                      <IconButton 
                        size="small"
                        onClick={() => handleOpenModal(merchant)}
                      >
                        <VisibilityIcon sx={{ fontSize: 18, color: '#2185d0' }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>{merchant.pg}</StyledTableCell>
                  <StyledTableCell>{merchant.loginId}</StyledTableCell>
                  <StyledTableCell>{merchant.merchantName}</StyledTableCell>
                  <StyledTableCell>{merchant.mid}</StyledTableCell>
                  <StyledTableCell>{merchant.tid}</StyledTableCell>
                  <StyledTableCell>{merchant.businessType}</StyledTableCell>
                  <StyledTableCell>{merchant.taxType}</StyledTableCell>
                  <StyledTableCell>{merchant.paymentMethod}</StyledTableCell>
                  <StyledTableCell>{merchant.authMethod}</StyledTableCell>
                  <StyledTableCell>{merchant.cancelAuth}</StyledTableCell>
                  <StyledTableCell>{merchant.settlement}</StyledTableCell>
                  <StyledTableCell>{merchant.wallet}</StyledTableCell>
                  <StyledTableCell>{merchant.settlementStatus}</StyledTableCell>
                  <StyledTableCell>{merchant.feeRate}</StyledTableCell>
                  <StyledTableCell>{merchant.depositRate}</StyledTableCell>
                  <StyledTableCell>{merchant.point}</StyledTableCell>
                  <StyledTableCell>{merchant.transactionLimit}</StyledTableCell>
                  <StyledTableCell>{merchant.installment}</StyledTableCell>
                  <StyledTableCell>{merchant.walletFixed}</StyledTableCell>
                  <StyledTableCell>{merchant.walletRate}</StyledTableCell>
                  <StyledTableCell>{merchant.walletLimit}</StyledTableCell>
                  <StyledTableCell>{merchant.sales1}</StyledTableCell>
                  <StyledTableCell>{merchant.sales2}</StyledTableCell>
                  <StyledTableCell>{merchant.sales3}</StyledTableCell>
                  <StyledTableCell>{merchant.agency}</StyledTableCell>
                  <StyledTableCell>{merchant.retail}</StyledTableCell>
                  <StyledTableCell>{merchant.branch}</StyledTableCell>
                  <StyledTableCell>{merchant.distributor}</StyledTableCell>
                  <StyledTableCell>{merchant.seniorMaster}</StyledTableCell>
                  <StyledTableCell>{merchant.master}</StyledTableCell>
                  <StyledTableCell>{merchant.registDate}</StyledTableCell>
                  <StyledTableCell>{merchant.modifyDate}</StyledTableCell>
                  <StyledTableCell>{merchant.workerId}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>
      <StyledTablePagination
        component="div"
        count={merchants.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
        labelDisplayedRows={({ from, to, count }) => 
          `전체 ${count}건 중 ${from}-${to}건`
        }
      />

      <MerchantNoModal
        open={modalOpen}
        onClose={handleCloseModal}
        data={selectedMerchant}
      />

      {editModalOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'background.paper',
            zIndex: 1300,
          }}
        >
          <Box sx={{ height: '100%' }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              p: 2,
              borderBottom: '1px solid #e0e0e0'
            }}>
              <Button 
                onClick={() => setEditModalOpen(false)}
                variant="outlined"
                size="small"
              >
                닫기
              </Button>
            </Box>
            <MerchantInput merchantData={selectedMerchant} />
          </Box>
        </Box>
      )}
    </Paper>
  );
}

export default MerchantNoTables;
