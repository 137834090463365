import React, { useState } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  IconButton,
  Tooltip,
  Checkbox,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArticleIcon from '@mui/icons-material/Article';
import UserInfoOne from '../../Common/SetModal/UserInfoOne';
import UserInfoTwo from '../../Common/SetModal/UserInfoTwo';
import DetailInfo from '../../Common/SetModal/Details';
import styled from '@emotion/styled';

// 테이블 열 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      minWidth: 'max-content',
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 컨테이너 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2
  },
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch'
  }
});

// 스타일 정의
const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px',
    padding: '0',
    margin: '0',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 130px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '8px 4px',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '6px 3px',
        fontSize: '0.75rem'
      }
    }
  }
};

// 아이콘 버튼 스타일
const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

// 메인 함수
function DefaultTables() {
  // 상태 관리
  const [userInfoOneOpen, setUserInfoOneOpen] = useState(false);
  const [userInfoTwoOpen, setUserInfoTwoOpen] = useState(false);
  const [detailInfoOpen, setDetailInfoOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedMerchantName, setSelectedMerchantName] = useState('');
  const [selectedRows, setSelectedRows] = useState([]); // 체크박스용 state 추가

  // 더미 데이터 생성
  const dummyData = Array(5).fill(null).map((_, index) => ({
    id: index + 1,
    no: (index + 1).toString(),
    pg: ['KG이니시스', '나이스페이먼츠', '토스페이먼츠'][index % 3],
    account: `user${123 + index}`,
    detail: '상세보기',
    walletUse: ['사용', '미사용'][index % 2],
    settlementAvailable: '금지',
    merchantTid: `TID${String(index + 1).padStart(8, '0')}`,
    merchantMid: `MID${String(index + 1).padStart(8, '0')}`,
    merchantName: `테스트가맹점${index + 1}`,
    paymentDate: `2024-03-${String(20 + index).padStart(2, '0')}`,
    settlementCycle: ['D+0', 'D+1', 'D+2'][index % 3],
    expectedDate: `2024-03-${String(21 + index).padStart(2, '0')}`,
    approvalCount: Math.floor(Math.random() * 50) + 10,
    cancelCount: Math.floor(Math.random() * 5),
    approvalAmount: (Math.floor(Math.random() * 1000000) + 500000).toLocaleString(),
    cancelAmount: (Math.floor(Math.random() * 100000)).toLocaleString(),
    detailInfo: '보기',
    detailCount: Math.floor(Math.random() * 10) + 1,
    actualSales: (Math.floor(Math.random() * 900000) + 100000).toLocaleString(),
    settlementAmount: (Math.floor(Math.random() * 800000) + 200000).toLocaleString(),
    merchantFeeRate: `${(Math.random() * 2000).toFixed(0)}/${(Math.random() * 3).toFixed(2)}%`,
    rmCount: Math.floor(Math.random() * 3),
    unpaidAmount: (Math.floor(Math.random() * 30000)).toLocaleString(),
    reserveAmount: (Math.floor(Math.random() * 100000)).toLocaleString(),
    paymentAmount: (Math.floor(Math.random() * 700000) + 300000).toLocaleString(),
    depositAmount: (Math.floor(Math.random() * 200000) + 50000).toLocaleString()
  }));

  // 테이블 헤더 데이터
  const tableHeaders = [
    { 
      id: 'checkbox', 
      label: <Checkbox 
        size="small"
        onChange={(e) => {
          const allRowIds = dummyData.map(row => row.id);
          if (e.target.checked) {
            setSelectedRows(allRowIds);
          } else {
            setSelectedRows([]);
          }
        }}
        checked={dummyData.length > 0 && selectedRows.length === dummyData.length}
        indeterminate={selectedRows.length > 0 && selectedRows.length < dummyData.length}
      />, 
      color: '#bbdefb' 
    },
    { id: 'no', label: 'NO', color: '#bbdefb' },
    { id: 'pg', label: 'PG', color: '#bbdefb' },
    { id: 'account', label: '계정', align: 'left', color: '#1976d2' },
    { id: 'detail', label: '상세', align: 'left', color: '#1976d2' },
    { id: 'walletUse', label: '월렛여부', align: 'center', color: '#1976d2' },
    { id: 'settlementAvailable', label: '정산가능', align: 'center', color: '#1976d2' },
    { id: 'merchantTid', label: '가맹점TID', align: 'left', color: '#1976d2' },
    { id: 'merchantMid', label: '가맹점MID', align: 'left', color: '#1976d2' },
    { id: 'merchantName', label: '가맹점명', align: 'left', color: '#1976d2' },
    { id: 'paymentDate', label: '결제일시', align: 'left', color: '#03a9f4' },
    { id: 'settlementCycle', label: '정산주기', align: 'center', color: '#03a9f4' },
    { id: 'expectedDate', label: '정산예정일', align: 'left', color: '#03a9f4' },
    { id: 'approvalCount', label: '승인건수', align: 'right', color: '#03a9f4' },
    { id: 'cancelCount', label: '취소건수', align: 'right', color: '#03a9f4' },
    { id: 'approvalAmount', label: '승인금액', align: 'right', color: '#03a9f4' },
    { id: 'cancelAmount', label: '취소금액', align: 'right', color: '#c8e6c9' },
    { id: 'detailInfo', label: '세부내역', align: 'left', color: '#c8e6c9' },
    { id: 'detailCount', label: '세부 건수', align: 'right', color: '#c8e6c9' },
    { id: 'actualSales', label: '실매출금액', align: 'right', color: '#ffffff' },
    { id: 'settlementAmount', label: '정산액', align: 'right', color: '#ffffff' },
    { id: 'merchantFeeRate', label: '가맹점수수료율', align: 'right', color: '#ffffff' },
    { id: 'rmCount', label: 'RM건수', align: 'right', color: '#ffffff' },
    { id: 'unpaidAmount', label: '미수금액', align: 'right', color: '#ffffff' },
    { id: 'reserveAmount', label: '유보금액', align: 'right', color: '#ffffff' },
    { id: 'paymentAmount', label: '지급금액', align: 'right', color: '#ffffff' },
    { id: 'depositAmount', label: '보증금액', align: 'right', color: '#ffffff' }
  ];

  // 사용자 상세 클릭 함수
  const handleUserDetailClick = (row) => {
    setSelectedRowData({
      amount: row.approvalAmount,
      approvalNo: row.merchantTid,
      transactionDate: row.paymentDate,
      cardNumber: row.cardNumber || '-',
      installment: row.installment || '-',
      productName: row.productName || '-'
    });
    setUserInfoOneOpen(true);
  };

  // 카드 상세 클릭 함수
  const handleCardDetailClick = (row) => {
    setUserInfoTwoOpen(true);
    setSelectedMerchantName(row.merchantName);
  };

  // 세부내역 클릭 함수
  const handleDetailInfoClick = () => {
    setDetailInfoOpen(true);
  };

  // 메인 코드
  return (
    <Card 
      sx={{ 
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        backgroundColor: '#fff'
      }}
    >
      <CardContent sx={{ padding: '16px', '&:last-child': { paddingBottom: '16px' } }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            mb: 1
          }}>
            <BlockIcon sx={{ 
              color: '#f44336',
              fontSize: '1.5rem'
            }} />
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: '1.1rem',
                fontWeight: 600,
                color: '#333'
              }}
            >
              정산금지 목록
            </Typography>
          </Box>

          <TableContainer component={Paper} sx={styles.tableContainer}>
            <Table sx={styles.table} stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <StyledTableCell 
                      key={header.id}
                      align={header.align || 'center'}
                      style={{ 
                        backgroundColor: header.color,
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                      }}
                    >
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dummyData.map((row) => (
                  <TableRow key={row.id}>
                    {tableHeaders.map((header) => (
                      <StyledTableCell 
                        key={`${row.id}-${header.id}`}
                        align={header.align || 'center'}
                      >
                        {header.id === 'checkbox' ? (
                          <Checkbox 
                            size="small"
                            checked={selectedRows.includes(row.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedRows([...selectedRows, row.id]);
                              } else {
                                setSelectedRows(selectedRows.filter(id => id !== row.id));
                              }
                            }}
                          />
                        ) : (
                          header.id === 'account' || header.id === 'detail' || header.id === 'detailInfo' ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                              {header.id === 'account' && (
                                <Tooltip title="사용자 정보">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => handleUserDetailClick(row)}
                                    sx={IconButtonStyle}
                                  >
                                    <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {header.id === 'detail' && (
                                <Tooltip title="결제 상세보기">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => handleCardDetailClick(row)}
                                    sx={IconButtonStyle}
                                  >
                                    <CreditCardIcon sx={{ fontSize: 20, color: '#2185d0' }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {header.id === 'detailInfo' && (
                                <Tooltip title="세부내역 보기">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => handleDetailInfoClick(row)}
                                    sx={IconButtonStyle}
                                  >
                                    <ArticleIcon sx={{ fontSize: 20, color: '#6435c9' }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          ) : (
                            row[header.id]
                          )
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>

      <DetailInfo
        open={detailInfoOpen}
        onClose={() => setDetailInfoOpen(false)}
      />
      
      <UserInfoOne
        open={userInfoOneOpen}
        onClose={() => setUserInfoOneOpen(false)}
        data={selectedRowData}
      />
      
      {userInfoTwoOpen && (
        <UserInfoTwo
          open={userInfoTwoOpen}
          onClose={() => setUserInfoTwoOpen(false)}
          businessName={selectedMerchantName}
        />
      )}
    </Card>
  );
}

export default DefaultTables;