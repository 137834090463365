import React from 'react';
import { 
  Table, 
  TableBody, 
  TableRow, 
  TableCell, 
  TableContainer, 
  Paper,
  Box,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '10px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard',
  backgroundColor: '#fff',
  color: '#1e293b',
  whiteSpace: 'nowrap',
  borderBottom: '1px solid #edf2f7',
  fontWeight: 400,
  width: '20%',
  '&:not(:last-child)': {
    borderRight: '1px solid #edf2f7',
  },
  '&.label': {
    backgroundColor: '#f8fafc',
    color: '#475569',
    fontWeight: 700,
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#f1f5f9',
    }
  },
  '&.value': {
    textAlign: 'right',
    paddingRight: '24px',
    '&.negative': {
      color: '#ef4444',
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)({
  '&:hover': {
    '& .label': {
      backgroundColor: '#f1f5f9',
    },
    '& .value': {
      backgroundColor: '#fafafa',
    }
  }
});

// 테이블 컨테이너 스타일
const StyledTableContainer = styled(TableContainer)({
  borderRadius: '12px',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
  border: '1px solid #edf2f7',
  transition: 'all 0.2s ease',
  marginBottom: '24px',
  overflowX: 'auto',
  '&:hover': {
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  }
});

// 메인 함수
const NewResultTables = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tableData = [
    [
      { label: '승인건수', value: '78' },
      { label: '취소건수', value: '2' },
      { label: '실거래건수', value: '76' },
      { label: '기간별거래처', value: '24' },
      { label: '월렛출금액', value: '0' }
    ],
    [
      { label: '승인금액', value: '16,991,090' },
      { label: '취소금액', value: '-1,238,500', isNegative: true },
      { label: '합계', value: '15,752,590' },
      { label: '기간내 취소액', value: '0' },
      { label: '', value: '' }
    ],
    [
      { label: '환입금액', value: '0' },
      { label: '상계금액', value: '0' },
      { label: '보류금액', value: '0' },
      { label: '가맹점정산액', value: '14,953,503' },
      { label: '', value: '' }
    ],
    [
      { label: '수수료 합계', value: '799,087' },
      { label: '지급금액', value: '0' },
      { label: '유보금', value: '345,797' },
      { label: '보증금액', value: '0' },
      { label: '', value: '' }
    ],
    [
      { label: '수기', value: '10' },
      { label: '온라인', value: '0' },
      { label: '단말기', value: '70' },
      { label: '기타', value: '0' },
      { label: '', value: '' }
    ]
  ];

  // 메인 코드
  return (
    <Box sx={{ mb: 3 }}>
      <StyledTableContainer>
        <Table 
          size="small" 
          sx={{ 
            tableLayout: 'fixed',
            minWidth: isMobile ? '800px' : '100%',
            '& td': {
              minWidth: isMobile ? '160px' : 'auto'
            }
          }}
        >
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <React.Fragment key={cellIndex}>
                    <StyledTableCell 
                      className="label"
                      sx={{
                        width: isMobile ? '80px' : '20%',
                      }}
                    >
                      {cell.label}
                    </StyledTableCell>
                    <StyledTableCell 
                      className={`value ${cell.isNegative ? 'negative' : ''}`}
                      sx={{
                        width: isMobile ? '80px' : '20%',
                      }}
                    >
                      {cell.value}
                    </StyledTableCell>
                  </React.Fragment>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
};

export default NewResultTables;
