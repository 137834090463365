// 내역조회 옵션 상수 정의
export const HISTORY_TYPE_OPTIONS = [
  { value: 'all', label: '전체' },
  { value: 'merchant', label: '머천트 내역' },
  { value: 'account_deposit', label: '계정입금내역' },
  { value: 'account_withdrawal', label: '계정 출금내역' },
  { value: 'non_withdrawal', label: '미출금 내역' },
  { value: 'manual_adjustment', label: '수기증감 내역' }
];

// 결제방식 옵션 상수 정의
export const PAYMENT_TYPE_OPTIONS = [
  { value: 'all', label: '전체' },
  { value: '234', label: '카드결제' },
  { value: '740', label: '현금결제' },
  { value: '741', label: '예비1' },
  { value: '742', label: '상품권' }
]; 