import React from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

// 컴부 컴포넌트
import Notice from '../Notice/Notice';

// Dashboard 내부 컴포넌트
import PaymentStats from './components/PaymentStats/PaymentStats';
import MerchantStats from './components/MerchantStats/MerchantStats';
import TransactionStats from './components/TransactionStats/TransactionStats';
import MonthlyStats from './components/TransactionStats/MonthlyStats';
import PaymentCharts from './components/PaymentCharts/PaymentCharts';
import TaxStats from './components/MerchantStats/TaxStats/TaxStats';
import BusinessStats from './components/MerchantStats/BusinessStats/BusinessStats';
import AuthStats from './components/MerchantStats/AuthStats/AuthStats';
import PGStats from './components/PGStats/PGStats';
import TopTenData from './components/TopTenData/TopTenData';

function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:780px)');
  const isTablet = useMediaQuery('(min-width:781px) and (max-width:1024px)');

  return (
    <Box 
      component="main" 
      sx={{ 
        p: isMobile ? '0px' : 2,
        mt: isMobile ? '10px' : 0,
        ml: '5px',
        mr: '5px',
        overflowY: 'auto'
      }}
    >
      <Notice />
      <Box sx={{ mb: 1 }} />
      
      <PaymentStats />
      <MerchantStats />
      
      <Box sx={{ mb: 3 }}>
        <Grid 
          container 
          spacing={2}
        >
          <Grid 
            item 
            xs={12} 
            md={6} 
            sx={{
              width: '100%',
              [theme.breakpoints.down('md')]: {
                marginBottom: 2
              }
            }}
          >
            <TransactionStats isEmbedded={true} />
          </Grid>
          <Grid 
            item 
            xs={12} 
            md={6}
            sx={{
              width: '100%'
            }}
          >
            <MonthlyStats isEmbedded={true} />
          </Grid>
        </Grid>
      </Box>
      {/* 결제내역 집계 차트 */}
      <PaymentCharts />
      {/* 과세 통계 */}
      <TaxStats />
      {/* 사업자 통계 */}
      <BusinessStats />
      {/* 인증별 결제금액 */}
      <AuthStats />
      {/* PG사 통계 */}
      <PGStats />
      {/* 상위 통계 */}
      <TopTenData />
    </Box>
  );
}

export default Dashboard;
