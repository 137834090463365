import React, { useState, useRef } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import styled from '@emotion/styled';

const AllAccountsVerification = () => {
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');

  // 더미 데이터
  const rows = [
    {
      no: 1,
      merchant_id: '1046440397',
      merchant_name: '중지 컬리브리 0397',
      bank_name: '우리은행',
      owner_name: '윤준혁',
      account_no: '1002-155-298023',
      verification_status: '검증완료'
    },
    {
      no: 2,
      merchant_id: '1046428671',
      merchant_name: '중지 고양시부시장3구 8671',
      bank_name: '신한퍼스트',
      owner_name: '정금숙',
      account_no: '132-081-382219',
      verification_status: '검증대기'
    },
    // ... 더 많은 더미 데이터 추가 가능
  ];

  // 정렬 핸들러
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = [...rows].sort((a, b) => {
    const isAsc = order === 'asc';
    if (orderBy === 'no') {
      return isAsc ? a.no - b.no : b.no - a.no;
    }
    return isAsc
      ? String(a[orderBy]).localeCompare(String(b[orderBy]))
      : String(b[orderBy]).localeCompare(String(a[orderBy]));
  });

  // 테이블 래퍼 스타일 추가
  const StyledTableWrapper = styled(Box)({
    overflow: 'auto',
    position: 'relative',
    maxWidth: '100%',
    '& .sticky-column': {
      position: 'sticky',
      left: 0,
      backgroundColor: '#fff',
      zIndex: 2,
      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '4px',
        background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
      }
    },
    '@media (max-width: 600px)': {
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      '&::-webkit-scrollbar': {
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }
  });

  // 검색 섹션 래퍼 스타일 추가
  const StyledSearchWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '@media (max-width: 600px)': {
      flexDirection: 'row',
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      padding: '8px 0',
      '&::-webkit-scrollbar': {
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }
  });

  // 테이블 셀 스타일 추가
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '4px 8px',
    fontSize: '0.813rem',
    // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
    letterSpacing: '-0.03em',
    borderBottom: '1px solid #e9ecef',
    borderRight: '1px solid #e9ecef',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    '@media (max-width: 600px)': {
      padding: '4px 8px'
    }
  }));

  return (
    <Box sx={{ p: 3 }}>
      {/* 헤더 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <AccountBalanceIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          // fontFamilly: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          가맹 전계좌 검증
        </Typography>
      </Box>

      {/* 검색 및 필터 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <StyledSearchWrapper>
          {/* 데이터 개수 선택 */}
          <RadioGroup
            row
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            sx={{ 
              flexShrink: 0,
              gap: 2 
            }}
          >
            {[50, 100, 200, 500].map((value) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio size="small" />}
                label={value}
              />
            ))}
          </RadioGroup>

          {/* 검색어 입력 */}
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ 
              flexShrink: 0,
              width: '300px' 
            }}
          />

          {/* 전체 가맹점 계좌 검증 버튼 */}
          <Button
            variant="contained"
            sx={{
              flexShrink: 0,
              backgroundColor: '#00acc1',
              '&:hover': {
                backgroundColor: '#007c91',
              }
            }}
          >
            전체 가맹점 계좌 검증
            
          </Button>
        </StyledSearchWrapper>
      </Paper>

      {/* 테이블 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table sx={{ 
              minWidth: '1200px',
              tableLayout: 'fixed'
            }}>
              <colgroup>
                <col style={{ width: '80px' }} />  {/* No */}
                <col style={{ width: '120px' }} />  {/* 로그인아이디 */}
                <col style={{ width: '200px' }} />  {/* 가맹점명 */}
                <col style={{ width: '120px' }} />  {/* 은행명 */}
                <col style={{ width: '100px' }} />  {/* 예금주 */}
                <col style={{ width: '180px' }} />  {/* 계좌번호 */}
                <col style={{ width: '100px' }} />  {/* 검증여부 */}
              </colgroup>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'no'}
                      direction={orderBy === 'no' ? order : 'asc'}
                      onClick={() => handleRequestSort('no')}
                    >
                      No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">로그인아이디</TableCell>
                  <TableCell align="center">가맹점명</TableCell>
                  <TableCell align="center">은행명</TableCell>
                  <TableCell align="center">예금주</TableCell>
                  <TableCell align="center">계좌번호</TableCell>
                  <TableCell align="center">검증여부</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row) => (
                  <TableRow key={row.no}>
                    <TableCell align="center">{row.no}</TableCell>
                    <TableCell align="center">{row.merchant_id}</TableCell>
                    <TableCell align="center">{row.merchant_name}</TableCell>
                    <TableCell align="center">{row.bank_name}</TableCell>
                    <TableCell align="center">{row.owner_name}</TableCell>
                    <TableCell align="center">{row.account_no}</TableCell>
                    <TableCell 
                      align="center"
                      sx={{ 
                        color: row.verification_status === '검증완료' ? '#d32f2f' : 'inherit',
                        fontWeight: row.verification_status === '검증완료' ? 600 : 400
                      }}
                    >
                      {row.verification_status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>
      </Paper>
    </Box>
  );
};

export default AllAccountsVerification;
