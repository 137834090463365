// 기본 스타일 및 UI 라이브러리 임포트
import 'bootstrap/dist/css/bootstrap.min.css';// 부트스트랩 스타일 임포트
import React, { useState, useEffect } from 'react';// 리액트 기본 임포트
import {//UI 라이브러리 임포트
  // MUI ThemeProvider를 통해 전역 테마 적용
  // - 일관된 디자인 시스템 구현
  // - 폰트, 색상, 간격 등의 디자인 토큰 관리
  // - 다크모드 등 테마 전환 기능 지원
  ThemeProvider,
  createTheme,
  CssBaseline,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Stack,
  Divider,
  Button,
  Fab,
  useMediaQuery
} from '@mui/material';
// 라우팅 및 라우트 관련 임포트
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
// 리액트 스트랩 임포트
import { Row, Col } from 'reactstrap';

  // Material-UI 아이콘
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// 컴포넌트 imports
import Sidebar from './Components/Sidebar';
import TransactionStats from './Components/Dashboard/components/TransactionStats/TransactionStats';
import TaxStats from './Components/Dashboard/components/MerchantStats/TaxStats/TaxStats';
import BusinessStats from './Components/Dashboard/components/MerchantStats/BusinessStats/BusinessStats';
import AuthStats from './Components/Dashboard/components/MerchantStats/AuthStats/AuthStats';
import Notice from './Components/Notice/Notice';
import FailHistory from './Components/PaymentFolder/FailHistory/FailHistory';
import AlarmHistory from './Components/PaymentFolder/AlarmHistory/AlarmHistory';
import PaymentHistory from './Components/PaymentFolder/PaymentHistory/PaymentHistory';
import PaymentAggregation from './Components/PaymentFolder/PaymentAggregation/PaymentAggregation';
import NonMerchantManagement from './Components/PaymentFolder/NonMerchantManagement/NonMerchantManagement';
import RegularPayment from './Components/PaymentFolder/RegularPayment/RegularPayment';
import Header from './Components/Header';
import AdminPage from './Components/AdminFolder/AdminPage';
import TestPage from './Components/AdminFolder/TestPage';
import ErrorLog from './Components/PaymentFolder/ErrorLog/ErrorLog';
import DuplicateCheck from './Components/PaymentFolder/DuplicateCheck/DuplicateCheck';
import CancelRequestDetails from './Components/PaymentFolder/CancelRequestDetails/CancelRequestDetails';
import CancelCompletionDetails from './Components/PaymentFolder/CancelCompletionDetails/CancelCompletionDetails';
import CancelRefundRegistration from './Components/PaymentFolder/CancelRefundRegistration/CancelRefundRegistration';
import ComplaintDetails from './Components/SETTLEMENT/ComplaintDetails/ComplaintDetails';
import SettlementCalendar from './Components/SETTLEMENT/SettlementCalendar/SettlementCalendar'; // 추가
import SettlementHoldDetails from './Components/SETTLEMENT/SettlementHoldDetails/SettlementHoldDetails';
import ScheduledSettlementDetails from './Components/SETTLEMENT/ScheduledSettlementDetails/ScheduledSettlementDetails'; // 추가
import ScheduledSettlementSummary from './Components/SETTLEMENT/ScheduledSettlementSummary/ScheduledSettlementSummary'; // 추가
import DepositHistory from './Components/SETTLEMENT/DepositHistory/DepositHistory';
import NonWallet from './Components/SETTLEMENT/Non-wallet/Nonwallet';
import Wallet from './Components/SETTLEMENT/Wallet/Wallet';
import WalletInOutHistory from './Components/SETTLEMENT/WalletInOutHistory/WalletInOutHistory';
import TransferHistory from './Components/SETTLEMENT/WalletInOutHistory/optionButton/TransferHistory';
import WalletAccountList from './Components/SETTLEMENT/WalletInOutHistory/optionButton/WalletAccountList';
import UnconfirmedDeposits from './Components/SETTLEMENT/WalletInOutHistory/optionButton/UnconfirmedDeposits';
import WithdrawalHold from './Components/SETTLEMENT/WalletInOutHistory/optionButton/WithdrawalHold';
import InputValidation from './Components/SETTLEMENT/InputValidation/InputValidation';
import TaxIT from './Components/SETTLEMENT/TaxIT/TaxIT';
// Dashboard import 추가
import Dashboard from './Components/Dashboard/Dashboard';
import NoticeDetails from './Components/Notice/NoticeDetails';
import GiganSales from './Components/Statistics/GiganSales/GiganSales';
import WolSales from './Components/Statistics/WolSales/WolSales';
import GamengSales from './Components/Statistics/GamengSales/GamengSales';
import CancelPopup from './Components/SETTLEMENT/ScheduledSettlementDetails/CancelPopup';
import AccountManagement from './Components/Management/AccountManagement/AccountManagement';
import MerchantManagement from './Components/Management/MerchantManagement/MerchantManagement';
import AccountInput from './Components/Management/AccountManagement/AccountOptions/AccountInput';
import AccountNo from './Components/Management/AccountManagement/AccountNo';
import MerchantInput from './Components/Management/MerchantManagement/MerchantInput/MerchantInput';
import MerchantNo from './Components/Management/MerchantManagement/MerchantNo';
import MaechulGamang from './Components/Statistics/MaechulGamang/MaechulGamang';
import MaechulYeongeop from './Components/Statistics/MaechulYeongeop/MaechulYeongeop';
import BunseokGigan from './Components/Statistics/bunseokGigan/bunseokGigan';
import BunseokWol from './Components/Statistics/bunseokWol/bunseokWol';
import BunseokGameng from './Components/Statistics/bunseokGameng/bunseokGameng';
import BunseokCard from './Components/Statistics/bunseokCard/bunseokCard';
import CashDamdang from './Components/Management/CashDamdang/Cashdamdang';
import CashInput from './Components/Management/CashDamdang/CashInput/CashInput';
import CashResultTables from './Components/CashResultTables';
import CashUser from './Components/Management/CashUser/CashUser';
import CashUserInput from './Components/Management/CashUser/CashUserInput/CashUserInput';
// Google Fonts import 추가
import '@fontsource/roboto/400.css'; // Regular
import '@fontsource/roboto/500.css'; // Medium
import '@fontsource/roboto/700.css'; // Bold

// Theme 설정
const theme = createTheme({
  typography: {
    // fontFamilly: 'Roboto, Arial, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        * {
          font-family: 'Roboto', Arial, sans-serif;
        }
        .receipt-modal * {
          font-family: 'Pretendard Variable', sans-serif;
        }
      `
    }
  }
});

// 앱 컴포넌트 정의
function App() {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const contentPadding = isMobile ? '1px' : '24px';

  // SettlementDownButton의 모든 팝업 경로 추가
  const hideHeaderPaths = [
    '/settlement/wallet-account-list',
    '/settlement/transfer-history',
    '/settlement/unconfirmed-deposits',
    '/settlement/withdrawal-hold',
    '/cancel-popup'
  ];

  // 현재 경로가 hideHeaderPaths에 포함되어 있는지 확인
  const shouldHideHeader = hideHeaderPaths.some(path => 
    location.pathname.includes(path)
  );

  // 스크롤 위치에 따라 스크롤 탑 버튼 표시 여부 결정
  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById('scroll-container');
      setShowScrollTop(scrollContainer.scrollTop > 300);
    };

    // 스크롤 이벤트 리스너 추가
    const scrollContainer = document.getElementById('scroll-container');
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, []);

  // 스크롤 탑 버튼 클릭 시 스크롤 이동 함수
  const scrollToTop = () => {
    const scrollContainer = document.getElementById('scroll-container');
    scrollContainer.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    // MUI ThemeProvider를 통해 전역 테마 적용
    // - 일관된 디자인 시스템 구현
    // - 폰트, 색상, 간격 등의 디자인 토큰 관리
    // - 다크모드 등 테마 전환 기능 지원
    <ThemeProvider theme={theme}>
      {/* CSS 기본 스타일 초기화 및 정규화 */}
      <CssBaseline />
      {/* 전체 레이아웃 컨테이너 */}
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {/* 헤더 표시 여부에 따라 Header 컴포넌트 렌더링 */}
        {!shouldHideHeader && <Header />}
        {/* 스크롤 컨테이너 */}
        {/* 메인 컨텐츠 영역 */}
        {/* 스크롤바 커스텀 스타일 적용 */}
        {/* 반응형 패딩 처리 */}
        <Box 
          id="scroll-container"
          sx={{ 
            flexGrow: 1,
            overflowY: 'auto',
            marginTop: shouldHideHeader ? '0' : '64px',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
              borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            p: isMobile ? '1px' : 3
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/fail-list" element={<FailHistory />} />
            <Route path="/alarm-history" element={<AlarmHistory />} />
            <Route path="/payment-history" element={<PaymentHistory />} />
            <Route path="/payment-aggregation" element={<PaymentAggregation />} />
            <Route path="/payment/non-merchant" element={<NonMerchantManagement />} />
            <Route path="/auto-payment" element={<RegularPayment />} />
            <Route path="/test" element={<TestPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/error-log" element={<ErrorLog />} />
            <Route path="/duplicate-check" element={<DuplicateCheck />} />
            <Route path="/cancel-requests" element={<CancelRequestDetails />} />
            <Route path="/cancel-completed" element={<CancelCompletionDetails />} />
            <Route path="/cancel-refund" element={<CancelRefundRegistration />} />
            <Route path="/complaint-details" element={<ComplaintDetails />} />
            <Route path="/settlement/complaint" element={<ComplaintDetails />} />
            <Route path="/settlement/calendar" element={<SettlementCalendar />} />
            <Route path="/settlement/hold" element={<SettlementHoldDetails />} />
            <Route path="/settlement/scheduled" element={<ScheduledSettlementDetails />} />
            <Route path="/settlement/summary" element={<ScheduledSettlementSummary />} />
            <Route path="/settlement/deposit" element={<DepositHistory />} />
            <Route path="/settlement/transfer/non-wallet" element={<NonWallet />} /> {/* 추가된 부분 */}
            <Route path="/settlement/transfer/wallet" element={<Wallet />} />
            <Route path="/settlement/wallet-history" element={<WalletInOutHistory />} />
            <Route path="/settlement/transfer-history" element={<TransferHistory />} />
            <Route path="/settlement/wallet-account-list" element={<WalletAccountList />} />
            <Route path="/settlement/unconfirmed-deposits" element={<UnconfirmedDeposits/>} />
            <Route path="/settlement/withdrawal-hold" element={<WithdrawalHold/>} />
            <Route path="/settlement/input-validation" element={<InputValidation />} />
            <Route path="/settlement/tax-it" element={<TaxIT />} />
            <Route path="/notice/:id" element={<NoticeDetails />} />
            <Route path="/statistics/sales/period" element={<GiganSales />} />
            <Route path="/statistics/sales/monthly" element={<WolSales/>} />
            <Route path="/statistics/sales/merchant" element={<GamengSales/>}/>
            <Route path="/cancel-popup" element={<CancelPopup />} />
            <Route path="/management/account" element={<AccountManagement />} />
            <Route path="/management/account/register" element={<AccountInput />} />
            <Route path="/management/merchant" element={<MerchantManagement />} />
            <Route path="/management/account/no" element={<AccountNo />} />
            <Route path="/management/merchant/register" element={<MerchantInput />} />
            <Route path="/management/merchant-no" element={<MerchantNo />} />
            <Route path="/statistics/payment/merchant" element={<MaechulGamang />} />
            <Route path="/statistics/payment/sales" element={<MaechulYeongeop />} />
            <Route path="/statistics/analysis/period" element={<BunseokGigan />} />
            <Route path="/statistics/analysis/monthly" element={<BunseokWol />} />
            <Route path="/statistics/analysis/merchant" element={<BunseokGameng />} />
            <Route path="/statistics/analysis/card" element={<BunseokCard />} />
            <Route path="/management/cashbill/manager" element={<CashDamdang />} />
            <Route path="/management/cashdamdang/register" element={<CashInput />} />
            <Route path="/management/cashdamdang/result" element={<CashResultTables />} />
            <Route path="/management/cashuser/manager" element={<CashUser />} />
            <Route path="/management/cashuser/register" element={<CashUserInput />} />
            <Route path="/management/cashdamdang/input" element={<CashInput />} />
          </Routes>
        </Box>
      </Box>

      {/* 스크롤 상단 이동 버튼 */}
      {/* 스크롤 위치에 따라 동적으로 표시/숨김 */}
      <Fab 
        color="primary" 
        size="small"
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          display: showScrollTop ? 'flex' : 'none'
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </ThemeProvider>
  );
}

export default App;