import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  TableSortLabel,
  TableContainer,
  Table,
  TableCell,
  Modal,
  Button
} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';

// 테이블 래퍼 스타일 수정
const StyledTableWrapper = styled(Box)(/* 기존 코드 유지 */);

// 테이블 셀 스타일 추가
const StyledTableCell = styled(TableCell)(/* 기존 코드 유지 */);

// 모달 스타일
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// 유저 알림 내역 컴포넌트
const UserNotificationLogs = () => {
  const [notificationText, setNotificationText] = useState('');
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [sendMessage, setSendMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [loginId, setLoginId] = useState('');
  const [info, setInfo] = useState('');
  const [displayTime, setDisplayTime] = useState('');

  // 더미 데이터
  const rows = [
    {
      no: 1,
      id: 'm92610082',
      message: '계정 보안에서 일부로 중가 가능을 안내드립니다.',
      send_time: '2024-12-06 10:09:48',
      end_time: '2024-12-06 11:09:48',
    },
    {
      no: 2,
      id: '1047036610',
      message: '관리자에 의해 설정이 변경되었습니다. 로그아웃후 재접속 바랍니다.',
      send_time: '2024-12-05 13:29:23',
      end_time: '2024-12-06 13:29:23',
    },
    // ... 더 많은 더미 데이터 추가 ...
  ] || []; // rows가 undefined일 경우 빈 배열로 초기화

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSendNotification = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setLoginId('');
    setInfo('');
    setDisplayTime('');
  };

  const handleRegisterNotification = () => {
    // 알림 등록 로직 추가
    console.log('알림 등록:', { loginId, info, displayTime });
    handleModalClose();
  };

  // 정렬된 데이터 반환
  const sortedRows = React.useMemo(() => {
    const comparator = (a, b) => {
      if (order === 'asc') {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    };

    return [...rows].sort(comparator);
  }, [rows, order, orderBy]);

  //메인 코드
  return (
    <Box sx={{ p: 3 }}>
      {/* 제목 영역 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <NotificationsActiveIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          // fontFamilly: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          유저 알림 내역
        </Typography>
      </Box>

      {/* 알림 입력 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2
        }}>
          <TextField
            fullWidth
            size="small"
            placeholder="전송할 알림 내용을 입력하세요"
            value={notificationText}
            onChange={(e) => setNotificationText(e.target.value)}
          />
          <Tooltip title="알림 전송">
            <IconButton
              onClick={handleSendNotification}
              sx={{
                backgroundColor: '#51cbce',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#34b5b8'
                }
              }}
            >
              <NotificationsActiveIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {sendMessage && (
          <Typography sx={{ mt: 1, color: '#1976d2' }}>
            {sendMessage}
          </Typography>
        )}
      </Paper>

      {/* 알림 작성 모달 */}
      <Modal
        open={openModal}
        onClose={handleModalClose}
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" gutterBottom>
            새로운 알림 등록
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="LoginID"
            value={loginId}
            onChange={(e) => setLoginId(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Info"
            value={info}
            onChange={(e) => setInfo(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="노출시간(분)"
            type="number"
            value={displayTime}
            onChange={(e) => setDisplayTime(e.target.value)}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleModalClose} color="inherit" sx={{ mr: 1 }}>
              취소
            </Button>
            <Button onClick={handleRegisterNotification} variant="contained">
              등록
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* 테이블 영역 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table sx={{ 
              minWidth: '1200px',
              tableLayout: 'fixed'  // 고정 테이블 레이아웃
            }}>
              <colgroup>
                <col style={{ width: '80px' }} />  {/* No */}
                <col style={{ width: '120px' }} />  {/* 아이디 */}
                <col style={{ width: '500px' }} />  {/* 내용 */}
                <col style={{ width: '160px' }} />  {/* 발송시간 */}
                <col style={{ width: '160px' }} />  {/* 종료시간 */}
                <col style={{ width: '100px' }} />  {/* Actions */}
              </colgroup>
              <thead>
                <tr style={{ backgroundColor: '#f8f9fa' }}>
                  <StyledTableCell align="center">No</StyledTableCell>
                  <StyledTableCell align="center">아이디</StyledTableCell>
                  <StyledTableCell align="center">내용</StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'send_time'}
                      direction={orderBy === 'send_time' ? order : 'asc'}
                      onClick={() => handleRequestSort('send_time')}
                    >
                      발송시간
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'end_time'}
                      direction={orderBy === 'end_time' ? order : 'asc'}
                      onClick={() => handleRequestSort('end_time')}
                    >
                      종료시간
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">삭제</StyledTableCell>
                </tr>
              </thead>
              <tbody>
                {sortedRows.map((row) => (
                  <tr key={row.no}>
                    <StyledTableCell align="center">{row.no}</StyledTableCell>
                    <StyledTableCell align="center">{row.id}</StyledTableCell>
                    <StyledTableCell sx={{ 
                      whiteSpace: 'normal',  // 내용 열만 줄바꿈 허용
                      wordBreak: 'break-all',  // 긴 텍스트 줄바꿈
                      minWidth: '500px'  // 최소 너비 보장
                    }}>
                      {row.message}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.send_time}</StyledTableCell>
                    <StyledTableCell align="center">{row.end_time}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Tooltip title="삭제">
                        <IconButton
                          size="medium"
                          sx={{
                            backgroundColor: '#ef8157',
                            color: '#fff',
                            width: '36px',
                            height: '36px',
                            '&:hover': {
                              backgroundColor: '#eb6532'
                            }
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>
      </Paper>
    </Box>
  );
};

export default UserNotificationLogs;