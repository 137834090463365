import React, { useState, useMemo } from 'react';
import { 
  Box, 
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Modal,
  Typography,
  Tooltip
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import ArticleIcon from '@mui/icons-material/Article';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import ReceiptIcon from '@mui/icons-material/Receipt';
import UserInfoOne from '../../Common/SetModal/UserInfoOne';
import UserInfoTwo from '../../Common/SetModal/UserInfoTwo';
import DetailInfo from '../../Common/SetModal/Details';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import dayjs from 'dayjs';

// 스타일 정의
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 컨테이너 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px'
  }
});

// 스타일 정의
const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

// 테이블 헤더 데이터
const tableHeaders = [
  { id: 'checkbox', label: '체크박스', color: '#bbdefb' },
  { id: 'no', label: 'NO', color: '#bbdefb' },
  { id: 'pg', label: 'PG', color: '#bbdefb' },
  { id: 'accountInfo', label: '계정정보', color: '#bbdefb' },
  { id: 'detail', label: '상세팝업', color: '#bbdefb' },
  { id: 'walletStatus', label: '월렛여부', color: '#2196f3' },
  { id: 'settlementPossible', label: '정산가능', color: '#2196f3' },
  { id: 'merchantTID', label: '가맹점TID', color: '#2196f3' },
  { id: 'merchantMID', label: '가맹점 MID', color: '#2196f3' },
  { id: 'merchantName', label: '가맹점명', color: '#2196f3' },
  { id: 'transactionDate', label: '결제일시', color: '#c8e6c9' },
  { id: 'settlementCycle', label: '정산주기', color: '#c8e6c9' },
  { id: 'expectedSettlementDate', label: '정산예정일', color: '#c8e6c9' },
  { id: 'approvalCount', label: '승인건수', color: '#c8e6c9' },
  { id: 'cancelCount', label: '취소건수', color: '#c8e6c9' },
  { id: 'approvalAmount', label: '승인금액', color: '#c8e6c9' },
  { id: 'cancelAmount', label: '취소금액', color: '#c8e6c9' },
  { id: 'detailInfo', label: '세부내역', color: '#c8e6c9' },
  { id: 'detailCount', label: '세부 건수', align: 'right', color: '#c8e6c9' },
  { id: 'actualSalesAmount', label: '실매출금액', color: '#ffffff' },
  { id: 'settlementAmount', label: '정산액', color: '#ffffff' },
  { id: 'merchantCommissionRate', label: '가맹점수수료율', color: '#ffffff' },
  { id: 'commission', label: '수수료', color: '#ffffff' },
  { id: 'rmCount', label: 'RM건수', color: '#ffffff' },
  { id: 'unpaidAmount', label: '미수금액', color: '#ffffff' },
  { id: 'retentionRate', label: '유보율', color: '#ffffff' },
  { id: 'paymentRate', label: '지급률', color: '#ffffff' },
  { id: 'depositRate', label: '보증금률', color: '#ffffff' },
];

// 더미 데이터
const dummyData = [
  {
    no: 1,
    pg: 'KSNET',
    accountInfo: '계정정보',
    detail: '상세정보',
    walletStatus: '사용중',
    settlementPossible: '정산가능',
    merchantTID: 'TR678901234',
    merchantMID: 'KS0001234',
    merchantName: '농부고을2',
    transactionDate: '2025-02-06 15:43:54',
    settlementCycle: 'D+1',
    expectedSettlementDate: '2025-02-07 15:43:54',
    approvalCount: 5,
    cancelCount: 1,
    approvalAmount: 1000000,
    cancelAmount: -50000,
    detailInfo: '세부내역',
    detailCount: 2,
    actualSalesAmount: 950000,
    settlementAmount: 931000,
    merchantCommissionRate: '2%',
    commission: 19000,
    rmCount: 0,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
  {
    no: 2,
    pg: 'NICE',
    accountInfo: '계정정보',
    detail: '상세정보',
    walletStatus: '미사용',
    settlementPossible: '정산가능',
    merchantTID: 'TRK2024031900002',
    merchantMID: 'M432109876',
    merchantName: '애플49',
    transactionDate: '2025-02-06 14:30:00',
    settlementCycle: 'D+2',
    expectedSettlementDate: '2025-02-08 14:30:00',
    approvalCount: 3,
    cancelCount: 0,
    approvalAmount: 500000,
    cancelAmount: 0,
    detailInfo: '세부내역',
    detailCount: 1,
    actualSalesAmount: 500000,
    settlementAmount: 485000,
    merchantCommissionRate: '3%',
    commission: 15000,
    rmCount: 1,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
  {
    no: 3,
    pg: '갤럭시아',
    accountInfo: '계정정보',
    detail: '상세정보',
    walletStatus: '사용중',
    settlementPossible: '정산가능',
    merchantTID: 'TR567890123',
    merchantMID: 'SPC107836',
    merchantName: '고기진심정육점',
    transactionDate: '2025-02-06 13:15:00',
    settlementCycle: 'D+3',
    expectedSettlementDate: '2025-02-09 13:15:00',
    approvalCount: 8,
    cancelCount: 2,
    approvalAmount: 2500000,
    cancelAmount: -200000,
    detailInfo: '세부내역',
    detailCount: 3,
    actualSalesAmount: 2300000,
    settlementAmount: 2231000,
    merchantCommissionRate: '3%',
    commission: 69000,
    rmCount: 2,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
  {
    no: 4,
    pg: 'EtoU',
    accountInfo: '계정정보',
    detail: '상세정보',
    walletStatus: '미사용',
    settlementPossible: '정산불가',
    merchantTID: 'TR567890124',
    merchantMID: 'SPC_112014',
    merchantName: '골드스파사우나',
    transactionDate: '2025-02-06 12:00:00',
    settlementCycle: 'D+4',
    expectedSettlementDate: '2025-02-10 12:00:00',
    approvalCount: 12,
    cancelCount: 3,
    approvalAmount: 3500000,
    cancelAmount: -300000,
    detailInfo: '세부내역',
    detailCount: 4,
    actualSalesAmount: 3200000,
    settlementAmount: 3104000,
    merchantCommissionRate: '3%',
    commission: 96000,
    rmCount: 1,
    unpaidAmount: 50000,
    retentionRate: '5%',
    paymentRate: '95%',
    depositRate: '0%',
  },
  {
    no: 5,
    pg: 'Kopay',
    accountInfo: '계정정보',
    detail: '상세정보',
    walletStatus: '사용중',
    settlementPossible: '정산가능',
    merchantTID: 'TR567890125',
    merchantMID: 'KS0001234',
    merchantName: '일일구세탁',
    transactionDate: '2025-02-06 11:30:00',
    settlementCycle: 'D+1',
    expectedSettlementDate: '2025-02-07 11:30:00',
    approvalCount: 6,
    cancelCount: 1,
    approvalAmount: 800000,
    cancelAmount: -50000,
    detailInfo: '세부내역',
    detailCount: 2,
    actualSalesAmount: 750000,
    settlementAmount: 727500,
    merchantCommissionRate: '3%',
    commission: 22500,
    rmCount: 0,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  }
];

// 아이콘 버튼 스타일
const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

// 메인 함수
function SettlementHoldDetailsTables({ 
  searchPanelOpen, 
  setSearchPanelOpen, 
  selectedRows, 
  setSelectedRows,
  searchConditions
}) {
  // 모달 열기 상태
  const [modalOpen, setModalOpen] = useState(false);
  // 선택된 사용자 데이터
  const [selectedUserData, setSelectedUserData] = useState(null);
  // 세부내역 모달 열기 상태
  const [detailInfoOpen, setDetailInfoOpen] = useState(false);
  // 선택된 행 데이터
  const [selectedRowData, setSelectedRowData] = useState(null);
  // 모달 상태 관리
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);

  // 전체 선택 함수
  const handleCheckboxChange = (checked, rowId) => {
    if (checked) {
      setSelectedRows(prev => [...prev, rowId]);
    } else {
      setSelectedRows(prev => prev.filter(id => id !== rowId));
    }
  };

  // 사용자 아이콘 클릭 함수
  const handleUserIconClick = (userData) => {
    setSelectedUserData(userData);
    setModalOpen(true);
  };

  // 세부내역 클릭 함수
  const handleDetailInfoClick = (row) => {
    setSelectedRowData(row);
    setDetailInfoOpen(true);
  };

  // 계정 정보 클릭 핸들러
  const handleAccountClick = (merchantName) => {
    setSelectedMerchant(merchantName);
    setAccountModalOpen(true);
  };

  // 상세 정보 클릭 핸들러 
  const handleDetailClick = (merchantName) => {
    setSelectedMerchant(merchantName);
    setDetailModalOpen(true);
  };

  // 세부내역 클릭 핸들러
  const handleDetailsClick = () => {
    setDetailsModalOpen(true);
  };

  // 필터링된 데이터 계산
  const filteredData = useMemo(() => {
    if (!searchConditions) return dummyData;

    return dummyData.filter(row => {
      // 날짜 필터
      if (searchConditions.startDate && searchConditions.endDate) {
        const transactionDate = dayjs(row.transactionDate);
        const startDate = dayjs(searchConditions.startDate);
        const endDate = dayjs(searchConditions.endDate).endOf('day');
        if (transactionDate.isBefore(startDate) || transactionDate.isAfter(endDate)) {
          return false;
        }
      }

      // 텍스트 필드 검색어 처리 (MID, TID, 가맹점명)
      if (searchConditions.searchText) {
        const searchText = searchConditions.searchText.toLowerCase();
        const isMatch = 
          row.merchantTID?.toLowerCase().includes(searchText) ||
          row.merchantMID?.toLowerCase().includes(searchText) ||
          row.merchantName?.toLowerCase().includes(searchText);
        if (!isMatch) return false;
      }

      // 토글 옵션 처리
      if (searchConditions.selectedToggleOptions?.length > 0) {
        for (const option of searchConditions.selectedToggleOptions) {
          switch (option.parentOption) {
            case 'pgCompany':
              if (row.pg?.toLowerCase() !== option.label.toLowerCase()) return false;
              break;
            case 'walletType':
              if (row.walletStatus?.toLowerCase() !== option.label.toLowerCase()) return false;
              break;
            case 'settlementCycle':
              if (row.settlementCycle?.toLowerCase() !== option.label.toLowerCase()) return false;
              break;
            // 필요한 다른 토글 옵션들 처리
          }
        }
      }

      // 기타 옵션들 처리
      if (searchConditions.sourceType && 
          row.sourceType?.toLowerCase() !== searchConditions.sourceType.toLowerCase()) {
        return false;
      }

      if (searchConditions.businessType && 
          row.businessType?.toLowerCase() !== searchConditions.businessType.toLowerCase()) {
        return false;
      }

      // PG사 필터 - 대소문자 구분 없이 비교
      if (searchConditions.pgCompany && 
          row.pg.toLowerCase() !== searchConditions.pgCompany.toLowerCase()) {
        return false;
      }

      // 정산가능여부 필터
      if (searchConditions.settlementPossible && 
          row.settlementPossible.toLowerCase() !== searchConditions.settlementPossible.toLowerCase()) {
        return false;
      }

      // 가맹점 TID 필터 - 부분 일치로 변경
      if (searchConditions.merchantTID && 
          !row.merchantTID.toLowerCase().includes(searchConditions.merchantTID)) {
        return false;
      }

      // 가맹점 MID 필터 - 부분 일치로 변경
      if (searchConditions.merchantMID && 
          !row.merchantMID.toLowerCase().includes(searchConditions.merchantMID)) {
        return false;
      }

      // 가맹점명 필터 - 부분 일치로 변경
      if (searchConditions.merchantName && 
          !row.merchantName.toLowerCase().includes(searchConditions.merchantName)) {
        return false;
      }

      // 정산주기 필터
      if (searchConditions.settlementCycle && 
          row.settlementCycle.toLowerCase() !== searchConditions.settlementCycle.toLowerCase()) {
        return false;
      }

      // RM건수 필터
      if (searchConditions.rmCount) {
        const count = parseInt(row.rmCount);
        if (searchConditions.rmCount === '0' && count !== 0) return false;
        if (searchConditions.rmCount === '1이상' && count === 0) return false;
      }

      // 미수금 필터
      if (searchConditions.unpaidAmount) {
        const amount = parseInt(row.unpaidAmount);
        if (searchConditions.unpaidAmount === '0' && amount !== 0) return false;
        if (searchConditions.unpaidAmount === '1이상' && amount === 0) return false;
      }

      // 보증금율 필터
      if (searchConditions.retentionRate && 
          row.retentionRate.toString() !== searchConditions.retentionRate) {
        return false;
      }

      // 월렛 상태 필터 - 값 매핑 수정
      if (searchConditions.walletType) {
        const walletTypeMap = {
          '사용': '사용중',
          '미사용': '미사용'
        };
        if (row.walletStatus !== walletTypeMap[searchConditions.walletType]) {
          return false;
        }
      }

      return true;
    });
  }, [searchConditions, dummyData]);

  // NO를 동적으로 계산하여 데이터 매핑
  const processedData = useMemo(() => {
    return filteredData.map((row, index) => ({
      ...row,
      no: index + 1  // 동적으로 NO 할당
    }));
  }, [filteredData]);

  // 메인 코드
  return (
    <>
      <Box mb={2}>
        <Tooltip title="보류취소">
          <IconButton 
            sx={{ 
              backgroundColor: '#dc3545',
              color: '#fff',
              '&:hover': { backgroundColor: '#c82333' },
              borderRadius: '4px',
              padding: '8px'
            }}
            onClick={() => console.log('정산보류 클릭')}
          >
            <CancelIcon />
            <Typography variant="caption" sx={{ marginLeft: 1 }}>
              보류취소
            </Typography>
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer component={StyledTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  align="center"
                  style={{ 
                    backgroundColor: header.color,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {processedData.map((row) => (
              <StyledTableRow key={row.merchantTID}>
                <StyledTableCell align="center">
                  <Checkbox 
                    checked={selectedRows.includes(row.no)}
                    onChange={(e) => handleCheckboxChange(e.target.checked, row.no)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{row.no}</StyledTableCell>
                <StyledTableCell align="center">{row.pg}</StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="사용자 정보">
                    <IconButton 
                      size="small"
                      onClick={() => handleAccountClick(row.merchantName)}
                      sx={IconButtonStyle}
                    >
                      <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="결제 상세보기">
                    <IconButton
                      size="small" 
                      onClick={() => handleDetailClick(row.merchantName)}
                      sx={IconButtonStyle}
                    >
                      <CreditCardIcon sx={{ fontSize: 20, color: '#2185d0' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="center">{row.walletStatus}</StyledTableCell>
                <StyledTableCell align="center">{row.settlementPossible}</StyledTableCell>
                <StyledTableCell align="center">{row.merchantTID}</StyledTableCell>
                <StyledTableCell align="center">{row.merchantMID}</StyledTableCell>
                <StyledTableCell align="center">{row.merchantName}</StyledTableCell>
                <StyledTableCell align="center">{row.transactionDate}</StyledTableCell>
                <StyledTableCell align="center">{row.settlementCycle}</StyledTableCell>
                <StyledTableCell align="center">{row.expectedSettlementDate}</StyledTableCell>
                <StyledTableCell align="center">{row.approvalCount}</StyledTableCell>
                <StyledTableCell align="center">{row.cancelCount}</StyledTableCell>
                <StyledTableCell align="right">{row.approvalAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.cancelAmount}</StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="세부내역 보기">
                    <IconButton
                      size="small"
                      onClick={handleDetailsClick}
                      sx={IconButtonStyle}
                    >
                      <ArticleIcon sx={{ fontSize: 20, color: '#6435c9' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="right">{row.detailCount}</StyledTableCell>
                <StyledTableCell align="right">{row.actualSalesAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.settlementAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.merchantCommissionRate}</StyledTableCell>
                <StyledTableCell align="right">{row.commission}</StyledTableCell>
                <StyledTableCell align="center">{row.rmCount}</StyledTableCell>
                <StyledTableCell align="right">{row.unpaidAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.retentionRate}</StyledTableCell>
                <StyledTableCell align="right">{row.paymentRate}</StyledTableCell>
                <StyledTableCell align="right">{row.depositRate}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 1,
          width: '400px',
          maxWidth: '90vw'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            사용자 정보
          </Typography>
          {selectedUserData && (
            <>
              <Typography>고객명: {selectedUserData.customerName}</Typography>
              <Typography>휴대폰 번호: {selectedUserData.phoneNumber}</Typography>
              <Typography>승인금액: {selectedUserData.approvalAmount}</Typography>
              <Typography>승인번호: {selectedUserData.approvalNo}</Typography>
              <Typography>결제일자: {selectedUserData.paymentDate}</Typography>
              <Typography>취소일자: {selectedUserData.cancelDate || '-'}</Typography>
              <Typography>첨부파일: {selectedUserData.hasAttachments ? '있음' : '없음'}</Typography>
            </>
          )}
        </Box>
      </Modal>

      <UserInfoOne
        open={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        merchantName={selectedMerchant}
      />

      <UserInfoTwo 
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        businessName={selectedMerchant}
      />

      <DetailInfo
        open={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
      />
    </>
  );
}

export default SettlementHoldDetailsTables;
