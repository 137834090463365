import React, { useState, useCallback, useEffect } from 'react';
import { 
  Box, 
  Paper,
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  useTheme,
  useMediaQuery,
  Button
} from '@mui/material';
import Notice from '../../Notice/Notice';
import NonMerchantTable from './NonMerchantTable';
import Breadcrumb from '../../Common/Breadcrumb';
import QuickMenu from '../../Common/QuickMenu';
import CurrentLocation from '../../Common/CurrentLocation';
import SearchIcon from '@mui/icons-material/Search';
import DateRangePicker from '../../Common/DateRangePicker';

// 미실적 가맹점관리 메인 함수
function NonMerchantManagement() {
  // 테마 및 미디어 쿼리 설정
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // 검색 상태값 정의
  const [searchText, setSearchText] = useState('');
  const [walletStatus, setWalletStatus] = useState('all');
  const [startDate, setStartDate] = useState('20250201');
  const [endDate, setEndDate] = useState('20250228');
  const [searchConditions, setSearchConditions] = useState(null);

  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  
      mr: '40px',  
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    searchBox: {
      width: '100%',
      mb: 2
    }
  };

  // 검색 처리 함수
  const handleSearch = () => {
    setSearchConditions({
      searchText,
      walletStatus,
      startDate,
      endDate
    });
  };

  // 날짜 포맷 변환 함수는 테이블 컴포넌트로 이동

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="미실적 가맹점관리" />
        
        <Box sx={styles.searchBox}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <DateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onStartDateChange={setStartDate}
                      onEndDateChange={setEndDate}
                      isSearchSection={true}
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <TextField
                      size="small"
                      placeholder="아이디, MID, 가맹점명"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        value={walletStatus}
                        onChange={(e) => setWalletStatus(e.target.value)}
                      >
                        <FormControlLabel value="all" control={<Radio />} label="전체" />
                        <FormControlLabel value="사용중" control={<Radio />} label="사용중" />
                        <FormControlLabel value="조회용" control={<Radio />} label="조회용" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} md={1}>
                    <Button
                      variant="contained"
                      onClick={handleSearch}
                      startIcon={<SearchIcon />}
                      fullWidth
                    >
                      검색
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        <NonMerchantTable searchConditions={searchConditions} />
      </Box>

      <QuickMenu />
    </Box>
  );
}

export default NonMerchantManagement;
