import React from 'react';
import { 
  Box, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Divider
} from '@mui/material';
import styled from '@emotion/styled';

// Details.js의 SummaryBox 스타일 추가
const SummaryBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '32px',
  padding: '12px 0',
  borderRadius: '4px',
  backgroundColor: '#f8f9fa',
  marginBottom: '16px'
});

// 더미 데이터 생성 함수
const generateDummyData = () => {
  return Array(5).fill(null).map((_, index) => ({
    no: index + 1,
    가맹점MID: `M2140381`,
    가맹점TID: `m20230926`,
    가맹점명: `1광역시 테스트`,
    거래일시: '2024-12-17 20:37:47',
    취소여부: '취소',
    승인번호: '30098500',
    발급사: '국민카드',
    승인금액: 0,
    취소금액: -1004,
    실매출금액: -1004,
    가맹점수수료율: '4000/8.8%',
    수수료: -88.35,
    보증금율: 0,
    보증금액: 0,
    입금액: -915
  }));
};

// 메인 코드
const CancelPopup = () => {
  const dummyData = generateDummyData();
  
  // 합계 계산
  const totalApproved = dummyData.reduce((sum, item) => sum + item.승인금액, 0);
  const totalCanceled = dummyData.reduce((sum, item) => sum + item.취소금액, 0);
  const total = totalApproved - totalCanceled;

  // 메인 코드
  return (
    <Box sx={{
      width: '100%',
      height: '100vh',
      bgcolor: 'background.paper',
      p: 2
    }}>
      {/* 합계 표시 박스 추가 */}
      <SummaryBox>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">승인금액</Typography>
          <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
            {totalApproved.toLocaleString()}원
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">취소금액</Typography>
          <Typography variant="h6" color="error" sx={{ fontWeight: 600 }}>
            {totalCanceled.toLocaleString()}원
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">합계</Typography>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {total.toLocaleString()}원
          </Typography>
        </Box>
      </SummaryBox>
      {/* 테이블 컨테이너 */}
      <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 150px)' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>가맹점MID</TableCell>
              <TableCell>가맹점TID</TableCell>
              <TableCell>가맹점명</TableCell>
              <TableCell>거래일시</TableCell>
              <TableCell>취소여부</TableCell>
              <TableCell>승인번호</TableCell>
              <TableCell>발급사</TableCell>
              <TableCell align="right">승인금액(원)</TableCell>
              <TableCell align="right">취소금액(원)</TableCell>
              <TableCell align="right">실매출금액(원)</TableCell>
              <TableCell align="right">가맹점수수료율</TableCell>
              <TableCell align="right">수수료(VAT포함)</TableCell>
              <TableCell align="right">보증금율(%)</TableCell>
              <TableCell align="right">보증금액</TableCell>
              <TableCell align="right">입금액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.no}</TableCell>
                <TableCell>{row.가맹점MID}</TableCell>
                <TableCell>{row.가맹점TID}</TableCell>
                <TableCell>{row.가맹점명}</TableCell>
                <TableCell>{row.거래일시}</TableCell>
                <TableCell>{row.취소여부}</TableCell>
                <TableCell>{row.승인번호}</TableCell>
                <TableCell>{row.발급사}</TableCell>
                <TableCell align="right">{row.승인금액}</TableCell>
                <TableCell align="right">{row.취소금액}</TableCell>
                <TableCell align="right">{row.실매출금액}</TableCell>
                <TableCell align="right">{row.가맹점수수료율}</TableCell>
                <TableCell align="right">{row.수수료}</TableCell>
                <TableCell align="right">{row.보증금율}</TableCell>
                <TableCell align="right">{row.보증금액}</TableCell>
                <TableCell align="right">{row.입금액}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

// 팝업을 열기 위한 함수
export const openCancelPopup = () => {
  const features = Object.entries({
    width: 1800,
    height: 800,
    left: (window.screen.width - 1800) / 2,
    top: (window.screen.height - 800) / 2,
    scrollbars: 'yes',
    resizable: 'yes'
  })
    .map(([key, value]) => `${key}=${value}`)
    .join(',');

  window.open(`${window.location.origin}/cancel-popup`, '기간내 취소', features);
};

export default CancelPopup;
