import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';

const UserManual = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      // 이미지 미리보기 URL 생성
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  // 메인 코드
  return (
    <Box sx={{ mt: 4, p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <ArticleIcon sx={{ color: '#1976d2' }} />
        <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
          유저 메뉴얼
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          variant="contained"
          component="label"
          sx={{
            backgroundColor: '#4a90e2',
            '&:hover': {
              backgroundColor: '#357abd'
            },
            boxShadow: '0 2px 4px rgba(74, 144, 226, 0.3)',
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          파일 선택
          <input 
            type="file" 
            hidden 
            onChange={handleFileSelect}
            accept="image/*"
          />
        </Button>
        <Typography variant="body1" sx={{ color: '#252631' }}>
          {selectedFile ? selectedFile.name : '선택된 파일 없음'}
        </Typography>
      </Box>
      
      {/* 이미지 미리보기 */}
      {previewUrl && (
        <Box sx={{ mt: 2, maxWidth: '500px' }}>
          <img 
            src={previewUrl} 
            alt="Preview" 
            style={{ 
              width: '100%', 
              height: 'auto',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }} 
          />
        </Box>
      )}

      <Button
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: '#4a90e2',
          '&:hover': {
            backgroundColor: '#357abd'
          },
          boxShadow: '0 2px 4px rgba(74, 144, 226, 0.3)',
          textTransform: 'none',
          fontWeight: 500
        }}
      >
        매뉴얼 등록
      </Button>
    </Box>
  );
};

export default UserManual;
