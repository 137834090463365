import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Button, Card, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, 
  IconButton, Tooltip, Modal, Paper, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// 카드 스타일
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

// 테이블 컨테이너 스타일
const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 380px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

// 테이블 로우 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

// 헤더 박스 스타일
const HeaderBox = styled(Box)({
  padding: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

// 초기값 세팅 + 더미데이터
const UserFAQ = () => {
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [faqs, setFaqs] = React.useState([
    {
      action: '변경',
      group: '결제사이트',
      public: 1,
      question: '기업 결제가 어떻게 되나요?',
      answer: '전자계약으로 방문하시면 기업결제 서비스를 이용하실 수 있습니다. 가입시 필요한 서류는 1) 사업자등록증 사본 1장 2) 업금 입증서 서류 1장 3) 법인: 법인등기부등본(현재) 1장이 필요합니다.',
      date: '2024-10-18'
    },
    {
      action: '추가',
      group: '메인사이트',
      public: 1,
      question: '보증보험 가입은 어떻게 해야하나요?',
      answer: '보증보험 가입은 서울보증보험에서 하시면 됩니다. 단, 업체에 따라 보험료가 다를 수 있습니다.',
      date: '2024-10-18'
    },
    {
      action: '변경',
      group: '결제사이트',
      public: 0,
      question: '정산은 언제 되나요?',
      answer: '정산은 D+5일 기준으로 진행됩니다. 공휴일이나 주말인 경우 다음 영업일에 처리됩니다.',
      date: '2024-10-18'
    }
  ]);

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editFaq, setEditFaq] = React.useState(null);
  const [editAnswer, setEditAnswer] = React.useState('');

  // FAQ 추가 모달 상태 관리
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newFaq, setNewFaq] = useState({
    group: '메인사이트',
    public: 0,
    question: '',
    answer: '',
    date: new Date().toISOString().split('T')[0]
  });

  // 등록시간 정렬 핸들러
  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedFaqs = [...faqs].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setFaqs(sortedFaqs);
  };

  // 수정 모달 핸들러
  const handleEditClick = (faq) => {
    setEditFaq(faq);
    setEditAnswer(faq.answer);
    setEditModalOpen(true);
  };

  // 수정 모달 닫기 핸들러
  const handleEditClose = () => {
    setEditModalOpen(false);
    setEditFaq(null);
    setEditAnswer('');
  };

  // 수정 모달 적용 핸들러
  const handleEditSubmit = () => {
    console.log('수정된 내용:', {
      ...editFaq,
      answer: editAnswer
    });
    handleEditClose();
  };

  // 모달 핸들러
  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setNewFaq({
      group: '메인사이트',
      public: 0,
      question: '',
      answer: '',
      date: new Date().toISOString().split('T')[0]
    });
  };

  const handleAddSubmit = () => {
    console.log('새로운 FAQ:', newFaq);
    handleAddClose();
  };

  // 메인 코드
  return (
    <>
      <StyledCard>
        <HeaderBox>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <QuestionAnswerIcon sx={{ color: '#1976d2' }} />
            <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
              유저 FAQ
            </Typography>
          </Box>
          <Button 
            variant="contained" 
            startIcon={<AddIcon />}
            onClick={handleAddClick}
            sx={{ 
              backgroundColor: '#4a90e2',
              '&:hover': {
                backgroundColor: '#357abd'
              },
              boxShadow: '0 2px 4px rgba(74, 144, 226, 0.3)',
              textTransform: 'none',
              fontWeight: 500
            }}
          >
            FAQ 추가
          </Button>
        </HeaderBox>
        <StyledTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>그룹</StyledTableCell>
                <StyledTableCell>공개/비공개</StyledTableCell>
                <StyledTableCell>제목</StyledTableCell>
                <StyledTableCell>답변</StyledTableCell>
                <StyledTableCell 
                  onClick={handleSortClick}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eaeaea'
                    }
                  }}
                >
                  등록시간 {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                </StyledTableCell>
                <StyledTableCell>작업</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faqs.map((faq, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{faq.group}</StyledTableCell>
                  <StyledTableCell>{faq.public ? '공개' : '비공개'}</StyledTableCell>
                  <StyledTableCell>{faq.question}</StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {faq.answer}
                  </StyledTableCell>
                  <StyledTableCell>{faq.date}</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                      <Tooltip title="수정">
                        <IconButton 
                          size="small" 
                          sx={{ color: '#fbc658' }}
                          onClick={() => handleEditClick(faq)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="삭제">
                        <IconButton size="small" sx={{ color: '#ef8157' }}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </StyledCard>

      {/* 수정 모달 */}
      <Modal
        open={editModalOpen}
        onClose={handleEditClose}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          pt: '15vh'
        }}
      >
        <Paper sx={{ 
          width: '600px',
          p: 3,
          border: '1px solid #ccc',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          borderRadius: '4px',
          bgcolor: '#f5f5f5'
        }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            FAQ 수정
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={editAnswer}
            onChange={(e) => setEditAnswer(e.target.value)}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: 1
          }}>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              sx={{ width: '80px' }}
            >
              확인
            </Button>
            <Button
              onClick={handleEditClose}
              variant="outlined"
              sx={{ width: '80px' }}
            >
              취소
            </Button>
          </Box>
        </Paper>
      </Modal>

      {/* FAQ 추가 모달 */}
      <Dialog 
        open={addModalOpen} 
        onClose={handleAddClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: '800px',
            minHeight: '550px'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'
        }}>
          FAQ 추가
        </DialogTitle>
        <DialogContent sx={{ 
          mt: 1,
          p: 3,
          '& .MuiFormControl-root': {
            minHeight: '65px'
          }
        }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ 
                  backgroundColor: 'white', 
                  px: 1,
                  mt: -1
                }}>그룹</InputLabel>
                <Select
                  value={newFaq.group}
                  onChange={(e) => setNewFaq({...newFaq, group: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value="메인사이트">메인사이트</MenuItem>
                  <MenuItem value="결제사이트">결제사이트</MenuItem>
                  <MenuItem value="관리자사이트">관리자사이트</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ 
                  backgroundColor: 'white', 
                  px: 1,
                  mt: -1
                }}>공개여부</InputLabel>
                <Select
                  value={newFaq.public}
                  onChange={(e) => setNewFaq({...newFaq, public: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value={0}>비공개</MenuItem>
                  <MenuItem value={1}>공개</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="제목"
                value={newFaq.question}
                onChange={(e) => setNewFaq({...newFaq, question: e.target.value})}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                type="date"
                label="일자"
                value={newFaq.date}
                onChange={(e) => setNewFaq({...newFaq, date: e.target.value})}
                InputLabelProps={{ 
                  shrink: true,
                  sx: { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  }
                }}
                sx={{ '& .MuiInputBase-root': { height: '40px' } }}
              />
            </Grid>
            <Grid item xs={12}>  {/* 답변은 전체 너비 사용 */}
              <TextField
                fullWidth
                multiline
                minRows={5}
                maxRows={10}
                label="답변"
                value={newFaq.answer}
                onChange={(e) => setNewFaq({...newFaq, answer: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  },
                  '& .MuiInputBase-root': {
                    height: 'auto',
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleAddClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            닫기
          </Button>
          <Button 
            onClick={handleAddSubmit}
            variant="contained" 
            color="primary"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
          <Button 
            variant="contained" 
            color="error"
            sx={{ minWidth: '80px', height: '36px' }}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserFAQ;
