import React from 'react';
import { Box, Typography, Divider, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const AdminStatusPanel = ({ categoryData, onMenuClick, isPanelOpen, onTogglePanel }) => {
  return (
    <>
      {/* 패널 토글 버튼 - 패널 바깥에 위치 */}
      <IconButton 
        onClick={onTogglePanel}
        sx={{
          position: 'fixed',
          right: isPanelOpen ? '280px' : '0', // 패널 열림 여부에 따라 버튼 위치 조정
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: '#fff',
          boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
          width: '20px',
          height: '40px',
          borderRadius: '4px 0 0 4px',
          zIndex: 1200, // 패널보다 높게 설정
          '&:hover': {
            backgroundColor: '#f5f5f5',
          }
        }}
      >
        <ChevronRightIcon
          sx={{ 
            transform: isPanelOpen ? 'rotate(0deg)' : 'rotate(180deg)', 
            fontSize: '20px' 
          }} 
        />
      </IconButton>

      {/* 패널 본체 */}
      <Box
        sx={{
          position: 'fixed',
          right: isPanelOpen ? '20px' : '-300px',
          top: '80px',
          width: '260px',
          height: 'calc(100vh - 100px)',
          backgroundColor: '#f5f5f5',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          borderRadius: '12px',
          p: 2,
          overflowY: 'auto',
          transition: 'right 0.3s ease',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          // // fontFamilly: "'Pretendard', 'Noto Sans KR', -apple-system, sans-serif",
          zIndex: 1100, // 패널의 z-index를 낮게 설정
        }}
      >
        {/* 기존 패널 내용 */}
        <Typography variant="h6" sx={{ mb: 1.5, fontWeight: 600, fontSize: '1.1rem' }}>
          관리자 메뉴
        </Typography>

        {categoryData.map((category, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 0.5,
                fontWeight: 600,
                color: category.color,
                fontSize: '0.95rem'
              }}
            >
              {category.title}
            </Typography>
            <Divider sx={{ mb: 0.5 }} />
            {category.items.map((item, itemIndex) => (
              <Box 
                key={itemIndex}
                onClick={item.onClick}
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  py: 0.3,
                  cursor: 'pointer',
                  '&:hover': {
                    color: category.color,
                    backgroundColor: 'rgba(0,0,0,0.04)',
                  }
                }}
              >
                {React.cloneElement(item.icon, { 
                  sx: { fontSize: 18, color: category.color }
                })}
                <Typography variant="body2" sx={{ fontSize: '0.85rem', color: '#212121' }}>
                  {item.title}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default AdminStatusPanel;
