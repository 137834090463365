import React, { useState, useEffect } from 'react';
import { 
  Box,
  IconButton,
  Tooltip,
  Drawer
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import QuickMenu from '../../Common/QuickMenu';
import StatisticsGiganSearch from '../../Common/StatisticsGiganSearch';
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import IlbyeolData from './bunseokGiganSection/ilbyeolData';
import PgData from './bunseokGiganSection/PgData';
import GamengData from './bunseokGiganSection/GamengData';
import InJeungData from './bunseokGiganSection/InJeungData';
import IlbyeolgwaseData from './bunseokGiganSection/ilbyeolgwaseData';
import IlsaeobjaData from './bunseokGiganSection/ilsaeobjaData';
import dayjs from 'dayjs';

// 메인 함수
function BunseokGigan() {
  // 상태값 관리
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [searchDates, setSearchDates] = useState({
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD')
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  // 모바일 감지 및 검색 패널 열기
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
    setSearchDates({
      startDate: searchParams.startDate,
      endDate: searchParams.endDate
    });
  };

  // 메인 박스 스타일
  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: 2,
    transition: 'padding 0.2s',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '10px'
    },
    ml: {
      xs: '10px',
      md: '50px'
    },
    '@media (max-width: 960px)': {
      ml: '10px',
      pr: '10px'
    }
  };

  // 메인 코드
  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 공지사항 */}
      <Notice />

      {/* 현재 위치 */}
      <CurrentLocation mainTitle="통계" subTitle="기간별 분석" />
      
      <Box sx={{ mb: '20px' }} />
      
      {/* 메인 컨텐츠 영역 */}
      <Box>
        {/* 데이터 컴포넌트들. 섹션별 분리하여 날짜 데이터 전달 */}
        <IlbyeolData searchDates={searchDates} />
        <PgData searchDates={searchDates} />
        <br />
        <GamengData searchDates={searchDates} />
        <br />
        <InJeungData searchDates={searchDates} />
        <br />
        <IlbyeolgwaseData searchDates={searchDates} />
        <br />
        <IlsaeobjaData searchDates={searchDates} />
      </Box>

      {/* 검색 패널 */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: 300,
            mt: '64px',
            pt: 2,
            px: 2,
            overflow: 'visible',
            '& > *': {  // Drawer 내부의 모든 요소에 대해
              position: 'relative',  // 상대 위치 설정
              zIndex: 'auto'  // z-index 자동 설정
            }
          },
          '& .MuiBackdrop-root': {  // 백드롭에 대한 스타일
            mt: '64px'
          }
        }}
        PaperProps={{
          sx: {
            height: 'calc(100% - 64px)',  // 상단 여백 고려
            overflowY: 'visible'  // 세로 스크롤도 visible로 설정
          }
        }}
      >
        <StatisticsGiganSearch 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
        />
      </Drawer>

      {/* 검색 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <Tooltip title="검색 패널 열기" placement="left">
          <IconButton
            onClick={() => setSearchPanelOpen(true)}
            sx={{
              position: 'fixed',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#fff',
              borderRadius: '12px 0 0 12px',
              boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: '#f5f5f5'
              },
              zIndex: 900
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
      )}

      {/* 퀵 메뉴 */}
      <QuickMenu />
    </Box>
  );
}

export default BunseokGigan;
