import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Collapse,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper,
  Select,
  Button,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// 스타일 컴포넌트들
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  '& .MuiGrid-root': {
    gap: '8px'
  }
}));

// 선택 컴포넌트 스타일
const StyledSelect = styled(Select)({
  minWidth: '200px',
  height: '40px',
  '& .MuiSelect-select': {
    padding: '6px 12px',
  }
});
// 섹션 타이틀 스타일
const SectionTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 600,
  color: '#1976d2',
  marginBottom: '16px'
});

const AgentLabel = styled(Typography)({
  fontSize: '0.875rem',
  color: '#666',
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  '& .required': {
    color: '#f44336',
    marginLeft: '2px'
  }
});

// 필드 라벨 스타일
const FieldLabel = styled(Typography)({
  fontSize: '0.875rem',
  color: '#666',
  marginBottom: '4px'
});

// 액션 버튼 스타일
const ActionButton = styled(Button)({
  height: '40px',
  fontSize: '0.875rem',
  padding: '0 12px',
  minWidth: 'auto',
  whiteSpace: 'nowrap'
});

// 폼 컨트롤 스타일
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '12px',
  width: 'auto',
  '& .MuiFormLabel-root': {
    minWidth: '120px',
    color: '#34495e',
    fontWeight: '500',
    fontSize: '0.9rem',
    transform: 'none',
    position: 'static',
    '&.Mui-focused': {
      color: '#3498db'
    }
  },
  '& .MuiInputBase-root': {
    width: '300px',
    transition: 'all 0.3s ease',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#f8f9fa'
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& fieldset': {
        borderWidth: '2px',
        borderColor: '#3498db'
      }
    }
  },

  // 모바일에서만 적용되는 스타일
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    
    '& .MuiFormLabel-root': {
      minWidth: 'auto',
      marginBottom: '4px'
    },
    
    '& .MuiInputBase-root': {
      width: '100%'
    }
  }
}));

// 주소 입력용 특별 FormControl
const AddressFormControl = styled(StyledFormControl)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: 'auto'
  },
  
  // 모바일에서만 적용되는 스타일
  [theme.breakpoints.down('sm')]: {
    '& .MuiBox-root': {
      width: '100%',
      flexDirection: 'row',
      gap: '8px',
      
      '& .MuiTextField-root': {
        flex: 1
      },
      
      '& .MuiButton-root': {
        minWidth: '80px'
      }
    }
  }
}));

// 버튼 스타일
const StyledButton = styled(Button)({
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 2px 8px rgba(52, 152, 219, 0.3)'
  }
});

// 상태값, 옵션값 세팅
function Gamengipryeok() {
  const [isExpanded, setIsExpanded] = useState(true);
  const [selections, setSelections] = useState({
    master: '',
    branch: '',
    distributor: '',
    agency: '',
    retail: '',
    sales1: '',
    sales2: '',
    sales3: '',
    empty: ''
  });

  // 선택 옵션들
  const options = {
    master: ['마스터1', '마스터2', '마스터3'],
    branch: ['지사1', '지사2', '지사3'],
    distributor: ['총판1', '총판2', '총판3'],
    agency: ['대리점1', '대리점2', '대리점3'],
    retail: ['리테일1', '리테일2', '리테일3'],
    sales1: ['영업1-1', '영업1-2', '영업1-3'],
    sales2: ['영업2-1', '영업2-2', '영업2-3'],
    sales3: ['영업3-1', '영업3-2', '영업3-3']
  };

  // 선택 옵션 변경 함수
  const handleChange = (field) => (event) => {
    setSelections(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  // 우편번호 검색 함수 추가
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handlePostcode = () => {
    new window.daum.Postcode({
      oncomplete: function(data) {
        document.querySelector('input[name="postcode"]').value = data.zonecode;
        document.querySelector('input[name="address"]').value = data.address;
        document.querySelector('input[name="detailAddress"]').focus();
      }
    }).open();
  };

  // 메인 코드
  return (
    <StyledPaper>
      <SectionTitle>
        가맹점 정보 입력하기
      </SectionTitle>

      
        
      {/* 사업자/개인 등 필드들 */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* 사업자/개인 */}
        <FormControl sx={{ width: '300px', mb: 0.75 }}>
          <FieldLabel>
            사업자/개인
            <span className="required">*</span>
          </FieldLabel>
          <StyledSelect size="small" defaultValue="">
            <MenuItem value="">사업자</MenuItem>
          </StyledSelect>
        </FormControl>

        {/* 과세 */}
        <FormControl sx={{ width: '300px', mb: 0.75 }}>
          <FieldLabel>
            과세
            <span className="required">*</span>
          </FieldLabel>
          <StyledSelect size="small" defaultValue="">
            <MenuItem value="">과세</MenuItem>
          </StyledSelect>
        </FormControl>

        {/* 원천 */}
        <FormControl sx={{ width: '300px', mb: 0.75 }}>
          <FieldLabel>
            원천
            <span className="required">*</span>
          </FieldLabel>
          <StyledSelect size="small" defaultValue="">
            <MenuItem value="">비원천</MenuItem>
          </StyledSelect>
        </FormControl>

        {/* 치환적용 */}
        <Box sx={{ mb: 2 }}>
          <FieldLabel>
            치환적용
            <span className="required">*</span>
          </FieldLabel>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              size="small"
              sx={{ width: '300px' }}
              placeholder="영업계정 아이디"
            />
            <ActionButton
              variant="contained"
              sx={{ bgcolor: '#00bcd4', height: '40px' }}
            >
              적용
            </ActionButton>
          </Box>
        </Box>

        {/* 체크박스들 */}
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={<Checkbox checked />}
            label="과세계산서/전산매체 발행"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="영수증 처리정보 발행"
          />
        </Box>
      </Box>

      {/* Gibon.js에서 가져온 필드들 추가 */}
      <StyledFormControl>
        <InputLabel>발행품목</InputLabel>
        <Select
          size="small"
          defaultValue="솔루션이용료"
        >
          <MenuItem value="슬립영수증">솔루션이용료</MenuItem>
        </Select>
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>가맹점명*</InputLabel>
        <TextField
          size="small"
          placeholder="가맹점 입력시 12자이내로 권장 드립니다."
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '0.875rem',
            },
            '& .MuiInputBase-input::placeholder': {
              fontSize: '0.75rem',
              opacity: 0.8
            }
          }}
        />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>대표자명*</InputLabel>
        <TextField size="small" />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>사업자등록번호*</InputLabel>
        <TextField size="small" sx={{ flex: 1 }} />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>주민번호</InputLabel>
        <TextField size="small" sx={{ flex: 1 }} />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>업종*</InputLabel>
        <TextField size="small" sx={{ flex: 1 }} />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>품목*</InputLabel>
        <TextField size="small" sx={{ flex: 1 }} />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>연락처</InputLabel>
        <TextField size="small" sx={{ flex: 1 }} />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>핸드폰번호</InputLabel>
        <TextField size="small" sx={{ flex: 1 }} />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>이메일*</InputLabel>
        <TextField
          size="small"
          placeholder="example@naver.com"
          sx={{ flex: 1 }}
        />
      </StyledFormControl>

      <AddressFormControl>
        <InputLabel>주소</InputLabel>
        <Box sx={{ 
          display: 'flex', 
          gap: 1, 
          width: '300px'
        }}>
          <TextField 
            size="small"
            name="postcode"
            sx={{ width: '180px' }}
            placeholder="우편번호"
            inputProps={{ readOnly: true }}
          />
          <StyledButton 
            variant="contained" 
            size="small"
            onClick={handlePostcode}
            sx={{
              width: '110px',
              backgroundColor: '#3498db',
              '&:hover': {
                backgroundColor: '#2980b9'
              }
            }}
          >
            우편번호
          </StyledButton>
        </Box>
      </AddressFormControl>

      <StyledFormControl>
        <InputLabel></InputLabel>
        <TextField
          size="small"
          name="address"
          placeholder="주소"
          inputProps={{ readOnly: true }}
        />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel></InputLabel>
        <TextField
          size="small"
          name="detailAddress"
          placeholder="상세주소"
        />
      </StyledFormControl>

      <StyledFormControl>
        <InputLabel>시리얼넘버</InputLabel>
        <TextField
          size="small"
          sx={{ flex: 1 }}
        />
      </StyledFormControl>
    </StyledPaper>
  );
}

export default Gamengipryeok;
