import React, { useMemo } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Box } from '@mui/material';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

// 스타일드 컴포넌트 정의
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '&.amount': {
    color: '#2185d0',
    textAlign: 'right',
  },
  '&.negative': {
    color: '#db2828',
    textAlign: 'right',
  },
  '&.total': {
    color: '#21ba45',
    textAlign: 'right',
    fontWeight: 'bold',
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 컨테이너 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #dee2e6',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    width: '100%',
    maxWidth: '1800px',
    padding: '0px',
    '@media (max-width: 1025px)': {
      maxHeight: 'calc(100vh - 250px)',
      '& table': {
        minWidth: '1200px'
      }
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 1025px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  },
  blueSection: {
    backgroundColor: '#EBF3FF !important',
  },
  skySection: {
    backgroundColor: '#E3FAFC !important',
  }
};

// 메인 함수
const ScSummaryTable = ({ startDate, endDate }) => {
  // 더미 데이터
  const dummyData = [
    {
      transactionDate: '2025-02-06 00:00:00 ~ 2025-02-07 00:00:00',
      creator: 'admin',
      approvalAmount: 36082940,
      cancelAmount: -2703500,
      totalAmount: 33379440,
      offsetAmount: 33379440,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 270,
      settlementAmount: 2091667,
      commission: 4553,
      depositAmount: 225333,
      executionAmount: 2223357,
      completionDate: '2025-02-06 14:48:51',
      reportCreatorId: 'admin'
    },
    {
      transactionDate: '2025-02-05 00:00:00 ~ 2025-02-06 00:00:00',
      creator: 'system',
      approvalAmount: 28456700,
      cancelAmount: -1856400,
      totalAmount: 26600300,
      offsetAmount: 26600300,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 185,
      settlementAmount: 1993200,
      commission: 2556,
      depositAmount: 775641,
      executionAmount: 41311,
      completionDate: '2025-02-05 14:40:20',
      reportCreatorId: 'system'
    },
    {
      transactionDate: '2025-02-04 00:00:00 ~ 2025-02-05 00:00:00',
      creator: 'admin',
      approvalAmount: 42157800,
      cancelAmount: -3102900,
      totalAmount: 39054900,
      offsetAmount: 39054900,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 320,
      settlementAmount: 12010000,
      commission: 7888,
      depositAmount: 225333,
      executionAmount: 77010000,
      completionDate: '2025-02-04 15:35:22',
      reportCreatorId: 'admin'
    },
    {
      transactionDate: '2025-02-03 00:00:00 ~ 2025-02-04 00:00:00',
      creator: 'system',
      approvalAmount: 31245600,
      cancelAmount: -2145800,
      totalAmount: 29099800,
      offsetAmount: 29099800,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 245,
      settlementAmount: 513157,
      commission: 4553,
      depositAmount: 775641,
      executionAmount: 2223357,
      completionDate: '2025-02-03 14:22:15',
      reportCreatorId: 'system'
    },
    {
      transactionDate: '2025-02-02 00:00:00 ~ 2025-02-03 00:00:00',
      creator: 'admin',
      approvalAmount: 38567400,
      cancelAmount: -2876500,
      totalAmount: 35690900,
      offsetAmount: 35690900,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 290,
      settlementAmount: 51311,
      commission: 2556,
      depositAmount: 225333,
      executionAmount: 41311,
      completionDate: '2025-02-02 14:15:33',
      reportCreatorId: 'admin'
    },
    {
      transactionDate: '2025-02-01 00:00:00 ~ 2025-02-02 00:00:00',
      creator: 'system',
      approvalAmount: 25678900,
      cancelAmount: -1987600,
      totalAmount: 23691300,
      offsetAmount: 23691300,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 175,
      settlementAmount: 12010000,
      commission: 7888,
      depositAmount: 775641,
      executionAmount: 77010000,
      completionDate: '2025-02-01 14:55:41',
      reportCreatorId: 'system'
    },
    {
      transactionDate: '2025-01-31 00:00:00 ~ 2025-02-01 00:00:00',
      creator: 'admin',
      approvalAmount: 45678900,
      cancelAmount: -3456700,
      totalAmount: 42222200,
      offsetAmount: 42222200,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 350,
      settlementAmount: 513157,
      commission: 4553,
      depositAmount: 225333,
      executionAmount: 2223357,
      completionDate: '2025-01-31 14:30:18',
      reportCreatorId: 'admin'
    },
    {
      transactionDate: '2025-01-30 00:00:00 ~ 2025-01-31 00:00:00',
      creator: 'system',
      approvalAmount: 33456700,
      cancelAmount: -2345600,
      totalAmount: 31111100,
      offsetAmount: 31111100,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 260,
      settlementAmount: 51311,
      commission: 2556,
      depositAmount: 775641,
      executionAmount: 41311,
      completionDate: '2025-01-30 14:42:55',
      reportCreatorId: 'system'
    },
    {
      transactionDate: '2025-01-29 00:00:00 ~ 2025-01-30 00:00:00',
      creator: 'admin',
      approvalAmount: 39876500,
      cancelAmount: -2987600,
      totalAmount: 36888900,
      offsetAmount: 36888900,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 295,
      settlementAmount: 12010000,
      commission: 7888,
      depositAmount: 225333,
      executionAmount: 77010000,
      completionDate: '2025-01-29 14:38:27',
      reportCreatorId: 'admin'
    },
    {
      transactionDate: '2025-01-28 00:00:00 ~ 2025-01-29 00:00:00',
      creator: 'system',
      approvalAmount: 27654300,
      cancelAmount: -1876500,
      totalAmount: 25777800,
      offsetAmount: 25777800,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 195,
      settlementAmount: 513157,
      commission: 4553,
      depositAmount: 775641,
      executionAmount: 2223357,
      completionDate: '2025-01-28 14:25:44',
      reportCreatorId: 'system'
    }
  ];

  // 날짜 필터링된 데이터 계산
  const filteredData = useMemo(() => {
    if (!startDate || !endDate) return dummyData;

    return dummyData.filter(row => {
      const transactionStartDate = row.transactionDate.split(' ~ ')[0];
      const rowDate = dayjs(transactionStartDate);
      const searchStartDate = dayjs(startDate);
      const searchEndDate = dayjs(endDate).endOf('day');

      return rowDate.isBetween(searchStartDate, searchEndDate, 'day', '[]');
    });
  }, [startDate, endDate]);

  // 메인 코드
  return (
    <TableContainer component={StyledTableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell className="primary-header">거래일시</StyledTableCell>
            <StyledTableCell className="primary-header">생성자</StyledTableCell>
            <StyledTableCell className="primary-header">승인금액</StyledTableCell>
            <StyledTableCell className="primary-header">취소금액</StyledTableCell>
            <StyledTableCell className="primary-header">합계</StyledTableCell>
            <StyledTableCell className="primary-header">상계금액</StyledTableCell>
            <StyledTableCell className="primary-header">보류금액</StyledTableCell>
            <StyledTableCell className="primary-header">이전보류반영금액</StyledTableCell>
            <StyledTableCell className="secondary-header">정산대상건수</StyledTableCell>
            <StyledTableCell className="secondary-header">정산반영금액</StyledTableCell>
            <StyledTableCell className="secondary-header">수수료(VAT포함)</StyledTableCell>
            <StyledTableCell className="secondary-header">보증금액</StyledTableCell>
            <StyledTableCell className="secondary-header">정산집행액</StyledTableCell>
            <StyledTableCell className="secondary-header">정산완료일시</StyledTableCell>
            <StyledTableCell className="secondary-header">정산보고생성아이디</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center">{row.transactionDate}</StyledTableCell>
              <StyledTableCell align="center">{row.creator}</StyledTableCell>
              <StyledTableCell align="right">{row.approvalAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right" className="negative">{row.cancelAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right" className="total">{row.totalAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.offsetAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.holdAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.previousHoldAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.settlementCount}</StyledTableCell>
              <StyledTableCell align="right">{row.settlementAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.commission.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.depositAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="right">{row.executionAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell align="center">{row.completionDate}</StyledTableCell>
              <StyledTableCell align="center">{row.reportCreatorId}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScSummaryTable;