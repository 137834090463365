import React, { useState, useEffect, useMemo } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Checkbox, TablePagination, Box, Button, IconButton, Tooltip, Modal, Dialog } from '@mui/material';
import styled from '@emotion/styled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import MaechulGamangOne from '../../Common/StatisticsModal/MaechulGamangOne';
import MaechulGamangTwo from '../../Common/StatisticsModal/MaechulGamangTwo';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.group-white': {
    backgroundColor: '#ffffff',
  },
  '&.group-gray': {
    backgroundColor: '#f5f5f5',
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px',
      
      '&[data-field="amount"]': {
        height: '40px',
        lineHeight: '40px'
      }
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.group-white, &.group-gray': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 워퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#a4c9ef',
    zIndex: 3
  }
});

// 테이블 페이지네이션 스타일
const StyledPagination = styled(TablePagination)({
  '.MuiTablePagination-select': {
    fontSize: '0.813rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.813rem',
    color: '#495057'
  }
});

// 아이콘 버튼 스타일
const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

// 테이블 페이지네이션 스타일
const StyledTablePagination = styled(TablePagination)({
  '.MuiTablePagination-select': {
    fontSize: '0.813rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.813rem',
    color: '#495057'
  }
});

// 메인 함수
function MaechulGamangTables({ searchConditions }) {
  const [payments] = useState([
    {
      id: 1,
      grade: 5,          // 등급 (5: 지사)
      gradeName: '지사',
      accountId: 'B001',
      parentAccountId: null,
      accountStatus: '정상',
      popupDetail: '상세보기',
      userId: 'm42845709',
      businessName: '네오알파시스템즈 윤병원',
      pgDaysElapsed: 'KG이니시스/30일',
      approvalAmount: 29760000,
      cancelAmount: -1230000,
      totalAmount: 28530000,
      feeAmount: 1440384,
      paymentAmount: 27089616,
      reserveAmount: 100000,
      paymentMethod: '신용카드',
      authMethod: '비인증',
      installment: '일시불',
      taxType: '세금계산서',
      settlementCycle: '1일',
      feeType: '정률',
      riskAmount: 0,
      refundAmount: 0,
      depositAmount: 1000000,
      branch: {
        name: '강남지사',
        feeRate: '3.3',
        payment: 150000
      },
      subAccounts: {
        headquarters: [
          {
            id: 2,
            accountId: 'H001',
            userId: 'm76640459',
            businessName: '케이밴코리아',
            // ... 다른 필드들도 동일한 구조로 추가
          },
          {
            id: 3,
            accountId: 'H002',
            userId: 'm98019007',
            businessName: '샤론정보통신',
            // ... 다른 필드들도 동일한 구조로 추가
          }
        ]
      }
    },
    {
      id: 4,
      grade: 5,
      gradeName: '지사',
      accountId: 'B002',
      parentAccountId: null,
      accountStatus: '정상',
      popupDetail: '상세보기',
      userId: 'elbio',
      businessName: '(주)페이업',
      pgDaysElapsed: 'NICE/30일',
      approvalAmount: 11096360,
      cancelAmount: -976479,
      totalAmount: 10119881,
      feeAmount: 976479,
      paymentAmount: 9143402,
      reserveAmount: 50000,
      paymentMethod: '간편결제',
      authMethod: '앱카드',
      installment: '3개월',
      taxType: '세금계산서',
      settlementCycle: '7일',
      feeType: '정률',
      riskAmount: 50000,
      refundAmount: 0,
      depositAmount: 500000,
      branch: {
        name: '부산지사',
        feeRate: '2.9',
        payment: 110000
      },
      subAccounts: {
        headquarters: [
          {
            id: 5,
            accountId: 'H003',
            userId: 'jlee1644337',
            businessName: '나이스정보통신',
            // ... 다른 필드들도 동일한 구조로 추가
          }
        ]
      }
    },
    {
      id: 2,
      grade: 4,          // 등급 (4: 총판)
      gradeName: '총판',
      accountId: 'H001',  // 계정 고유 ID
      parentAccountId: 'B001', // 상위 지사 계정 ID
      accountStatus: '정상',
      popupDetail: '상세보기',
      userId: 'head123',
      businessName: '서울총판',
      pgDaysElapsed: 'KG이니시스/30일',
      approvalAmount: 8567000,
      cancelAmount: -500000,
      totalAmount: 8067000,
      feeAmount: 428350,
      paymentAmount: 7638650,
      reserveAmount: 50000,
      paymentMethod: '신용카드',
      authMethod: '단말기',
      installment: '3개월',
      taxType: '세금계산서',
      settlementCycle: '1일',
      feeType: '정률',
      riskAmount: 0,
      refundAmount: 0,
      depositAmount: 500000,
      branch: {
        name: '서초지사',
        feeRate: '3.1',
        payment: 130000
      },
      headquarters: {
        feeRate: '1.0',
        payment: 45000
      },
      agency: {
        feeRate: '0.55',
        payment: 25000
      },
      retailer: {
        feeRate: '0.33',
        payment: 15000
      },
      sales1: {
        feeRate: '0.22',
        payment: 10000
      },
      sales2: {
        feeRate: '0.22',
        payment: 10000
      },
      sales3: {
        feeRate: '0.22',
        payment: 10000
      },
      subAccounts: {
        agency: {
          name: '강남대리점',
          feeRate: '0.55',
          payment: 25000
        },
        retailer: {
          name: '강남1지점',
          feeRate: '0.33',
          payment: 15000
        },
        sales1: {
          name: '영업1팀',
          feeRate: '0.22',
          payment: 10000
        },
        sales2: {
          name: '영업2팀',
          feeRate: '0.22',
          payment: 10000
        },
        sales3: {
          name: '영업3팀',
          feeRate: '0.22',
          payment: 10000
        }
      }
    },
    {
      id: 3,
      grade: 4,          // 등급 (4: 총판)
      gradeName: '총판',
      accountId: 'H002',  // 다른 총판
      parentAccountId: 'B001', // 같은 지사 소속
      accountStatus: '정상',
      popupDetail: '상세보기',
      userId: 'head456',
      businessName: '부산총판',
      pgDaysElapsed: 'KG이니시스/30일',
      approvalAmount: 7567000,
      cancelAmount: -300000,
      totalAmount: 7267000,
      feeAmount: 398350,
      paymentAmount: 6868650,
      reserveAmount: 40000,
      paymentMethod: '간편결제',
      authMethod: '앱카드',
      installment: '일시불',
      taxType: '세금계산서',
      settlementCycle: '1일',
      feeType: '정률',
      riskAmount: 0,
      refundAmount: 0,
      depositAmount: 300000,
      branch: {
        name: '송파지사',
        feeRate: '2.9',
        payment: 110000
      },
      headquarters: {
        feeRate: '0.9',
        payment: 40000
      },
      agency: {
        feeRate: '0.45',
        payment: 20000
      },
      retailer: {
        feeRate: '0.28',
        payment: 12000
      },
      sales1: {
        feeRate: '0.18',
        payment: 8000
      },
      sales2: {
        feeRate: '0.18',
        payment: 8000
      },
      sales3: {
        feeRate: '0.18',
        payment: 8000
      }
    }
  ]);

  //상태값 관리
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [showDetailColumns, setShowDetailColumns] = useState(true);
  const [showSubAccounts, setShowSubAccounts] = useState(true);
  const [expandedRows, setExpandedRows] = useState(new Set());

  // 클릭 핸들러
  const handleAccountClick = (businessName) => {
    setSelectedBusiness(businessName);
    setAccountModalOpen(true);
  };

  const handleDetailClick = (businessName) => {
    setSelectedBusiness(businessName);
    setDetailModalOpen(true);
  };

  // 버튼 확장/축소 토글 함수 추가
  const toggleRowExpansion = (id) => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  // 기본 컬럼 정의 수정
  const baseColumns = [
    { field: 'detail', header: '세부', align: 'center' },
    { field: 'accountStatus', header: '계정상태', align: 'center' },
    { field: 'popupDetail', header: '팝업/상세', align: 'center' },
    { field: 'userId', header: '아이디', align: 'left' },
    { field: 'businessName', header: '상호', align: 'left' },
    { field: 'pgDaysElapsed', header: 'PG/경과일', align: 'left' },
    { field: 'approvalAmount', header: '승인금액', align: 'right' },
    { field: 'cancelAmount', header: '취소금액', align: 'right' },
    { field: 'totalAmount', header: '합계', align: 'right' },
    { field: 'feeAmount', header: '수수료', align: 'right' },
    { field: 'paymentAmount', header: '지급액', align: 'right' },
    { field: 'reserveAmount', header: '유보액', align: 'right' },
    { field: 'paymentMethod', header: '결제수단', align: 'center' },
    { field: 'authMethod', header: '인증방식', align: 'center' },
    { field: 'installment', header: '할부개월', align: 'center' },
    { field: 'taxType', header: '개인/세금', align: 'center' },
    { field: 'settlementCycle', header: '정산주기', align: 'center' },
    { field: 'feeType', header: '정액/정률', align: 'center' },
    { field: 'riskAmount', header: '리스크금액', align: 'right' },
    { field: 'refundAmount', header: '환입금액', align: 'right' },
    { field: 'depositAmount', header: '보증금액', align: 'right' }
  ];

  // accountGroups 수정
  const accountGroups = [
    { field: 'branch', name: '지사', className: 'group-white' },        // branch로 수정
    { field: 'headquarters', name: '총판', className: 'group-gray' },   // headquarters로 수정
    { field: 'agency', name: '대리점', className: 'group-white' },      // agency로 수정
    { field: 'retailer', name: '리테일러', className: 'group-gray' },   // retailer로 수정
    { field: 'sales1', name: '영업1', className: 'group-white' },
    { field: 'sales2', name: '영업2', className: 'group-gray' },
    { field: 'sales3', name: '영업3', className: 'group-white' }
  ];

  // 등급 상수 정의
  const GRADE_LEVELS = {
    BRANCH: 5,      // 지사
    HEAD: 4,        // 총판
    AGENCY: 3,      // 대리점
    RETAILER: 2,    // 리테일러
    SALES: 1        // 영업
  };

  // 등급별 매핑 수정
  const getGradeForGroup = (groupField) => {
    switch (groupField) {
      case 'branch': return 5;
      case 'headquarters': return 4;
      case 'agency': return 3;
      case 'retailer': return 2;
      case 'sales1':
      case 'sales2':
      case 'sales3':
        return 1;
      default: return 0;
    }
  };

  // 하위 계정 찾기 함수
  const findSubAccounts = (accountId) => {
    return payments.filter(account => account.parentAccountId === accountId);
  };

  // 등급에 따른 배경색 결정 함수
  const getGradeBackgroundColor = (grade) => {
    switch(grade) {
      case GRADE_LEVELS.BRANCH: return '#ffffff';
      case GRADE_LEVELS.HEAD: return '#f8f9fa';
      case GRADE_LEVELS.AGENCY: return '#f1f3f5';
      case GRADE_LEVELS.RETAILER: return '#e9ecef';
      case GRADE_LEVELS.SALES: return '#dee2e6';
      default: return '#ffffff';
    }
  };

  // 하위 계정 렌더링 함수
  const renderSubAccounts = (parentRow) => {
    if (!expandedRows.has(parentRow.id)) return null;
    
    // 하위 계정 찾기
    const subAccounts = findSubAccounts(parentRow.accountId);
    
    // 하위 계정 렌더링
    return subAccounts.map(subRow => (
      <StyledTableRow 
        key={subRow.id}
        sx={{ backgroundColor: '#f8f9fa' }}
      >
        {/* 세부 버튼 컬럼 */}
        <StyledTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {subRow.grade > 1 && ( // 영업1,2,3(grade=1)이 아닌 경우에만 버튼 표시
              <IconButton
                size="small"
                onClick={() => toggleRowExpansion(subRow.id)}
              >
                {expandedRows.has(subRow.id) ? (
                  <RemoveCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#ef4444' }} />
                ) : (
                  <AddCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#2e7d32' }} />
                )}
              </IconButton>
            )}
          </Box>
        </StyledTableCell>

        {/* 나머지 컬럼들 */}
        {baseColumns.slice(1).map(column => (
          <StyledTableCell 
            key={column.field} 
            align={column.align}
          >
            {typeof subRow[column.field] === 'number' 
              ? subRow[column.field].toLocaleString() 
              : subRow[column.field]}
          </StyledTableCell>
        ))}
        
        {/* ... 나머지 컬럼들도 동일한 방식으로 표시 ... */}
      </StyledTableRow>
    ));
  };

  // 검색 조건에 따른 데이터 필터링
  const filteredData = useMemo(() => {
    if (!searchConditions) return payments;

    return payments.filter(row => {
      // 하위 계정은 필터링하지 않음
      if (row.parentAccountId) return true;

      // 텍스트 검색 (아이디, 상호, PG/경과일)
      if (searchConditions.searchText) {
        const searchLower = searchConditions.searchText.toLowerCase();
        const matchesSearch = 
          row.userId?.toLowerCase().includes(searchLower) ||          // 아이디
          row.businessName?.toLowerCase().includes(searchLower) ||    // 상호
          row.pgDaysElapsed?.toLowerCase().includes(searchLower);     // PG/경과일

        if (!matchesSearch) return false;
      }

      return true;
    });
  }, [searchConditions, payments]);

  // 모든 행을 순서대로 렌더링하기 위한 함수
  const getRowsToRender = () => {
    let rows = [];
    
    // 최상위 계정들(지사)을 먼저 찾음
    const topLevelAccounts = filteredData.filter(row => !row.parentAccountId);
    
    // 각 최상위 계정에 대해
    topLevelAccounts.forEach(account => {
      // 1. 현재 계정 추가
      rows.push(account);
      
      // 2. 확장된 상태면 하위 계정들 추가
      if (expandedRows.has(account.id)) {
        const subAccounts = filteredData.filter(row => row.parentAccountId === account.accountId);
        rows.push(...subAccounts);
      }
    });
    
    return rows;
  };

  // 하위 계정 컬럼 렌더링 수정
  const renderSubAccountColumns = (row) => {
    return accountGroups.map(group => {
      const groupGrade = getGradeForGroup(group.field);
      if (row.grade >= groupGrade && row[group.field]) {
        const accountInfo = row[group.field];
        return (
          <React.Fragment key={group.field}>
            <StyledTableCell className={group.className} align="left">
              {accountInfo.name || '-'}
            </StyledTableCell>
            <StyledTableCell className={group.className} align="right">
              {accountInfo.feeRate}%
            </StyledTableCell>
            <StyledTableCell className={group.className} align="right">
              {accountInfo.payment.toLocaleString()}
            </StyledTableCell>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={group.field}>
          <StyledTableCell className={group.className}>-</StyledTableCell>
          <StyledTableCell className={group.className}>-</StyledTableCell>
          <StyledTableCell className={group.className}>-</StyledTableCell>
        </React.Fragment>
      );
    });
  };

  // 더미데이터 추가
  const dummyData = [
    {
      id: 1,
      grade: 5,          // 지사
      gradeName: '지사',
      accountId: 'B001',
      parentAccountId: null,
      accountStatus: '정상',
      userId: 'm42845709',
      businessName: '네오알파시스템즈 윤병원',
      pgDaysElapsed: 'KG이니시스/30일',
      approvalAmount: 29760000,
      // ... 다른 필드들 ...
      subAccounts: {
        headquarters: [
          {
            id: 2,
            accountId: 'D001',
            grade: 4,          // 대리점
            gradeName: '대리점',
            userId: 'm76640459',
            businessName: '케이밴코리아',
            settlementCycle: '15일',
            subAccounts: [      // 대리점의 하위계정
              {
                id: 21,
                accountId: 'R001',
                grade: 3,      // 리테일러
                gradeName: '리테일러',
                userId: 'retail_k1',
                businessName: '스마트페이솔루션',
                settlementCycle: '15일'
              },
              {
                id: 22,
                accountId: 'S201',
                grade: 1,      // 영업2
                gradeName: '영업2',
                userId: 'sales2_k1',
                businessName: '페이원시스템',
                settlementCycle: '15일'
              }
            ]
          },
          {
            id: 3,
            accountId: 'D002',
            grade: 4,          // 대리점
            gradeName: '대리점',
            userId: 'm98019007',
            businessName: '샤론정보통신',
            settlementCycle: '7일',
            subAccounts: [      // 대리점의 하위계정
              {
                id: 31,
                accountId: 'S101',
                grade: 2,      // 영업1
                gradeName: '영업1',
                userId: 'sales1_s1',
                businessName: '페이테크원',
                settlementCycle: '7일'
              }
            ]
          }
        ]
      }
    },
    // ... 다른 상위계정들도 같은 구조로 추가
  ];

  // 메인 코드
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <StyledTableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              {/* 기본 컬럼 헤더 */}
              {baseColumns.map(column => (
                <StyledTableCell 
                  key={column.field} 
                  className="primary-header" 
                  align={column.align}
                >
                  {column.header}
                </StyledTableCell>
              ))}
              
              {/* 지사 기준 하위계정들에 대한 정보를 편하게 확인하기 위해서 코드 섹션 분리함 */}
              {accountGroups.map(group => (
                <React.Fragment key={group.field}>
                  <StyledTableCell className={`primary-header ${group.className}`} align="left">
                    {group.name}
                  </StyledTableCell>
                  <StyledTableCell className={`primary-header ${group.className}`} align="right">
                    수수료율(VAT포함)
                  </StyledTableCell>
                  <StyledTableCell className={`primary-header ${group.className}`} align="right">
                    지급액
                  </StyledTableCell>
                </React.Fragment>
              ))}

              {/* 그래프 헤더 추가 */}
              <StyledTableCell className="primary-header" align="center">
                Graph
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {getRowsToRender().map((row) => (
              <StyledTableRow 
                key={row.id}
                sx={{ 
                  backgroundColor: row.parentAccountId ? '#f8f9fa' : '#ffffff',
                  '& td': row.parentAccountId ? { paddingLeft: '2rem' } : {}
                }}
              >
                {/* 세부 버튼 */}
                <StyledTableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {row.grade > 1 && (
                      <IconButton
                        size="small"
                        onClick={() => toggleRowExpansion(row.id)}
                      >
                        {expandedRows.has(row.id) ? (
                          <RemoveCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#ef4444' }} />
                        ) : (
                          <AddCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#2e7d32' }} />
                        )}
                      </IconButton>
                    )}
                  </Box>
                </StyledTableCell>

                {/* 기본 정보 컬럼들 */}
                {baseColumns.slice(1).map(column => (
                  <StyledTableCell 
                    key={column.field} 
                    align={column.align}
                  >
                    {typeof row[column.field] === 'number' 
                      ? row[column.field].toLocaleString() 
                      : row[column.field]}
                  </StyledTableCell>
                ))}

                {/* 하위 계정 그룹 정보 - 등급 체크 수정 */}
                {renderSubAccountColumns(row)}

                {/* 그래프 셀 추가 */}
                <StyledTableCell>
                  <Box sx={{ 
                    width: '100px', 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: '2px'
                  }}>
                    <Box sx={{ 
                      height: '20px',
                      backgroundColor: '#2e7d32',
                      width: '60px'
                    }} />
                    <Box sx={{ 
                      height: '20px',
                      backgroundColor: '#d32f2f',
                      width: '20px'
                    }} />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>

      <MaechulGamangOne 
        open={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        businessName={selectedBusiness}
      />
      <MaechulGamangTwo
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        businessName={selectedBusiness}
      />
    </Paper>
  );
}

export default MaechulGamangTables;