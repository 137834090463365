import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styled from '@emotion/styled';

// 공지사항 래퍼 스타일 컴포넌트
const NoticeWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  padding: '12px 20px',
  gap: '20px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  marginTop: '-20px',
  marginBottom: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  marginTop: '5px',
  '@media (max-width: 600px)': {
    padding: '12px 16px',
    gap: '16px',
    marginTop: '10px',
  }
});

// 공지사항 텍스트 영역 스타일 컴포넌트
const NoticeContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  width: '100%',
  overflow: 'hidden',
  '& a': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    minWidth: 0
  }
});

const Notice = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const notices = [
    { id: 1, text: "11월 무이자할부 안내", link: "/notice/1" },
    { id: 2, text: "카드사 불가 업종", link: "/notice/2" },
    { id: 3, text: "불량거래 가맹점에 대한 안내", link: "/notice/3" },
    { id: 4, text: "업데이트 안내 ver.2.1.1", link: "/notice/4" },
    { id: 5, text: "고객센터 업무시간 안내", link: "/notice/5" }
  ];

  // 타이머 설정
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === notices.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [notices.length]);

  // 메인 코드
  return (
    <NoticeWrapper>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        minWidth: 'fit-content',
        flexShrink: 0
      }}>
        <CampaignIcon sx={{ 
          color: '#2185d0',
          fontSize: '1.2rem'
        }} />
        <Typography sx={{
          fontWeight: 600,
          color: '#2185d0',
          fontSize: '0.9rem'
        }}>
          공지사항
        </Typography>
      </Box>

      <NoticeContent>
        <Typography 
          component="a" 
          href={notices[currentIndex].link}
          sx={{
            color: '#495057',
            textDecoration: 'none',
            fontSize: '0.9rem',
            transition: 'all 0.2s ease',
            paddingRight: '12px',
            marginLeft: '12px',
            
            '&:hover': {
              color: '#1a1a1a',
              transform: 'scale(1.01)',
              fontWeight: 500,
            }
          }}
        >
          {notices[currentIndex].text}
        </Typography>
        <KeyboardArrowRightIcon sx={{ 
          color: '#adb5bd',
          fontSize: '1.2rem',
          flexShrink: 0
        }} />
      </NoticeContent>
    </NoticeWrapper>
  );
};

export default Notice;