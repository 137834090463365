import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Collapse,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CardLimitChange from '../Popup/CardLimitChange';

// 페이퍼 스타일
const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    marginBottom: '8px'
  }
}));

// 섹션 타이틀 스타일
const SectionTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 600,
  color: '#1976d2',
  marginBottom: '16px'
});

// 폼 컨트롤 스타일
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '12px',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    marginBottom: '8px',

    '& .MuiSelect-root, & .MuiTextField-root, & .MuiButton-root': {
      width: '100%'
    }
  }
}));

// 라벨 스타일
const Label = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#34495e',
  minWidth: '240px',
  fontWeight: '500',
  
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    fontSize: '0.85rem',
    marginBottom: '2px'
  }
}));

// 서브 라벨 스타일
const SubLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#34495e',
  minWidth: '240px',
  fontWeight: '500',
  paddingLeft: '20px',
  
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    fontSize: '0.85rem',
    paddingLeft: '12px',
    marginBottom: '2px'
  }
}));

// 타이틀 래퍼 스타일
const TitleWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  marginBottom: '16px'
});

// 서브 섹션 래퍼 스타일
const SubSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  marginBottom: '8px',
  paddingLeft: '20px',
  
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '12px',
    marginBottom: '4px'
  }
}));

// 액션 버튼 스타일
const ActionButton = styled(Button)({
  height: '40px',
  fontSize: '0.875rem',
  padding: '0 12px',
  minWidth: '100px',
  backgroundColor: '#00bcd4',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#00acc1'
  }
});

// 이벤트, 상태값 관리
const DetailsSection = () => {
  const [isLimitExpanded, setIsLimitExpanded] = useState(true);
  const [isSmsExpanded, setIsSmsExpanded] = useState(true);
  const [isCardLimitOpen, setIsCardLimitOpen] = useState(false);

  // 카드 한도 변경 팝업 열기
  const handleCardLimitClick = () => {
    setIsCardLimitOpen(true);
  };

  // 카드 한도 변경 팝업 닫기
  const handleCardLimitClose = () => {
    setIsCardLimitOpen(false);
  };

  // 숫자 입력 처리 함수
  const handleNumberInput = (e) => {
    // 숫자만 허용하고 콤마 제거
    let value = e.target.value.replace(/[^0-9]/g, '');
    // 콤마 추가
    value = value === '' ? '0' : Number(value).toLocaleString();
    e.target.value = value;
  };

  // 메인 코드
  return (
    <StyledPaper>
      <SectionTitle>상세 설정</SectionTitle>

      {/* 인증결제 사용여부 */}
      <StyledFormControl>
        <Label sx={{ ml: '20px' }}>인증결제 사용여부</Label>
        <Select size="small" defaultValue="인증결제 사용" sx={{ width: '300px' }}>
          <MenuItem value="인증결제 사용">인증결제 사용</MenuItem>
          <MenuItem value="미사용">미사용</MenuItem>
        </Select>
      </StyledFormControl>

      {/* 정기결제 사용여부 */}
      <StyledFormControl>
        <Label sx={{ ml: '20px' }}>정기결제 사용여부</Label>
        <Select size="small" defaultValue="정기결제 미사용" sx={{ width: '300px' }}>
          <MenuItem value="정기결제 사용">정기결제 사용</MenuItem>
          <MenuItem value="정기결제 미사용">정기결제 미사용</MenuItem>
        </Select>
      </StyledFormControl>

      {/* 일반결제 한도 섹션 */}
      <SubSectionWrapper onClick={() => setIsLimitExpanded(!isLimitExpanded)}>
        <Label>일반결제 한도 ▲</Label>
        {/* {isLimitExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
      </SubSectionWrapper>

      <Collapse in={isLimitExpanded}>
        <StyledFormControl>
          <SubLabel>건한도</SubLabel>
          <TextField 
            size="small" 
            defaultValue="10,000,000" 
            sx={{ 
              width: '300px',
              '& input': { textAlign: 'right' }
            }}
            onInput={handleNumberInput}
          />
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>일한도</SubLabel>
          <TextField 
            size="small" 
            defaultValue="200,000,000" 
            sx={{ 
              width: '300px',
              '& input': { textAlign: 'right' }
            }}
            onInput={handleNumberInput}
          />
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>월한도</SubLabel>
          <TextField 
            size="small" 
            defaultValue="300,000,000" 
            sx={{ 
              width: '300px',
              '& input': { textAlign: 'right' }
            }}
            onInput={handleNumberInput}
          />
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>할부개월</SubLabel>
          <Select size="small" defaultValue="12" sx={{ width: '300px' }}>
            <MenuItem value="12">12개월</MenuItem>
            <MenuItem value="24">6개월</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>카드별 한도</SubLabel>
          <ActionButton
            variant="contained"
            sx={{ width: '300px' }}
            onClick={handleCardLimitClick}
          >
            카드별한도 변경
          </ActionButton>
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>카드사</SubLabel>
          <Select size="small" defaultValue="선택" sx={{ width: '300px' }}>
            <MenuItem value="선택">선택</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>카드번호</SubLabel>
          <TextField size="small" sx={{ width: '300px' }} />
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>동일카드 중복결제 가능횟수</SubLabel>
          <Select 
            size="small" 
            defaultValue={1} 
            sx={{ width: '300px' }}
          >
            {[...Array(10)].map((_, i) => (
              <MenuItem key={i + 1} value={i + 1}>{i + 1}회</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Collapse>

      {/* 문자 섹션 */}
      <SubSectionWrapper onClick={() => setIsSmsExpanded(!isSmsExpanded)}>
        <Label>문자 ▲</Label>
        {/* {isSmsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
      </SubSectionWrapper>

      <Collapse in={isSmsExpanded}>
        <StyledFormControl>
          <SubLabel>문자발송 건당 비용</SubLabel>
          <TextField size="small" defaultValue="100" sx={{ width: '300px' }} />
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>실적문자 사용여부</SubLabel>
          <Select size="small" defaultValue="미사용" sx={{ width: '300px' }}>
            <MenuItem value="사용">사용</MenuItem>
            <MenuItem value="미사용">미사용</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>실적문자 발송시간</SubLabel>
          <Select size="small" defaultValue="미발송" sx={{ width: '300px' }}>
            <MenuItem value="발송">발송</MenuItem>
            <MenuItem value="미발송">미발송</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <SubLabel>정기결제 문자발송</SubLabel>
          <Select size="small" defaultValue="미발송" sx={{ width: '300px' }}>
            <MenuItem value="발송">발송</MenuItem>
            <MenuItem value="미발송">미발송</MenuItem>
          </Select>
        </StyledFormControl>
      </Collapse>

      <CardLimitChange 
        open={isCardLimitOpen} 
        onClose={handleCardLimitClose}
      />
    </StyledPaper>
  );
};

export default DetailsSection;
