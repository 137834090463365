import React from 'react';
import { Box, Card, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { useStyles } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

// 사업자 통계 컴포넌트
const BusinessStats = () => {
  const classes = useStyles();

  // 테이블 데이터
  const tableData = [
    { type: '거래금액', count: 12696, amount: 1741199115, isTotal: true },
    { type: '개인 거래금액', count: 4389, amount: 926731422, isTotal: false },
    { type: '사업자 거래금액', count: 8307, amount: 814467693, isTotal: false }
  ];

  // 차트 데이터
  const barChartData = {
    labels: ['11월'],
    datasets: [
      {
        label: '결제금액',
        backgroundColor: 'rgba(88, 130, 250, 0.8)',
        data: [1741199115],
        barPercentage: 0.7,
        borderRadius: 5
      },
      {
        label: '개인 거래금액',
        backgroundColor: 'rgba(255, 165, 0, 0.8)',
        data: [926731422],
        barPercentage: 0.7,
        borderRadius: 5
      },
      {
        label: '사업자 거래금액',
        backgroundColor: 'rgba(149, 165, 166, 0.8)',
        data: [814467693],
        barPercentage: 0.7,
        borderRadius: 5
      }
    ]
  };

  // 차트 데이터
  const pieChartData = {
    labels: ['사업자', '개인'],
    datasets: [{
      data: [814467693, 926731422],
      backgroundColor: [
        'rgba(149, 165, 166, 0.8)',
        'rgba(255, 165, 0, 0.8)'
      ],
      borderWidth: 0
    }]
  };

  //차트 데이터
  const installmentData = {
    labels: ['일시불', '2개월', '3개월', '4개월', '5개월', '6개월', '7개월', '8개월', '9개월', '10개월', '11개월', '12개월 이상'],
    datasets: [{
      data: [82.143, 0.549, 4.121, 0.275, 1.099, 2.198, 0, 0.275, 0, 0.824, 0.275, 1.099],
      backgroundColor: [
        'rgba(88, 130, 250, 0.8)',
        'rgba(255, 165, 0, 0.8)',
        'rgba(46, 204, 113, 0.8)',
        'rgba(52, 152, 219, 0.8)',
        'rgba(155, 89, 182, 0.8)',
        'rgba(52, 73, 94, 0.8)',
        'rgba(241, 196, 15, 0.8)',
        'rgba(230, 126, 34, 0.8)',
        'rgba(231, 76, 60, 0.8)',
        'rgba(149, 165, 166, 0.8)',
        'rgba(189, 195, 199, 0.8)',
        'rgba(127, 140, 141, 0.8)'
      ]
    }]
  };

  // 차트 데이터(도넛)
  const installmentPieData = {
    labels: ['일시불', '2개월', '3개월', '4개월', '5개월', '6개월', '7개월', '8개월', '9개월', '10개월', '11개월', '12개월 이상'],
    datasets: [{
      data: [82.143, 0.549, 4.121, 0.275, 1.099, 2.198, 0, 0.275, 0, 0.824, 0.275, 1.099],
      backgroundColor: [
        'rgba(88, 130, 250, 0.8)',
        'rgba(255, 165, 0, 0.8)',
        'rgba(46, 204, 113, 0.8)',
        'rgba(52, 152, 219, 0.8)',
        'rgba(155, 89, 182, 0.8)',
        'rgba(52, 73, 94, 0.8)',
        'rgba(241, 196, 15, 0.8)',
        'rgba(230, 126, 34, 0.8)',
        'rgba(231, 76, 60, 0.8)',
        'rgba(149, 165, 166, 0.8)',
        'rgba(189, 195, 199, 0.8)',
        'rgba(127, 140, 141, 0.8)'
      ],
      borderWidth: 0
    }]
  };

  const commonOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif",
            weight: '500'
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: 12,
        titleFont: {
          size: 13,
          family: "'Noto Sans KR', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 12,
          family: "'Noto Sans KR', sans-serif"
        }
      }
    }
  };

  const installmentOptions = {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      legend: {
        display: false
      },
      tooltip: {
        ...commonOptions.plugins.tooltip,
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw}%`;
          }
        }
      }
    }
  };

  const installmentPieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '60%',
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          padding: 15,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 11,
            family: "'Noto Sans KR', sans-serif",
            weight: '500'
          },
          boxWidth: 7,
          generateLabels: (chart) => {
            const data = chart.data;
            return data.labels.map((label, i) => ({
              text: `${label} (${data.datasets[0].data[i]}%)`,
              fillStyle: data.datasets[0].backgroundColor[i],
              hidden: false,
              index: i
            }));
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: 12,
        titleFont: {
          size: 13,
          family: "'Noto Sans KR', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 12,
          family: "'Noto Sans KR', sans-serif"
        },
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw}%`;
          }
        }
      }
    }
  };

  // 메인 코드
  return (
    <Box className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                월별 사업자 결제금액
              </Typography>
            </Box>
            <TableContainer 
              component={Paper} 
              elevation={0}
              sx={{
                overflowX: 'auto',
                '& .MuiTable-root': {
                  minWidth: '800px'  // 모바일에서 최소 너비 설정
                },
                '& .MuiTableCell-root': {
                  whiteSpace: 'nowrap'  // 텍스트 줄바꿈 방지
                }
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">결제건수</TableCell>
                    <TableCell align="center">11월</TableCell>
                    <TableCell align="center">합계</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index} className={row.isTotal ? classes.totalRow : ''}>
                      <TableCell>{row.type}</TableCell>
                      <TableCell align="center">{row.count.toLocaleString()}</TableCell>
                      <TableCell align="center">{row.amount.toLocaleString()}</TableCell>
                      <TableCell align="center">{row.amount.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                월별 사업자 결제금액
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Bar data={barChartData} options={commonOptions} />
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                사업자/개인 비율
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Pie data={pieChartData} options={commonOptions} />
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                일시불/할부 비율
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Bar data={installmentData} options={installmentOptions} />
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                일시불/할부 분포도
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Pie data={installmentData} options={installmentPieOptions} />
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessStats;