import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Box,
  Button,
  IconButton,
  Snackbar
} from '@mui/material';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CancelModal from '../../Common/PayModal/CancelModal';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

// 취소 요청 버튼 컴포넌트
const CancelRequestButtons = ({ onButtonClick }) => {
  const buttons = [
    {
      label: '취소반려',
      icon: <ReplyAllIcon />,
      value: 'reject',
      color: '#dc3545'
    },
    {
      label: '취소승인',
      icon: <CancelIcon />,
      value: 'approve',
      color: '#1976d2'
    }
  ];

  // 메인 코드
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ 
        display: 'flex',
        gap: 1
      }}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            startIcon={button.icon}
            onClick={() => onButtonClick?.(button.value)}
            sx={{
              bgcolor: '#fff',
              color: button.color,
              border: `1px solid ${button.color}`,
              fontSize: '0.875rem',
              height: '36px',
              padding: '6px 16px',
              minWidth: 'auto',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: `${button.color}10`,
                border: `1px solid ${button.color}`,
                boxShadow: 'none'
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  height: '28px',
  lineHeight: '1.2',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      padding: '4px 8px'
    }
  },

  '&.MuiTableCell-paddingCheckbox': {
    width: '32px',
    padding: '0 4px',
    '& .MuiCheckbox-root': {
      padding: '4px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  border: '1px solid #e9ecef',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  backgroundColor: '#fff',
  maxHeight: 'calc(100vh - 300px)',
  
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },

  '& .MuiCheckbox-root': {
    padding: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem'
    }
  }
});

// 검색 조건에 따른 필터링 함수
const useFilteredData = (data, searchConditions) => {
  return React.useMemo(() => {
    if (!searchConditions) return data;

    return data.filter(item => {
      let isMatched = true;

      // 날짜 범위 검색
      if (searchConditions.startDate && searchConditions.endDate) {
        const requestDate = dayjs(item.requestDate);
        const startDate = dayjs(searchConditions.startDate);
        const endDate = dayjs(searchConditions.endDate).endOf('day');
        isMatched = isMatched && requestDate.isBetween(startDate, endDate, 'day', '[]');
      }

      // 텍스트 검색 (가맹점명, MID, TID, 승인번호)
      if (searchConditions.searchText && isMatched) {
        const searchLower = searchConditions.searchText.toLowerCase();
        isMatched = (
          item.merchantName?.toLowerCase().includes(searchLower) ||
          item.merchantMID?.toLowerCase().includes(searchLower) ||
          item.merchantTID?.toLowerCase().includes(searchLower) ||
          item.approvalNo?.toLowerCase().includes(searchLower)
        );
      }

      // PG사 필터링 - pg 속성으로 변경
      if (searchConditions.pgCompany && isMatched) {
        isMatched = item.pg === searchConditions.pgCompany;
      }

      // 할부 필터링
      if (searchConditions.installment && isMatched) {
        const installmentValue = searchConditions.installment === 'single' 
          ? '일시불'
          : `${searchConditions.installment}개월`;
        isMatched = item.installment === installmentValue;
      }

      return isMatched;
    });
  }, [data, searchConditions]);
};

// 더미데이터 추가 (5개)
const dummyData = [
  {
    id: 1,
    pg: 'KSNET',  // pg로 통일
    requestDate: '2025-02-06 15:39:44',
    transactionDate: '2025-02-06 15:30:00',
    merchantName: '네오알파시스템즈',
    merchantMID: 'galaxia_175',
    merchantTID: 'TR678901234',
    approvalNo: '65934468',
    status: '취소요청',
    accountStatus: '정상',
    refundAmount: '50,000',
    walletUse: '사용중',
    amount: '176,000',
    receipt: 'Y',
    issuer: '삼성카드',
    cardNumber: '5137-92**-****-4575',
    installment: '일시불',
    feeRate: '3.3%/3000',
    deposit: '1,000',
    depositAmount: '172,000',
    accountBalance: '587,777,864',
    deductionAmount: '4,000',
    vanTrxId: 'galaxia_auto_175_20241227000001'
  },
  {
    id: 2,
    pg: 'NICE',  // pg로 통일
    requestDate: '2025-02-05 14:40:00',
    transactionDate: '2025-02-05 14:35:00',
    merchantName: '샤론정보통신',
    merchantMID: 'nice_176',
    merchantTID: 'TRK2024031900002',
    approvalNo: '65908892',
    status: '취소요청',
    accountStatus: '정상',
    refundAmount: '35,000',
    walletUse: '조회용',
    amount: '35,000',
    receipt: 'Y',
    issuer: '현대카드',
    cardNumber: '4009-78**-****-8206',
    installment: '3개월',
    feeRate: '2.5%/8000',
    deposit: '2,000',
    depositAmount: '25,000',
    accountBalance: '747,500',
    deductionAmount: '8,000',
    vanTrxId: 'nice_auto_176_20241227000002'
  },
  {
    id: 3,
    pg: 'Kopay',  // pg로 통일
    requestDate: '2025-02-04 11:30:00',
    transactionDate: '2025-02-04 11:25:00',
    merchantName: '고기진심정육점',
    merchantMID: 'kopay_177',
    merchantTID: 'TR567890123',
    approvalNo: '65934469',
    status: '취소요청',
    accountStatus: '정상',
    refundAmount: '35,000',
    walletUse: '조회용',
    amount: '35,000',
    receipt: 'Y',
    issuer: '현대카드',
    cardNumber: '4009-78**-****-8206',
    installment: '3개월',
    feeRate: '2.5%/8000',
    deposit: '2,000',
    depositAmount: '25,000',
    accountBalance: '747,500',
    deductionAmount: '8,000',
    vanTrxId: 'kopay_auto_177_20241227000002'
  },
  {
    id: 4,
    pg: 'EtoU',  // pg로 통일
    requestDate: '2025-02-03 09:15:00',
    transactionDate: '2025-02-03 09:10:00',
    merchantName: '골드스파사우나',
    merchantMID: 'etou_178',
    merchantTID: 'TR678901235',
    approvalNo: '65934470',
    status: '취소요청',
    accountStatus: '정상',
    refundAmount: '35,000',
    walletUse: '조회용',
    amount: '35,000',
    receipt: 'Y',
    issuer: '현대카드',
    cardNumber: '4009-78**-****-8206',
    installment: '3개월',
    feeRate: '2.5%/8000',
    deposit: '2,000',
    depositAmount: '25,000',
    accountBalance: '747,500',
    deductionAmount: '8,000',
    vanTrxId: 'etou_auto_178_20241227000002'
  },
  {
    id: 5,
    pg: 'AYNIL',  // pg로 통일
    requestDate: '2025-02-02 16:20:00',
    transactionDate: '2025-02-02 16:15:00',
    merchantName: '일일구세탁',
    merchantMID: 'aynil_179',
    merchantTID: 'TR678901236',
    approvalNo: '65934471',
    status: '취소요청',
    accountStatus: '정상',
    refundAmount: '35,000',
    walletUse: '조회용',
    amount: '35,000',
    receipt: 'Y',
    issuer: '현대카드',
    cardNumber: '4009-78**-****-8206',
    installment: '3개월',
    feeRate: '2.5%/8000',
    deposit: '2,000',
    depositAmount: '25,000',
    accountBalance: '747,500',
    deductionAmount: '8,000',
    vanTrxId: 'aynil_auto_179_20241227000002'
  }
];

const CancelRequestTables = ({ searchConditions }) => {
  console.log('CancelRequestTables - Received searchConditions:', searchConditions);
  const filteredData = useFilteredData(dummyData, searchConditions);
  console.log('CancelRequestTables - Filtered Data:', filteredData);

  // 전체 선택 상태
  const [selectAll, setSelectAll] = useState(false);
  // 선택된 항목 상태
  const [selectedItems, setSelectedItems] = useState([]);
  // 계정 모달 열기 상태
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  // 선택된 계정 상태
  const [selectedAccount, setSelectedAccount] = useState(null);
  // 스낵바 열기 상태
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // 전체 선택 함수
  const handleSelectAll = (checked) => {
    console.log("전체 선택:", checked);
    setSelectAll(checked);
    if (checked) {
      const allIds = filteredData.map(row => row.id);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  // 항목 선택 함수
  const handleSelectItem = (checked, id) => {
    if (checked) {
      const newSelected = [...selectedItems, id];
      setSelectedItems(newSelected);
      if (newSelected.length === filteredData.length) {
        setSelectAll(true);
      }
    } else {
      setSelectedItems(selectedItems.filter(item => item !== id));
      setSelectAll(false);
    }
  };

  // 계정 클릭 함수
  const handleAccountClick = (row) => {
    setSelectedAccount(row);
    setAccountModalOpen(true);
  };

  // 승인번호 클릭 함수
  const handleAuthClick = (row) => {
    const message = `승인번호: ${row.approvalNo}\n환입금액: ${row.refundAmount}원`;
    
    navigator.clipboard.writeText(message).then(() => {
      setAlertMessage(`클립보드에 복사되었습니다\n\n${message}`);
      setOpenSnackbar(true);
    });
  };

  // 테이블 헤더 데이터
  const tableHeaders = [
    { 
      id: 'checkbox', 
      label: <Checkbox 
        checked={selectAll}
        indeterminate={selectedItems.length > 0 && selectedItems.length < filteredData.length}
        onChange={(e) => handleSelectAll(e.target.checked)}
      />, 
      color: '#ffffff' 
    },
    { id: 'no', label: 'No', color: '#1976d2' },
    { id: 'accountDetail', label: '상세', color: '#1976d2' },
    { id: 'status', label: '상태', color: '#1976d2' },
    { id: 'pg', label: 'PG', color: '#1976d2' },
    { id: 'requestDate', label: '요청일시', color: '#1976d2' },
    { id: 'transactionDate', label: '거래일시', color: '#1976d2' },
    { id: 'merchantMID', label: '가맹점 MID', color: '#1976d2' },
    { id: 'merchantTID', label: '가맹점 TID', color: '#1976d2' },
    
    { id: 'accountStatus', label: '계정상태', color: '#90caf9' },
    { id: 'refundAmount', label: '환입금액', color: '#90caf9' },
    { id: 'walletUse', label: '월렛사용여부', color: '#90caf9' },
    { id: 'amount', label: '거래금액', color: '#90caf9' },
    { id: 'approvalNo', label: '승인번호', color: '#90caf9' },
    { id: 'merchantName', label: '가맹점명', color: '#90caf9' },
    { id: 'receipt', label: '영수증', color: '#90caf9' },
    
    { id: 'issuer', label: '발급사', color: '#ffffff' },
    { id: 'cardNumber', label: '카드번호', color: '#ffffff' },
    { id: 'installment', label: '할부', color: '#ffffff' },
    { id: 'feeRate', label: '수수료율/금액', color: '#ffffff' },
    { id: 'deposit', label: '보증금', color: '#ffffff' },
    { id: 'depositAmount', label: '입금액', color: '#ffffff' },
    { id: 'accountBalance', label: '계정잔액', color: '#ffffff' },
    { id: 'deductionAmount', label: '차감금액', color: '#ffffff' },
    { id: 'vanTrxId', label: 'vanTrxidtrxid', color: '#ffffff' }
  ];

  // 메인 코드
  return (
    <>
      <CancelRequestButtons 
        onButtonClick={(value) => {
          console.log('Button clicked:', value);
          console.log('Selected items:', selectedItems);
        }} 
      />
      <TableContainer component={StyledTableWrapper}>
        <Table sx={{ minWidth: 2000 }}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  align="center"
                  className="primary-header"
                  sx={header.id === 'checkbox' ? {
                    backgroundColor: '#1976d2',
                    '& .MuiCheckbox-root': {
                      color: '#ffffff',
                      '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                        color: '#ffffff',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem'
                      }
                    }
                  } : undefined}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">
                  <Checkbox 
                    checked={selectedItems.includes(row.id)}
                    onChange={(e) => handleSelectItem(e.target.checked, row.id)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    size="small"
                    onClick={() => handleAccountClick(row)}
                    sx={{
                      padding: '4px',
                      '&:hover': {
                        backgroundColor: '#e8f5e9'
                      }
                    }}
                  >
                    <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                  </IconButton>
                </StyledTableCell>
                {tableHeaders.slice(3).map((header) => (
                  <StyledTableCell
                    key={`${row.id}-${header.id}`}
                    align="center"
                  >
                    {header.id === 'approvalNo' ? (
                      <span
                        onClick={() => handleAuthClick(row)}
                        style={{ cursor: 'pointer', color: '#1976d2' }}
                      >
                        {row[header.id]}
                      </span>
                    ) : row[header.id]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CancelModal
        open={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        data={selectedAccount}
        modalType="account"
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            whiteSpace: 'pre-line'
          }
        }}
      />
    </>
  );
};

export default CancelRequestTables; 