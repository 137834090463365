import React from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  minWidth: 'max-content',
  
  '&.total-column': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '@media (max-width: 600px)': {
    padding: '4px 8px'
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 래퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 메인 함수
function GamaengjeomTop({ searchParams }) {
  // 더미 데이터 10개로 수정
  const topData = [
    { 
      no: 1,
      sangho: '농부고2',
      business: '네오플랜 온병원',
      account: 'm76640459',
      count: 125,
      amount: 179549888,
      cancelAmount: 1005000,
      realAmount: 178544888,
      payType: '수기'
    },
    { 
      no: 2,
      sangho: '농부고4',
      business: '네오플랜 온병원',
      account: 'm75094552',
      count: 59,
      amount: 114392900,
      cancelAmount: 3800000,
      realAmount: 110592900,
      payType: '수기'
    },
    { 
      no: 3,
      sangho: '농부고3',
      business: '네오플랜 온병원',
      account: 'm87965090',
      count: 66,
      amount: 81512500,
      cancelAmount: 0,
      realAmount: 81512500,
      payType: '수기'
    },
    { 
      no: 4,
      sangho: '쉬즈하시의원 딤플(관악)',
      business: 'B분사',
      account: '107836',
      count: 303,
      amount: 77882100,
      cancelAmount: 5270400,
      realAmount: 72611700,
      payType: '오프PG'
    },
    { 
      no: 5,
      sangho: '(주)농부고올',
      business: '네오플랜 온병원',
      account: 'm21644470',
      count: 30,
      amount: 66046000,
      cancelAmount: 0,
      realAmount: 66046000,
      payType: '수기'
    },
    { 
      no: 6,
      sangho: '전수희',
      business: '네오플랜 온병원',
      account: 'm99986962',
      count: 39,
      amount: 62999000,
      cancelAmount: 1230000,
      realAmount: 61769000,
      payType: '수기'
    },
    { 
      no: 7,
      sangho: '(주)으르카코리아',
      business: '네오알파시스템즈 온병원',
      account: 'm42845709',
      count: 23,
      amount: 43712592,
      cancelAmount: 3506880,
      realAmount: 40205712,
      payType: '수기'
    },
    { 
      no: 8,
      sangho: '김소편',
      business: '네오플랜 온병원',
      account: '1047097906',
      count: 11,
      amount: 33900000,
      cancelAmount: 0,
      realAmount: 33900000,
      payType: '오프PG'
    },
    { 
      no: 9,
      sangho: '환제푸드',
      business: '주식회사 킬페이먼츠',
      account: 'kg8837',
      count: 25,
      amount: 33090000,
      cancelAmount: 0,
      realAmount: 33090000,
      payType: '수기'
    },
    { 
      no: 10,
      sangho: '더착한의원',
      business: '네오플랜 온병원',
      account: 'm33221144',
      count: 18,
      amount: 31500000,
      cancelAmount: 1500000,
      realAmount: 30000000,
      payType: '수기'
    }
  ];

  // 메인 코드
  return (
    <Paper elevation={1} sx={{ p: 3, backgroundColor: '#ffffff', borderRadius: '8px' }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#424242' }}>
        가맹점 결제순위 TOP 10
      </Typography>
      
      <StyledTableWrapper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="sticky-column">No</StyledTableCell>
                <StyledTableCell>가맹점</StyledTableCell>
                <StyledTableCell>영업</StyledTableCell>
                <StyledTableCell>계정명</StyledTableCell>
                <StyledTableCell className="primary-header" align="right">거래건수</StyledTableCell>
                <StyledTableCell className="primary-header" align="right">총 거래금액</StyledTableCell>
                <StyledTableCell className="secondary-header" align="right">취소금액</StyledTableCell>
                <StyledTableCell className="secondary-header" align="right">실매출금액</StyledTableCell>
                <StyledTableCell className="secondary-header" align="center">결제형식</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {topData.map((row) => (
                <StyledTableRow key={row.no}>
                  <StyledTableCell className="sticky-column">{row.no}</StyledTableCell>
                  <StyledTableCell>{row.sangho}</StyledTableCell>
                  <StyledTableCell>{row.business}</StyledTableCell>
                  <StyledTableCell>{row.account}</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">
                    {row.count.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">
                    {row.amount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="secondary-header">
                    {row.cancelAmount > 0 ? `-${row.cancelAmount.toLocaleString()}` : '0'}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="secondary-header">
                    {row.realAmount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="center" className="secondary-header">{row.payType}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableWrapper>
    </Paper>
  );
}

export default GamaengjeomTop; 