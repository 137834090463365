import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

// BonNote 스타일 적용
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#1976d2', // 기존 파란색
    color: '#ffffff',
  },

  // 특정 ID에 스타일 추가
  '&#specific-id': {
    color: '#000000', // 텍스트 색상 검정
    backgroundColor: '#1565c0', // 더 진한 파란색
    fontWeight: 'bold', // 텍스트 두껍게
    borderBottom: '2px solid #e9ecef', // 기존 테두리 유지
    borderRight: '1px solid #e9ecef',
    fontSize: '0.813rem', // 기존 텍스트 크기 유지
  },
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 래퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// 차트 래퍼 스타일 컴포넌트 추가
const ChartWrapper = styled(Box)({
  width: '100%',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 메인 함수
function GamengData() {
  // 30개의 가맹점 더미 데이터 생성
  const generateDummyData = () => {
    const data = {};
    for(let i = 1; i <= 30; i++) {
      const dailyData = Array.from({ length: 14 }, (_, idx) => ({
        [`${String(idx + 1).padStart(2, '0')}일`]: Math.floor(Math.random() * 30000000)
      })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

      // 합계 계산
      const totalAmount = Object.values(dailyData).reduce((sum, value) => sum + value, 0);

      data[`${100 + i}`] = {
        가맹점명: `가맹점${i}`,
        아이디: `m${70000000 + i}`,
        건수합계: Math.floor(Math.random() * 100) + 20,
        합계: totalAmount,  // 합계 추가
        ...dailyData
      };
    }
    return data;
  };

  // 더미 데이터 생성
  const dummyData = generateDummyData();
  const [displayData] = useState(dummyData);

  // 상위/하위 가맹점 필터링 함수
  const getTopBottomMerchants = () => {
    const merchantTotals = Object.values(dummyData).map(merchant => ({
      name: merchant.가맹점명,
      total: merchant.합계
    })).sort((a, b) => b.total - a.total);

    return {
      top10: merchantTotals.slice(0, 10),
      bottom10: merchantTotals.slice(-10)
    };
  };

  // 바이올린 플롯 데이터 계산 함수
  const calculateViolinData = () => {
    const days = Array.from({ length: 14 }, (_, i) => `${String(i + 1).padStart(2, '0')}일`);
    const { top10, bottom10 } = getTopBottomMerchants();
    
    return days.map(day => {
      // 해당 일자의 모든 가맹점 매출액
      const values = Object.values(dummyData).map(merchant => merchant[day]);
      
      // 커널 밀도 추정을 위한 데이터 준비
      const min = Math.min(...values);
      const max = Math.max(...values);
      const range = max - min;
      
      // 더 많은 구간으로 나누어 부드러운 곡선 생성
      const points = 100;
      const bandwidth = range / 15; // 커널 대역폭 조정
      
      // 커널 밀도 추정 (KDE)
      const kde = Array.from({ length: points }, (_, i) => {
        const x = min + (range * i) / (points - 1);
        const density = values.reduce((sum, value) => {
          const z = (value - x) / bandwidth;
          // 가우시안 커널 사용
          return sum + Math.exp(-0.5 * z * z) / Math.sqrt(2 * Math.PI);
        }, 0);
        return [density / (values.length * bandwidth), x];
      });
      
      // 밀도값 정규화 및 스케일링
      const maxDensity = Math.max(...kde.map(([d]) => d));
      const scaledKde = kde.map(([d, x]) => [d / maxDensity * 0.35, x]);
      
      // 바이올린 모양을 위한 대칭 데이터 생성
      const violin = [
        ...scaledKde.map(([d, x]) => [-d, x]),
        ...scaledKde.reverse().map(([d, x]) => [d, x])
      ];

      // 상위/하위 가맹점 데이터
      const topMerchants = Object.values(dummyData)
        .filter(m => top10.some(top => top.name === m.가맹점명))
        .map(m => ({
          name: m.가맹점명,
          value: m[day],
          day: day
        }));

      const bottomMerchants = Object.values(dummyData)
        .filter(m => bottom10.some(bottom => bottom.name === m.가맹점명))
        .map(m => ({
          name: m.가맹점명,
          value: m[day],
          day: day
        }));

      // 사분위수 계산
      const sortedValues = [...values].sort((a, b) => a - b);
      const q1 = sortedValues[Math.floor(values.length * 0.25)];
      const median = sortedValues[Math.floor(values.length * 0.5)];
      const q3 = sortedValues[Math.floor(values.length * 0.75)];

      return {
        violin,
        topMerchants,
        bottomMerchants,
        median,
        q1,
        q3
      };
    });
  };

  // 바이올린 데이터 계산
  const violinData = calculateViolinData();

  // 차트 옵션 정의
  const chartOption = {
    title: {
      text: '일자별 가맹점 매출 분포',
      left: 'center',
      top: 0,
      textStyle: {
        fontSize: 14,
        color: '#333'
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function(params) {
        if (!params.data) return '';
        
        if (params.seriesName === '상위 가맹점' || params.seriesName === '하위 가맹점') {
          const data = params.data;
          return `${data.day}<br/>
                 ${data.name}<br/>
                 매출액: ${data.value[1].toLocaleString()}원`;
        }
        if (params.seriesName === '중앙값') {
          return `${params.name}<br/>
                 중앙값: ${params.data.value[1].toLocaleString()}원`;
        }
        if (params.seriesName === '매출 분포') {
          const value = Math.abs(params.data[1]);
          return `${params.name}<br/>
                 해당 구간 매출액: ${value.toLocaleString()}원<br/>
                 (전체 가맹점 중 ${(Math.abs(params.data[0]) * 3.3).toFixed(1)}% 분포)`;
        }
        return '';
      }
    },
    legend: {
      data: ['매출 분포', '중앙값', '상위 가맹점', '하위 가맹점'],
      bottom: 0
    },
    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%',
      top: '15%'
    },
    xAxis: {
      type: 'category',
      data: Array.from({ length: 14 }, (_, i) => `${String(i + 1).padStart(2, '0')}일`),
      axisLabel: {
        fontSize: 11,
        color: '#666'
      }
    },
    yAxis: {
      type: 'value',
      name: '매출액',
      axisLabel: {
        formatter: (value) => {
          if (value === 0) return '0';
          return (value / 10000000).toFixed(1) + '천만';
        },
        fontSize: 11,
        color: '#666'
      }
    },
    series: [
      {
        name: '매출 분포',
        type: 'custom',
        renderItem: function(params, api) {
          const points = violinData[params.dataIndex].violin.map(([y, x]) => 
            api.coord([params.dataIndex + y, x])
          );
          return {
            type: 'polygon',
            shape: {
              points: points,
              smooth: 0.5 // 곡선을 부드럽게 처리
            },
            style: {
              fill: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: 'rgba(37, 99, 235, 0.2)' },
                { offset: 0.5, color: 'rgba(37, 99, 235, 0.8)' },
                { offset: 1, color: 'rgba(37, 99, 235, 0.2)' }
              ]),
              stroke: 'rgba(37, 99, 235, 0.8)',
              lineWidth: 1
            }
          };
        },
        data: violinData.map((_, i) => i),
        z: 1
      },
      {
        name: '중앙값',
        type: 'scatter',
        data: violinData.map((item, i) => ({
          value: [i, item.median],
          name: `${String(i + 1).padStart(2, '0')}일`,
          symbolSize: 8,
          itemStyle: {
            color: '#fff',
            borderColor: '#1e40af',
            borderWidth: 2
          }
        })),
        z: 2
      },
      {
        name: '상위 가맹점',
        type: 'scatter',
        data: violinData.flatMap((item, index) => 
          item.topMerchants.map(merchant => ({
            value: [index, merchant.value],
            day: merchant.day,
            name: merchant.name,
            symbolSize: 8
          }))
        ),
        itemStyle: {
          color: '#2563eb'
        },
        z: 3
      },
      {
        name: '하위 가맹점',
        type: 'scatter',
        data: violinData.flatMap((item, index) => 
          item.bottomMerchants.map(merchant => ({
            value: [index, merchant.value],
            day: merchant.day,
            name: merchant.name,
            symbolSize: 8
          }))
        ),
        itemStyle: {
          color: '#ef4444'
        },
        z: 3
      }
    ]
  };

  // 메인 코드(현재 차트 이외에 참고할만한 차트들 있음. 본전산 리빌드 인수인계 시트 확인)
  return (
    <Paper sx={{ 
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: 'none',
      border: '1px solid #e9ecef',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <Typography variant="h6" sx={{ p: 2, borderBottom: '1px solid #e9ecef' }}>
        가맹점별 데이터
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <StyledTableWrapper>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="sticky-column">가맹점명</StyledTableCell>
                <StyledTableCell>아이디</StyledTableCell>
                <StyledTableCell id = 'specific-id'>건수합계</StyledTableCell>
                <StyledTableCell id = 'specific-id'>합계금액</StyledTableCell>
                {Array.from({ length: 14 }, (_, idx) => (
                  <StyledTableCell key={idx}>
                    {String(idx + 1).padStart(2, '0')}일
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {Object.entries(displayData).map(([id, data]) => (
                <StyledTableRow key={id}>
                  <StyledTableCell className="sticky-column">{data.가맹점명}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center' }}>{data.아이디}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>
                    {data.건수합계.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>
                    {data.합계.toLocaleString()}
                  </StyledTableCell>
                  {Array.from({ length: 14 }, (_, idx) => (
                    <StyledTableCell key={idx} sx={{ textAlign: 'right' }}>
                      {data[`${String(idx + 1).padStart(2, '0')}일`].toLocaleString()}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableWrapper>

        <ChartWrapper>
          <Box sx={{ 
            minWidth: window.innerWidth <= 600 ? '1200px' : 'auto',
            height: '600px'
          }}>
            <ReactECharts 
              option={chartOption}
              style={{ height: '100%' }}
              className="echarts-for-react boot-chart"
            />
          </Box>
        </ChartWrapper>
      </Box>
    </Paper>
  );
}

export default GamengData;
