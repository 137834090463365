// src/Components/PaymentStats/PaymentStats.js
import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMediaQuery } from '@mui/material';

// 결제 통계 컴포넌트 (카드로 분리)
function PaymentStats() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:800px) and (max-width:1025px)');

  const dailyStats = [
    {
      title: '전일 결제액',
      value: '39,575,524',
      trend: 'up'
    },
    {
      title: '당일 결제액',
      value: '115,184,800',
      trend: 'up'
    },
    {
      title: '전일 vs 당일 증감액',
      value: '75,609,276',
      trend: 'up'
    },
    {
      title: '전일 취소액',
      value: '-225,900',
      trend: 'down'
    },
    {
      title: '전전일 vs 전일 증감액',
      value: '4,636,114',
      trend: 'up'
    },
    {
      title: '전월 결제액',
      value: '523,184,900',
      trend: 'up'
    },
    {
      title: '당월 결제액',
      value: '621,959,000',
      trend: 'up'
    },
    {
      title: '전월 vs 당월 증감액',
      value: '98,774,100',
      trend: 'up'
    },
    {
      title: '전월 취소액',
      value: '-1,225,900',
      trend: 'down'
    },
    {
      title: '전전월 vs 전월 증감액',
      value: '15,636,114',
      trend: 'up'
    }
  ];

  // 메인 코드
  return (
    <Box sx={{ 
      flexGrow: 1, 
      mb: 2,
      overflowX: isTablet ? 'auto' : 'visible',
      '& .MuiCard-root': {
        width: isMobile ? '100%' : isTablet ? '180px' : 'calc(20% - 16px)',
        marginBottom: isMobile ? 2 : 2,
        minHeight: isTablet ? '120px' : 'auto',
        background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
        borderRadius: '12px',
        transition: 'all 0.3s ease',
        boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
        '&:hover': {
          transform: 'translateY(-3px)',
          boxShadow: '0 4px 20px rgba(0,0,0,0.12)',
          background: 'linear-gradient(135deg, #ffffff 0%, #f0f2f5 100%)'
        }
      }
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          flexWrap: isTablet ? 'nowrap' : 'wrap',
          gap: 2
        }}>
          {dailyStats.slice(0, 5).map((stat, index) => (
            <Card key={index}>
              <Box sx={{ 
                p: 2.5,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '4px',
                  height: '100%',
                  background: 'linear-gradient(to bottom, #2185d0, #64b5f6)',
                  opacity: 0.7
                }
              }}>
                <Typography sx={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  color: '#2c3e50',
                  mb: 2,
                  textAlign: 'center',
                  letterSpacing: '0.5px'
                }}>
                  {stat.title}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1
                }}>
                  <Typography sx={{
                    fontSize: '1.1rem',
                    fontWeight: 700,
                    color: '#000000',
                    letterSpacing: '0.5px'
                  }}>
                    {stat.value}
                  </Typography>
                  {stat.trend && (
                    stat.trend === 'up' ? 
                      <ArrowDropUpIcon sx={{ 
                        color: '#e53935',
                        fontSize: '20px',
                        marginLeft: '2px'
                      }} /> :
                      <ArrowDropDownIcon sx={{ 
                        color: '#1e88e5',
                        fontSize: '20px',
                        marginLeft: '2px'
                      }} />
                  )}
                </Box>
              </Box>
            </Card>
          ))}
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          flexWrap: isTablet ? 'nowrap' : 'wrap',
          gap: 2
        }}>
          {dailyStats.slice(5, 10).map((stat, index) => (
            <Card key={index + 5}>
              <Box sx={{ 
                p: 2.5,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '4px',
                  height: '100%',
                  background: 'linear-gradient(to bottom, #43a047, #81c784)',
                  opacity: 0.7
                }
              }}>
                <Typography sx={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  color: '#2c3e50',
                  mb: 2,
                  textAlign: 'center',
                  letterSpacing: '0.5px'
                }}>
                  {stat.title}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1
                }}>
                  <Typography sx={{
                    fontSize: '1.1rem',
                    fontWeight: 700,
                    color: '#000000',
                    letterSpacing: '0.5px'
                  }}>
                    {stat.value}
                  </Typography>
                  {stat.trend && (
                    stat.trend === 'up' ? 
                      <ArrowDropUpIcon sx={{ 
                        color: '#e53935',
                        fontSize: '20px',
                        marginLeft: '2px'
                      }} /> :
                      <ArrowDropDownIcon sx={{ 
                        color: '#1e88e5',
                        fontSize: '20px',
                        marginLeft: '2px'
                      }} />
                  )}
                </Box>
              </Box>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentStats;