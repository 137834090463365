import React from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';

// BonNote PC버전 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 컨테이너 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// 차트 래퍼 스타일
const ChartWrapper = styled(Box)({
  width: '100%',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch'
  }
});

function CardJipgye({ searchParams }) {
  // 더미 데이터
  const cardData = [
    { card: '비씨', amount: 95622880, amountRatio: 23.616, count: 272, countRatio: 18.579, risk: 0 },
    { card: '농협', amount: 31745870, amountRatio: 7.84, count: 191, countRatio: 13.046, risk: 0 },
    { card: '수협', amount: 19366790, amountRatio: 4.783, count: 10, countRatio: 0.683, risk: 0 },
    { card: '국민', amount: 53367870, amountRatio: 13.18, count: 277, countRatio: 18.921, risk: 0 },
    { card: '삼성', amount: 62988150, amountRatio: 15.556, count: 116, countRatio: 7.923, risk: 0 },
    { card: '롯데', amount: 35325340, amountRatio: 8.724, count: 85, countRatio: 5.806, risk: 0 },
    { card: '현대', amount: 26772290, amountRatio: 6.612, count: 133, countRatio: 9.085, risk: 0 },
    { card: '신한', amount: 47947710, amountRatio: 11.842, count: 188, countRatio: 12.842, risk: 0 },
    { card: '하나', amount: 18086850, amountRatio: 4.467, count: 80, countRatio: 5.464, risk: 0 },
    { card: '우리', amount: 8084570, amountRatio: 1.997, count: 36, countRatio: 2.459, risk: 0 },
    { card: '외환', amount: 1595190, amountRatio: 0.394, count: 41, countRatio: 2.801, risk: 0 },
    { card: '광주', amount: 1606700, amountRatio: 0.397, count: 24, countRatio: 1.639, risk: 0 },
    { card: '제주', amount: 717200, amountRatio: 0.177, count: 4, countRatio: 0.273, risk: 0 }
  ];

  // 총 거래금액 차트 옵션
  const amountChartOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}원 ({d}%)'
    },
    legend: {
      orient: 'vertical',
      right: 10,
      top: 'center',
      textStyle: { fontSize: 12 }
    },
    series: [{
      name: '총 거래금액',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: true,
        position: 'outside',
        formatter: '{b}\n{d}%',
        fontSize: 12
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 12,
          fontWeight: 'bold'
        }
      },
      data: cardData.map(item => ({
        name: item.card,
        value: item.amount
      }))
    }]
  };

  // 거래건수 차트 옵션
  const countChartOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}건 ({d}%)'
    },
    legend: {
      orient: 'vertical',
      right: 10,
      top: 'center',
      textStyle: { fontSize: 12 }
    },
    series: [{
      name: '거래건수',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: true,
        position: 'outside',
        formatter: '{b}\n{d}%',
        fontSize: 12
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 12,
          fontWeight: 'bold'
        }
      },
      data: cardData.map(item => ({
        name: item.card,
        value: item.count
      }))
    }]
  };

  // 메인 함수
  return (
    <Paper elevation={1} sx={{ p: 3, backgroundColor: '#ffffff', borderRadius: '8px' }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#424242' }}>
        카드사별 집계
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <StyledTableWrapper>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell className="sticky-column">카드사</StyledTableCell>
                    <StyledTableCell className="primary-header">거래건수</StyledTableCell>
                    <StyledTableCell className="primary-header">총 거래금액</StyledTableCell>
                    <StyledTableCell className="secondary-header">거래금액 비율</StyledTableCell>
                    <StyledTableCell className="secondary-header">거래건수 비율</StyledTableCell>
                    <StyledTableCell className="secondary-header">risk</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {cardData.map((row) => (
                    <StyledTableRow key={row.card}>
                      <StyledTableCell className="sticky-column">{row.card}</StyledTableCell>
                      <StyledTableCell align="right">
                        {row.count.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.amount.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.amountRatio}%</StyledTableCell>
                      <StyledTableCell align="right">{row.countRatio}%</StyledTableCell>
                      <StyledTableCell align="center">{row.risk}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledTableWrapper>
        </Grid>
        
        <Grid item xs={7}>
          <Grid container spacing={2}>
            {/* 상단 차트 */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center" sx={{ mb: 1 }}>
                카드사별 거래금액
              </Typography>
              <ChartWrapper>
                <ReactECharts 
                  option={amountChartOption}
                  style={{ height: '290px' }}
                  className="echarts-for-react boot-chart"
                />
              </ChartWrapper>
            </Grid>
            {/* 하단 차트 */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center" sx={{ mb: 1 }}>
                카드사별 거래건수
              </Typography>
              <ChartWrapper>
                <ReactECharts 
                  option={countChartOption}
                  style={{ height: '290px' }}
                  className="echarts-for-react boot-chart"
                />
              </ChartWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CardJipgye; 