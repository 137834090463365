import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Checkbox, TablePagination, Box, Button, IconButton, Tooltip, Modal, Dialog } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import MerchantStatusIcons from '../../Common/MerchantStatusIcons';
import CancelOptions from '../../Common/CancelOptions';
import MerchantIcons from '../../Common/MerchantIcons';
import { ReceiptIconButton } from '../../Common/PlusIcons';
import RMquestion from '../../Common/RMquestion';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import UserInfoOne from '../../Common/PayModal/UserInfoOne';
import UserInfoTwo from '../../Common/PayModal/UserInfoTwo';
import SetOff from '../../Common/SetOff';
import axios from 'axios';
import Bill1 from '../../AdminFolder/0Su/Bill1';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CardLimitChange from '../../Management/MerchantManagement/MerchantInput/Popup/CardLimitChange';

// 스타일 컴포넌트 통합
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      minWidth: 'max-content',
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      minWidth: 'max-content',
    }
  },

  '&.MuiTableCell-paddingCheckbox': {
    width: '32px',
    padding: '0 4px',
    '& .MuiCheckbox-root': {
      padding: '4px',
      '& .MuiSvgIcon-root': {
        fontSize: '1.2rem'
      }
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 래퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 테이블 페이지네이션 스타일
const StyledPagination = styled(TablePagination)({
  '.MuiTablePagination-select': {
    fontSize: '0.813rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.813rem',
    color: '#495057'
  }
});

// 아이콘 버튼 스타일
const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

// 테이블 페이지네이션 스타일
const StyledTablePagination = styled(TablePagination)({
  '.MuiTablePagination-select': {
    fontSize: '0.813rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.813rem',
    color: '#495057'
  }
});

// 알림 스타일
const StyledAlert = styled(Alert)({
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  fontSize: '1rem',
  '& .MuiAlert-message': {
    padding: '12px 0',
    whiteSpace: 'pre-line',
    lineHeight: '1.5'
  },
  '& .MuiAlert-icon': {
    fontSize: '24px'
  }
});

// 클릭 가능한 셀 스타일 추가
const ClickableCell = styled(StyledTableCell)({
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: '#f8f9fa'
  }
});

function PaymentHistoryTable({ 
  searchParams,
  onCheckboxChange, 
  selectedApprovals, 
  setSelectedApprovals,
  searchPanelOpen,
  onCancelOption,
  onIconClick,
  onReceiptClick,
  limit,
  onDataUpdate
}) {
  // 상태값 정의
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRMModalOpen, setIsRMModalOpen] = useState(false);
  const [selectedMerchantData, setSelectedMerchantData] = useState(null);
  const [userInfoOneOpen, setUserInfoOneOpen] = useState(false);
  const [userInfoTwoOpen, setUserInfoTwoOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedMerchantName, setSelectedMerchantName] = useState('');
  const [isSetOffModalOpen, setIsSetOffModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1000);
  const rowsPerPage = searchParams?.limit || 10;
  const MAX_DATA_COUNT = 1000;
  const [sortDirection, setSortDirection] = useState(null);
  const [showReceipt, setShowReceipt] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isCardLimitOpen, setIsCardLimitOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  // 페이지 변경 함수
  const handlePageChange = (event, newPage) => {
    // 최대 데이터 개수를 넘지 않도록 체크
    if ((newPage + 1) * rowsPerPage <= MAX_DATA_COUNT) {
      console.log('페이지 변경:', {
        이전: page,
        다음: newPage,
        건너뛸개수: newPage * rowsPerPage
      });
      
      setPage(newPage);
      getPaymentHistory(newPage * rowsPerPage);
    }
  };

  // 결제내역 조회 함수
  const getPaymentHistory = async (skip = 0) => {
    try {
      const instance = axios.create({
        baseURL: 'https://api-fsai-bnine.211.45.182.26.nip.io',
        headers: {
          'accept': 'application/json'
        }
      });

      // SearchOptions에서 넘어온 검색 조건들을 API 파라미터에 추가
      const params = {
        skip: skip,
        limit: Math.min(rowsPerPage, MAX_DATA_COUNT - skip),
        // 날짜 파라미터
        start_date: searchParams?.startDate?.replace(/-/g, ''),
        end_date: searchParams?.endDate?.replace(/-/g, ''),
        
        // 할부 필터
        installment: searchParams?.installment || '',
        
        // 결제형식 필터
        payment_type: searchParams?.paymentType || '',
        
        // MID, TID 검색
        merchant_id: searchParams?.merchantMID || '',
        terminal_id: searchParams?.merchantTID || '',
        
        // 기타 필요한 검색 조건들...
      };

      console.log('API 요청 파라미터:', params);

      const response = await instance.get('/payments/', { params });
      
      // 응답 데이터 처리
      const formattedData = response.data.map(item => ({
        ...item,
        // 할부 정보 포맷팅
        installment: item.installment === '00' ? '일시불' : `${Number(item.installment)}개월`,
        // 결제형식 포맷팅
        paymentType: item.paymentType || '수기', // 기본값 설정
      }));

      setPayments(formattedData);
      setTotalCount(response.headers['x-total-count'] || 1000);
      
      // 부모 컴포넌트에 데이터 업데이트 알림
      if (onDataUpdate) {
        onDataUpdate({
          data: formattedData,
          getAllData: () => getAllPaymentData(searchParams)
        });
      }

    } catch (error) {
      console.error('Error fetching payment history:', error);
      setError('데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 검색 조건이 변경될 때마다 첫 페이지부터 다시 조회
  useEffect(() => {
    if (searchParams) {
      getPaymentHistory(0);
      setPage(0); // 페이지 초기화
    }
  }, [searchParams, rowsPerPage]);

  // 민원건 클릭 핸들러
  const handleRMClick = (row) => {
    if (selectedApprovals.length === 1) {
      setSelectedMerchantData({
        merchantName: row.merchantName,
        loginId: 'm20230926',
        phone: row.phoneNumber,
        mobile: '010-4117-5805',
        address: '서울 서대문구 신촌로 25',
        branchName: '에픽하우스',
        branchLoginId: 'b19006',
        branchPhone: '010-5500-5487',
        branchMobile: '010-5500-5487',
        branchAddress: '서울 동작구 동작대로27다길 29 2층'
      });
      setIsRMModalOpen(true);
    }
  };

  // 카드 상세 데이터 포맷 함수
  const formatCardDetailData = (row) => ([
    {
      type: '마스터',
      businessName: row.merchantName,
      feeRate: '1.485',
      feeAmount: 756,
      paymentAmount: 358,
      withholding: '0%',
      pointFee: '0',
      rewardFee: '0',
      accountNumber: '257-223937-04-030'
    },
    {
      type: '지사',
      businessName: row.merchantName,
      feeRate: '-',
      feeAmount: '-',
      paymentAmount: '-',
      withholding: '0%',
      pointFee: '0',
      rewardFee: '0',
      accountNumber: '666201-01-767587'
    },
    // ... 필요한 만큼 데이터 추가
  ]);

  // 카드 상세 클릭 핸들러
  const handleCardDetailClick = (row) => {
    setUserInfoTwoOpen(true);
    setSelectedMerchantName(row.merchantName);
  };

  // 사용자 상세 클릭 핸들러
  const handleUserDetailClick = (row) => {
    setSelectedRowData({
      amount: row.amount,
      approvalNo: row.approvalNo,
      transactionDate: row.transactionDate,
      cardNumber: row.cardNumber,
      installment: row.installment,
      productName: row.productName
    });
    setUserInfoOneOpen(true);
  };

    const tableWidth = '100%';

  // 체크박스 변경 함수
  const handleCheckboxChange = (checked, approvalNo) => {
    if (Array.isArray(approvalNo)) {
      // 전체 선택/의 경우
      setSelectedApprovals(checked ? approvalNo : []);
    } else {
      // 개별 선택/해제의 경우
      if (checked) {
        setSelectedApprovals(prev => [...prev, approvalNo]);
      } else {
        setSelectedApprovals(prev => prev.filter(no => no !== approvalNo));
      }
    }

    // 취소 옵션 섹션으로 스크롤
    const cancelOptionsElement = document.querySelector('.cancel-options-section');
    if (cancelOptionsElement) {
      cancelOptionsElement.scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  // 취소 옵션 컴포넌트 속성
  const cancelOptionsProps = {
    onCancelOption: onCancelOption,
    selectedItems: selectedApprovals
  };

  // 결제금액 정렬 함수
  const handleSortClick = () => {
    setSortDirection(current => {
      if (current === null) return 'asc';
      if (current === 'asc') return 'desc';
      return null;
    });
  };

  // 정렬된 결제내역 계산
  const sortedPayments = React.useMemo(() => {
    if (!sortDirection) return payments;
    
    return [...payments].sort((a, b) => {
      const amountA = Number(a.amount);
      const amountB = Number(b.amount);
      
      return sortDirection === 'asc' ? amountA - amountB : amountB - amountA;
    });
  }, [payments, sortDirection]);

  // getAllPaymentData 함수 추가
  const getAllPaymentData = async (searchParams) => {
    try {
      const instance = axios.create({
        baseURL: 'https://api-fsai-bnine.211.45.182.26.nip.io',
        headers: {
          'accept': 'application/json'
        }
      });

      const params = {
        skip: 0,
        limit: 1000,
        start_date: searchParams?.startDate?.replace(/-/g, ''),
        end_date: searchParams?.endDate?.replace(/-/g, ''),
        installment: searchParams?.installment || '',
        payment_type: searchParams?.paymentType || '',
        merchant_id: searchParams?.merchantMID || '',
        terminal_id: searchParams?.merchantTID || '',
      };

      const response = await instance.get('/payments/', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching all data:', error);
      return null;
    }
  };

  // 클립보드 복사 및 알림 함수
  const handleDateClick = (payment) => {
    const formattedDate = payment.trxDate.replace(
      /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      '$1-$2-$3 $4:$5:$6'
    );
    
    const message = `민원건 안내드립니다.\n\n거래일시: ${formattedDate}\n카드사: ${payment.issuer}\n승인번호: ${payment.authCd}\n결제금액: ${Number(payment.amount).toLocaleString()}원`;
    
    navigator.clipboard.writeText(message).then(() => {
      setAlertMessage(`클립보드에 복사되었습니다\n\n${message}`);
      setOpenSnackbar(true);
    });
  };

  // 알림 닫기 함수
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // 승인번호 클릭 핸들러 추가
  const handleAuthClick = (payment) => {
    const message = `승인번호: ${payment.authCd}\n입금액: ${Number(payment.amount).toLocaleString()}원`;
    
    navigator.clipboard.writeText(message).then(() => {
      setAlertMessage(`클립보드에 복사되었습니다\n\n${message}`);
      setOpenSnackbar(true);
    });
  };

  // handleSelectAll 함수 추가
  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      const allApprovalNos = sortedPayments.map(payment => payment.regno);
      setSelectedApprovals(allApprovalNos);
    } else {
      setSelectedApprovals([]);
    }
  };

  // handleTidClick 함수 추가
  const handleTidClick = () => {
    setIsCardLimitOpen(true);
  };

  // 메인 코드
  return (
    <Paper sx={{ 
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: 'none',
      border: '1px solid #e9ecef',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <Box 
        className="cancel-options-section"
        sx={{ 
          display: 'flex',
          gap: 4,
          backgroundColor: '#f8f9fa',
          padding: '20px',
          borderRadius: '8px',
          mb: 3,
          width: '100%',
          '@media (max-width: 600px)': {
            overflowX: 'auto',
            WebkitOverflowScrolling: 'touch',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.2)',
              borderRadius: '3px'
            }
          }
        }}
      >
        <Box sx={{ 
          display: 'flex',
          gap: 2,
          '@media (max-width: 600px)': {
            minWidth: 'max-content'  // 내용이 잘리지 않도록 최소 너비 설정
          }
        }}>
          <CancelOptions {...cancelOptionsProps} />
          <Box sx={{ borderLeft: '1px solid #ddd' }} />
          <MerchantIcons onIconClick={onIconClick} />
        </Box>
      </Box>

      <StyledTableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell 
                className="primary-header" 
                align="center" 
                padding="checkbox"
                sx={{
                  backgroundColor: '#1976d2',  // FailHistoryTable과 동일한 헤더 색상
                  '& .MuiCheckbox-root': {
                    color: '#ffffff',
                    '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                      color: '#ffffff',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.2rem'
                    }
                  }
                }}
              >
                <Checkbox
                  checked={selectAll}
                  indeterminate={selectedApprovals.length > 0 && selectedApprovals.length < sortedPayments.length}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  size="small"
                />
              </StyledTableCell>
              <StyledTableCell className="primary-header" align="left">가맹점명</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">거래일시</StyledTableCell>
              <StyledTableCell 
                className="primary-header" 
                data-field="amount"
                align="right"
                onClick={handleSortClick}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#1565c0'
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  minHeight: '48px', // 전체 높이 최소값 설정                  
                  gap: '4px'
                }}
              >
                결제금액
                {sortDirection === 'asc' && '↑'}
                {sortDirection === 'desc' && '↓'}
                {sortDirection === null && '-'}
              </StyledTableCell>
              <StyledTableCell className="primary-header" align="left">할부</StyledTableCell>
              <StyledTableCell 
                className="primary-header" 
                align="left"
                sortDirection={false}
              >
                승인번호
              </StyledTableCell>
              <StyledTableCell className="primary-header" align="left">카드사</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">카드번호</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">상품</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">연락처</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">영수증</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">결제형식</StyledTableCell>
              
              <StyledTableCell className="secondary-header" align="left">계정/상세</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">수수료(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">월렛잔액</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">정산금(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">지급액(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">유보액(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">환입금</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">미수금</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">보증금(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="left">PG</StyledTableCell>
              <StyledTableCell className="secondary-header" align="left">MID</StyledTableCell>
              <StyledTableCell className="secondary-header" align="left">TID</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortedPayments.map((payment) => (
              <StyledTableRow 
                key={payment.regno}
                className={Number(payment.amount) < 0 ? 'negative-amount' : ''}
              >
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    checked={selectedApprovals.includes(payment.regno)}
                    onChange={(e) => handleCheckboxChange(e.target.checked, payment.regno)}
                    size="small"
                  />
                </StyledTableCell>
                <StyledTableCell>{payment.cname}</StyledTableCell>
                <ClickableCell onClick={() => handleDateClick(payment)}>
                  {payment.trxDate.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6')}
                </ClickableCell>
                <StyledTableCell align="right">{Number(payment.amount).toLocaleString()}원</StyledTableCell>
                <StyledTableCell>
                  {payment.installment === '00' 
                    ? '일시불' 
                    : `${Number(payment.installment)}개월`
                  }
                </StyledTableCell>
                <ClickableCell onClick={() => handleAuthClick(payment)}>
                  {payment.authCd}
                </ClickableCell>
                <StyledTableCell>{payment.issuer}</StyledTableCell>
                <StyledTableCell>
                  {payment.issuer === '농협카드' 
                    ? payment.bin
                        .replace(/^(\d{6})\d{6}(\d{4})$/, '$1******$2')
                        .replace(/(\d{4})([0-9*]{4})([0-9*]{4})([0-9*]{4})/, '$1-$2-$3-$4')
                    : payment.bin
                        .replace(/(\d{4})([0-9*]{4})([0-9*]{4})([0-9*]{4})/, '$1-$2-$3-$4')
                  }
                </StyledTableCell>
                <StyledTableCell>{payment.goodsname}</StyledTableCell>
                <StyledTableCell>{payment.usertel}</StyledTableCell>
                <StyledTableCell>
                  <ReceiptIconButton onClick={() => setShowReceipt(true)} />
                </StyledTableCell>
                <StyledTableCell>
                  {payment.pay_type === '2' ? '단말기' : payment.pay_type === '1' ? '수기' : payment.pay_type}
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                    <Tooltip title="결제 상세보기">
                      <IconButton 
                        size="small" 
                        onClick={() => handleCardDetailClick(payment)}
                        sx={IconButtonStyle}
                      >
                        <CreditCardIcon sx={{ fontSize: 20, color: '#2185d0' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="사용자 정보">
                      <IconButton 
                        size="small" 
                        onClick={() => handleUserDetailClick(payment)}
                        sx={IconButtonStyle}
                      >
                        <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{`${payment.admin_fee} / ${payment.payfee}%`}</StyledTableCell>
                <StyledTableCell>{Number(payment.balance_com).toLocaleString()}</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>{Number(payment.balance_refund).toLocaleString()}</StyledTableCell>
                <StyledTableCell>{Number(payment.balance_mem).toLocaleString()}</StyledTableCell>
                <StyledTableCell>{`${payment.balance_block} / ${payment.deposit}%`}</StyledTableCell>
                <StyledTableCell>{payment.van}</StyledTableCell>
                <StyledTableCell>{payment.mchtId}</StyledTableCell>
                <ClickableCell onClick={handleTidClick}>
                  {payment.tmnId}
                </ClickableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>

      <RMquestion
        open={isRMModalOpen}
        onClose={() => setIsRMModalOpen(false)}
        data={selectedMerchantData}
      />
      
      <UserInfoOne
        open={userInfoOneOpen}
        onClose={() => setUserInfoOneOpen(false)}
        data={selectedRowData}
      />
      
      {userInfoTwoOpen && (
        <UserInfoTwo
          open={userInfoTwoOpen}
          onClose={() => setUserInfoTwoOpen(false)}
          businessName={selectedMerchantName}
        />
      )}

      <Modal
        open={isSetOffModalOpen}
        onClose={() => setIsSetOffModalOpen(false)}
        aria-labelledby="setoff-modal-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiModal-backdrop': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <Box sx={{ 
          width: '95%',
          maxWidth: '1300px',
          minWidth: '1000px',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          height: '90vh',
          overflow: 'hidden'
        }}>
          <SetOff onClose={() => setIsSetOffModalOpen(false)} />
        </Box>
      </Modal>

      <StyledTablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ from, to, count }) => {
          const actualFrom = page * rowsPerPage + 1;
          const actualTo = Math.min((page + 1) * rowsPerPage, count);
          return `전체 ${count.toLocaleString()}건 중 ${actualFrom.toLocaleString()}-${actualTo.toLocaleString()}건`;
        }}
        sx={{
          borderTop: '1px solid #e9ecef',
          '.MuiTablePagination-toolbar': {
            backgroundColor: '#ffffff',
            minHeight: '48px'
          }
        }}
      />

      <Dialog 
        open={showReceipt} 
        onClose={() => setShowReceipt(false)}
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'visible'
          }
        }}
      >
        <Bill1 onClose={() => setShowReceipt(false)} />
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StyledAlert 
          onClose={handleCloseSnackbar} 
          severity="success"
          sx={{ 
            minWidth: '400px',
            backgroundColor: '#e8f5e9',
            color: '#1b5e20',
            border: '1px solid #a5d6a7',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            '& .MuiAlert-icon': {
              color: '#2e7d32'
            }
          }}
        >
          {alertMessage}
        </StyledAlert>
      </Snackbar>

      <CardLimitChange
        open={isCardLimitOpen}
        onClose={() => setIsCardLimitOpen(false)}
      />
    </Paper>
  );
}

PaymentHistoryTable.propTypes = {
  searchParams: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    limit: PropTypes.number
  }),
  onCheckboxChange: PropTypes.func,
  selectedApprovals: PropTypes.array,
  setSelectedApprovals: PropTypes.func,
  searchPanelOpen: PropTypes.bool,
  onCancelOption: PropTypes.func,
  onIconClick: PropTypes.func,
  onReceiptClick: PropTypes.func,
  limit: PropTypes.number,
  onDataUpdate: PropTypes.func
};

export default PaymentHistoryTable;