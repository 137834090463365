import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import QuickMenu from '../../Common/QuickMenu';
import SearchOptions from '../../Common/SearchOptions';
import Notice from '../../Notice/Notice';
import ComplaintDetailsTables from './ComplaintDetailsTables';
import CurrentLocation from '../../Common/CurrentLocation';
import dayjs from 'dayjs';

// 메인 함수
function ComplaintDetails() {
  // 상태값 정의
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [selectedRows, setSelectedRows] = useState([]);
  
  // searchConditions 초기값 설정
  const [searchConditions, setSearchConditions] = useState({
    // 날짜 관련
    startDate: '2025-02-01',
    endDate: '2025-02-28',
    
    // 검색 텍스트
    searchText: '',
    
    // 토글 옵션
    selectedToggleOptions: [],
    
    // 구분 필터
    sourceType: '',        // 원천구분
    businessType: '',      // 사업자구분
    taxType: '',          // 과세구분
    depositType: '',      // 입금구분
    recurringType: '',    // 정기결제구분
    duplicateType: '',    // 중복결제구분
    walletType: '',       // 월렛사용구분
    
    // PG 관련
    pgCompany: '',        // PG사
    pgType: '',          // PG구분
    
    // 결제 관련
    paymentMethod: '',    // 결제수단
    paymentType: '',      // 결제형태
    installmentType: '',  // 할부구분
    
    // 상태 관련
    complaintType: '',    // 민원종류
    completionStatus: '',  // 완료여부
    approvalStatus: '',   // 승인상태
    cancelStatus: '',     // 취소상태
    
    // 정산 관련
    settlementStatus: '', // 정산상태
    settlementType: '',   // 정산구분
    
    // 기타
    branchName: '',       // 지사명
    merchantName: '',     // 가맹점명
    agencyName: '',       // 대리점명
    
    // 금액 관련
    minAmount: '',        // 최소금액
    maxAmount: '',        // 최대금액
    
    // 수수료 관련
    commissionType: '',   // 수수료구분
    commissionRate: '',   // 수수료율
  });

  // 사이즈감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 처리 함수
  const handleSearch = (searchParams) => {
    console.log('ComplaintDetails - 받은 검색 파라미터:', searchParams);
    
    // 날짜 형식 변환
    const formattedStartDate = searchParams.startDate ? 
      dayjs(searchParams.startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = searchParams.endDate ? 
      dayjs(searchParams.endDate).format('YYYY-MM-DD') : '';

    const newSearchConditions = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      searchText: searchParams.searchText || '',
      pgCompany: searchParams.pgCompany || '',  // PG사 필터
      branch: searchParams.branch || '',        // 지사명 필터
      // 기존 검색 조건들 유지
      ...searchParams
    };

    console.log('테이블로 전달하는 검색 조건:', newSearchConditions);
    setSearchConditions(newSearchConditions);
  };

  // 스타일 정의
  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  // 메인 코드
  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="거래민원 내역" />
        <ComplaintDetailsTables 
          searchConditions={searchConditions}  // searchParams 대신 searchConditions로 변경
          searchPanelOpen={searchPanelOpen}
          setSearchPanelOpen={setSearchPanelOpen}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Box>

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default ComplaintDetails;