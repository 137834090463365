import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, Grid, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { useMediaQuery } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// 스타일 정의
const useStyles = makeStyles((theme) => ({
  statsCard: {
    marginBottom: '20px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    backgroundColor: '#fff',
    transition: 'transform 0.2s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
      transform: 'translateY(-4px)'
    }
  },
  statsHeader: {
    color: '#428bca',
    padding: '10px',
    width: '100%',
    margin: '0 auto',
    fontSize: '2rem',
    fontWeight: 700,
    background: '#f4f4f4',
    border: '1px solid #dddddd',
    borderBottom: 'none',
    textAlign: 'center',
  },
  statsHeaderDanger: {
    color: '#ff3333',
    padding: '10px',
    width: '100%',
    margin: '0 auto',
    marginTop: '0px',
    fontSize: '2rem',
    fontWeight: 700,
    background: '#f4f4f4',
    border: '1px solid #dddddd',
    borderTop: '1px solid #dddddd',
    borderBottom: 'none',
    textAlign: 'center',
  },
  statsRow: {
    display: 'flex',
    border: '1px solid #DDDDDD',
    padding: '17px',
    margin: 0,
    borderTop: 'none',
    '&:last-child': {
      borderBottom: '1px solid #DDDDDD',
    },
  },
  statsLabel: {
    flex: 1,
    fontSize: '2rem',
    textAlign: 'left',
  },
  statsValue: {
    flex: 3,
    fontSize: '1.8rem',
    textAlign: 'right',
    // fontFamilly: 'Montserrat, sans-serif',
    fontWeight: 600,
    color: '#2c3e50',
    letterSpacing: '0.5px'
  },
  statsValuePrimary: {
    flex: 3,
    fontSize: '1.8rem',
    textAlign: 'right',
    // fontFamilly: 'Montserrat, sans-serif',
    fontWeight: 600,
    color: '#428bca',
    letterSpacing: '0.5px'
  },
  statsValueDanger: {
    flex: 3,
    fontSize: '1.8rem',
    textAlign: 'right',
    // fontFamilly: 'Montserrat, sans-serif',
    fontWeight: 600,
    color: '#ff3333',
    letterSpacing: '0.5px'
  },
  chartCard: {
    height: '400px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    backgroundColor: '#fff',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateY(-4px)'
    }
  },
  chartHeader: {
    padding: '15px',
    '& h4': {
      margin: 0,
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  },
  chartContainer: {
    position: 'relative',
    height: 'calc(100% - 60px)',
    padding: '15px',
  },
}));

// ChartJS 컴포넌트 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TransactionStats = ({ isEmbedded = false }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:780px)');

  // 오늘 거래액 데이터
  const todayData = {
    total: "55,471,550",
    manual: "24,460,000",
    terminal: "31,011,550",
    online: "0",
    settlement: "0"
  };

  // 차트 데이터 - 기존 3개 데이터셋 유지
  const chartData = {
    labels: ["1일", "2일", "3일", "4일", "5일", "6일", "7일"],
    datasets: [
      {
        label: "승인금액",
        borderColor: "#4CAF50",
        backgroundColor: "rgba(76, 175, 80, 0.05)",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#4CAF50",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        borderWidth: 2,
        data: [50, 150, 100, 190, 130, 90, 150]
      },
      {
        label: "취소금액",
        borderColor: "#f44336",
        backgroundColor: "rgba(244, 67, 54, 0.05)",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#f44336",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        borderWidth: 2,
        data: [20, 40, 30, 50, 35, 25, 45]
      },
      {
        label: "순승인금액",
        borderColor: "#2196F3",
        backgroundColor: "rgba(33, 150, 243, 0.05)",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#2196F3",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        borderWidth: 2,
        data: [30, 110, 70, 140, 95, 65, 105]
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#666666',
          font: {
            size: 12
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#666666",
          font: {
            size: 11
          }
        }
      },
      x: {
        ticks: {
          color: "#666666",
          font: {
            size: 11
          }
        }
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: isEmbedded ? 0 : 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{
          width: '100%',
          flexBasis: isMobile ? '100%' : 'auto'
        }}>
          <Card className={classes.statsCard} sx={{ height: '100%' }}>
            <Box className={classes.statsHeader}>
              <Typography>오늘 거래액</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}>합계</Typography>
              <Typography className={classes.statsValuePrimary}>{todayData.total}원</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}>수기</Typography>
              <Typography className={classes.statsValue}>{todayData.manual}원</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}>단말기</Typography>
              <Typography className={classes.statsValue}>{todayData.terminal}원</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}>온라인</Typography>
              <Typography className={classes.statsValue}>{todayData.online}원</Typography>
            </Box>
            <Box className={classes.statsHeaderDanger} sx={{ borderTop: '1px solid #dddddd' }}>
              <Typography>오늘 정산예정액</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}></Typography>
              <Typography className={classes.statsValueDanger}>{todayData.settlement}원</Typography>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} sx={{
          width: '100%',
          flexBasis: isMobile ? '100%' : 'auto'
        }}>
          <Card className={classes.chartCard} sx={{ 
            height: isMobile ? '400px' : '100%',
            minHeight: isMobile ? '400px' : 'auto'
          }}>
            <Box className={classes.chartHeader}>
              <Typography variant="h4">거래액 추이</Typography>
            </Box>
            <Box className={classes.chartContainer}>
              <Line data={chartData} options={{
                ...chartOptions,
                maintainAspectRatio: false,
                responsive: true
              }} />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionStats;