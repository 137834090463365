//RM온보드
import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  IconButton,
  Typography,
  styled,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Button,
  TableHead,
  ImageList,
  ImageListItem,
  Modal
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';

// 민원 모달 컴포넌트
const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '1200px',
    maxWidth: '95vw',
    height: '95vh',
    maxHeight: '95vh',
    overflow: 'hidden'
  },
  '& .MuiDialogTitle-root': {
    position: 'static',
    backgroundColor: 'white',
    borderBottom: '1px solid #e9ecef'
  }
});

// 테이블 스타일
const StyledTableCell = styled(TableCell)({
  padding: '8px 16px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  '&:last-child': {
    borderRight: 'none'
  },
  '&.header': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    color: '#495057'
  }
});
  
// 테이블 컨테이너 스타일
const TableContainer = styled(Box)({
  flex: 1,
  minWidth: '48%',  // 두 테이블이 나란히 오도록
  marginBottom: '20px'
});

// 선택 스타일
const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '8px 14px',
    fontSize: '0.875rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#e0e0e0',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#bdbdbd',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
  },
  backgroundColor: 'white'
});

// 메뉴 아이템 스타일
const StyledMenuItem = styled(MenuItem)({
  fontSize: '0.875rem',
  '&.Mui-selected': {
    backgroundColor: '#f5f5f5'
  }
});

// 미리보기 모달 스타일
const PreviewModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// 미리보기 이미지 스타일
const PreviewImage = styled('img')({
  maxWidth: '90vw',
  maxHeight: '90vh',
  objectFit: 'contain'
});

// 상태값 세팅.
const RMquestion = ({ open, onClose, data }) => {
  const [complaintType, setComplaintType] = React.useState('선택');
  const [processStatus, setProcessStatus] = React.useState('대기중');
  const [previewImage, setPreviewImage] = React.useState(null);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  // 임시 첨부파일 데이터
  const attachments = [
    {
      id: 1,
      name: '영수증.jpg',
      url: 'https://via.placeholder.com/150',
      type: 'image/jpeg'
    }
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setPreviewImage(null);
    const fileInput = document.getElementById('file-upload');
    if (fileInput) fileInput.value = '';
  };

  const handlePreviewClick = (image) => {
    setSelectedImage(image);
    setPreviewOpen(true);
  };

  const handleDownload = (file) => {
    // 실제 구현에서는 서버에서 파일을 다운로드
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        <DialogTitle sx={{ 
          position: 'static',
          borderBottom: '1px solid #e9ecef',
          padding: '16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          zIndex: 'auto'
        }}>
          <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
            민원 내역
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ 
          padding: '24px',
          position: 'static',
          overflowY: 'auto',
          height: 'calc(100% - 64px)'
        }}>
          {/* 가맹점 정보 타이틀 - 테이블 바깥에 위치 */}
          <Box sx={{ 
            width: '100%',
            borderBottom: '1px solid #e9ecef',
            pb: 1,
            mb: 3,
            mt: 2,
          }}>
            <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
              가맹점 정보
            </Typography>
          </Box>

          {/* 테이블 컨테이너 */}
          <Box sx={{ 
            display: 'flex', 
            gap: '24px',
            mb: 3
          }}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <StyledTableCell className="header" width="30%">가맹점명</StyledTableCell>
                    <StyledTableCell>{data?.merchantName}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">로그인ID</StyledTableCell>
                    <StyledTableCell>{data?.loginId}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">연락처</StyledTableCell>
                    <StyledTableCell>{data?.phone}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">휴대전화</StyledTableCell>
                    <StyledTableCell>{data?.mobile}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">주소</StyledTableCell>
                    <StyledTableCell>{data?.address}</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* 지사 정보 테이블 */}
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <StyledTableCell className="header" width="30%">지사명</StyledTableCell>
                    <StyledTableCell>{data?.branchName}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">지사ID</StyledTableCell>
                    <StyledTableCell>{data?.branchLoginId}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">연락처</StyledTableCell>
                    <StyledTableCell>{data?.branchPhone}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">휴대전화</StyledTableCell>
                    <StyledTableCell>{data?.branchMobile}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">주소</StyledTableCell>
                    <StyledTableCell>{data?.branchAddress}</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* 하단 테이블 영역 */}
          <Box sx={{ 
            display: 'flex', 
            gap: '24px'
          }}>
            {/* 수수료 정보 테이블 */}
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <StyledTableCell className="header" width="30%">수수료</StyledTableCell>
                    <StyledTableCell>8.0000</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">정산주기(일)</StyledTableCell>
                    <StyledTableCell>1</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">정산상태(원)</StyledTableCell>
                    <StyledTableCell>미정산</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">RM횟수</StyledTableCell>
                    <StyledTableCell>2</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">수수료율(%)</StyledTableCell>
                    <StyledTableCell>3.5</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* 상태 정보 테이블 */}
            <TableContainer sx={{ display: 'flex', flexDirection: 'column' }}>
              <Table size="small" sx={{ flex: '0 0 auto' }}>
                <TableBody>
                  <TableRow>
                    <StyledTableCell className="header" width="30%">업종</StyledTableCell>
                    <StyledTableCell>테스트</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">품목</StyledTableCell>
                    <StyledTableCell>품목</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">결제형식</StyledTableCell>
                    <StyledTableCell>수기</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell className="header">���증방식</StyledTableCell>
                    <StyledTableCell>구인증</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box sx={{ flex: '1 1 auto' }} /> {/* 백을 위한 빈 공간 */}
            </TableContainer>
          </Box>

          {/* 민원 접수 섹션 */}
          <Box sx={{ 
            borderBottom: '1px solid #e9ecef',
            pb: 1,
            mb: 3,
            mt: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
              민원 접수
            </Typography>
          </Box>

          

          {/* 민 테이블을 나란히 배치하기 위한 그리드 컨테이너 */}
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '16px',
            mb: 3
          }}>
            {/* 첫 번째 테이블 */}
            <Table size="small" sx={{ border: '1px solid #e0e0e0' }}>
              <TableBody>
                <TableRow>
                  <StyledTableCell className="header" width="30%">거래일시</StyledTableCell>
                  <StyledTableCell>{data?.transactionDate}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">회신기한</StyledTableCell>
                  <StyledTableCell>{data?.replyDate}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">고객명</StyledTableCell>
                  <StyledTableCell>{data?.customerName}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">결제연락처</StyledTableCell>
                  <StyledTableCell>{data?.phone}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">민원종류</StyledTableCell>
                  <StyledTableCell>
                    <StyledSelect
                      value={complaintType}
                      onChange={(e) => setComplaintType(e.target.value)}
                      size="small"
                      fullWidth
                    >
                      <StyledMenuItem value="선택">선택</StyledMenuItem>
                      <StyledMenuItem value="결제취소">카드사</StyledMenuItem>
                      <StyledMenuItem value="액오류">PG사</StyledMenuItem>
                      <StyledMenuItem value="금액오류">인콜</StyledMenuItem>
                      <StyledMenuItem value="기타">기타</StyledMenuItem>
                    </StyledSelect>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">처리여부</StyledTableCell>
                  <StyledTableCell>
                    <StyledSelect
                      value={processStatus}
                      onChange={(e) => setProcessStatus(e.target.value)}
                      size="small"
                      fullWidth
                      sx={{
                        backgroundColor: 
                          processStatus === '선택' ? '#ffebee' :
                          processStatus === '대기중' || processStatus === '응답필요' ? '#e3f2fd' :
                          processStatus === '취소완료' || processStatus === '부분취소완료' || processStatus === '민원취하' ? '#e8f5e9' :
                          processStatus === '보완요청' || processStatus === '반려' ? '#fff0f0' :
                          'white'
                      }}
                    >
                      <StyledMenuItem 
                        value="선택"
                        sx={{ 
                          '&.Mui-selected': { backgroundColor: '#ffebee' },
                          '&.Mui-selected:hover': { backgroundColor: '#ffcdd2' }
                        }}
                      >
                        선택
                      </StyledMenuItem>
                      <StyledMenuItem 
                        value="대기중"
                        sx={{ 
                          '&.Mui-selected': { backgroundColor: '#e3f2fd' },
                          '&.Mui-selected:hover': { backgroundColor: '#bbdefb' }
                        }}
                      >
                        대기중
                      </StyledMenuItem>
                      <StyledMenuItem 
                        value="응답필요"
                        sx={{ 
                          '&.Mui-selected': { backgroundColor: '#e3f2fd' },
                          '&.Mui-selected:hover': { backgroundColor: '#bbdefb' }
                        }}
                      >
                        응답필요
                      </StyledMenuItem>
                      <StyledMenuItem 
                        value="취소완료"
                        sx={{ 
                          '&.Mui-selected': { backgroundColor: '#e8f5e9' },
                          '&.Mui-selected:hover': { backgroundColor: '#c8e6c9' }
                        }}
                      >
                        취소완료
                      </StyledMenuItem>
                      <StyledMenuItem 
                        value="부분취소완료"
                        sx={{ 
                          '&.Mui-selected': { backgroundColor: '#e8f5e9' },
                          '&.Mui-selected:hover': { backgroundColor: '#c8e6c9' }
                        }}
                      >
                        부분취소완료
                      </StyledMenuItem>
                      <StyledMenuItem 
                        value="민원취하"
                        sx={{ 
                          '&.Mui-selected': { backgroundColor: '#e8f5e9' },
                          '&.Mui-selected:hover': { backgroundColor: '#c8e6c9' }
                        }}
                      >
                        민원취하
                      </StyledMenuItem>
                      <StyledMenuItem 
                        value="보완요청"
                        sx={{ 
                          '&.Mui-selected': { backgroundColor: '#fff0f0' },
                          '&.Mui-selected:hover': { backgroundColor: '#ffe6e6' }
                        }}
                      >
                        보완요청
                      </StyledMenuItem>
                      <StyledMenuItem 
                        value="반려"
                        sx={{ 
                          '&.Mui-selected': { backgroundColor: '#fff0f0' },
                          '&.Mui-selected:hover': { backgroundColor: '#ffe6e6' }
                        }}
                      >
                        반려
                      </StyledMenuItem>
                    </StyledSelect>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>

            {/* 두 번째 테이블 */}
            <Table size="small" sx={{ border: '1px solid #e0e0e0' }}>
              <TableBody>
                <TableRow>
                  <StyledTableCell className="header" width="30%">승인번호</StyledTableCell>
                  <StyledTableCell>{data?.approvalNo}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">승인금액</StyledTableCell>
                  <StyledTableCell>{data?.amount}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">카드사</StyledTableCell>
                  <StyledTableCell>{data?.cardCompany}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">할부개월</StyledTableCell>
                  <StyledTableCell>{data?.installment}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">접속아이디</StyledTableCell>
                  <StyledTableCell>{data?.loginId}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">등록일</StyledTableCell>
                  <StyledTableCell>{data?.registrationDate}</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          {/* 민원인연락처와 민원품목을 위한 1행 */}
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '16px',
            mb: 2
          }}>
            <TextField
              label="민원인연락처"
              size="small"
              fullWidth
              sx={{ backgroundColor: 'white' }}
            />
            <TextField
              label="민원품목"
              size="small"
              fullWidth
              sx={{ backgroundColor: 'white' }}
            />
          </Box>

          {/* 민원내용 텍스트필드 */}
          <TextField
            label="민원내용"
            multiline
            rows={4}
            fullWidth
            sx={{ mt: 2 }}
          />

          {/* 회신내역 섹 */}
          <Box sx={{ 
            borderBottom: '1px solid #e9ecef',
            pb: 1,
            mb: 3,
            mt: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
              회신내역
            </Typography>
          </Box>

          {/* 등록자와 이메일 입력 필드 */}
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '16px',
            mb: 2
          }}>
            <TextField
              label="등록자"
              size="small"
              value="b19006"
              fullWidth
              sx={{ backgroundColor: 'white' }}
            />
            <TextField
              label="이메일"
              size="small"
              fullWidth
              sx={{ backgroundColor: 'white' }}
            />
          </Box>

          {/* 회신내용과 첨부파�� */}
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: '3fr 1fr',
            gap: '16px',
            mb: 3,
            alignItems: 'start'
          }}>
            <TextField
              label="회신내용"
              multiline
              rows={4}
              fullWidth
              sx={{ backgroundColor: 'white' }}
            />
            <Box sx={{ 
              border: '1px dashed #ccc',
              borderRadius: 1,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              height: '100%',
              boxSizing: 'border-box'
            }}>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                id="file-upload"
                onChange={handleFileUpload}
              />
              <label htmlFor="file-upload">
                <Button
                  variant="outlined"
                  component="span"
                  size="small"
                >
                  파일 선택
                </Button>
              </label>
              <Box sx={{ 
                width: '100%',
                height: '80px',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative'
              }}>
                {previewImage ? (
                  <>
                    <img 
                      src={previewImage} 
                      alt="Preview" 
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                      }}
                    />
                    <IconButton
                      size="small"
                      onClick={handleRemoveImage}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: '2px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.9)'
                        }
                      }}
                    >
                      <CloseIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </>
                ) : (
                  '미리보기'
                )}
              </Box>
            </Box>
          </Box>

          {/* 계정목록 선택 - 오른쪽 정렬 및 크기 조정 */}
          <Box sx={{ 
            mb: 2,
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <FormControl size="small" sx={{ width: '200px' }}>
              <Select
                value="계정목록"
                sx={{ 
                  backgroundColor: 'white',
                  '& .MuiSelect-select': {
                    padding: '8px 12px'
                  }
                }}
              >
                <MenuItem value="계정목록">계정목록</MenuItem>
                <MenuItem value="영업3">영업3</MenuItem>
                <MenuItem value="영업2">영업2</MenuItem>
                <MenuItem value="영업1">영업1</MenuItem>
                <MenuItem value="리테일러">리테일러</MenuItem>
                <MenuItem value="대리점">대리점</MenuItem>
                <MenuItem value="총판">총판</MenuItem>
                <MenuItem value="지사">지사</MenuItem>
                <MenuItem value="마스터">마스터</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* 회신내역 테이블 */}
          <TableContainer sx={{ mb: 3 }}>
            <Table size="small" sx={{ border: '1px solid #e0e0e0' }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" sx={{ backgroundColor: '#f5f5f5' }}>회신회차</StyledTableCell>
                  <StyledTableCell align="center" sx={{ backgroundColor: '#f5f5f5' }}>내용</StyledTableCell>
                  <StyledTableCell align="center" sx={{ backgroundColor: '#f5f5f5' }}>첨부파일</StyledTableCell>
                  <StyledTableCell align="center" sx={{ backgroundColor: '#f5f5f5' }}>처리일시</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center">1</StyledTableCell>
                  <StyledTableCell>고객님 문의신 내용 확인했습니다.</StyledTableCell>
                  <StyledTableCell align="center">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                      <Box 
                        component="img" 
                        src="https://via.placeholder.com/150"
                        sx={{ 
                          width: 40, 
                          height: 40, 
                          cursor: 'pointer',
                          borderRadius: 1,
                          objectFit: 'cover'
                        }}
                        onClick={() => handlePreviewClick({
                          url: "https://via.placeholder.com/800",
                          fileName: "첨부파일1.jpg"
                        })}
                      />
                      <IconButton 
                        size="small" 
                        onClick={() => handleDownload({
                          url: "https://via.placeholder.com/800",
                          fileName: "첨부파일1.jpg"
                        })}
                        sx={{ color: '#1976d2' }}
                      >
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">2024-01-15 14:30:00</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">2</StyledTableCell>
                  <StyledTableCell>처리가 완료되었습니다.</StyledTableCell>
                  <StyledTableCell align="center">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                      <Box 
                        component="img" 
                        src="https://via.placeholder.com/150"
                        sx={{ 
                          width: 40, 
                          height: 40, 
                          cursor: 'pointer',
                          borderRadius: 1,
                          objectFit: 'cover'
                        }}
                        onClick={() => handlePreviewClick({
                          url: "https://via.placeholder.com/800",
                          fileName: "첨부파일2.jpg"
                        })}
                      />
                      <IconButton 
                        size="small" 
                        onClick={() => handleDownload({
                          url: "https://via.placeholder.com/800",
                          fileName: "첨부파일2.jpg"
                        })}
                        sx={{ color: '#1976d2' }}
                      >
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">2024-01-16 09:15:00</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </StyledDialog>

      <PreviewModal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
      >
        <Box sx={{ 
          bgcolor: 'background.paper',
          p: 2,
          borderRadius: 1,
          position: 'relative'
        }}>
          <IconButton
            onClick={() => setPreviewOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              bgcolor: 'rgba(0,0,0,0.5)',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.7)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <PreviewImage
              src={selectedImage.url}
              alt={selectedImage.name}
            />
          )}
        </Box>
      </PreviewModal>
    </>
  );
};

export default RMquestion;