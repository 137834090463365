import React, { useState, useMemo } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  IconButton,
  Tooltip,
  Checkbox,
  Typography,
  Card,
  CardContent,
  Button
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import ArticleIcon from '@mui/icons-material/Article';
import DetailInfo from '../../Common/SetModal/Details';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

// 테이블 열 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  height: '28px',
  lineHeight: '1.2',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
  },

  '&.MuiTableCell-paddingCheckbox': {
    width: '32px',
    padding: '0 4px',
    '& .MuiCheckbox-root': {
      padding: '4px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 컨테이너 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  border: '1px solid #e9ecef',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  backgroundColor: '#fff',
  maxHeight: 'calc(100vh - 300px)',
  
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },

  '& .MuiCheckbox-root': {
    padding: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem'
    }
  },

  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

// 스타일 정의
const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px',
    padding: '0',
    margin: '0',
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    minWidth: 'auto',
    '& .MuiTableCell-root': {
      padding: '6px 4px',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '4px 3px',
        fontSize: '0.75rem'
      }
    }
  }
};

// 테이블 헤더 데이터
const tableHeaders = [
  { id: 'checkbox', label: '일괄체크', align: 'center', color: '#1976d2', textColor: '#fff' },
  { id: 'no', label: 'No', align: 'center', color: '#1976d2', textColor: '#fff' },
  { id: 'merchantMid', label: '가맹점MID', align: 'left', color: '#1976d2', textColor: '#fff' },
  { id: 'merchantTid', label: '가맹점TID', align: 'left', color: '#1976d2', textColor: '#fff' },
  { id: 'merchantName', label: '가맹점명', align: 'left', color: '#1976d2', textColor: '#fff' },
  { id: 'totalCount', label: '총 매출건수', align: 'right', color: '#03a9f4', textColor: '#fff' },
  { id: 'cancelCount', label: '취소건수', align: 'right', color: '#03a9f4', textColor: '#fff' },
  { id: 'detailInfo', label: '세부내역', align: 'center', color: '#03a9f4', textColor: '#fff' },
  { id: 'checkedCount', label: '세부체크건수', align: 'center', color: '#03a9f4', textColor: '#fff' },
  { id: 'approvalAmount', label: '승인금액', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'cancelAmount', label: '취소금액', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'actualSales', label: '실매출금액', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'merchantFeeRate', label: '가맹점수수료', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'feeAmount', label: '수수료(VAT포함)', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'depositRate', label: '보증금율', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'depositAmount', label: '보증금액', align: 'right', color: '#ffffff', textColor: '#000' },
  { id: 'paymentAmount', label: '입금액', align: 'center', color: '#ffffff', textColor: '#000' }
];

// 더미데이터 정의
const dummyData = [
  {
    no: 1,
    merchantMid: 'KS0001234',
    merchantTid: 'TR678901234',
    merchantName: '농부고을2',
    totalCount: 5,
    cancelCount: 1,
    detailInfo: '보기',
    checkedCount: 3,
    approvalAmount: '5,000',
    cancelAmount: '0',
    actualSales: '789,223,868',
    merchantFeeRate: '3.52%',
    feeAmount: '4,553',
    depositRate: '3%',
    depositAmount: '225,333',
    paymentAmount: '232,444'
  },
  {
    no: 2,
    merchantMid: 'M432109876',
    merchantTid: 'TRK2024031900002',
    merchantName: '애플49',
    totalCount: 7,
    cancelCount: 2,
    detailInfo: '보기',
    checkedCount: 4,
    approvalAmount: '35,000',
    cancelAmount: '224',
    actualSales: '546,222,567',
    merchantFeeRate: '2.41%',
    feeAmount: '2,556',
    depositRate: '6%',
    depositAmount: '775,641',
    paymentAmount: '654,373'
  },
  {
    no: 3,
    merchantMid: 'SPC107836',
    merchantTid: 'TR567890123',
    merchantName: '고기진심정육점',
    totalCount: 8,
    cancelCount: 3,
    detailInfo: '보기',
    checkedCount: 5,
    approvalAmount: '83,333',
    cancelAmount: '333,444',
    actualSales: '789,223,868',
    merchantFeeRate: '3.52%',
    feeAmount: '7,888',
    depositRate: '8%',
    depositAmount: '225,333',
    paymentAmount: '232,444'
  },
  {
    no: 4,
    merchantMid: 'SPC_112014',
    merchantTid: 'TR567890124',
    merchantName: '골드스파사우나',
    totalCount: 22,
    cancelCount: 4,
    detailInfo: '보기',
    checkedCount: 6,
    approvalAmount: '176,000',
    cancelAmount: '0',
    actualSales: '546,222,567',
    merchantFeeRate: '2.41%',
    feeAmount: '2,556',
    depositRate: '10%',
    depositAmount: '775,641',
    paymentAmount: '654,373'
  },
  {
    no: 5,
    merchantMid: 'KS0001235',
    merchantTid: 'TR678901235',
    merchantName: '일일구세탁',
    totalCount: 5,
    cancelCount: 2,
    detailInfo: '보기',
    checkedCount: 3,
    approvalAmount: '1,000,000',
    cancelAmount: '224',
    actualSales: '789,223,868',
    merchantFeeRate: '3.52%',
    feeAmount: '4,553',
    depositRate: '3%',
    depositAmount: '225,333',
    paymentAmount: '232,444'
  },
  {
    no: 6,
    merchantMid: 'SPC_112015',
    merchantTid: 'TR567890125',
    merchantName: '골드스파사우나 매점',
    totalCount: 7,
    cancelCount: 3,
    detailInfo: '보기',
    checkedCount: 4,
    approvalAmount: '332,111',
    cancelAmount: '333,444',
    actualSales: '546,222,567',
    merchantFeeRate: '2.41%',
    feeAmount: '2,556',
    depositRate: '6%',
    depositAmount: '775,641',
    paymentAmount: '654,373'
  },
  {
    no: 7,
    merchantMid: 'KS0001236',
    merchantTid: 'TR678901236',
    merchantName: '네오플랜 윤병원',
    totalCount: 8,
    cancelCount: 4,
    detailInfo: '보기',
    checkedCount: 5,
    approvalAmount: '225,333',
    cancelAmount: '0',
    actualSales: '789,223,868',
    merchantFeeRate: '3.52%',
    feeAmount: '7,888',
    depositRate: '8%',
    depositAmount: '225,333',
    paymentAmount: '232,444'
  },
  {
    no: 8,
    merchantMid: 'M432109877',
    merchantTid: 'TRK2024031900003',
    merchantName: '케이밴코리아',
    totalCount: 22,
    cancelCount: 2,
    detailInfo: '보기',
    checkedCount: 6,
    approvalAmount: '775,641',
    cancelAmount: '224',
    actualSales: '546,222,567',
    merchantFeeRate: '2.41%',
    feeAmount: '2,556',
    depositRate: '10%',
    depositAmount: '775,641',
    paymentAmount: '654,373'
  },
  {
    no: 9,
    merchantMid: 'SPC107837',
    merchantTid: 'TR567890126',
    merchantName: '네오알파시스템즈 윤병원',
    totalCount: 5,
    cancelCount: 3,
    detailInfo: '보기',
    checkedCount: 3,
    approvalAmount: '232,444',
    cancelAmount: '333,444',
    actualSales: '789,223,868',
    merchantFeeRate: '3.52%',
    feeAmount: '4,553',
    depositRate: '3%',
    depositAmount: '225,333',
    paymentAmount: '232,444'
  },
  {
    no: 10,
    merchantMid: 'SPC_112016',
    merchantTid: 'TR567890127',
    merchantName: '샤론정보통신 이자형',
    totalCount: 7,
    cancelCount: 4,
    detailInfo: '보기',
    checkedCount: 4,
    approvalAmount: '888,999',
    cancelAmount: '0',
    actualSales: '546,222,567',
    merchantFeeRate: '2.41%',
    feeAmount: '2,556',
    depositRate: '6%',
    depositAmount: '775,641',
    paymentAmount: '654,373'
  }
];

// useFilteredData 훅 추가
const useFilteredData = (data, searchConditions) => {
  return useMemo(() => {
    if (!searchConditions) return data;

    return data.filter(row => {
      const searchTextLower = searchConditions.searchText.toLowerCase();
      
      // 텍스트 검색 조건 확인
      const matchesText = 
        (!searchTextLower) ||
        row.merchantName.toLowerCase().includes(searchTextLower) ||
        row.merchantMid.toLowerCase().includes(searchTextLower) ||
        row.merchantTid.toLowerCase().includes(searchTextLower);

      // 날짜 범위 검색 조건 확인
      const rowDate = dayjs(row.requestDate);
      const startDate = dayjs(searchConditions.startDate).startOf('day');
      const endDate = dayjs(searchConditions.endDate).endOf('day');
      const matchesDate = rowDate.isBetween(startDate, endDate, 'day', '[]');

      return matchesText && matchesDate;
    });
  }, [data, searchConditions]);
};

// 메인 함수
function RestoreTables({ searchConditions }) {
  // 상세 내역 열기 상태
  const [detailInfoOpen, setDetailInfoOpen] = useState(false);
  // 전체 선택 상태
  const [selectAll, setSelectAll] = useState(false);
  // 선택된 항목 상태
  const [selectedItems, setSelectedItems] = useState({});

  const handleDetailInfoClick = () => {
    setDetailInfoOpen(true);
  };

  // 필터링된 데이터 사용
  const filteredData = useFilteredData(dummyData, searchConditions);

  // 전체 선택 함수 수정
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    
    const newSelectedItems = {};
    filteredData.forEach(row => {
      newSelectedItems[row.no] = isChecked;  // id 대신 no 사용
    });
    setSelectedItems(newSelectedItems);
  };

  // 단일 선택 함수 수정
  const handleSingleSelect = (no) => {  // id 대신 no 사용
    setSelectedItems(prev => {
      const newSelected = { ...prev, [no]: !prev[no] };
      // 현재 페이지의 모든 항목이 선택되었는지 확인
      const allSelected = filteredData.every(row => newSelected[row.no]);
      setSelectAll(allSelected);
      return newSelected;
    });
  };

  return (
    <Card sx={{ 
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      backgroundColor: '#fff',
      mb: 2
    }}>
      <CardContent sx={{ padding: '12px', '&:last-child': { paddingBottom: '12px' } }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button
            variant="contained"
            startIcon={<RestoreIcon />}
            sx={{
              backgroundColor: '#2196f3',
              color: '#fff',
              alignSelf: 'flex-start',
              '&:hover': {
                backgroundColor: '#1976d2'
              },
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: 600,
              padding: '6px 12px',
              height: '32px'
            }}
          >
            상계복원
          </Button>

          <TableContainer component={StyledTableWrapper}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <StyledTableCell 
                      key={header.id}
                      align={header.align}
                      style={{ 
                        backgroundColor: header.color,
                        color: header.textColor,
                        fontWeight: 'bold',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                      }}
                    >
                      {header.id === 'checkbox' ? (
                        <Checkbox
                          size="small"
                          checked={selectAll}
                          onChange={handleSelectAll}
                          sx={{
                            color: '#fff',
                            '&.Mui-checked': {
                              color: '#fff',
                            },
                          }}
                        />
                      ) : (
                        header.label
                      )}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row) => (
                  <TableRow key={row.no}>
                    {tableHeaders.map((header) => (
                      <StyledTableCell 
                        key={`${row.no}-${header.id}`}
                        align={header.align}
                      >
                        {header.id === 'checkbox' ? (
                          <Checkbox 
                            size="small"
                            checked={selectedItems[row.no] || false}
                            onChange={() => handleSingleSelect(row.no)}
                          />
                        ) : header.id === 'detailInfo' ? (
                          <Tooltip title="세부내역 보기">
                            <IconButton 
                              size="small" 
                              onClick={handleDetailInfoClick}
                              sx={{
                                padding: '4px',
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                              }}
                            >
                              <ArticleIcon sx={{ fontSize: 20, color: '#6435c9' }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          row[header.id]
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>

      <DetailInfo
        open={detailInfoOpen}
        onClose={() => setDetailInfoOpen(false)}
      />
    </Card>
  );
}

export default RestoreTables;
