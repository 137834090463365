import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@mui/material';
import LanIcon from '@mui/icons-material/Lan';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import ExcelJS from 'exceljs';
import SearchIcon from '@mui/icons-material/Search';
const IPBlock = () => {
  const [searchText, setSearchText] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newBlock, setNewBlock] = useState({
    ip: '',
    content: ''
  });
  const resizableInputRef = useRef(null);

  // TextField 리사이즈 이벤트 핸들러
  useEffect(() => {
    const resizableInput = resizableInputRef.current;
    if (!resizableInput) return;

    const handleMouseDown = (event) => {
      if (event.button !== 0) return; // 왼쪽 버튼만 처리

      const startX = event.clientX;
      const startY = event.clientY;
      const startWidth = parseInt(getComputedStyle(resizableInput).width, 10);
      const startHeight = parseInt(getComputedStyle(resizableInput).height, 10);
      const minWidth = 200; // 최소 너비
      const minHeight = 40; // 최소 높이

      const doDrag = (event) => {
        const newWidth = Math.max(startWidth + event.clientX - startX, minWidth);
        const newHeight = Math.max(startHeight + event.clientY - startY, minHeight);
        
        resizableInput.style.width = `${newWidth}px`;
        resizableInput.style.height = `${newHeight}px`;
      };

      const stopDrag = () => {
        document.removeEventListener('mousemove', doDrag);
        document.removeEventListener('mouseup', stopDrag);
      };

      document.addEventListener('mousemove', doDrag);
      document.addEventListener('mouseup', stopDrag);
    };

    resizableInput.addEventListener('mousedown', handleMouseDown);

    return () => {
      resizableInput.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleAddOpen = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setNewBlock({ ip: '', content: '' });
  };

  const handleAddSubmit = () => {
    console.log("차단 IP 추가:", newBlock);
    handleAddClose();
  };

  // 5. 엑셀 다운로드 설정
  const excelDownloadSettings = {
    fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    worksheetName: 'IP차단목록',
    fileNamePrefix: 'ip_block_list_'
  };

  // 6. 컬럼 매핑 정의
  const columnMapping = {
    'No.': 'no',
    '차단IP': 'ip',
    '차단내용': 'content',
    '등록일자': 'register_date'
  };

  // 7. 엑셀 헤더 정의
  const headers = ['No.', '차단IP', '차단내용', '등록일자'];

  // 3. 컬럼 정렬 설정
  const columnAlignments = {
    'No.': 'center',
    '차단IP': 'center',
    '차단내용': 'left',
    '등록일자': 'center'
  };

  // 4. 엑셀 스타일 설정
  const defaultCellStyle = {
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    },
    font: {
      size: 10,
      name: '맑은 고딕'
    }
  };

  const headerCellStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF1976D2' }
    },
    font: {
      size: 10,
      color: { argb: 'FFFFFFFF' },
      bold: true,
      name: '맑은 고딕'
    },
    height: 30
  };

  const defaultRowHeight = 25;

  // 2. 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );
    return maxDataLength * 2;
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(excelDownloadSettings.worksheetName);

      worksheet.columns = headers.map(header => ({
        header,
        key: columnMapping[header],
        width: getMaxColumnWidth(rows, header),
        style: {
          alignment: {
            horizontal: columnAlignments[header],
            vertical: 'middle'
          }
        }
      }));

      worksheet.addRows(rows);

      worksheet.eachRow((row, rowNumber) => {
        row.height = defaultRowHeight;
        row.eachCell(cell => Object.assign(cell, defaultCellStyle));
        
        if (rowNumber === 1) {
          row.height = headerCellStyle.height;
          row.eachCell(cell => Object.assign(cell, headerCellStyle));
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: excelDownloadSettings.fileType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${excelDownloadSettings.fileNamePrefix}${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 더미 데이터
  const rows = [
    {
      no: 5,
      ip: '121.165.129.213',
      content: '비정상적인 접근 시도',
      register_date: '2024-12-03'
    },
    {
      no: 4,
      ip: '175.118.44.171',
      content: '무단 스크래핑 시도',
      register_date: '2024-10-06'
    }
  ];

  // 버튼 정의 수정
  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2',
      onClick: () => console.log('Copy clicked')
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32',
      onClick: handleExcelDownload
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2',
      onClick: () => console.log('Print clicked')
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      {/* 헤더 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <LanIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          // fontFamilly: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          IP차단
        </Typography>
      </Box>

      {/* 검색 및 필터 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            <TextField
              size="small"
              placeholder="검색어를 입력하세요"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ width: '300px' }}
            />
            {/* 검색 버튼 */}
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={() => {/* 검색 로직 구현 */}}
            sx={{ minWidth: '100px' }}
          >
            검색
          </Button>

            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.label}>
                <IconButton
                  onClick={button.onClick}
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>

          <Button
            variant="contained"
            onClick={handleAddOpen} // 수정된 부분
            sx={{
              backgroundColor: '#00acc1',
              '&:hover': {
                backgroundColor: '#008a9a'
              }
            }}
          >
            등록하기
          </Button>
        </Box>
      </Paper>

      {/* 테이블 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f8f9fa' }}>
                <th style={tableHeaderStyle}>No</th>
                <th style={tableHeaderStyle}>차단IP</th>
                <th style={tableHeaderStyle}>차단내용</th>
                <th style={tableHeaderStyle}>등록일자</th>
                <th style={tableHeaderStyle}>삭제</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.no}>
                  <td style={tableCellStyle}>{row.no}</td>
                  <td style={tableCellStyle}>{row.ip}</td>
                  <td style={tableCellStyle}>{row.content}</td>
                  <td style={tableCellStyle}>{row.register_date}</td>
                  <td style={tableCellStyle}>
                    <ActionButton
                      title="삭제"
                      icon={<DeleteIcon />}
                      color="#ef5350"
                      hoverColor="#e53935"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Paper>

      {/* IP 차단 추가 모달. 작성모달임 */}
      <Dialog 
        open={addModalOpen} 
        onClose={handleAddClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: '800px',
            minHeight: '550px'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'
        }}>
          IP 차단 등록
        </DialogTitle>
        <DialogContent sx={{ 
          mt: 1,
          p: 3,
        }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">IP</Typography>
              <TextField
                fullWidth
                size="small"
                value={newBlock.ip}
                onChange={(e) => setNewBlock({...newBlock, ip: e.target.value})}
                sx={{
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">차단내용</Typography>
              <div className="grow-wrap" style={{ position: 'relative' }}>
                <textarea
                  ref={resizableInputRef}
                  style={{
                    width: '100%',
                    minHeight: '50px',
                    resize: 'none',
                    overflow: 'hidden',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '4px'
                  }}
                  value={newBlock.content}
                  onChange={(e) => setNewBlock({...newBlock, content: e.target.value})}
                  onInput={(e) => {
                    e.target.style.height = 'auto';
                    e.target.style.height = e.target.scrollHeight + 'px';
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleAddClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            닫기
          </Button>
          <Button 
            onClick={handleAddSubmit}
            variant="contained" 
            color="primary"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// 스타일 상수
const tableHeaderStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  color: '#344767',
  fontWeight: 600,
  fontSize: '0.75rem',
  textTransform: 'uppercase'
};

const tableCellStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  fontSize: '0.875rem'
};

// 액션 버튼 컴포넌트
const ActionButton = ({ title, icon, color, hoverColor }) => (
  <Tooltip title={title}>
    <IconButton
      size="medium"
      sx={{
        backgroundColor: color,
        color: '#fff',
        width: '36px',
        height: '36px',
        '&:hover': {
          backgroundColor: hoverColor
        }
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: '1.2rem' } })}
    </IconButton>
  </Tooltip>
);

export default IPBlock;