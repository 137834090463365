import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  TextField,
  Drawer,
  Button
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import DownloadIcon from '@mui/icons-material/Download';
import DateRangePicker from '../../Common/DateRangePicker';
import QuickMenu from '../../Common/QuickMenu';
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import ExcelJS from 'exceljs';
// import SearchOptions from '../../Common/SearchOptions';

// 스타일드 컴포넌트 수정
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// 버튼 컴포넌트
const ActionButton = styled(IconButton)({
  width: '40px',
  height: '40px',
  backgroundColor: '#fff',
  marginRight: '8px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
});

// ErrorLog에서 가져온 모바일 관련 스타일 추가
const MobileTableRow = styled(TableRow)({
  '& td': {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px',
    whiteSpace: 'nowrap',
    '& > *': {
      marginRight: '8px'
    }
  }
});

const MobileTableCell = styled(TableCell)({
  padding: '12px 16px',
  whiteSpace: 'nowrap',
  '&.header': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#1976d2'
  }
});

// 모바일 테이블 스타일 수정
const columnStyles = {
  no: { minWidth: '40px', marginRight: '16px' },
  pg: { minWidth: '80px', marginRight: '16px' },
  merchantNo: { minWidth: '120px', marginRight: '16px' },
  mid: { minWidth: '100px', marginRight: '16px' },
  tmnId: { minWidth: '100px', marginRight: '16px' },
  merchantName: { minWidth: '120px', marginRight: '16px' },
  transactionTime: { minWidth: '150px', marginRight: '16px' },
  amount: { minWidth: '100px', marginRight: '16px' },
  cardNo: { minWidth: '120px', marginRight: '16px' },
  approvalNo: { minWidth: '100px', marginRight: '16px' },
  status: { minWidth: '80px', marginRight: '16px' }
};

// 금액 포맷팅 함수 추가
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// 카드번호 마스킹 함수 수정
const maskCardNumber = (cardNumber) => {
  // 하이픈 제거 후 마스킹 처리
  const numbers = cardNumber.replace(/-/g, '');
  const maskedNumber = numbers.replace(/(\d{6})(\d{6})(\d{4})/, '$1******$3');
  
  // 4자리마다 하이픈 추가
  return maskedNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
};

// 더미데이터 새로 생성
const dummyData = [
  {
    no: 1,
    transactionTime: '2025-02-06 15:43:54',
    pgName: 'KSNET',
    merchantId: 'KS0001234',
    merchantName: '농부고을2',
    registerName: '등록자1',
    amount: '176,000',
    cardNo: '5137-9200-****-4575',
    validThru: '03/27',
    customerName: '홍길동',
    password: '**',
    phone: '010-0444-2222',
    email: 'aaa@dsdsds'
  },
  {
    no: 2,
    transactionTime: '2025-02-05 14:30:00',
    pgName: 'NICE',
    merchantId: 'M432109876',
    merchantName: '애플49',
    registerName: '등록자2',
    amount: '1,000,000',
    cardNo: '4009-7870-****-8206',
    validThru: '12/29',
    customerName: '김철수',
    password: '**',
    phone: '010-2222-3333',
    email: 'test@test.com'
  },
  {
    no: 3,
    transactionTime: '2025-02-04 11:20:00',
    pgName: '갤럭시아',
    merchantId: 'SPC107836',
    merchantName: '고기진심정육점',
    registerName: '등록자3',
    amount: '583,000',
    cardNo: '9411-2350-****-1234',
    validThru: '08/25',
    customerName: '강동원',
    password: '**',
    phone: '010-3333-4444',
    email: 'test2@test.com'
  },
  {
    no: 4,
    transactionTime: '2025-02-07 09:15:00',
    pgName: 'EtoU',
    merchantId: 'SPC_112014',
    merchantName: '골드스파사우나',
    registerName: '등록자1',
    amount: '890,000',
    cardNo: '6233-1150-****-7890',
    validThru: '05/26',
    customerName: '박박이',
    password: '**',
    phone: '010-5555-6666',
    email: 'park@test.com'
  },
  {
    no: 5,
    transactionTime: '2025-02-03 16:45:00',
    pgName: 'Kopay',
    merchantId: 'KP9987123',
    merchantName: '일일구세탁',
    registerName: '등록자2',
    amount: '45,000',
    cardNo: '7755-8840-****-2468',
    validThru: '11/28',
    customerName: '이민호',
    password: '**',
    phone: '010-7777-8888',
    email: 'lee@test.com'
  }
];

function DuplicateCheck() {
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 800 && window.innerWidth <= 1025);
  const [isCompact, setIsCompact] = useState(window.innerWidth <= 1025);
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [searchParams, setSearchParams] = useState({
    dateRange: {
      startDate: '',
      endDate: ''
    },
    searchText: ''
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      setIsTablet(window.innerWidth >= 800 && window.innerWidth <= 1025);
      setIsCompact(window.innerWidth <= 1025);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 컬럼 매핑 정의
  const columnMapping = {
    'No': 'no',
    'PG': 'pgName',
    '사업자번호': 'merchantId',
    'MID': 'mid',
    'TMNID': 'tmnId',
    '가맹점명': 'merchantName',
    '거래일시': 'transactionTime',
    '금액': 'amount',
    '카드번호': 'cardNo',
    '승인번호': 'approvalNo',
    '취소여부': 'status'
  };

  // 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );
    const getTextWidth = (text) => {
      return Array.from(text).reduce((width, char) => {
        return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
      }, 0);
    };
    const maxWidth = Math.max(
      getTextWidth(header),
      ...data.map(row => getTextWidth(String(row[fieldName] || '')))
    );
    return maxWidth + Math.ceil(maxWidth * 0.2);
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('중복결제 확인');

    // 스타일 정의
    const defaultCellStyle = {
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      font: {
        size: 10,
        name: '맑은 고딕'
      }
    };

    const headerCellStyle = {
      ...defaultCellStyle,
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF1976D2' }
      },
      font: {
        size: 10,
        color: { argb: 'FFFFFFFF' },
        bold: true,
        name: '맑은 고딕'
      }
    };

    // 헤더 추가
    const headers = Object.keys(columnMapping);
    worksheet.addRow(headers);

    // 데이터 추가
    filteredData.forEach(row => {
      const rowData = headers.map(header => row[columnMapping[header]]);
      worksheet.addRow(rowData);
    });

    // 스타일 적용
    worksheet.getRow(1).height = 30;
    worksheet.eachRow((row, rowNumber) => {
      row.height = 25;
      row.eachCell((cell) => {
        cell.style = rowNumber === 1 ? headerCellStyle : defaultCellStyle;
        if (rowNumber === 1) {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
      });
    });

    // 컬럼 너비 및 정렬 설정
    headers.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = getMaxColumnWidth(filteredData, header);
      column.eachCell((cell, rowNumber) => {  // rowNumber를 여기서 받아옴
        if (rowNumber > 1) {  // 헤더가 아닌 경우에만 적용
          cell.alignment = { 
            vertical: 'middle', 
            horizontal: ['amount'].includes(columnMapping[header]) ? 'right' : 'center'
          };
        }
      });
    });

    // 파일 다운로드
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `중복결제확인_${new Date().toISOString().split('T')[0]}.xlsx`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  // 검색 버튼 클릭 핸들러
  const handleSearch = () => {
    setSearchParams({
      dateRange: {
        startDate,
        endDate
      },
      searchText
    });
  };

  // 데이터 필터링 함수
  const filterData = useCallback(() => {
    return dummyData.filter(item => {
      // 1. 날짜 필터링 (검색 버튼으로 적용)
      const transactionDate = new Date(item.transactionTime);
      const start = searchParams.dateRange.startDate ? new Date(formatDateString(searchParams.dateRange.startDate)) : null;
      const end = searchParams.dateRange.endDate ? new Date(formatDateString(searchParams.dateRange.endDate)) : null;
      
      if (end) end.setHours(23, 59, 59, 999);

      const isInDateRange = (!start || transactionDate >= start) && 
                          (!end || transactionDate <= end);

      // 2. 텍스트 검색 (검색 버튼으로 적용)
      const searchLower = searchParams.searchText.toLowerCase();
      const matchesSearch = !searchParams.searchText || 
        item.merchantId.toLowerCase().includes(searchLower) ||
        item.merchantName.toLowerCase().includes(searchLower) ||
        item.cardNo.toLowerCase().includes(searchLower);

      return isInDateRange && matchesSearch;
    });
  }, [searchParams]);

  // 검색 파라미터 변경시 데이터 업데이트
  useEffect(() => {
    const filtered = filterData();
    setFilteredData(filtered);
  }, [filterData]);

  // YYYYMMDD 형식을 YYYY-MM-DD 형식으로 변환
  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}-${month}-${day}`;
  };

  // 메인 코드
  return (
    <Box component="main" sx={{
      flexGrow: 1,
      p: 3,
      mt: '5px',
      ml: '40px',
      mr: '40px',
      height: 'auto',
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    }}>
      <Box sx={{ width: '100%' }}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="중복확인" />
        
        <Paper sx={{ p: 3, borderRadius: '12px' }}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              mb: 2,
              alignItems: isMobile ? 'flex-start' : 'center',
              justifyContent: 'space-between'
            }}>
              <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                    isSearchSection={true}
                  />
                </Box>
                <TextField
                  size="small"
                  placeholder="MID, 가맹점명, 카드번호 검색"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  sx={{ minWidth: '250px' }}
                />
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}
                  sx={{ minWidth: '100px' }}
                >
                  검색
                </Button>
              </Box>
              
              <Box sx={{ 
                display: 'flex', 
                gap: 1, 
                alignItems: 'center',
                mt: isMobile ? 1 : 0
              }}>
                <Tooltip title="Copy">
                  <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
                    <FontAwesomeIcon icon={faCopy} />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="Excel">
                  <ActionButton 
                    onClick={handleExcelDownload}
                    sx={{ borderColor: '#1976d2', color: '#1976d2' }}
                  >
                    <DownloadIcon />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="Print">
                  <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
                    <FontAwesomeIcon icon={faPrint} />
                  </ActionButton>
                </Tooltip>
              </Box>
            </Box>

            <TableContainer component={StyledTableWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="primary-header" align="center">No</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">거래일시</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">PG</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">MID</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">가맹점명</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">등록자</StyledTableCell>
                    <StyledTableCell className="primary-header" align="right">금액</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">카드번호</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">유효기간</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">고객명</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">비밀번호</StyledTableCell>
                    <StyledTableCell className="primary-header" align="center">연락처</StyledTableCell>
                    <StyledTableCell className="primary-header" align="left">이메일</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, index) => (
                    <StyledTableRow key={index} hover>
                      <StyledTableCell align="center">{index + 1}</StyledTableCell>
                      <StyledTableCell align="center">{row.transactionTime}</StyledTableCell>
                      <StyledTableCell align="center">{row.pgName}</StyledTableCell>
                      <StyledTableCell align="center">{row.merchantId}</StyledTableCell>
                      <StyledTableCell align="center">{row.merchantName}</StyledTableCell>
                      <StyledTableCell align="center">{row.registerName}</StyledTableCell>
                      <StyledTableCell align="right">{row.amount}</StyledTableCell>
                      <StyledTableCell align="center">{row.cardNo}</StyledTableCell>
                      <StyledTableCell align="center">{row.validThru}</StyledTableCell>
                      <StyledTableCell align="center">{row.customerName}</StyledTableCell>
                      <StyledTableCell align="center">{row.password}</StyledTableCell>
                      <StyledTableCell align="center">{row.phone}</StyledTableCell>
                      <StyledTableCell align="left">{row.email}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>

      <QuickMenu />
    </Box>
  );
}

export default DuplicateCheck;
