import React, { useState } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  IconButton,
  Tooltip,
  Checkbox,
  Card,
  CardContent
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArticleIcon from '@mui/icons-material/Article';
import UserInfoOne from '../../Common/SetModal/UserInfoOne';
import UserInfoTwo from '../../Common/SetModal/UserInfoTwo';
import DetailInfo from '../../Common/SetModal/Details';
import styled from '@emotion/styled';
import SettlementButton from '../../Common/SettlementButton';

// 테이블 열 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  height: '28px',
  lineHeight: '1.2',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
  },

  '&.MuiTableCell-paddingCheckbox': {
    width: '32px',
    padding: '0 4px',
    '& .MuiCheckbox-root': {
      padding: '4px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 컨테이너 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  border: '1px solid #e9ecef',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  backgroundColor: '#fff',
  maxHeight: 'calc(100vh - 300px)',
  
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },

  '& .MuiCheckbox-root': {
    padding: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem'
    }
  }
});

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px',
    padding: '0',
    margin: '0',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 130px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '8px 4px',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '6px 3px',
        fontSize: '0.75rem'
      }
    }
  }
};

// 더미 데이터
const dummyData = [
  {
    no: 1,
    pg: 'KICC',
    accountInfo: 'Account 1',
    detail: '상세보기',
    walletStatus: 'Yes',
    settlementPossible: 'Yes',
    merchantTID: 'TID123',
    merchantMID: 'MID123',
    merchantName: 'Merchant 1',
    transactionDate: '2024-01-01',
    settlementCycle: 'D+1',
    expectedSettlementDate: '2024-01-02',
    approvalCount: 5,
    cancelCount: 1,
    approvalAmount: 500000,
    cancelAmount: -10000,
    detailCount: 1,
    actualSalesAmount: 490000,
    settlementAmount: 480000,
    merchantCommissionRate: '480000/1.5%',
    commission: 7200,
    rmCount: 0,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
  {
    no: 2,
    pg: 'PayUp',
    accountInfo: 'Account 2',
    detail: '상세보기',
    walletStatus: 'No',
    settlementPossible: 'No',
    merchantTID: 'TID456',
    merchantMID: 'MID456',
    merchantName: 'Merchant 2',
    transactionDate: '2024-01-02',
    settlementCycle: 'D+2',
    expectedSettlementDate: '2024-01-04',
    approvalCount: 3,
    cancelCount: 0,
    approvalAmount: 300000,
    cancelAmount: 0,
    detailCount: 0,
    actualSalesAmount: 300000,
    settlementAmount: 300000,
    merchantCommissionRate: '480000/2%',
    commission: 6000,
    rmCount: 1,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
];

// 아이콘 버튼 스타일
const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

// 메인 함수  
function ScheduledOffsetTable() {
  // 상태 관리
  const [userInfoOneOpen, setUserInfoOneOpen] = useState(false);
  const [userInfoTwoOpen, setUserInfoTwoOpen] = useState(false);
  const [detailInfoOpen, setDetailInfoOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedMerchantName, setSelectedMerchantName] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});

  // 테이블 헤더 데이터
  const tableHeaders = [
    { 
      id: 'checkbox', 
      label: <Checkbox 
        size="small"
        onChange={(e) => {
          const allRowIds = dummyData.map(row => row.id);
          if (e.target.checked) {
            setSelectedRows(allRowIds);
          } else {
            setSelectedRows([]);
          }
        }}
        checked={dummyData.length > 0 && selectedRows.length === dummyData.length}
        indeterminate={selectedRows.length > 0 && selectedRows.length < dummyData.length}
      />, 
      color: '#bbdefb' 
    },
    { id: 'no', label: 'NO', color: '#bbdefb' },
    { id: 'pg', label: 'PG', color: '#bbdefb' },
    { id: 'account', label: '계정', color: '#bbdefb' },
    { id: 'detail', label: '상세', color: '#bbdefb' },
    { id: 'walletStatus', label: '월렛여부', color: '#2196f3' },
    { id: 'settlementPossible', label: '정산가능', color: '#2196f3' },
    { id: 'merchantTID', label: '가맹점TID', color: '#2196f3' },
    { id: 'merchantMID', label: '가맹점 MID', color: '#2196f3' },
    { id: 'merchantName', label: '가맹점명', color: '#2196f3' },
    { id: 'transactionDate', label: '결제일시', color: '#c8e6c9' },
    { id: 'settlementCycle', label: '정산주기', color: '#c8e6c9' },
    { id: 'expectedSettlementDate', label: '정산예정일', color: '#c8e6c9' },
    { id: 'approvalCount', label: '승인건수', color: '#c8e6c9' },
    { id: 'cancelCount', label: '취소건수', color: '#c8e6c9' },
    { id: 'approvalAmount', label: '승인금액', color: '#c8e6c9' },
    { id: 'cancelAmount', label: '취소금액', color: '#c8e6c9' },
    { id: 'detailInfo', label: '세부내역', color: '#c8e6c9' },
    { id: 'detailCount', label: '세부 건수', color: '#c8e6c9' },
    { id: 'actualSalesAmount', label: '실매출금액', color: '#ffffff' },
    { id: 'settlementAmount', label: '정산액', color: '#ffffff' },
    { id: 'merchantCommissionRate', label: '가맹점수수료율', color: '#ffffff' },
    { id: 'commission', label: '수수료', color: '#ffffff' },
    { id: 'rmCount', label: 'RM건수', color: '#ffffff' },
    { id: 'unpaidAmount', label: '미수금액', color: '#ffffff' },
    { id: 'retentionRate', label: '유보율', color: '#ffffff' },
    { id: 'paymentRate', label: '지급률', color: '#ffffff' },
    { id: 'depositRate', label: '보증금률', color: '#ffffff' },
  ];

  // 전체 선택 함수
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    
    const newSelectedItems = {};
    dummyData.forEach(row => {
      newSelectedItems[row.no] = isChecked;
    });
    setSelectedItems(newSelectedItems);
  };

  // 단일 선택 함수
  const handleSingleSelect = (no) => {
    setSelectedItems(prev => {
      const newSelected = { ...prev, [no]: !prev[no] };
      // 현재 페이지의 모든 항목이 선택되었는지 확인
      const allSelected = dummyData.every(row => newSelected[row.no]);
      setSelectAll(allSelected);
      return newSelected;
    });
  };

  // 사용자 상세 클릭 함수
  const handleUserDetailClick = (row) => {
    setSelectedRowData({
      amount: row.approvalAmount,
      approvalNo: row.merchantTid,
      transactionDate: row.paymentDate,
      cardNumber: row.cardNumber || '-',
      installment: row.installment || '-',
      productName: row.productName || '-'
    });
    setUserInfoOneOpen(true);
  };

  // 카드 상세 클릭 함수
  const handleCardDetailClick = (row) => {
    setUserInfoTwoOpen(true);
    setSelectedMerchantName(row.merchantName);
  };

  // 세부내역 클릭 함수
  const handleDetailInfoClick = () => {
    setDetailInfoOpen(true);
  };

  // 메인 코드
  return (
    <Card 
      sx={{ 
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        backgroundColor: '#fff'
      }}
    >
      <CardContent sx={{ padding: '16px', '&:last-child': { paddingBottom: '16px' } }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Box mb={2}>
            <SettlementButton />
          </Box>

          <TableContainer component={StyledTableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <StyledTableCell 
                      key={header.id}
                      align="center"
                      style={{ 
                        backgroundColor: header.color,
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                      }}
                    >
                      {header.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dummyData.map((row) => (
                  <StyledTableRow key={row.no}>
                    {tableHeaders.map((header) => (
                      <StyledTableCell 
                        key={`${row.no}-${header.id}`}
                        align={
                          ['approvalAmount', 'cancelAmount', 'actualSalesAmount', 
                           'settlementAmount', 'commission', 'unpaidAmount'].includes(header.id) 
                            ? 'right' 
                            : 'center'
                        }
                      >
                        {header.id === 'checkbox' ? (
                          <Checkbox 
                            size="small"
                            checked={selectedItems[row.no] || false}
                            onChange={() => handleSingleSelect(row.no)}
                          />
                        ) : (
                          header.id === 'account' || header.id === 'detail' || header.id === 'detailInfo' ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                              {header.id === 'account' && (
                                <Tooltip title="사용자 정보">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => handleUserDetailClick(row)}
                                    sx={IconButtonStyle}
                                  >
                                    <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {header.id === 'detail' && (
                                <Tooltip title="결제 상세보기">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => handleCardDetailClick(row)}
                                    sx={IconButtonStyle}
                                  >
                                    <CreditCardIcon sx={{ fontSize: 20, color: '#2185d0' }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {header.id === 'detailInfo' && (
                                <Tooltip title="세부내역 보기">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => handleDetailInfoClick(row)}
                                    sx={IconButtonStyle}
                                  >
                                    <ArticleIcon sx={{ fontSize: 20, color: '#6435c9' }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          ) : (
                            row[header.id]
                          )
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>

      <DetailInfo
        open={detailInfoOpen}
        onClose={() => setDetailInfoOpen(false)}
      />
      
      <UserInfoOne
        open={userInfoOneOpen}
        onClose={() => setUserInfoOneOpen(false)}
        data={selectedRowData}
      />
      
      {userInfoTwoOpen && (
        <UserInfoTwo
          open={userInfoTwoOpen}
          onClose={() => setUserInfoTwoOpen(false)}
          businessName={selectedMerchantName}
        />
      )}
    </Card>
  );
}

export default ScheduledOffsetTable;
