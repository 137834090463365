import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,
  Modal,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import UserInfoOne from '../../Common/PayModal/UserInfoOne';
import UserInfoTwo from '../../Common/PayModal/UserInfoTwo';
import MerchantIcons from '../../Common/MerchantIcons';
import BlockIcon from '@mui/icons-material/Block';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AutorenewIcon from '@mui/icons-material/Autorenew';

// 표 스타일 통일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px',
      
      '&[data-field="amount"]': {
        height: '40px',
        lineHeight: '40px'
      }
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 래퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#a4c9ef',
    zIndex: 3
  }
});

// 숫자 포맷 함수
const formatNumber = (value, isAmount = false) => {
  if (!value) return '0';
  
  const num = typeof value === 'string' ? parseFloat(value) : value;
  const formattedNum = Math.abs(num).toLocaleString();
  
  if (num < 0) {
    return `-${formattedNum}`;
  }
  return formattedNum;
};

const formatFee = (amount, rate) => {
  if (!amount || !rate) return '0';
  const feeAmount = Math.floor(amount * (parseFloat(rate) / 100));
  return `+${feeAmount.toLocaleString()}/${rate}`;
};

const formatAmountWithCount = (amount, count) => {
  if (!amount) return '0 (0건)';
  return `${formatNumber(amount, true)} (${count}건)`;
};

// Modal 스타일 추가
const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const ModalContent = styled(Box)({
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  minWidth: '300px',
  maxWidth: '500px'
});

// IconButtonStyle 정의 추가
const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

// 메인 코드
function PayMentAggregationTable({ searchPanelOpen, searchConditions, onDataUpdate }) {
  // 모달 상태 관리
  const [agencyModalOpen, setAgencyModalOpen] = useState(false);
  const [merchantModalOpen, setMerchantModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [userInfoOneOpen, setUserInfoOneOpen] = useState(false);
  const [userInfoTwoOpen, setUserInfoTwoOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedMerchantName, setSelectedMerchantName] = useState('');

  // 더미데이터 @DummyData.txt 형식으로 변경 (10개)
  const dummyData = [
    {
      merchantName: '농부고을2',
      merchantStatus: ['sms'],
      approvalAmount: 2500000,
      cancelAmount: 0,
      totalSales: "2,500,000 (3건)",
      transactionCount: '3/0',
      averageAmount: 833333,
      feeRate: '117,500/4.700',
      settlementCycle: 'D+1',
      settlementRate: '2,382,500',
      riskCount: 0,
      branchName: '사업자/원천/네오알파시스템즈 윤병원',
      paymentRate: '11,000/0.7',
      reserveRate: '0',
      deductionRate: '0',
      refundRate: '0',
      unpaidRate: '0',
      depositRate: '0',
      pgName: 'KSNET',
      mid: 'KS0001234',
      tid: 'TR678901234',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      merchantName: '애플49',
      merchantStatus: ['sms'],
      approvalAmount: 1000000,
      cancelAmount: 0,
      totalSales: "1,000,000 (2건)",
      transactionCount: '2/0',
      averageAmount: 500000,
      feeRate: '47,000/4.700',
      settlementCycle: 'D+2',
      settlementRate: '953,000',
      riskCount: 1,
      branchName: '개인/원천/N오평욱B 49',
      paymentRate: '8,000/0.8',
      reserveRate: '0',
      deductionRate: '0',
      refundRate: '0',
      unpaidRate: '0',
      depositRate: '0',
      pgName: 'NICE',
      mid: 'M432109876',
      tid: 'TRK2024031900002',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      merchantName: '고기진심정육점',
      merchantStatus: ['sms'],
      approvalAmount: 3500000,
      cancelAmount: 224000,
      totalSales: "3,276,000 (5건)",
      transactionCount: '5/1',
      averageAmount: 655200,
      feeRate: '153,972/4.700',
      settlementCycle: 'D+1',
      settlementRate: '3,122,028',
      riskCount: 2,
      branchName: '개인/원천/오평욱N',
      paymentRate: '15,000/0.7',
      reserveRate: '0',
      deductionRate: '0',
      refundRate: '0',
      unpaidRate: '0',
      depositRate: '0',
      pgName: '갤럭시아',
      mid: 'SPC107836',
      tid: 'TR567890123',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      merchantName: '농부고을2',
      merchantStatus: ['sms'],
      approvalAmount: 2500000,
      cancelAmount: 0,
      totalSales: "2,500,000 (3건)",
      transactionCount: '3/0',
      averageAmount: 833333,
      feeRate: '117,500/4.700',
      settlementCycle: 'D+1',
      settlementRate: '2,382,500',
      riskCount: 0,
      branchName: '사업자/원천/네오알파시스템즈 윤병원',
      paymentRate: '11,000/0.7',
      reserveRate: '0',
      deductionRate: '0',
      refundRate: '0',
      unpaidRate: '0',
      depositRate: '0',
      pgName: 'KSNET',
      mid: 'KS0001234',
      tid: 'TR678901234',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      merchantName: '애플49',
      merchantStatus: ['sms'],
      approvalAmount: 1000000,
      cancelAmount: 0,
      totalSales: "1,000,000 (2건)",
      transactionCount: '2/0',
      averageAmount: 500000,
      feeRate: '47,000/4.700',
      settlementCycle: 'D+2',
      settlementRate: '953,000',
      riskCount: 1,
      branchName: '개인/원천/N오평욱B 49',
      paymentRate: '8,000/0.8',
      reserveRate: '0',
      deductionRate: '0',
      refundRate: '0',
      unpaidRate: '0',
      depositRate: '0',
      pgName: 'NICE',
      mid: 'M432109876',
      tid: 'TRK2024031900002',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    {
      merchantName: '고기진심정육점',
      merchantStatus: ['sms'],
      approvalAmount: 3500000,
      cancelAmount: 224000,
      totalSales: "3,276,000 (5건)",
      transactionCount: '5/1',
      averageAmount: 655200,
      feeRate: '153,972/4.700',
      settlementCycle: 'D+1',
      settlementRate: '3,122,028',
      riskCount: 2,
      branchName: '개인/원천/오평욱N',
      paymentRate: '15,000/0.7',
      reserveRate: '0',
      deductionRate: '0',
      refundRate: '0',
      unpaidRate: '0',
      depositRate: '0',
      pgName: '갤럭시아',
      mid: 'SPC107836',
      tid: 'TR567890123',
      smsEnabled: '사용',
      detailButton: '팝업',
      accountButton: '팝업'
    },
    
    // ... 4번부터 10번까지 비슷한 패턴으로 반복 (생략)
  ];

  // 검색 조건에 따른 필터링 함수
  const filterData = (data) => {
    if (!searchConditions) return data;

    return data.filter(item => {
      let isMatched = true;

      // 텍스트 검색 (가맹점명, MID, TID)
      if (searchConditions.searchText) {
        const searchLower = searchConditions.searchText.toLowerCase();
        const matchMerchant = item.merchantName?.toLowerCase().includes(searchLower);
        const matchMID = item.mid?.toLowerCase().includes(searchLower);
        const matchTID = item.tid?.toLowerCase().includes(searchLower);
        
        isMatched = matchMerchant || matchMID || matchTID;
      }

      // 일시불/할부 필터링
      if (searchConditions.installment && isMatched) {
        // 일시불/할부 로직 추가
        isMatched = item.installment === searchConditions.installment;
      }

      // PG사 필터링
      if (searchConditions.pg && isMatched) {
        isMatched = item.pgName === searchConditions.pg;
      }

      // 선택된 토글 옵션 필터링
      if (searchConditions.selectedToggleOptions?.length > 0 && isMatched) {
        isMatched = searchConditions.selectedToggleOptions.some(option => {
          switch (option.parentOption) {
            case 'branch':
              return item.branchName.includes(option.label);
            case 'pgCompany':
              return item.pgName === option.label;
            case 'installment':
              return item.installment === option.label;
            case 'merchantMID':
              return item.mid === option.label;
            case 'tid':
              return item.tid === option.label;
            default:
              return true;
          }
        });
      }

      return isMatched;
    });
  };

  // 필터링된 데이터 업데이트
  useEffect(() => {
    const filteredData = filterData(dummyData);
    onDataUpdate(filteredData);
  }, [searchConditions, onDataUpdate]);

  // 지사명 클릭 핸들러
  const handleAgencyClick = (agencyName) => {
    setSelectedData(agencyName);
    setAgencyModalOpen(true);
  };

  // 가맹점 클릭 핸들러
  const handleMerchantClick = (merchantName) => {
    setSelectedData(merchantName);
    setMerchantModalOpen(true);
  };

  // 카드 상세 클릭 핸들러
  const handleCardDetailClick = (row) => {
    setUserInfoTwoOpen(true);
    setSelectedMerchantName(row.merchantName);
  };

  // 사용자 상세 클릭 핸들러
  const handleUserDetailClick = (row) => {
    setSelectedRowData({
      amount: row.amount,
      approvalNo: row.approvalNo,
      transactionDate: row.transactionDate,
      cardNumber: row.cardNumber,
      installment: row.installment,
      productName: row.productName
    });
    setUserInfoOneOpen(true);
  };

  console.log('PaymentAggregationTable - Search Panel State:', searchPanelOpen);
  
  // data prop이 비어있으면 더미데이터를 사용
  const displayData = filterData(dummyData);

  const handleIconClick = (value) => {
    console.log('Icon clicked:', value);
    // 아이콘 클릭 처리 로직 추가
  };

  return (
    <Paper sx={{ 
      width: '100%',
      overflow: 'hidden',
      transition: 'all 0.3s',
      marginRight: searchPanelOpen ? '280px' : '0'
    }}>
      {/* MerchantIcons 추가 */}
      <Box sx={{ 
        p: 2,
        borderBottom: '1px solid #e0e0e0',
        backgroundColor: '#fff'
      }}>
        <MerchantIcons onIconClick={handleIconClick} />
      </Box>

      <StyledTableWrapper>
        <Table stickyHeader className="sum-jungsan-list">
          <TableHead>
            <TableRow>
              <StyledTableCell className="primary-header sticky-header">No</StyledTableCell>
              <StyledTableCell className="primary-header">가맹점명</StyledTableCell>
              <StyledTableCell className="primary-header">승인금액</StyledTableCell>
              <StyledTableCell className="primary-header">취소금액</StyledTableCell>
              <StyledTableCell className="primary-header">매출합계</StyledTableCell>
              <StyledTableCell className="primary-header">승인/취소건수</StyledTableCell>
              <StyledTableCell className="primary-header">평균단가</StyledTableCell>
              <StyledTableCell className="primary-header">수수료 (%)</StyledTableCell>
              <StyledTableCell className="primary-header">정산금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">RISK건수</StyledTableCell>
              <StyledTableCell className="primary-header">지사명</StyledTableCell>
              <StyledTableCell className="primary-header">지급액(%)</StyledTableCell>
              <StyledTableCell className="primary-header">유보액(%)</StyledTableCell>
              <StyledTableCell className="primary-header">원차감(%)</StyledTableCell>
              <StyledTableCell className="primary-header">환입금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">미수금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">보증금(%)</StyledTableCell>
              <StyledTableCell className="primary-header">PG</StyledTableCell>
              <StyledTableCell className="primary-header">MID</StyledTableCell>
              <StyledTableCell className="primary-header">TID</StyledTableCell>
              <StyledTableCell className="primary-header">상세</StyledTableCell>
              <StyledTableCell className="primary-header">계정</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((row, index) => (
              <StyledTableRow 
                key={index}
                className={Number(row.totalSales.replace(/[^0-9-]/g, '')) < 0 ? 'negative-amount' : ''}
              >
                <StyledTableCell className="sticky-column" align="right">{index + 1}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.merchantName}
                  {' '}
                  <Box component="span" sx={{ display: 'inline-flex', gap: 0.5, alignItems: 'center', ml: 1 }}>
                    {row.merchantStatus.map((status, idx) => {
                      switch(status) {
                        case 'block':
                          return <BlockIcon key={idx} sx={{ fontSize: '1rem', color: '#dc3545' }} />;
                        case 'sms':
                          return <TextsmsIcon key={idx} sx={{ fontSize: '1rem', color: '#4caf50' }} />;
                        case 'recurring':
                          return <AutorenewIcon key={idx} sx={{ fontSize: '1rem', color: '#2196f3' }} />;
                        default:
                          return null;
                      }
                    })}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.approvalAmount)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.cancelAmount)}</StyledTableCell>
                <StyledTableCell align="right">{row.totalSales}</StyledTableCell>
                <StyledTableCell align="center">{row.transactionCount}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.averageAmount)}</StyledTableCell>
                <StyledTableCell align="center">{row.feeRate}</StyledTableCell>
                <StyledTableCell align="right">{row.settlementRate}</StyledTableCell>
                <StyledTableCell align="right">{row.riskCount}</StyledTableCell>
                <StyledTableCell align="center">{row.branchName}</StyledTableCell>
                <StyledTableCell align="right">{row.paymentRate}</StyledTableCell>
                <StyledTableCell align="right">{row.reserveRate}</StyledTableCell>
                <StyledTableCell align="right">{row.deductionRate}</StyledTableCell>
                <StyledTableCell align="right">{row.refundRate}</StyledTableCell>
                <StyledTableCell align="right">{row.unpaidRate}</StyledTableCell>
                <StyledTableCell align="right">{row.depositRate}</StyledTableCell>
                <StyledTableCell align="left">{row.pgName}</StyledTableCell>
                <StyledTableCell align="center">{row.mid}</StyledTableCell>
                <StyledTableCell align="center">{row.tid}</StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="결제 상세보기">
                    <IconButton 
                      size="small" 
                      onClick={() => handleCardDetailClick(row)}
                      sx={IconButtonStyle}
                    >
                      <CreditCardIcon sx={{ fontSize: 20, color: '#2185d0' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="사용자 정보">
                    <IconButton 
                      size="small" 
                      onClick={() => handleUserDetailClick(row)}
                      sx={IconButtonStyle}
                    >
                      <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>

      {/* 지사 데이터 모달 */}
      <StyledModal
        open={agencyModalOpen}
        onClose={() => setAgencyModalOpen(false)}
      >
        <ModalContent>
          <Typography variant="h6" component="h2" gutterBottom>
            해당 지사 데이터
          </Typography>
          <Typography>
            지사명: {selectedData}
          </Typography>
          {/* 추가 지사 데이터 표시 */}

        </ModalContent>
      </StyledModal>

      {/* 가맹점 데이터 모달 */}
      <StyledModal
        open={merchantModalOpen}
        onClose={() => setMerchantModalOpen(false)}
      >
        <ModalContent>
          <Typography variant="h6" component="h2" gutterBottom>
            해당 가맹점 데이터
          </Typography>
          <Typography>
            가맹점명: {selectedData}
          </Typography>
          {/* 추가 가맹점 데이터 표시 */}
        </ModalContent>
      </StyledModal>

      {/* 모달 컴포넌트 추가 */}
      <UserInfoOne
        open={userInfoOneOpen}
        onClose={() => setUserInfoOneOpen(false)}
        data={selectedRowData}
      />
      
      {userInfoTwoOpen && (
        <UserInfoTwo
          open={userInfoTwoOpen}
          onClose={() => setUserInfoTwoOpen(false)}
          businessName={selectedMerchantName}
        />
      )}

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        p: 2,
        backgroundColor: '#fff'
      }}>
        <Pagination count={10} variant="outlined" shape="rounded" />
      </Box>
    </Paper>
  );
}

export default PayMentAggregationTable;
