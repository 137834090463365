import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Box,
  styled 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '1400px',
    minHeight: '800px',
    maxHeight: '90vh',
    borderRadius: '8px'
  }
}));

// 모달 헤더 스타일
const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9',
  marginBottom: '16px'
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)({
  padding: '6px 16px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  height: '32px',
  whiteSpace: 'nowrap',
  '&.no-cell': {
    width: '45px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 8px'
  },
  '&.label-cell': {
    width: '200px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 12px'
  },
  '&.value-cell': {
    width: '300px',
    color: '#495057',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    padding: '6px 12px'
  }
});

// 개행 문자열 처리 함수
const formatValue = (value) => {
  if (typeof value === 'string' && value.includes('\n')) {
    return value.split('\n').join(' ');
  }
  return value;
};

// 메인 코드
const MerchantModalOne = ({ open, onClose, merchantData }) => {
  const leftTableData = [
    { no: 1, label: 'GID', value: merchantData?.id || '' },
    { no: 2, label: '로그인아이디', value: merchantData?.loginId || '' },
    { no: 3, label: 'MID', value: merchantData?.mid || '' },
    { no: 4, label: 'TID', value: merchantData?.tid || '' },
    { no: 5, label: '가맹점명', value: merchantData?.merchantName || '' },
    { no: 6, label: '대표자명', value: '김진' },
    { no: 7, label: '사업자/개인', value: merchantData?.businessStatus || '' },
    { no: 8, label: '과세/원천', value: merchantData?.businessStatus || '' },
    // { no: 8, label: '세금계산서', value: merchantData?.taxType || '' },
    { no: 9, label: '사업자번호/주민번호', value: '348-45-00674\n780225-1654515' },
    { no: 10, label: '은행명', value: '새마을금고' },
    { no: 11, label: '예금주', value: '김진' },
    { no: 12, label: '계좌번호', value: '9003-2850-0633-6' },
    { no: 13, label: '업종', value: '도소매' },
    { no: 14, label: '품명', value: '식품즉석판매가공업' },
    { no: 15, label: '연락처', value: '032-888-6492' },
    { no: 16, label: '휴대번호', value: '010-9163-4322' },
    { no: 17, label: '이메일', value: 'gogiinsim@naver.com' },
    { no: 18, label: '주소', value: '인천 미추구 낙섬동로 42-21층 고기천상점 본점' },
    { no: 19, label: '시리얼번호', value: '' },
    // { no: 20, label: 'TRACKID', value: 'TR_20231205_001' },
    // { no: 21, label: 'TRXID', value: 'TRX_20231205_001' }
  ];

  // 오른쪽 테이블 데이터
  const rightTableData = [
    { no: 20, label: '비회원에게 송금 정액', value: merchantData?.walletFixed || '500' },
    { no: 21, label: '비회원에게 송금 정률', value: merchantData?.walletRate || '0.3' },
    { no: 22, label: '회원에게 송금 정액', value: '500' },
    { no: 23, label: '회원에게 송금 정률', value: '0.3' },
    { no: 24, label: '나의계좌 출금 수수료 정액', value: '500' },
    { no: 25, label: '나의계좌 출금 수수료 정률(%)', value: '0.3' },
    { no: 26, label: '어드민 입금 수수료 정액', value: '0' },
    { no: 27, label: '어드민 입금 수수료 정률(%)', value: '0' },
    { no: 28, label: '마스터 입금 수수료 정액', value: '0' },
    { no: 29, label: '마스터 입금 수수료 정률(%)', value: '0' },
    { no: 30, label: '건한도', value: merchantData?.limitPerCase || '3,000,000' },
    { no: 31, label: '일한도', value: '200,000,000' },
    { no: 32, label: '월한도', value: '3,000,000,000' },
    { no: 33, label: '마스터', value: merchantData?.master || '문자\n1.485% / 1.49% / 0% / 0%' },
    { no: 34, label: '지사', value: merchantData?.branch || '네오알바서비스팀은 문영원\n0%' },
    { no: 35, label: '총판', value: merchantData?.distributor || 'NH네오뱅킹 입금 노출\n1.1% / 1.10% / 0% / 0%' },
    { no: 36, label: '대리점', value: merchantData?.agency || 'NH오픈뱅킹\n0.555% / 0.55% / 0% / 0%' },
    { no: 37, label: '리테일', value: merchantData?.retail || '' },
    { no: 38, label: '영업1', value: merchantData?.sales1 || '' },
    { no: 39, label: '영업2', value: merchantData?.sales2 || '' },
    { no: 40, label: '영업3', value: merchantData?.sales3 || '' }
  ];

  // 메인 코드
  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth={false}
    >
      <StyledDialogTitle>
        가맹점 상세정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ 
        p: 3,
        pt: 4
      }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableContainer>
              <Table size="small" sx={{ '& td': { height: '32px' } }}>
                <TableBody>
                  {leftTableData.map((row) => (
                    <TableRow key={row.no}>
                      <StyledTableCell className="no-cell">{row.no}</StyledTableCell>
                      <StyledTableCell className="label-cell">{row.label}</StyledTableCell>
                      <StyledTableCell className="value-cell">
                        {formatValue(row.value)}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <TableContainer>
              <Table size="small" sx={{ '& td': { height: '32px' } }}>
                <TableBody>
                  {rightTableData.map((row) => (
                    <TableRow key={row.no}>
                      <StyledTableCell className="no-cell">{row.no}</StyledTableCell>
                      <StyledTableCell className="label-cell">{row.label}</StyledTableCell>
                      <StyledTableCell className="value-cell">
                        {formatValue(row.value)}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default MerchantModalOne;
