import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Box,
  Button,
  IconButton,
  Tooltip,
  Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CancelModal from '../../Common/PayModal/CancelModal';
import styled from '@emotion/styled';
import ExcelJS from 'exceljs';

// 버튼 컴포넌트
const ActionButtons = ({ onButtonClick }) => {
  const buttons = [
    {
      label: '내역삭제',
      icon: <DeleteIcon />,
      value: 'delete',
      color: '#dc3545' 
    },
    {
      label: '취소승인',
      icon: <CancelIcon />,
      value: 'cancel',
      color: '#1976d2'  // 빨간색
    },
    {
      label: '엑셀다운로드',
      icon: null,
      value: 'excel',
      color: '#1976d2'  // 빨간색
    }
  ];

  // 버튼 컴포넌트 렌더링
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ 
        display: 'flex',
        gap: 1
      }}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            startIcon={button.icon}
            onClick={() => onButtonClick?.(button.value)}
            sx={{
              bgcolor: '#fff',
              color: button.color,
              border: `1px solid ${button.color}`,
              fontSize: '0.875rem',
              height: '36px',
              padding: '6px 16px',
              minWidth: 'auto',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: `${button.color}10`,
                border: `1px solid ${button.color}`,
                boxShadow: 'none'
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

// 스타일드 컴포넌트 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  height: '28px',
  lineHeight: '1.2',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      padding: '4px 8px'
    }
  },

  '&.MuiTableCell-paddingCheckbox': {
    width: '32px',
    padding: '0 4px',
    '& .MuiCheckbox-root': {
      padding: '4px'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  border: '1px solid #e9ecef',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  backgroundColor: '#fff',
  maxHeight: 'calc(100vh - 300px)',
  
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },

  '& .MuiCheckbox-root': {
    padding: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem'
    }
  }
});

// 메인 컴포넌트
const DefaultTables = ({ onExcelDownload }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // 계정 클릭 함수
  const handleAccountClick = (row) => {
    setSelectedAccount(row);
    setAccountModalOpen(true);
  };

  // 승인번호 클릭 함수
  const handleAuthClick = (row) => {
    const message = `승인번호: ${row.approvalNo}\n거래금액: ${row.amount}원`;
    
    navigator.clipboard.writeText(message).then(() => {
      setAlertMessage(`클립보드에 복사되었습니다\n\n${message}`);
      setOpenSnackbar(true);
    });
  };

  // 더미 데이터 생성
  const dummyData = Array(5).fill(null).map((_, index) => ({
    id: index + 1,
    no: (index + 1).toString(),
    accountInfo: `user${123 + index}`,
    status: '취소요청',
    pg: 'KG이니시스',
    requestDate: '2024-03-20 14:30:00',
    transactionDate: '2024-03-20 14:25:00',
    merchantMID: `MID${123456 + index}`,
    merchantTID: `TID${789012 + index}`,
    accountStatus: '정상',
    refundAmount: '50,000',
    walletUse: 'Y',
    amount: '50,000',
    approvalNo: `AP${123456 + index}`,
    merchantName: `테스트가맹점${index + 1}`,
    receipt: 'Y',
    issuer: '신한카드',
    cardNumber: '123456******7890',
    installment: '일시불',
    feeRate: '3.3',
    deposit: '1,000',
    depositAmount: '48,000',
    accountBalance: '100,000',
    deductionAmount: '2,000',
    vanTrxId: `VAN${123456 + index}`
  }));

  // 전체 선택 함수
  const handleSelectAll = (checked) => {
    console.log("전체 선택:", checked);
    setSelectAll(checked);
    if (checked) {
      const allIds = dummyData.map(row => row.id);
      console.log("선택된 모든 ID:", allIds);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  // 개별 선택 함수
  const handleSelectItem = (checked, id) => {
    console.log("개별 선택:", checked, id);
    if (checked) {
      const newSelected = [...selectedItems, id];
      setSelectedItems(newSelected);
      if (newSelected.length === dummyData.length) {
        setSelectAll(true);
      }
    } else {
      setSelectedItems(selectedItems.filter(item => item !== id));
      setSelectAll(false);
    }
  };

  // 테이블 헤더 데이터
  const tableHeaders = [
    { 
      id: 'checkbox', 
      label: <Checkbox 
        checked={selectAll}
        indeterminate={selectedItems.length > 0 && selectedItems.length < dummyData.length}
        onChange={(e) => handleSelectAll(e.target.checked)}
      />, 
      color: '#ffffff' 
    },
    { id: 'no', label: 'No', color: '#1976d2' },
    { id: 'accountInfo', label: '상세', color: '#1976d2' },
    { id: 'status', label: '상태', color: '#1976d2' },
    { id: 'pg', label: 'PG', color: '#1976d2' },
    { id: 'requestDate', label: '요청일시', color: '#1976d2' },
    { id: 'transactionDate', label: '거래일시', color: '#1976d2' },
    { id: 'merchantMID', label: '가맹점 MID', color: '#1976d2' },
    { id: 'merchantTID', label: '가맹점 TID', color: '#1976d2' },
    
    { id: 'accountStatus', label: '계정상태', color: '#90caf9' },
    { id: 'refundAmount', label: '환입금액', color: '#90caf9' },
    { id: 'walletUse', label: '월렛사용여부', color: '#90caf9' },
    { id: 'amount', label: '거래금액', color: '#90caf9' },
    { id: 'approvalNo', label: '승인번호', color: '#90caf9' },
    { id: 'merchantName', label: '가맹점명', color: '#90caf9' },
    { id: 'receipt', label: '영수증', color: '#90caf9' },
    
    { id: 'issuer', label: '발급사', color: '#ffffff' },
    { id: 'cardNumber', label: '카드번호', color: '#ffffff' },
    { id: 'installment', label: '할부', color: '#ffffff' },
    { id: 'feeRate', label: '수수료율/금액', color: '#ffffff' },
    { id: 'deposit', label: '보증금', color: '#ffffff' },
    { id: 'depositAmount', label: '입금액', color: '#ffffff' },
    { id: 'accountBalance', label: '계정잔액', color: '#ffffff' },
    { id: 'deductionAmount', label: '차감금액', color: '#ffffff' },
    { id: 'vanTrxId', label: 'vanTrxidtrxid', color: '#ffffff' }
  ];

  // 엑셀 다운로드를 위한 데이터 준비 함수
  const prepareExcelData = () => {
    const headers = tableHeaders
      .filter(header => header.id !== 'checkbox' && header.id !== 'accountInfo')
      .map(header => header.label);

    const data = dummyData.map(row => {
      return tableHeaders
        .filter(header => header.id !== 'checkbox' && header.id !== 'accountInfo')
        .map(header => row[header.id]);
    });

    return {
      headers,
      data
    };
  };

  // ActionButtons 컴포넌트 수정
  const handleButtonClick = (value) => {
    if (value === 'excel') {
      const excelData = prepareExcelData();
      onExcelDownload?.(excelData);
    } else {
      console.log('Button clicked:', value);
      console.log('Selected items:', selectedItems);
    }
  };

  // 메인 코드
  return (
    <>
      <ActionButtons onButtonClick={handleButtonClick} />
      <TableContainer component={StyledTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.id}
                  align="center"
                  className="primary-header"
                  sx={header.id === 'checkbox' ? {
                    backgroundColor: '#1976d2',
                    '& .MuiCheckbox-root': {
                      color: '#ffffff',
                      '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                        color: '#ffffff',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem'
                      }
                    }
                  } : undefined}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">
                  <Checkbox 
                    checked={selectedItems.includes(row.id)}
                    onChange={(e) => handleSelectItem(e.target.checked, row.id)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{row.no}</StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="계정 정보 보기">
                    <IconButton
                      size="small"
                      onClick={() => handleAccountClick(row)}
                      sx={{
                        padding: '4px',
                        '&:hover': {
                          backgroundColor: '#e8f5e9'
                        }
                      }}
                    >
                      <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                {tableHeaders.slice(3).map((header) => (
                  <StyledTableCell
                    key={`${row.id}-${header.id}`}
                    align="center"
                  >
                    {header.id === 'approvalNo' ? (
                      <span
                        onClick={() => handleAuthClick(row)}
                        style={{ cursor: 'pointer', color: '#1976d2' }}
                      >
                        {row[header.id]}
                      </span>
                    ) : (
                      row[header.id]
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CancelModal
        open={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        data={selectedAccount}
        modalType="account"
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            whiteSpace: 'pre-line'
          }
        }}
      />
    </>
  );
};

export default DefaultTables;