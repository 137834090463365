import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import RouterIcon from '@mui/icons-material/Router';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import ExcelJS from 'exceljs';
import SearchIcon from '@mui/icons-material/Search';

const AdminAccessIPCheck = () => {
  const [searchText, setSearchText] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newNotice, setNewNotice] = useState({
    ip: '',
    location: ''
  });
  const [textFieldWidth, setTextFieldWidth] = useState('300px');
  const [textFieldHeight, setTextFieldHeight] = useState('40px');
  const resizableInputRef = useRef(null);

  // 더미 데이터 추가
  const rows = [
    {
      no: 1,
      ip: '192.168.1.1',
      type: '본사',
      register_time: '2024-03-19 14:30:00'
    },
    {
      no: 2,
      ip: '192.168.1.2',
      type: '지사',
      register_time: '2024-03-19 15:45:00'
    },
    {
      no: 3,
      ip: '192.168.1.3',
      type: '대리점',
      register_time: '2024-03-19 16:20:00'
    }
  ];

  // 5. 엑셀 다운로드 설정
  const excelDownloadSettings = {
    fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    worksheetName: '관리자접속IP목록',
    fileNamePrefix: 'admin_access_ip_'
  };

  // 6. 컬럼 매핑 정의
  const columnMapping = {
    'No.': 'no',
    'IP': 'ip',
    '위치': 'type',
    '등록일시': 'register_time'
  };

  // 7. 엑셀 헤더 정의
  const headers = ['No.', 'IP', '위치', '등록일시'];

  // 3. 컬럼 정렬 설정
  const columnAlignments = {
    'No.': 'center',
    'IP': 'center',
    '위치': 'center',
    '등록일시': 'center'
  };

  // 4. 엑셀 스타일 설정
  const defaultCellStyle = {
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    },
    font: {
      size: 10,
      name: '맑은 고딕'
    }
  };

  const headerCellStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF1976D2' }
    },
    font: {
      size: 10,
      color: { argb: 'FFFFFFFF' },
      bold: true,
      name: '맑은 고딕'
    },
    height: 30
  };

  const defaultRowHeight = 25;

  // 2. 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );

    const getTextWidth = (text) => {
      return Array.from(text).reduce((width, char) => {
        return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
      }, 0);
    };

    const maxWidth = Math.max(
      getTextWidth(header),
      ...data.map(row => getTextWidth(String(row[fieldName] || '')))
    );

    return maxWidth + Math.ceil(maxWidth * 0.2);
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      if (!rows || rows.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(excelDownloadSettings.worksheetName);

      worksheet.columns = headers.map(header => ({
        header,
        key: columnMapping[header],
        width: getMaxColumnWidth(rows, header),
        style: {
          alignment: {
            horizontal: columnAlignments[header],
            vertical: 'middle'
          }
        }
      }));

      worksheet.addRows(rows);

      worksheet.eachRow((row, rowNumber) => {
        row.height = defaultRowHeight;
        row.eachCell(cell => Object.assign(cell, defaultCellStyle));
        
        if (rowNumber === 1) {
          row.height = headerCellStyle.height;
          row.eachCell(cell => Object.assign(cell, headerCellStyle));
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: excelDownloadSettings.fileType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${excelDownloadSettings.fileNamePrefix}${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 버튼 정의 수정
  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2',
      onClick: () => console.log('Copy clicked')
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32',
      onClick: handleExcelDownload
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2',
      onClick: () => console.log('Print clicked')
    }
  ];

  // TextField 리사이즈 이벤트 핸들러 개선
  useEffect(() => {
    const resizableInput = resizableInputRef.current;
    if (!resizableInput) return;

    const handleMouseDown = (event) => {
      if (event.button !== 0) return; // 왼쪽 버튼만 처리

      const startX = event.clientX;
      const startY = event.clientY;
      const startWidth = parseInt(getComputedStyle(resizableInput).width, 10);
      const startHeight = parseInt(getComputedStyle(resizableInput).height, 10);
      const minWidth = 200; // 최소 너비
      const minHeight = 40; // 최소 높이

      const doDrag = (event) => {
        const newWidth = Math.max(startWidth + event.clientX - startX, minWidth);
        const newHeight = Math.max(startHeight + event.clientY - startY, minHeight);
        
        setTextFieldWidth(`${newWidth}px`);
        setTextFieldHeight(`${newHeight}px`);
      };

      const stopDrag = () => {
        document.removeEventListener('mousemove', doDrag);
        document.removeEventListener('mouseup', stopDrag);
      };

      document.addEventListener('mousemove', doDrag);
      document.addEventListener('mouseup', stopDrag);
    };

    // 텍스트 입력에 따른 자동 높이 조절
    const handleInput = () => {
      resizableInput.style.height = 'auto';
      const newHeight = Math.max(
        resizableInput.scrollHeight,
        parseInt(getComputedStyle(resizableInput).minHeight, 10)
      );
      setTextFieldHeight(`${newHeight}px`);
    };

    resizableInput.addEventListener('mousedown', handleMouseDown);
    resizableInput.addEventListener('input', handleInput);

    return () => {
      resizableInput.removeEventListener('mousedown', handleMouseDown);
      resizableInput.removeEventListener('input', handleInput);
    };
  }, []);

  const handleAddOpen = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
  };

  const handleAddSubmit = () => {
    console.log("추가된 IP:", newNotice);
    handleAddClose();
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* 헤더 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <RouterIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          // fontFamilly: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          어드민 IP 체크
        </Typography>
      </Box>

      {/* 검색 및 필터 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            <TextField
              size="small"
              placeholder="검색어를 입력하세요"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              inputRef={resizableInputRef}
              multiline
              sx={{
                width: textFieldWidth,
                '& .MuiInputBase-root': {
                  height: textFieldHeight,
                  resize: 'both',
                  overflow: 'auto',
                  transition: 'none'
                },
                '& .MuiInputBase-input': {
                  height: '100% !important',
                  resize: 'none'
                }
              }}
            />
            {/* 검색 버튼 */}
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={() => {/* 검색 로직 구현 */}}
            sx={{ minWidth: '100px' }}
          >
            검색
          </Button>
            
            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.label}>
                <IconButton
                  onClick={button.onClick}
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>

          <Button
            variant="contained"
            onClick={handleAddOpen}
            sx={{
              backgroundColor: '#00acc1',
              '&:hover': {
                backgroundColor: '#008a9a'
              }
            }}
          >
            등록하기
          </Button>
        </Box>
      </Paper>

      {/* 테이블 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f8f9fa' }}>
                <th style={tableHeaderStyle}>No</th>
                <th style={tableHeaderStyle}>IP</th>
                <th style={tableHeaderStyle}>장소</th>
                <th style={tableHeaderStyle}>등록시간</th>
                <th style={tableHeaderStyle}>삭제</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.no}>
                  <td style={tableCellStyle}>{row.no}</td>
                  <td style={tableCellStyle}>{row.ip}</td>
                  <td style={tableCellStyle}>{row.type}</td>
                  <td style={tableCellStyle}>{row.register_time}</td>
                  <td style={tableCellStyle}>
                    <ActionButton
                      title="삭제"
                      icon={<DeleteIcon />}
                      color="#ef5350"
                      hoverColor="#e53935"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Paper>

      {/* 공지 추가 모달 */}
      <Dialog 
        open={addModalOpen} 
        onClose={handleAddClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: '800px',
            minHeight: '550px'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'
        }}>
          공지등록
        </DialogTitle>
        <DialogContent sx={{ 
          mt: 1,
          p: 3,
        }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">IP</Typography>
              <TextField
                fullWidth
                size="small"
                value={newNotice.ip}
                onChange={(e) => setNewNotice({...newNotice, ip: e.target.value})}
                sx={{
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -1
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">위치</Typography>
              <div className="grow-wrap" style={{ position: 'relative' }}>
                <textarea
                  ref={resizableInputRef}
                  style={{
                    width: '100%',
                    minHeight: '50px',
                    resize: 'none', // 기본 크기 조절 비활성화
                    overflow: 'hidden', // 스크롤 비활성화
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '4px'
                  }}
                  value={newNotice.location}
                  onChange={(e) => setNewNotice({...newNotice, location: e.target.value})}
                  onInput={(e) => {
                    e.target.style.height = 'auto'; // 높이 초기화
                    e.target.style.height = e.target.scrollHeight + 'px'; // 높이 조정
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleAddClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            닫기
          </Button>
          <Button 
            onClick={handleAddSubmit}
            variant="contained" 
            color="primary"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// 스타일 상수
const tableHeaderStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  color: '#344767',
  fontWeight: 600,
  fontSize: '0.75rem',
  textTransform: 'uppercase'
};

const tableCellStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  fontSize: '0.875rem'
};

// 액션 버튼 컴포넌트
const ActionButton = ({ title, icon, color, hoverColor }) => (
  <Tooltip title={title}>
    <IconButton
      size="medium"
      sx={{
        backgroundColor: color,
        color: '#fff',
        width: '36px',
        height: '36px',
        '&:hover': {
          backgroundColor: hoverColor
        }
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: '1.2rem' } })}
    </IconButton>
  </Tooltip>
);

export default AdminAccessIPCheck;