import React, { useState } from 'react';
import { 
  Box, 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  Select, 
  MenuItem, 
  TextField,
  IconButton,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  InputLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DateRangePicker from './DateRangePicker';
import dayjs from 'dayjs';
import { HISTORY_TYPE_OPTIONS, PAYMENT_TYPE_OPTIONS } from '../../Constants/settlementOptions';

const StyledBox = styled(Box)({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '16px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  position: 'relative',
  zIndex: 1200,
  overflow: 'visible',
  '& .MuiPopover-root, .MuiPopper-root': {
    position: 'fixed !important',
    transform: 'none !important',
    left: '240px !important',
    zIndex: 1500,
    '& .MuiPaper-root': {
      zIndex: 1500
    }
  }
});

const GroupBox = styled(Box)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  minWidth: '100px',
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  backgroundColor: '#fff',
  padding: '0 4px',
  '& .MuiFormControlLabel-root': {
    marginRight: '0',
    marginLeft: '0',
    '& .MuiRadio-root': {
      padding: '2px'
    },
    '& .MuiTypography-root': {
      fontSize: '0.75rem'
    }
  },
  '& .MuiRadioGroup-root': {
    marginLeft: '2px'
  },
  '&::before': {
    content: 'attr(data-title)',
    position: 'absolute',
    top: '-8px',
    left: '8px',
    padding: '0 4px',
    backgroundColor: '#fff',
    fontSize: '0.7rem',
    color: '#666',
    whiteSpace: 'nowrap'
  }
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root': {
    padding: '4px 8px',
    fontSize: '0.75rem',
    height: '42px',
    border: '1px solid #e0e0e0',
    '&.Mui-selected': {
      backgroundColor: '#1976d2',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#1565c0',
        color: '#fff'
      }
    }
  }
}));

const LabelWrapper = styled(Box)({
  position: 'relative',
  '& .label': {
    position: 'absolute',
    top: '-24px',
    left: '0',
    fontSize: '0.85rem',
    color: '#666',
    backgroundColor: 'transparent'
  }
});

const SearchArea = ({ onSearch }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountMatch, setAccountMatch] = useState('미검증');
  const [withdrawalLimit, setWithdrawalLimit] = useState('출금가능');
  const [businessAccount, setBusinessAccount] = useState('지사');
  const [historyType, setHistoryType] = useState('all');
  const [merchantAccount, setMerchantAccount] = useState('가맹');
  const [paymentType, setPaymentType] = useState('all');
  const [pageSize, setPageSize] = useState('100');
  const [searchText, setSearchText] = useState('');

  const handleAccountMatchChange = (event, newValue) => {
    if (newValue === null) {
      setAccountMatch(null);
    } else {
      setAccountMatch(newValue === accountMatch ? null : newValue);
    }
  };

  const handleSearch = () => {
    const searchConditions = {
      startDate,
      endDate,
      searchText,
      withdrawalLimit,
      paymentType,
      historyType,
      accountMatch,
      businessAccount,
      merchantAccount,
      pageSize
    };

    console.log('Search conditions from SettlementSearch:', searchConditions);
    onSearch(searchConditions);
  };

  return (
    <StyledBox>
      {/* 날짜 선택 */}
      <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        alignItems: 'center',
        flexWrap: 'wrap',
        position: 'relative',
        zIndex: 1,
        '& .MuiPickersPopper-root': {
          zIndex: 9999
        }
      }}>
        <Box sx={{ 
          position: 'relative',
          '& .MuiPopover-root, .MuiPopper-root': {
            zIndex: 1500
          }
        }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            isSearchSection={true}
          />
        </Box>
      </Box>

      {/* 라디오 버튼 그룹들 먼저 배치 */}
      {/* 출금제한 */}
      <GroupBox data-title="출금제한">
        <RadioGroup row value={withdrawalLimit} onChange={(e) => setWithdrawalLimit(e.target.value)}>
          <FormControlLabel value="출금가능" control={<Radio size="small" />} label="출금가능" />
          <FormControlLabel value="출금불가" control={<Radio size="small" />} label="출금불가" />
        </RadioGroup>
      </GroupBox>

      {/* 영업계정 */}
      <GroupBox data-title="영업계정">
        <RadioGroup row value={businessAccount} onChange={(e) => setBusinessAccount(e.target.value)}>
          <FormControlLabel value="지사" control={<Radio size="small" />} label="지사" />
          <FormControlLabel value="대리점" control={<Radio size="small" />} label="대리점" />
        </RadioGroup>
      </GroupBox>

      {/* 가맹/계정 */}
      <GroupBox data-title="가맹/계정">
        <FormControl>
          <RadioGroup
            row
            value={merchantAccount}
            onChange={(e) => setMerchantAccount(e.target.value)}
          >
            <FormControlLabel value="가맹" control={<Radio size="small" />} label="가맹" />
            <FormControlLabel value="계정" control={<Radio size="small" />} label="계정" />
          </RadioGroup>
        </FormControl>
      </GroupBox>

      {/* 내역조회 Select 수정 */}
      <FormControl size="medium" sx={{ minWidth: '140px' }}>
        <InputLabel>내역조회</InputLabel>
        <Select
          value={historyType}
          onChange={(e) => setHistoryType(e.target.value)}
        >
          {HISTORY_TYPE_OPTIONS.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* 계좌 일치여부 */}
      <LabelWrapper>
        <span className="label">계좌 일치여부</span>
        <StyledToggleButtonGroup
          value={accountMatch}
          exclusive
          onChange={handleAccountMatchChange}
          size="medium"
        >
          <ToggleButton value="미검증">미검증</ToggleButton>
          <ToggleButton value="계좌일치">계좌일치</ToggleButton>
          <ToggleButton value="계좌불일치">계좌불일치</ToggleButton>
        </StyledToggleButtonGroup>
      </LabelWrapper>

      {/* 결제방식 Select 수정 */}
      <FormControl size="medium" sx={{ minWidth: '140px' }}>
        <InputLabel>결제방식</InputLabel>
        <Select
          value={paymentType}
          onChange={(e) => setPaymentType(e.target.value)}
        >
          {PAYMENT_TYPE_OPTIONS.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* 페이지 크기 */}
      <FormControl size="medium" sx={{ minWidth: '80px' }}>
        <Select
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
        >
          <MenuItem value="30">30</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
          <MenuItem value="200">200</MenuItem>
        </Select>
      </FormControl>

      {/* 검색 필드와 버튼 */}
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <TextField
          size="medium"
          placeholder="머천트 아이디, 계좌번호"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ width: '220px' }}
        />
        <IconButton 
          onClick={handleSearch}
          sx={{ 
            backgroundColor: '#1976d2', 
            color: 'white',
            padding: '12px',
            '&:hover': {
              backgroundColor: '#1565c0'
            }
          }}
        >
          <SearchIcon sx={{ fontSize: '1.2rem' }} />
        </IconButton>
      </Box>
    </StyledBox>
  );
};

export default SearchArea;