import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Snackbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 16px rgba(0,0,0,0.12)',
  },
}));

// 섹션 타이틀 스타일
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    left: '0',
    width: '80px',
    height: '2px',
    backgroundColor: '#e74c3c',
  },
}));

const ChugaInput = () => {
  // 상태값 관리
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // 파일 드래그 & 드롭 영역 핸들러
  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    
    const validFiles = files.filter(file => allowedTypes.includes(file.type));
    const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      setAlertMessage('jpg, jpeg, png, pdf 파일만 업로드 가능합니다.');
      setOpenSnackbar(true);
      return;
    }

    // 유효한 파일 처리
    const newFiles = validFiles.map((file, index) => ({
      id: uploadedFiles.length + index + 1,
      name: file.name,
      file: URL.createObjectURL(file),
      type: file.type
    }));

    setUploadedFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  // 파일 삭제 핸들러
  const handleDelete = (id) => {
    setUploadedFiles(uploadedFiles.filter(file => file.id !== id));
  };

  // 드래그 이벤트 핸들러
  const handleDragStart = (e) => {
    // 테이블 내부의 이미지에 대한 드래그 이벤트 방지
    e.preventDefault();
    e.stopPropagation();
  };

  // 메인 코드
  return (
    <StyledPaper elevation={2}>
      <SectionTitle>추가 입력</SectionTitle>
      <Box sx={{ width: '100%' }}>
        {/* 파일 드래그 & 드롭 영역 */}
        <Box
          sx={{
            border: '1px dashed #ccc',
            borderRadius: '4px',
            p: 2,
            mb: 2,
            textAlign: 'center',
            backgroundColor: '#fafafa'
          }}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <Typography variant="body2">
            파일을 이곳에 끌어다 놓으세요 (jpg, jpeg, png, pdf)
          </Typography>
        </Box>

        {/* 업로드된 파일 테이블 */}
        {uploadedFiles.length > 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: '10%' }}>No</TableCell>
                  <TableCell align="center" sx={{ width: '30%' }}>이미지</TableCell>
                  <TableCell align="center" sx={{ width: '40%' }}>파일명</TableCell>
                  <TableCell align="center" sx={{ width: '20%' }}>삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadedFiles.map((file, index) => (
                  <TableRow key={file.id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {file.type === 'application/pdf' ? (
                        <Box sx={{
                          width: '120px',
                          height: '120px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: '0 auto',
                          backgroundColor: '#f5f5f5',
                          borderRadius: '4px'
                        }}>
                          <Typography variant="caption">PDF</Typography>
                        </Box>
                      ) : (
                        <Box 
                          sx={{
                            width: '120px',
                            height: '120px',
                            margin: '0 auto',
                            overflow: 'hidden',
                            borderRadius: '4px',
                            userSelect: 'none', // 텍스트 선택 방지
                            WebkitUserDrag: 'none', // Safari에서 드래그 방지
                            WebkitTouchCallout: 'none', // iOS에서 길게 누르기 방지
                          }}
                          onDragStart={handleDragStart} // 드래그 시작 방지
                          onMouseDown={(e) => e.preventDefault()} // 마우스 다운 이벤트 방지
                        >
                          <img
                            src={file.file}
                            alt={file.name}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              pointerEvents: 'none', // 모든 마우스 이벤트 방지
                            }}
                          />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="center">{file.name}</TableCell>
                    <TableCell align="center">
                      <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(file.id)}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* 입력 필드들 */}
        <Box sx={{ '& > :not(:last-child)': { mb: 2 } }}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>비고</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
            />
          </Box>
          
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>요청란</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
            />
          </Box>
          
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>보완사항</Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        message={alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </StyledPaper>
  );
};

export default ChugaInput;