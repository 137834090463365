import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dayjs from 'dayjs';

// 필요한 컴포넌트들 import
import Notice from '../../Notice/Notice';
import SearchOptions from '../../Common/SearchOptions';
import ResultTable from '../../Common/SettlementResultData/SetResultTables';
import ResultText from '../../Common/SettlementResultData/SetResultText'; // 추가
import SettlementHoldDetailsTables from './SettlementHoldDetailsTables';
import QuickMenu from '../../Common/QuickMenu';
import CurrentLocation from '../../Common/CurrentLocation';



// 메인 함수
function SettlementHoldDetails() {
  // 테마 설정
  const theme = useTheme();
  // 모바일 여부 확인
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // 태블릿 여부 확인
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  // 검색 패널 열기 상태
  const [searchPanelOpen, setSearchPanelOpen] = useState(!isMobile);
  // 선택된 행 상태
  const [selectedRows, setSelectedRows] = useState([]);

  // 검색 조건 상태 추가
  const [searchConditions, setSearchConditions] = useState(null);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      if (!isMobile && !isTablet) {
        setSearchPanelOpen(true);
      } else if (isMobile || isTablet) {
        setSearchPanelOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile, isTablet]);

  // 검색 핸들러 수정
  const handleSearch = (searchParams) => {
    console.log('받은 검색 파라미터:', searchParams); // 디버깅용

    // 날짜 형식 변환
    const formattedStartDate = searchParams.startDate ? 
      dayjs(searchParams.startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = searchParams.endDate ? 
      dayjs(searchParams.endDate).format('YYYY-MM-DD') : '';

    // SearchOptions에서 받은 값을 테이블에 맞게 변환
    const newSearchConditions = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      // 텍스트 필드 검색어 처리
      searchText: searchParams.searchText || '',  // MID, TID, 가맹점명 검색용
      // 토글 옵션들 처리
      selectedToggleOptions: searchParams.selectedToggleOptions || [],
      // 기타 옵션들
      sourceType: searchParams.sourceType || '',
      businessType: searchParams.businessType || '',
      taxType: searchParams.taxType || '',
      depositType: searchParams.depositType || '',
      recurringType: searchParams.recurringType || '',
      duplicateType: searchParams.duplicateType || '',
      walletType: searchParams.walletType || '', // walletType 추가
    };

    console.log('테이블로 전달하는 검색 조건:', newSearchConditions); // 디버깅용
    setSearchConditions(newSearchConditions);
  };

  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    noticeBox: { 
      px: 0,
      mx: 0,
      width: '100%',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      mb: 2,
      ml: { xs: '10px', md: '0px' }
    },
    tableContainer: {
      marginLeft: { xs: '10px', md: '40px' },
      marginRight: { xs: '10px', md: '40px' },
      maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 400px)' },
      overflowY: 'auto',
      overflowX: 'auto',
      width: '100%',
      backgroundColor: '#fff', 
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)', 
    },
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    },
    closeButton: {
      position: 'absolute',
      left: '-20px',
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: '#fff',
      borderRadius: '50%',
      boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
      '&:hover': {
        backgroundColor: '#f5f5f5'
      }
    }
  };

  // 메인 코드
  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="정산보류 내역" />
        <ResultText />
        <ResultTable />
        <SettlementHoldDetailsTables 
          searchPanelOpen={searchPanelOpen}
          setSearchPanelOpen={setSearchPanelOpen}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          searchConditions={searchConditions}
        />
      </Box>

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
          initialValues={searchConditions}
        />
      </Drawer>
    </Box>
  );
}

export default SettlementHoldDetails;
