import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';

// BonNote 스타일 적용
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#1976d2', // 기존 파란색
    color: '#ffffff',
  },

  // 특정 ID에 스타일 추가
  '&#specific-id': {
    color: '#000000', // 텍스트 색상 검정
    backgroundColor: '#1565c0', // 더 진한 파란색
    fontWeight: 'bold', // 텍스트 두껍게
    borderBottom: '2px solid #e9ecef', // 기존 테두리 유지
    borderRight: '1px solid #e9ecef',
    fontSize: '0.813rem', // 기존 텍스트 크기 유지
  },
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 래퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// 차트 래퍼 스타일 컴포넌트 추가
const ChartWrapper = styled(Box)({
  width: '100%',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 메인 함수
function InjeungData() {
  // 더미 데이터 생성
  const generateDummyData = () => {
    return {
      '거래금액': {
        건수: Math.floor(Math.random() * 10000),
        ...Array.from({ length: 14 }, (_, idx) => ({
          [`${String(idx + 1).padStart(2, '0')}일`]: Math.floor(Math.random() * 100000000)
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        합계: 0  // 합계 초기화
      },
      '비인증 거래금액': {
        건수: Math.floor(Math.random() * 1000),
        ...Array.from({ length: 14 }, (_, idx) => ({
          [`${String(idx + 1).padStart(2, '0')}일`]: Math.floor(Math.random() * 20000000)
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        합계: 0
      },
      '인증 거래금액': {
        건수: Math.floor(Math.random() * 5000),
        ...Array.from({ length: 14 }, (_, idx) => ({
          [`${String(idx + 1).padStart(2, '0')}일`]: Math.floor(Math.random() * 50000000)
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        합계: 0
      },
      '구인증 거래금액': {
        건수: Math.floor(Math.random() * 3000),
        ...Array.from({ length: 14 }, (_, idx) => ({
          [`${String(idx + 1).padStart(2, '0')}일`]: Math.floor(Math.random() * 30000000)
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        합계: 0
      }
    };
  };

  const dummyData = generateDummyData();

  // 데이터 생성 후 합계 계산
  Object.keys(dummyData).forEach(key => {
    dummyData[key].합계 = Array.from({ length: 14 }, (_, i) => {
      const day = `${String(i + 1).padStart(2, '0')}일`;
      return dummyData[key][day] || 0;
    }).reduce((sum, value) => sum + value, 0);
  });

  // 막대 차트 옵션
  const barChartOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' }
    },
    legend: {
      data: ['거래금액', '비인증 거래금액', '인증 거래금액', '구인증 거래금액'],
      bottom: 0,
      textStyle: { fontSize: 11 }
    },
    grid: {
      top: '5%',
      left: '3%',
      right: '4%',
      bottom: '15%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: Array.from({ length: 14 }, (_, i) => `${String(i + 1).padStart(2, '0')}일`),
      axisLabel: {
        fontSize: 11,
        color: '#666'
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => {
          const num = value / 10000000;
          return num === 10 ? '1억' : num + '천만';
        },
        fontSize: 11,
        color: '#666'
      }
    },
    series: Object.entries(dummyData).map(([name, data]) => ({
      name: name,
      type: 'bar',
      barWidth: '8%',  // 막대 너비 더 축소
      data: Array.from({ length: 14 }, (_, i) => {
        const day = `${String(i + 1).padStart(2, '0')}일`;
        return data[day];
      })
    }))
  };

  // 도넛 차트 옵션
  const donutChartOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      right: '10%',
      top: 'center',
      textStyle: { fontSize: 11 }
    },
    series: [{
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['40%', '50%'],
      avoidLabelOverlap: false,
      label: {
        show: false
      },
      data: [
        { value: dummyData['인증 거래금액'].건수, name: '인증' },
        { value: dummyData['비인증 거래금액'].건수, name: '비인증' },
        { value: dummyData['구인증 거래금액'].건수, name: '구인증' }
      ]
    }]
  };

  // 메인 함수
  return (
    <Paper sx={{ 
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: 'none',
      border: '1px solid #e9ecef',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <Typography variant="h6" sx={{ p: 2, borderBottom: '1px solid #e9ecef' }}>
        인증별 결제금액
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <StyledTableWrapper>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="sticky-column primary-header">구분</StyledTableCell>
                <StyledTableCell className="primary-header">건수</StyledTableCell>
                <StyledTableCell className="primary-header">합계</StyledTableCell>
                {Array.from({ length: 14 }, (_, i) => (
                  <StyledTableCell key={i} className="secondary-header">
                    {String(i + 1).padStart(2, '0')}일
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {Object.entries(dummyData).map(([name, data]) => (
                <StyledTableRow key={name}>
                  <StyledTableCell className="sticky-column">{name}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>
                    {data.건수.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>
                    {data.합계.toLocaleString()}
                  </StyledTableCell>
                  {Array.from({ length: 14 }, (_, i) => {
                    const day = `${String(i + 1).padStart(2, '0')}일`;
                    return (
                      <StyledTableCell key={i} sx={{ textAlign: 'right' }}>
                        {(data[day] || 0).toLocaleString()}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableWrapper>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <ChartWrapper>
              <Box sx={{ minWidth: window.innerWidth <= 600 ? '1200px' : 'auto' }}>
                <ReactECharts 
                  option={barChartOption}
                  style={{ height: '300px' }}
                  className="echarts-for-react boot-chart"
                />
              </Box>
            </ChartWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChartWrapper>
              <Box sx={{ minWidth: window.innerWidth <= 600 ? '600px' : 'auto' }}>
                <ReactECharts 
                  option={donutChartOption}
                  style={{ height: '300px' }}
                  className="echarts-for-react boot-chart"
                />
              </Box>
            </ChartWrapper>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default InjeungData;
