import React, { useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { useStyles } from './styles';
import { useMediaQuery } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

// 과세 통계 컴포넌트
const TaxStats = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:800px)');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedMonth, setSelectedMonth] = useState('11');
  const [sortedData, setSortedData] = useState([
    {
      type: '거래금액',
      count: 12528,
      amount: 1689991365,
      isTotal: true
    },
    {
      type: '비과세 거래금액',
      count: 8087,
      amount: 1237410930,
      isTotal: false
    },
    {
      type: '과세 거래금액',
      count: 4441,
      amount: 452580435,
      isTotal: false
    }
  ]);

  // 정렬 함수
  const handleSort = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    const sorted = [...sortedData].sort((a, b) => {
      if (a.isTotal) return -1;
      if (b.isTotal) return 1;
      return newDirection === 'asc' 
        ? a.amount - b.amount 
        : b.amount - a.amount;
    });
    setSortDirection(newDirection);
    setSortedData(sorted);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const barChartData = {
    labels: ['11월'],
    datasets: [
      {
        label: '결제금액',
        backgroundColor: 'rgba(81, 203, 206, 0.8)',
        data: [1689991365],
        barPercentage: 0.7,
        borderRadius: 5
      },
      {
        label: '비과세 거래금액',
        backgroundColor: 'rgba(251, 198, 88, 0.8)',
        data: [1237410930],
        barPercentage: 0.7,
        borderRadius: 5
      },
      {
        label: '과세 거래금액',
        backgroundColor: 'rgba(149, 165, 166, 0.8)',
        data: [452580435],
        barPercentage: 0.7,
        borderRadius: 5
      }
    ]
  };

  const pieChartData = {
    labels: ['과세', '비과세'],
    datasets: [{
      data: [452580435, 1237410930],
      backgroundColor: [
        'rgba(81, 203, 206, 0.8)',
        'rgba(251, 198, 88, 0.8)'
      ],
      borderWidth: 0,
      hoverOffset: 4
    }]
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif",
            weight: '500'
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: 12,
        titleFont: {
          size: 13,
          family: "'Noto Sans KR', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 12,
          family: "'Noto Sans KR', sans-serif"
        },
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `${context.dataset.label}: ${value.toLocaleString()}원`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif"
          },
          padding: 10,
          callback: value => value.toLocaleString() + '원'
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif"
          }
        }
      }
    }
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '30%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif",
            weight: '500'
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: 12,
        titleFont: {
          size: 13,
          family: "'Noto Sans KR', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 12,
          family: "'Noto Sans KR', sans-serif"
        },
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${percentage}% (${value.toLocaleString()}원)`;
          }
        }
      }
    }
  };

  // 메인 코드
  return (
    <Box className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Box className={classes.cardHeader}>
              <Typography className={classes.chartTitle}>
                월별 과세 결제금액
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <TableContainer 
                component={Paper} 
                elevation={0}
                sx={{
                  overflowX: 'auto',
                  '& .MuiTable-root': {
                    minWidth: isMobile ? '800px' : 'auto'
                  },
                  '& .MuiTableCell-root': {
                    padding: isMobile ? '8px' : '16px',
                    whiteSpace: 'nowrap'
                  }
                }}
              >
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>결제건수</TableCell>
                      <TableCell>11월</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center" justifyContent="center">
                          합계
                          <IconButton 
                            size="small"
                            onClick={handleSort}
                            className={classes.sortButton}
                          >
                            {sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((row, index) => (
                      <TableRow 
                        key={index}
                        className={row.isTotal ? classes.totalRow : ''}
                      >
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.count.toLocaleString()}</TableCell>
                        <TableCell>{row.amount.toLocaleString()}</TableCell>
                        <TableCell>{row.amount.toLocaleString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                월별 과세 결제금액 추이
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.chartSubtitle}>
                월별 과세/비과세 결제 현황
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Bar data={barChartData} options={barChartOptions} />
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                과세/비과세 비율
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.chartSubtitle}>
                전체 결제금액 대비
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Pie data={pieChartData} options={pieChartOptions} />
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TaxStats;