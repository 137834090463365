import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Button, Card, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, IconButton, 
  Tooltip, TextField, RadioGroup, FormControlLabel, Radio 
} from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import DateRangePicker from '../../Common/DateRangePicker';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import ExcelJS from 'exceljs';

// 스타일 컴포넌트
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

// 테이블 컨테이너 스타일
const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 430px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

// 테이블 로우 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafbfc',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
    '& .MuiTableCell-root': {
      color: 'inherit'
    }
  },
  td: {
    fontSize: '0.875rem',
    color: '#333',
    borderBottom: '1px solid #e0e0e0'
  }
}));

// 전화번호 텍스트 스타일
const PhoneNumberText = styled('span')(({ isMatched }) => ({
  color: isMatched ? '#333' : '#e53935',
  '&:hover': {
    color: isMatched ? '#333' : '#e53935'
  }
}));

// 헤더 박스 스타일
const HeaderBox = styled(Box)({
  padding: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

// 검색 섹션 스타일 컴포넌트 추가
const SearchSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '0 24px 16px 24px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  '&::-webkit-scrollbar': {
    height: '6px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '3px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '3px',
    '&:hover': {
      background: '#666'
    }
  }
});

// 컬럼 매핑 정의
const columnMapping = {
  'No': 'no',
  '등록일시': 'date',
  '가맹점/영업': 'type',
  'ID': 'id',
  '상호명': 'name',
  'IP': 'ip',
  '등록 휴대번호': 'registeredPhone',
  '로그인 휴대번호': 'loginPhone'
};

// 컬럼 너비 계산 함수
const getMaxColumnWidth = (data, header) => {
  const fieldName = columnMapping[header];
  const maxDataLength = Math.max(
    header.length,
    ...data.map(row => {
      const value = String(row[fieldName] || '');
      return value.length;
    })
  );
  const getTextWidth = (text) => {
    return Array.from(text).reduce((width, char) => {
      return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
    }, 0);
  };
  const maxWidth = Math.max(
    getTextWidth(header),
    ...data.map(row => getTextWidth(String(row[fieldName] || '')))
  );
  return maxWidth + Math.ceil(maxWidth * 0.2);
};

// 초기값 세팅 + 더미
const SMSVerificationHistory = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [records, setRecords] = useState([
    {
      no: 1,
      date: '2024-12-05 17:13:20',
      type: '가맹점',
      id: 'm90745089',
      name: '안경선',
      ip: '106.101.196.221',
      registeredPhone: '010-9074-5089',
      loginPhone: '010-9074-5089'
    },
    {
      no: 2,
      date: '2024-12-05 16:12:17',
      type: '가맹점',
      id: 'm48780064',
      name: '오성상사',
      ip: '211.235.97.144',
      registeredPhone: '010-4878-0051',
      loginPhone: '010-3766-0071'
    },
    {
      no: 3,
      date: '2024-12-05 15:45:33',
      type: '영업',
      id: 's77123456',
      name: '김영업',
      ip: '192.168.1.100',
      registeredPhone: '010-1234-5678',
      loginPhone: '010-1234-5678'
    },
    {
      no: 4,
      date: '2024-12-05 14:30:22',
      type: '가맹점',
      id: 'm55667788',
      name: '대한상사',
      ip: '172.16.0.100',
      registeredPhone: '010-5566-7788',
      loginPhone: '010-9988-7766'
    },
    {
      no: 5,
      date: '2024-12-05 13:15:45',
      type: '영업',
      id: 's88990011',
      name: '이영업',
      ip: '10.0.0.50',
      registeredPhone: '010-8899-0011',
      loginPhone: '010-7788-9900'
    }
  ]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountType, setAccountType] = useState('전체');

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedRecords = [...records].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setRecords(sortedRecords);
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('SMS인증내역');

    // 스타일 정의
    const defaultCellStyle = {
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      font: {
        size: 10,
        name: '맑은 고딕'
      }
    };

    const headerCellStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF1976D2' }
      },
      font: {
        size: 10,
        color: { argb: 'FFFFFFFF' },
        bold: true,
        name: '맑은 고딕'
      },
      height: 30
    };

    // 헤더 설정
    const headers = Object.keys(columnMapping);
    worksheet.addRow(headers);

    // 데이터 추가
    records.forEach(record => {
      const row = headers.map(header => record[columnMapping[header]]);
      worksheet.addRow(row);
    });

    // 스타일 적용
    worksheet.getRow(1).height = headerCellStyle.height;
    worksheet.getRow(1).eachCell(cell => {
      Object.assign(cell, headerCellStyle);
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // 컬럼 너비 설정
    headers.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = getMaxColumnWidth(records, header);
      column.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // 데이터 행 스타일 적용
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        row.height = 25;
        row.eachCell(cell => {
          Object.assign(cell, defaultCellStyle);
        });
      }
    });

    // 파일 다운로드
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `SMS인증내역_${new Date().toISOString().split('T')[0]}.xlsx`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  // 메인 코드
  return (
    <StyledCard>
      <HeaderBox>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SmsIcon sx={{ color: '#d32f2f' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            문자 인증내역
          </Typography>
        </Box>
      </HeaderBox>

      <SearchSection>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
        />
        <TextField
          size="small"
          placeholder="로그인 아이디, IP, 휴대번호"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          sx={{ 
            width: '300px',
            flexShrink: 0
          }}
        />
        {/* 검색 버튼 */}
        <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={() => {/* 검색 로직 구현 */}}
            sx={{ minWidth: '100px' }}
          >
            검색
          </Button>
        <RadioGroup
          row
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
          sx={{ flexShrink: 0 }}
        >
          <FormControlLabel value="가맹점" control={<Radio />} label="가맹점" />
          <FormControlLabel value="영업" control={<Radio />} label="영업" />
        </RadioGroup>
        <Tooltip title="엑셀 다운로드">
          <IconButton
            onClick={handleExcelDownload}
            sx={{
              width: '40px',
              height: '40px',
              backgroundColor: '#fff',
              border: '1px solid #2e7d32',
              color: '#2e7d32',
              flexShrink: 0,
              '&:hover': {
                backgroundColor: '#e8f5e9',
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </SearchSection>

      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell 
                onClick={handleSortClick}
                sx={{ cursor: 'pointer' }}
              >
                등록일시 {sortOrder === 'asc' ? ' ▲' : ' ▼'}
              </StyledTableCell>
              <StyledTableCell>가맹점/영업</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>상호명</StyledTableCell>
              <StyledTableCell>IP</StyledTableCell>
              <StyledTableCell>등록 휴대번호</StyledTableCell>
              <StyledTableCell>로그인 휴대번호</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{record.no}</StyledTableCell>
                <StyledTableCell>{record.date}</StyledTableCell>
                <StyledTableCell>{record.type}</StyledTableCell>
                <StyledTableCell>{record.id}</StyledTableCell>
                <StyledTableCell>{record.name}</StyledTableCell>
                <StyledTableCell>{record.ip}</StyledTableCell>
                <StyledTableCell>{record.registeredPhone}</StyledTableCell>
                <StyledTableCell>
                  <PhoneNumberText isMatched={record.registeredPhone === record.loginPhone}>
                    {record.loginPhone}
                  </PhoneNumberText>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </StyledCard>
  );
};

export default SMSVerificationHistory;
