import React, { useState } from 'react';
import Breadcrumb from '../Common/Breadcrumb';
import { Box, Typography, Paper, Divider, IconButton, Tooltip } from '@mui/material';
import Notice from '../Notice/Notice';
import AdminStatusPanel from './Adminsidebar';
// 유저 서비스 관련 아이콘
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ArticleIcon from '@mui/icons-material/Article';
// 전산 옵션 관련 아이콘
import DnsIcon from '@mui/icons-material/Dns';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SecurityIcon from '@mui/icons-material/Security';
// ON/OFF 관련 아이콘
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PaymentIcon from '@mui/icons-material/Payment';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// PG사 관련 아이콘
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
// 설정 및 관리 아
import SettingsIcon from '@mui/icons-material/Settings';
import SmsIcon from '@mui/icons-material/Sms';
// 변경 이력 아이콘
import HistoryIcon from '@mui/icons-material/History';
import ApiIcon from '@mui/icons-material/Api';
// 링크 관련 아이콘
import LinkIcon from '@mui/icons-material/Link';
import PaymentsIcon from '@mui/icons-material/Payments';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import CodeIcon from '@mui/icons-material/Code';
import RouterIcon from '@mui/icons-material/Router';
import LanIcon from '@mui/icons-material/Lan';
import ErrorIcon from '@mui/icons-material/Error';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import UserNotices from './Options Folder/UserNotices';
import UserFAQ from './Options Folder/UserFAQ';
import UserManual from './Options Folder/UserManual';
import SystemNotice from './Options Folder/SystemNotice';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SMSVerificationHistory from './Options Folder/SMSVerificationHistory';
import SMSSendingHistory from './Options Folder/SMSSendingHistory';
import UserAccessHistory from './Options Folder/UserAccessHistory';
import ErrorMessage from './Options Folder/ErrorMessage';
import PersonOffIcon from '@mui/icons-material/PersonOff';  // 유저 메뉴 On/Off용
import DeveloperBoardOffIcon from '@mui/icons-material/DeveloperBoardOff';  // 서브전산 메뉴 On/Off용
import MenuOnOff from './Options Folder/MenuOnOff';
import CashBillMenuOnOff from './Options Folder/CashBillMenuOnOff';  // 추가
import UserMenuOnOff from './Options Folder/UserMenuOnOff';  // 추가
import SubSystemOnOff from './Options Folder/SubSystemOnOff';  // 추가
import PGProviderSettings from './Options Folder/PGProviderSettings';
import PGProviderOptions from './Options Folder/PGProviderOptions';
import ConfigurationandSettings from './Options Folder/ConfigurationandSettings';
import WalletManagement from './Options Folder/WalletManagement';
import MerchantSMSManagement from './Options Folder/MerchantSMSManagement';
import MerchantBalance from './Options Folder/MerchantBalance';
import ExcelManagement from './Options Folder/ExcelManagement';
import APIStatusHistory from './Options Folder/APIStatusHistory';
import EditHistory from './Options Folder/EditHistory';
import PointHistory from './AdminOptions Folder/PointHistory';
import FileUploadHistory from './AdminOptions Folder/FileUploadHistory';
import DatabaseIntegrityCheck from './AdminOptions Folder/DatabaseIntegrityCheck';
import UserNotificationLogs from './AdminOptions Folder/UserNotificationLogs';
import NotificationsIcon from '@mui/icons-material/Notifications';  // 추가
import ServerQueryLogs from './AdminOptions Folder/ServerQueryLogs';  // 렌더링 부분 추가
import ServerResources from './AdminOptions Folder/ServerResources';
import ServerModule from './AdminOptions Folder/ServerModule';
import PaymentHistoryDBCheck from './AdminOptions Folder/PaymentHistoryDBCheck';
import WalletPendingList from './AdminOptions Folder/WalletPendingList';
import AdminAccessIPCheck from './AdminOptions Folder/AdminAccessIPCheck';
import AllAccountsVerification from './AdminOptions Folder/AllAccountsVerification';
import IPBlock from './AdminOptions Folder/IPBlock';

const AdminPage = () => {
  const [selectedComponent, setSelectedComponent] = React.useState(null);
  const [isPanelOpen, setIsPanelOpen] = React.useState(true);

  const menuCategories = [
    {
      title: '관리자 메뉴',
      items: [
        { title: '유저', subItems: ['유저 공지사항', '유저 FAQ', '유저 메뉴얼'] },
        { title: '내역', subItems: ['본전산 공지사항', '문자 인증내역', '문자 발송내역', '통합유저 접속내역', '오류메세지'] },
        { title: 'On/Off 메뉴', subItems: ['메뉴 On/Off', '캐시빌 메뉴 On/Off', '유저 메뉴 On/Off', '서브전산 메뉴On/Off'] },
        { title: 'PG사', subItems: ['Pg사별 세팅', 'Pg사 설정'] },
        { title: '설정', subItems: ['설정 및 세팅', '월렛 정보관리', '머천트 SMS관리', '머천트 잔액'] },
        { title: '이력 확인', subItems: ['엑셀관리', 'API 상태 이력', '수정 이력'] },
        { title: '회사', subItems: ['mainpay', 'platbill', '리뉴얼 mainpay', 'cash2check'] }
      ]
    }
  ];

  //상단에 일자로 정렬되는 메뉴 버튼
  const topMenuButtons = [
    { 
      title: '포인트 내역', 
      icon: <ListAltIcon sx={{ fontSize: 24, color: '#00acc1' }} />,
      onClick: () => setSelectedComponent('pointHistory')
    },
    { 
      title: '파일 업로드 내역', 
      icon: <CloudUploadIcon sx={{ fontSize: 24, color: '#00acc1' }} />,
      onClick: () => setSelectedComponent('fileUploadHistory')
    },
    { 
      title: 'DB 일치검사', 
      icon: <StorageIcon sx={{ fontSize: 24, color: '#00acc1' }} />,
      onClick: () => setSelectedComponent('databaseIntegrityCheck')
    },
    { 
      title: '유저알림 전송 및 내역', 
      icon: <NotificationsIcon sx={{ fontSize: 24, color: '#00acc1' }} />,
      onClick: () => setSelectedComponent('userNotificationLogs')
    },
    { title: '서버쿼리 로그', icon: <BuildIcon sx={{ fontSize: 24, color: '#00acc1' }} />, onClick: () => setSelectedComponent('serverQueryLogs') },
    { title: '서버 리소스 정보', icon: <DnsIcon sx={{ fontSize: 24, color: '#00acc1' }} />, onClick: () => setSelectedComponent('serverResources') },
    { title: '서버 모듈 정보', icon: <IntegrationInstructionsIcon sx={{ fontSize: 24, color: '#00acc1' }} />, onClick: () => setSelectedComponent('serverModule') },
    { title: '결제내역 DB 일치 검사', icon: <AdminPanelSettingsIcon sx={{ fontSize: 24, color: '#00acc1' }} />, onClick: () => setSelectedComponent('paymentHistoryDBCheck') },
    { title: '가맹 전계좌 검증', icon: <VerifiedUserIcon sx={{ fontSize: 24, color: '#00acc1' }} />, onClick: () => setSelectedComponent('allAccountsVerification') },
    { title: '월렛 출금대기 리스트', icon: <AccountBalanceWalletIcon sx={{ fontSize: 24, color: '#00acc1' }} />, onClick: () => setSelectedComponent('walletPendingList') },
    { title: '어드민 IP 체크', icon: <RouterIcon sx={{ fontSize: 24, color: '#00acc1' }} />, onClick: () => setSelectedComponent('adminAccessIPCheck') },
    { title: 'IP 차단', icon: <LanIcon sx={{ fontSize: 24, color: '#00acc1' }} />, onClick: () => setSelectedComponent('ipBlock') }
  ];

  // 우측 메뉴 버튼
  const categoryData = [
    {
      title: '유저 서비스',
      color: '#1976d2',
      items: [
        { 
          title: '유저 공지사항', 
          icon: <NotificationsActiveIcon />,
          onClick: () => handleMenuClick('유저 공지사항')
        },
        { 
          title: '유저 FAQ', 
          icon: <QuestionAnswerIcon />,
          onClick: () => handleMenuClick('유저 FAQ')
        },
        { 
          title: '유저 메뉴얼', 
          icon: <ArticleIcon />,
          onClick: () => handleMenuClick('유저 메뉴얼')
        }
      ]
    },
    {
      title: '내역',
      color: '#d32f2f',
      items: [
        { 
          title: '본전산 공지사항', 
          icon: <DnsIcon />,
          onClick: () => handleMenuClick('본전산 공지사항')
        },
        { 
          title: '문자 인증내역', 
          icon: <SmsIcon />,
          onClick: () => handleMenuClick('문자 인증내역')
        },
        { 
          title: '문자 발송내역', 
          icon: <SmsIcon />,
          onClick: () => handleMenuClick('문자 발송내역')
        },
        { 
          title: '통합유저 접속내역', 
          icon: <PeopleAltIcon />,
          onClick: () => handleMenuClick('통합유저 접속내역')
        },
        { 
          title: '오류메세지', 
          icon: <ErrorIcon />,
          onClick: () => handleMenuClick('오류메세지')
        }
      ]
    },
    {
      title: 'On/Off 메뉴',
      color: '#2e7d32',
      items: [
        { 
          title: '메뉴 On/Off', 
          icon: <ToggleOnIcon />,
          onClick: () => handleMenuClick('메뉴 On/Off')
        },
        { 
          title: '캐시빌 메뉴 On/Off', 
          icon: <PaymentIcon />,
          onClick: () => handleMenuClick('캐시빌 메뉴 On/Off')
        },
        { 
          title: '유저 메뉴 On/Off', 
          icon: <PersonOffIcon />,
          onClick: () => handleMenuClick('유저 메뉴 On/Off')
        },
        { 
          title: '서브전산 메뉴 On/Off', 
          icon: <DeveloperBoardOffIcon />,
          onClick: () => handleMenuClick('서브전산 메뉴 On/Off')
        }
      ]
    },
    {
      title: 'PG사',
      color: '#ed6c02',
      items: [
        { 
          title: 'Pg사별 세팅', 
          icon: <AccountBalanceIcon />,
          onClick: () => handleMenuClick('Pg사별 세팅')
        },
        { 
          title: 'Pg사 설정', 
          icon: <ReceiptLongIcon />,
          onClick: () => handleMenuClick('Pg사 설정')
        }
      ]
    },
    {
      title: '설정',
      color: '#9c27b0',
      items: [
        { 
          title: '설정 및 세팅', 
          icon: <SettingsIcon />,
          onClick: () => handleMenuClick('설정 및 세팅')
        },
        { 
          title: '월렛 정보관리', 
          icon: <PaymentIcon />,
          onClick: () => handleMenuClick('월렛 정보관리')
        },
        { 
          title: '머천트 SMS관리', 
          icon: <SmsIcon />,
          onClick: () => handleMenuClick('머천트 SMS관리')
        },
        { 
          title: '머천트 잔액', 
          icon: <AccountBalanceIcon />,
          onClick: () => handleMenuClick('머천트 잔액')
        }
      ]
    },
    {
      title: '이력 확인',
      color: '#0288d1',
      items: [
        { 
          title: '엑셀관리', 
          icon: <ListAltIcon />,
          onClick: () => handleMenuClick('엑셀관리')
        },
        { 
          title: 'API 상태 이력', 
          icon: <ApiIcon />,
          onClick: () => handleMenuClick('API 상태 이력')
        },
        { 
          title: '수정 이력', 
          icon: <HistoryIcon />,
          onClick: () => handleMenuClick('수정 이력')
        }
      ]
    },
    {
      title: '회사',
      color: '#424242',
      items: [
        { 
          title: 'mainpay', 
          icon: <LinkIcon />, 
          onClick: () => window.open('https://w.mainpay.me/wallet/m/index.php?login', '_blank')
        },
        { 
          title: 'platbill', 
          icon: <LinkIcon />, 
          onClick: () => window.open('https://platbill.kr/wallet/m/index.php?login', '_blank')
        },
        { 
          title: 'bitopay', 
          icon: <LinkIcon />, 
          onClick: () => window.open('https://bitopay.kr/wallet/m/index.php?login', '_blank')
        },
        { 
          title: '리뉴얼 mainpay', 
          icon: <LinkIcon />, 
          onClick: () => window.open('https://w.mainpay.me/cashbill/index.php?login', '_blank')
        },
        { 
          title: 'cash2check', 
          icon: <LinkIcon />, 
          onClick: () => window.open('https://cash2check.com/company/login.php', '_blank')
        },
        { 
          title: 'ondapay', 
          icon: <LinkIcon />, 
          onClick: () => window.open('https://ondapay.kr/company/login.php', '_blank')
        }
    ]
    }
  ];

  // 우측 메뉴 버튼 클릭 핸들러
  const handleMenuClick = (title) => {
    switch(title) {
      case '유저 공지사항':
        setSelectedComponent('userNotices');
        break;
      case '유저 FAQ':
        setSelectedComponent('userFAQ');
        break;
      case '유저 메뉴얼':
        setSelectedComponent('userManual');
        break;
      case '본전산 공지사항':
        setSelectedComponent('systemNotice');
        break;
      case '문자 인증내역':
        setSelectedComponent('smsVerification');
        break;
        case '문자 발송내역':  // 추가된 부분
      setSelectedComponent('smsSendingHistory');
      break;
      case '통합유저 접속내역':
        setSelectedComponent('userAccessHistory');
        break;
      case '오류메세지':
        setSelectedComponent('errorMessage');
        break;
      case '메뉴 On/Off':
        setSelectedComponent('menuOnOff');
        break;
      case '캐시빌 메뉴 On/Off':  // 추가
        setSelectedComponent('cashBillMenuOnOff');
        break;
      case '유저 메뉴 On/Off':  // 추가
        setSelectedComponent('userMenuOnOff');
        break;
      case '서브전산 메뉴 On/Off':  // 추가
        setSelectedComponent('subSystemOnOff');
        break;
      case 'Pg사별 세팅':
        setSelectedComponent('pgProviderSettings');
        break;
      case 'Pg사 설정':
        setSelectedComponent('pgProviderOptions');
        break;
      case '설정 및 세팅':
        setSelectedComponent('configurationSettings');
        break;
      case '월렛 정보관리':
        setSelectedComponent('walletManagement');
        break;
      case '머천트 SMS관리':
        setSelectedComponent('merchantSMSManagement');
        break;
      case '머천트 잔액':
        setSelectedComponent('merchantBalance');
        break;
      case '엑셀관리':
        setSelectedComponent('excelManagement');
        break;
      case 'API 상태 이력':
        setSelectedComponent('apiStatusHistory');
        break;
      case '수정 이력':
        setSelectedComponent('editHistory');
        break;
      default:
        setSelectedComponent(null);
    }
  };

  const handleTogglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  // 메인 코드
  return (
    <Box sx={{ 
      display: 'flex',
      // fontFamilly: "'Pretendard', 'Noto Sans KR', -apple-system, sans-serif"
    }}>
      <AdminStatusPanel 
        categoryData={categoryData} 
        onMenuClick={handleMenuClick}
        isPanelOpen={isPanelOpen}
        onTogglePanel={handleTogglePanel}
      />

      <Box sx={{
        flexGrow: 1,
        ml: '40px',
        mr: isPanelOpen ? '300px' : '40px',
        transition: 'margin 0.3s ease'
      }}>
        <Box sx={{ 
          minHeight: '100%', 
          pb: '40px',
        }}>
          <Box sx={{ mt: '24px' }}></Box>
          <Notice />
          <Box sx={{ mb: 3 }} />
          
          <Typography variant="h5" sx={{ 
            mt: 4, 
            mb: 2, 
            fontWeight: 600,
            // fontFamilly: "'Pretendard', 'Noto Sans KR', sans-serif"
          }}>
            관리자 옵션
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'nowrap',
            gap: 1,
            mb: 3,
            backgroundColor: '#ffffff',
            p: 2,
            borderRadius: '8px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            justifyContent: 'space-between',
            overflowX: 'auto'
          }}>
            {topMenuButtons.map((button, index) => (
              <Box 
                key={index} 
                onClick={button.onClick}  // 전체 Box에 onClick 이벤트 추가
                sx={{ 
                  textAlign: 'center',
                  p: 1,
                  cursor: 'pointer',  // 커서 포인터 추가
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 172, 193, 0.04)',
                    transform: 'translateY(-2px)'  // 호버 시 약간 위로 올라가는 효과
                  }
                }}
              >
                <IconButton 
                  sx={{ 
                    p: 1,
                    '&:hover': {
                      backgroundColor: 'transparent'  // 아콘 버튼의 기본 호버 효과 제거
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
                <Typography variant="body2" sx={{ 
                  mt: 0.5,
                  fontSize: '0.85rem',
                  lineHeight: 1.2
                }}>
                  {button.title}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* 선택된 컴포넌트에 따라 렌더링. 또한 상태값 체크 */}
          {selectedComponent === 'userNotices' && <UserNotices />}
          {selectedComponent === 'userFAQ' && <UserFAQ />}
          {selectedComponent === 'userManual' && <UserManual />}
          {selectedComponent === 'systemNotice' && <SystemNotice />}
          {selectedComponent === 'smsVerification' && <SMSVerificationHistory />}
          {selectedComponent === 'smsSendingHistory' && <SMSSendingHistory />}
          {selectedComponent === 'userAccessHistory' && <UserAccessHistory />}
          {selectedComponent === 'errorMessage' && <ErrorMessage />}
          {selectedComponent === 'menuOnOff' && <MenuOnOff />}
          {selectedComponent === 'cashBillMenuOnOff' && <CashBillMenuOnOff />}
          {selectedComponent === 'userMenuOnOff' && <UserMenuOnOff />}
          {selectedComponent === 'subSystemOnOff' && <SubSystemOnOff />}
          {selectedComponent === 'pgProviderSettings' && <PGProviderSettings />}
          {selectedComponent === 'pgProviderOptions' && <PGProviderOptions />}
          {selectedComponent === 'configurationSettings' && <ConfigurationandSettings />}
          {selectedComponent === 'walletManagement' && <WalletManagement />}
          {selectedComponent === 'merchantSMSManagement' && <MerchantSMSManagement />}
          {selectedComponent === 'merchantBalance' && <MerchantBalance />}
          {selectedComponent === 'excelManagement' && <ExcelManagement />}
          {selectedComponent === 'apiStatusHistory' && <APIStatusHistory />}
          {selectedComponent === 'editHistory' && <EditHistory />}
          {selectedComponent === 'pointHistory' && <PointHistory />}
          {selectedComponent === 'fileUploadHistory' && <FileUploadHistory />}
          {selectedComponent === 'databaseIntegrityCheck' && <DatabaseIntegrityCheck />}
          {selectedComponent === 'userNotificationLogs' && <UserNotificationLogs />}
          {selectedComponent === 'serverQueryLogs' && <ServerQueryLogs />}
          {selectedComponent === 'serverResources' && <ServerResources />}
          {selectedComponent === 'serverModule' && <ServerModule />}
          {selectedComponent === 'paymentHistoryDBCheck' && <PaymentHistoryDBCheck />}
          {selectedComponent === 'allAccountsVerification' && <AllAccountsVerification />}
          {selectedComponent === 'walletPendingList' && <WalletPendingList />}
          {selectedComponent === 'adminAccessIPCheck' && <AdminAccessIPCheck />}
          {selectedComponent === 'ipBlock' && <IPBlock />}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminPage;
