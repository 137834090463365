import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// 페이퍼 스타일
const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    marginBottom: '8px'
  }
}));

// 섹션 타이틀 스타일
const SectionTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 600,
  color: '#1976d2',
  marginBottom: '16px'
});

// 키 행 스타일
const KeyRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '12px',
  '& .MuiFormControl-root': {
    minWidth: '120px'
  },
  
  // 모바일에서만 적용
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '4px',
    
    '& .MuiFormControl-root': {
      minWidth: 'auto',
      width: '100%'
    },
    
    // 하단 행의 버튼/상태선택 그룹
    '& .button-status-group': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr auto',
      gap: '4px',
      marginTop: '4px',
      
      '& .MuiButton-root': {
        width: '100%',
        minWidth: 'auto',
        height: '32px',
        fontSize: '0.75rem'
      },
      
      '& .MuiSelect-root': {
        width: '100%'
      }
    }
  }
}));

// 라벨 스타일
const Label = styled(Typography)({
  fontSize: '0.9rem',
  color: '#34495e',
  minWidth: '80px',
  fontWeight: '500',
  '& .required': {
    color: '#f44336',
    marginLeft: '2px'
  }
});

// 크로스 키 필드 스타일
const CrossKeyField = styled(TextField)({
  flex: 1,
});

// 액션 버튼 스타일
const ActionButton = styled(Button)({
  height: '40px',
  fontSize: '0.875rem',
  padding: '0 12px',
  minWidth: '100px',
});

// 상태 선택 스타일
const StatusSelect = styled(Select)({
  minWidth: '120px',
  '& .MuiSelect-select': {
    padding: '8px 12px',
  }
});

// 타이틀 래퍼 스타일
const TitleWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  marginBottom: '16px'
});

// 메인 코드(키 값 입력 부분 나열)
const PgKey = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <StyledPaper>
      <TitleWrapper onClick={() => setIsExpanded(!isExpanded)}>
        <SectionTitle>PG키 설정</SectionTitle>
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </TitleWrapper>

        <Collapse in={isExpanded}>
        {[1, 2, 3, 4].map((index) => (
          <Box key={index} sx={{ mb: 3 }}>
            {/* 상단 행: PG, TID, MID */}
            <KeyRow>
              <Label>PG{index === 1 && <span className="required">*</span>}</Label>
              <Select
                size="small"
                defaultValue=""
                sx={{ width: '150px' }}
              >
                <MenuItem value="">=PG=</MenuItem>
              </Select>
              
              <Label>TID{index === 1 && <span className="required">*</span>}</Label>
              <TextField
                size="small"
                sx={{ width: '150px' }}
              />
              
              <Label>MID{index === 1 && <span className="required">*</span>}</Label>
              <TextField
                size="small"
                sx={{ width: '150px' }}
              />
            </KeyRow>

            {/* 하단 행: Cross KEY, 버튼, 상태선택, 체크박스 */}
            <KeyRow>
              <Label>Cross KEY{index === 1 && <span className="required">*</span>}</Label>
              <CrossKeyField
                size="small"
              />
              <Box className="button-status-group" sx={{ 
                display: 'flex', 
                gap: 1, 
                alignItems: 'center',
                minWidth: 'fit-content'
              }}>
                <ActionButton
                  variant="contained"
                  sx={{ 
                    bgcolor: '#00bcd4',
                    '&:hover': { bgcolor: '#00acc1' }
                  }}
                >
                  중복 확인
                </ActionButton>
                <StatusSelect
                  size="small"
                  defaultValue="비인증"
                >
                  <MenuItem value="인증">인증</MenuItem>
                  <MenuItem value="구인증">구인증</MenuItem>
                  <MenuItem value="비인증">비인증</MenuItem>
                </StatusSelect>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ margin: 0 }}
                />
              </Box>
            </KeyRow>
          </Box>
        ))}
      </Collapse>
    </StyledPaper>
  );
};

export default PgKey;
