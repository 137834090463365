import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Box,
  styled 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// 모달 스타일
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '800px',
    minHeight: '400px',
    maxHeight: '90vh',
    borderRadius: '8px'
  }
}));

// 모달 헤더 스타일
const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9',
  marginBottom: '16px'
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)({
  padding: '6px 16px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  height: '32px',
  whiteSpace: 'nowrap',
  '&.no-cell': {
    width: '45px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 8px'
  },
  '&.label-cell': {
    width: '200px',
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    padding: '6px 12px'
  },
  '&.value-cell': {
    width: '300px',
    color: '#495057',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    padding: '6px 12px'
  }
});

// 메인 코드
const MerchantNoModal = ({ open, onClose, data }) => {
  const tableData = [
    { no: 1, label: 'GID', value: data?.id || '' },
    { no: 2, label: '로그인아이디', value: data?.loginId || 'm250109' },
    { no: 3, label: 'MID', value: data?.mid || 'm250109' },
    { no: 4, label: 'TID', value: data?.tid || 'm250109' },
    { no: 5, label: '가맹점명', value: data?.merchantName || '20250109 테스트' },
    { no: 6, label: '대표자명', value: data?.representative || '20250109 테스트' },
    { no: 7, label: '사업자/개인', value: data?.businessType || '사업자' },
    { no: 8, label: '세금/원천', value: data?.taxType || '세금 비원천' },
    { no: 9, label: '사업자번호/주민번호', value: data?.businessNumber || '202-50-109 202501-09' },
    { no: 10, label: '은행명', value: data?.bankName || '' },
    { no: 11, label: '예금주', value: data?.accountHolder || 'b19003' },
    { no: 12, label: '계좌번호', value: data?.accountNumber || '' },
    { no: 13, label: '업종', value: data?.businessCategory || '20250109 테스트' },
    { no: 14, label: '품명', value: data?.productName || '20250109 테스트' },
    { no: 15, label: '연락처', value: data?.phone || '' },
    { no: 16, label: '휴대번호', value: data?.mobile || '' },
    { no: 17, label: '이메일', value: data?.email || 'ta@naver.com' },
    { no: 18, label: '주소', value: data?.address || '' },
    { no: 19, label: '시리얼번호', value: data?.serialNumber || '' }
  ];

  // 메인 코드
  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth={false}
    >
      <StyledDialogTitle>
        가맹점 정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ p: 3, pt: 4 }}>
        <TableContainer>
          <Table size="small" sx={{ '& td': { height: '32px' } }}>
            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.no}>
                  <StyledTableCell className="no-cell">{row.no}</StyledTableCell>
                  <StyledTableCell className="label-cell">{row.label}</StyledTableCell>
                  <StyledTableCell className="value-cell">{row.value}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default MerchantNoModal;
