import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 페이퍼 스타일
const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
}));

// 섹션 타이틀 스타일
const SectionTitle = styled(Typography)({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db'
});

// StyledFormControl 수정 - gap 줄임
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: '12px',  // 이것도 살짝 줄임
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0px',  // 16px에서 8px로 줄임
  '& .MuiFormLabel-root': {
    minWidth: '280px',
    color: '#34495e',
    fontWeight: '400',
    fontSize: '0.875rem'
  }
}));

// 필드 라벨 스타일
const FieldLabel = styled(Typography)({
  minWidth: '280px',
  fontSize: '0.875rem',
  color: '#34495e',
  '& .required': {
    color: '#f44336',
    marginLeft: '4px'
  }
});

// 액션 버튼 스타일
const ActionButton = styled(Button)({
  height: '32px',
  fontSize: '0.875rem',
  padding: '0 12px',
  minWidth: 'auto',
  whiteSpace: 'nowrap'
});

// 메인 코드
const Service = () => {
  return (
    <StyledPaper>
      <SectionTitle>서비스 설정</SectionTitle>
      <Box>
        {/* 결제수단 */}
        <StyledFormControl>
          <FieldLabel>
            결제수단
            <span className="required">*</span>
          </FieldLabel>
          <Select
            size="small"
            defaultValue=""
            sx={{ width: '300px' }}
          >
            <MenuItem value="">선택</MenuItem>
          </Select>
        </StyledFormControl>

        {/* 결제 암호화 키 */}
        <StyledFormControl>
          <FieldLabel>
            결제 암호화 키
            <span className="required">*</span>
          </FieldLabel>
          <Box sx={{ display: 'flex', gap: 1, width: '380px' }}>
            <TextField
              size="small"
              sx={{ flex: 1 }}
              disabled
            />
            <ActionButton
              variant="contained"
              color="info"
            >
              키 생성
            </ActionButton>
          </Box>
        </StyledFormControl>

        {/* Return URL */}
        <StyledFormControl>
          <FieldLabel>Return URL</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '300px' }}
          />
        </StyledFormControl>

        {/* 로그인 아이디 */}
        <StyledFormControl>
          <FieldLabel>
            로그인아이디
            <span className="required">*</span>
          </FieldLabel>
          <Box sx={{ display: 'flex', gap: 1, width: '380px' }}>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
            <ActionButton
              variant="contained"
              color="info"
            >
              자동생성
            </ActionButton>
            <ActionButton
              variant="contained"
              color="success"
            >
              중복확인
            </ActionButton>
          </Box>
        </StyledFormControl>

        {/* 비밀번호 */}
        <StyledFormControl>
          <FieldLabel>비밀번호</FieldLabel>
          <Box sx={{ display: 'flex', gap: 1, width: '380px' }}>
            <TextField
              type="password"
              size="small"
              sx={{ flex: 1 }}
              value="••••••••"
            />
            <ActionButton
              variant="contained"
              color="info"
            >
              초기화
            </ActionButton>
          </Box>
        </StyledFormControl>

        {/* 비밀번호 확인 */}
        <StyledFormControl>
          <FieldLabel>비밀번호 확인</FieldLabel>
          <TextField
            type="password"
            size="small"
            sx={{ width: '300px' }}
          />
        </StyledFormControl>

        {/* 이체 비밀번호 */}
        <StyledFormControl>
          <FieldLabel>이체 비밀번호</FieldLabel>
          <Box sx={{ display: 'flex', gap: 1, width: '380px' }}>
            <TextField
              size="small"
              sx={{ flex: 1 }}
            />
            <ActionButton
              variant="contained"
              color="info"
            >
              이체 비밀번호 초기화
            </ActionButton>
          </Box>
        </StyledFormControl>

        {/* 가맹점GID */}
        <StyledFormControl>
          <FieldLabel>가맹점GID</FieldLabel>
          <TextField
            size="small"
            sx={{ width: '300px' }}
          />
        </StyledFormControl>

        {/* 본사등급 사용여부 */}
        <StyledFormControl>
          <FieldLabel>본사등급 사용여부</FieldLabel>
          <Select
            size="small"
            defaultValue=""
            sx={{ width: '300px' }}
          >
            <MenuItem value="">미사용</MenuItem>
          </Select>
        </StyledFormControl>

        {/* 본사 로그인 ID */}
        <StyledFormControl>
          <FieldLabel>본사 로그인 ID</FieldLabel>
          <TextField
            size="small"
            placeholder="본사의 loginId를 입력하세요"
            sx={{ 
              width: '300px',
              backgroundColor: '#f5f5f5',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'transparent' },
                '&:hover fieldset': { borderColor: 'transparent' },
                '&.Mui-focused fieldset': { borderColor: 'transparent' }
              }
            }}
          />
        </StyledFormControl>
      </Box>
    </StyledPaper>
  );
};

export default Service;
