import React, { useState, useMemo } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Modal,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import styled from '@emotion/styled';
import ButtonWidget from '../../Common/TransferButton';
import ArticleIcon from '@mui/icons-material/Article';
// import TransferDetails from '../../Common/SetModal/TransferDetails';
import WalletModal from '../../Common/SetModal/WalletModal';
import dayjs from 'dayjs';

// 테이블 열 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#bbdefb',
      color: '#000000',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#2196f3',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.tertiary-header': {
      backgroundColor: '#c8e6c9',
      color: '#000000',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.quaternary-header': {
      backgroundColor: '#f0f0f0',
      color: '#000000',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '&.amount': {
    color: '#2196f3',
    textAlign: 'right'
  },
  '&.cancel': {
    color: '#dc3545',
    textAlign: 'right'
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 워퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

// 스타일 정의
const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

// 테이블 헤더 데이터
const tableHeaders = [
  { id: 'checkbox', label: '', color: '#bbdefb' },
  { id: 'no', label: 'NO', color: '#bbdefb' },
  { id: 'status', label: '상태', color: '#bbdefb' },
  { id: 'merchantMID', label: 'MID', color: '#bbdefb' },
  { id: 'merchantTID', label: 'TID', color: '#bbdefb' },
  { id: 'merchantName', label: '가맹점명', color: '#bbdefb' },
  { id: 'settlementCycle', label: '정산주기', color: '#bbdefb' },
  { id: 'transactionDate', label: '결제일시', color: '#2196f3' },
  { id: 'expectedSettlementDate', label: '정산예정일', color: '#2196f3' },
  { id: 'settlementTargetAmount', label: '정산 대상액', color: '#2196f3' },
  { id: 'detail', label: '세부내역', color: '#2196f3' },
  { id: 'offsetAmount', label: '상계금액', color: '#2196f3' },
  { id: 'remittanceAmount', label: '송금예정액', color: '#2196f3' },
  { id: 'approvalCount', label: '승인건수', color: '#2196f3' },
  { id: 'cancelCount', label: '취소건수', color: '#2196f3' },
  { id: 'approvalAmount', label: '승인금액', color: '#c8e6c9' },
  { id: 'cancelAmount', label: '취소금액', color: '#c8e6c9' },
  { id: 'commissionAmount', label: '수수료금액', color: '#c8e6c9' },
  { id: 'guaranteeAmount', label: '보증금액', color: '#c8e6c9' },
  { id: 'accountHolder', label: '예금주', color: '#f0f0f0' },
  { id: 'merchantPhone', label: '가맹점 휴대폰', color: '#f0f0f0' },
  { id: 'bankName', label: '은행명', color: '#f0f0f0' },
  { id: 'accountNumber', label: '계좌번호', color: '#f0f0f0' },
];

// 더미 데이터
const dummyData = [
  {
    no: 1,
    status: '정산가능',
    merchantMID: 'KS0001234',
    merchantTID: 'TR678901234',
    merchantName: '농부고을2',
    settlementCycle: 'D+1',
    transactionDate: '2025-02-06',
    expectedSettlementDate: '2025-02-07',
    settlementTargetAmount: 4390000,
    offsetAmount: 222444,
    remittanceAmount: 4167556,
    approvalCount: 5,
    cancelCount: 1,
    approvalAmount: 4500000,
    cancelAmount: -110000,
    commissionAmount: 72000,
    guaranteeAmount: 0,
    accountHolder: '홍길동',
    merchantPhone: '010-0444-2222',
    bankName: '하나은행',
    accountNumber: '1234567890',
  },
  {
    no: 2,
    status: '정산가능',
    merchantMID: 'M432109876',
    merchantTID: 'TRK2024031900002',
    merchantName: '애플49',
    settlementCycle: 'D+2',
    transactionDate: '2025-02-05',
    expectedSettlementDate: '2025-02-07',
    settlementTargetAmount: 1993200,
    offsetAmount: 123456,
    remittanceAmount: 1869744,
    approvalCount: 3,
    cancelCount: 0,
    approvalAmount: 2000000,
    cancelAmount: 0,
    commissionAmount: 45000,
    guaranteeAmount: 0,
    accountHolder: '김철수',
    merchantPhone: '010-1234-5678',
    bankName: '신한은행',
    accountNumber: '9876543210',
  },
  {
    no: 3,
    status: '정산가능',
    merchantMID: 'SPC107836',
    merchantTID: 'TRK2024031900003',
    merchantName: '고기진심정육점',
    settlementCycle: 'D+1',
    transactionDate: '2025-02-04',
    expectedSettlementDate: '2025-02-05',
    settlementTargetAmount: 3500000,
    offsetAmount: 150000,
    remittanceAmount: 3350000,
    approvalCount: 4,
    cancelCount: 0,
    approvalAmount: 3500000,
    cancelAmount: 0,
    commissionAmount: 55000,
    guaranteeAmount: 0,
    accountHolder: '강동원',
    merchantPhone: '010-9999-8888',
    bankName: '국민은행',
    accountNumber: '1112223333',
  },
  {
    no: 4,
    status: '정산가능',
    merchantMID: 'SPC_112014',
    merchantTID: 'TRK2024031900004',
    merchantName: '골드스파사우나',
    settlementCycle: 'D+2',
    transactionDate: '2025-02-03',
    expectedSettlementDate: '2025-02-05',
    settlementTargetAmount: 2800000,
    offsetAmount: 130000,
    remittanceAmount: 2670000,
    approvalCount: 6,
    cancelCount: 1,
    approvalAmount: 3000000,
    cancelAmount: -200000,
    commissionAmount: 48000,
    guaranteeAmount: 0,
    accountHolder: '박박이',
    merchantPhone: '010-7777-6666',
    bankName: '우리은행',
    accountNumber: '4445556666',
  },
  {
    no: 5,
    status: '정산가능',
    merchantMID: 'KS0001234',
    merchantTID: 'TRK2024031900005',
    merchantName: '일일구세탁',
    settlementCycle: 'D+1',
    transactionDate: '2025-02-02',
    expectedSettlementDate: '2025-02-03',
    settlementTargetAmount: 1500000,
    offsetAmount: 80000,
    remittanceAmount: 1420000,
    approvalCount: 2,
    cancelCount: 0,
    approvalAmount: 1500000,
    cancelAmount: 0,
    commissionAmount: 30000,
    guaranteeAmount: 0,
    accountHolder: '이순신',
    merchantPhone: '010-5555-4444',
    bankName: '농협은행',
    accountNumber: '7778889999',
  }
];

// 메인 코드
function NonwalletTable({ 
  searchPanelOpen, 
  setSearchPanelOpen, 
  selectedRows, 
  setSelectedRows,
  searchConditions  // 검색 조건 props 추가
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('하이픈');
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const handleCheckboxChange = (checked, rowId) => {
    if (checked) {
      setSelectedRows(prev => [...prev, rowId]);
    } else {
      setSelectedRows(prev => prev.filter(id => id !== rowId));
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleDetailClick = (row) => {
    setSelectedDetail(row);
    setDetailModalOpen(true);
  };

  // 전체 선택 핸들러 추가
  const handleSelectAll = () => {
    if (selectedRows.length === dummyData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(dummyData.map(row => row.no));
    }
  };

  // 검색 조건에 따른 데이터 필터링
  const filteredData = useMemo(() => {
    if (!searchConditions) return dummyData;

    return dummyData.filter(row => {
      // 날짜 범위 체크
      if (searchConditions.startDate && searchConditions.endDate) {
        const rowDate = dayjs(row.transactionDate);
        const start = dayjs(searchConditions.startDate);
        const end = dayjs(searchConditions.endDate).endOf('day');
        if (!rowDate.isBetween(start, end, 'day', '[]')) return false;
      }

      // MID/TID/가맹점명 검색
      if (searchConditions.searchText) {
        const searchLower = searchConditions.searchText.toLowerCase();
        if (!row.merchantMID.toLowerCase().includes(searchLower) &&
            !row.merchantTID.toLowerCase().includes(searchLower) &&
            !row.merchantName.toLowerCase().includes(searchLower)) {
          return false;
        }
      }

      // 토글 옵션들 처리
      if (searchConditions.selectedToggleOptions?.length > 0) {
        for (const option of searchConditions.selectedToggleOptions) {
          switch (option.parentOption) {
            case 'settlementCycle':
              if (row.settlementCycle?.toLowerCase() !== option.label.toLowerCase()) return false;
              break;
            // 필요한 경우 다른 토글 옵션들도 여기에 추가
          }
        }
      }

      return true;
    });
  }, [searchConditions, dummyData]);

  // 메인 코드
  return (
    <>
      <Box mb={2}>
  <Box 
    sx={{ 
      mb: 1, 
      display: 'flex', 
      alignItems: 'center', 
      flexWrap: 'nowrap', 
      whiteSpace: 'nowrap' 
    }}
  >
    {/* 라디오 버튼 1 */}
    <label>
      <input 
        type="radio" 
        value="하이픈" 
        checked={selectedOption === '하이픈'} 
        onChange={handleOptionChange} 
      /> 
      하이픈
    </label>

    {/* 라디오 버튼 2 */}
    <label style={{ marginLeft: '10px' }}>
      <input 
        type="radio" 
        value="웰컴" 
        checked={selectedOption === '웰컴'} 
        onChange={handleOptionChange} 
      /> 
      웰컴
    </label>

    {/* 버튼 위젯 */}
    <Box sx={{ ml: 2 }}>
      <ButtonWidget showFirst={false} showSecond={true} />
    </Box>
  </Box>
        
        <TableContainer component={StyledTableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell 
                  key="checkbox"
                  className="primary-header"
                >
                  <Checkbox
                    checked={selectedRows.length === dummyData.length}
                    onChange={handleSelectAll}
                    indeterminate={selectedRows.length > 0 && selectedRows.length < dummyData.length}
                    size="small"
                  />
                </StyledTableCell>
                {tableHeaders.slice(1).map((header) => (
                  <StyledTableCell 
                    key={header.id}
                    className={`${
                      header.color === '#bbdefb' ? 'primary-header' :
                      header.color === '#2196f3' ? 'secondary-header' :
                      header.color === '#c8e6c9' ? 'tertiary-header' :
                      'quaternary-header'
                    }`}
                  >
                    {header.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row) => (
                <StyledTableRow key={row.no}>
                  <StyledTableCell>
                    <Checkbox 
                      checked={selectedRows.includes(row.no)}
                      onChange={(e) => handleCheckboxChange(e.target.checked, row.no)}
                    />
                  </StyledTableCell>
                  {tableHeaders.slice(1).map((header) => (
                    <StyledTableCell 
                      key={`${row.no}-${header.id}`}
                      align={
                        ['approvalAmount', 'cancelAmount', 'commissionAmount', 'guaranteeAmount', 'settlementTargetAmount', 'offsetAmount', 'remittanceAmount'].includes(header.id)
                          ? 'right'
                          : 'center'
                      }
                      className={
                        header.id === 'cancelAmount' 
                          ? 'cancel' 
                          : ['approvalAmount', 'settlementTargetAmount', 'offsetAmount', 'remittanceAmount'].includes(header.id)
                          ? 'amount'
                          : ''
                      }
                    >
                      {typeof row[header.id] === 'number' 
                        ? row[header.id].toLocaleString() 
                        : row[header.id]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 1,
          width: '400px',
          maxWidth: '90vw'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            사용자 정보
          </Typography>
          {selectedUserData && (
            <>
              <Typography>고객명: {selectedUserData.customerName}</Typography>
              <Typography>휴대폰 번호: {selectedUserData.phoneNumber}</Typography>
              <Typography>승인금액: {selectedUserData.approvalAmount}</Typography>
              <Typography>승인번호: {selectedUserData.approvalNo}</Typography>
              <Typography>결제일자: {selectedUserData.paymentDate}</Typography>
              <Typography>취소일자: {selectedUserData.cancelDate || '-'}</Typography>
              <Typography>첨부파일: {selectedUserData.hasAttachments ? '있음' : '없음'}</Typography>
            </>
          )}
        </Box>
      </Modal>

      <WalletModal 
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
      />
    </>
  );
}

export default NonwalletTable;
