import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// 스타일 컴포넌트
const StyledPaper = styled(Box)(({ theme }) => ({
  padding: '24px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 16px rgba(0,0,0,0.12)'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    marginBottom: '8px'
  }
}));

// 섹션 타이틀 스타일
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: '#2c3e50',
  marginBottom: '24px',
  paddingBottom: '12px',
  borderBottom: '2px solid #3498db',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    left: '0',
    width: '80px',
    height: '2px',
    backgroundColor: '#e74c3c'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '16px',
    paddingBottom: '8px'
  }
}));

// 가상계좌 버튼 그룹을 위한 스타일
const VirtualAccountBox = styled(Box)(({ theme }) => ({
  display: 'flex', 
  gap: 1, 
  alignItems: 'center',
  
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    
    '& .MuiTypography-root': {
      marginBottom: '8px'
    },
    
    '& .button-group': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '8px'
    }
  }
}));

// 하단 버튼 그룹을 위한 스타일
const ButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex', 
  gap: 1, 
  justifyContent: 'flex-end',
  
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'stretch',
    '& .MuiButton-root': {
      flex: 1
    }
  }
}));

// StyledFormControl 수정 - 라벨 너비 통일
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '12px',
  width: 'auto',
  '& .MuiFormLabel-root': {
    minWidth: '240px', // 가장 긴 라벨 기준으로 너비 설정
    color: '#34495e',
    fontWeight: '500',
    fontSize: '0.9rem',
    transform: 'none',
    position: 'static',
    '&.Mui-focused': {
      color: '#3498db'
    }
  }
}));

// 상태 버튼 스타일 (높이 조정)
const StatusButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: '8px 16px',
  fontSize: '0.875rem',
  height: '40px', // TextField 높이와 맞춤
  backgroundColor: '#ff6b6b',
  '&:hover': {
    backgroundColor: '#ff5252'
  }
}));

// 검증 버튼 스타일 (높이 조정)
const VerifyButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: '8px 16px',
  fontSize: '0.875rem',
  height: '40px' // TextField 높이와 맞춤
}));

// 가상계좌 버튼 스타일
const VirtualAccountButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: '8px 16px',
  fontSize: '0.875rem',
  height: '40px'
}));

//메인코드
const Jeongsan = () => {
  return (
    <StyledPaper elevation={2} sx={{ mt: 3 }}>
      <SectionTitle>정산정보</SectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* 매일 지급 수수료 */}
          <StyledFormControl>
            <InputLabel>매입 지급 수수료(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 매입 포함 지급 수수료 */}
          <StyledFormControl>
            <InputLabel>매입 포함 지급 수수료(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 계정 포인트 지급 수수료 */}
          <StyledFormControl>
            <InputLabel>계정 포인트 지급 수수료(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 계정 적립금 지급 수수료 */}
          <StyledFormControl>
            <InputLabel>계정 적립금 지급 수수료(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 은행명 */}
          <StyledFormControl>
            <InputLabel>은행명 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                select
                fullWidth
                size="small"
                variant="outlined"
              >
                <MenuItem value="shinhan">신한</MenuItem>
                {/* 다른 은행 옵션들 */}
              </TextField>
            </Box>
          </StyledFormControl>

          {/* 예금주 */}
          <StyledFormControl>
            <InputLabel>예금주 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                disabled
                value="b19006"
              />
            </Box>
          </StyledFormControl>

          {/* 계좌번호 */}
          <StyledFormControl>
            <InputLabel>계좌번호 :</InputLabel>
            <Box sx={{ 
              display: 'flex', 
              gap: 1, 
              width: '300px', 
              alignItems: 'center'
            }}>
              <TextField
                size="small"
                variant="outlined"
                sx={{ flex: 1 }}
              />
              <StatusButton variant="contained" size="small">
                미검증
              </StatusButton>
              <VerifyButton variant="contained" size="small">
                검증
              </VerifyButton>
            </Box>
          </StyledFormControl>

          {/* 정산일 */}
          <StyledFormControl>
            <InputLabel>정산일 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                select
                fullWidth
                size="small"
                variant="outlined"
              >
                <MenuItem value="immediate">즉시</MenuItem>
                {/* 다른 정산일 옵션들 */}
              </TextField>
            </Box>
          </StyledFormControl>

          {/* 출금 가능시간 */}
          <StyledFormControl>
            <InputLabel>출금 가능시간 :</InputLabel>
            <Box sx={{ display: 'flex', gap: 1, width: '300px' }}>
              <TextField
                select
                size="small"
                sx={{ width: '50%' }}
              >
                <MenuItem value="00">00시</MenuItem>
              </TextField>
              <TextField
                select
                size="small"
                sx={{ width: '50%' }}
              >
                <MenuItem value="00">00시</MenuItem>
              </TextField>
            </Box>
          </StyledFormControl>

          {/* 월렛출금 미사용 */}
          <StyledFormControl>
            <InputLabel>월렛출금 미사용 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="미사용"
              />
            </Box>
          </StyledFormControl>

          {/* 머천트 계좌 */}
          <StyledFormControl>
            <InputLabel>머천트 계좌 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                select
                fullWidth
                size="small"
                variant="outlined"
                defaultValue=""
              >
                <MenuItem value="option1">옵션 1</MenuItem>
                <MenuItem value="option2">옵션 2</MenuItem>
                <MenuItem value="option3">옵션 3</MenuItem>
              </TextField>
            </Box>
          </StyledFormControl>

          {/* 가상계좌 */}
          <StyledFormControl>
            <InputLabel>가상계좌 :</InputLabel>
            <Box sx={{ 
              display: 'flex', 
              gap: 1, 
              width: '300px'
            }}>
              <VirtualAccountButton
                variant="contained"
                sx={{ flex: 1, bgcolor: '#ff6b6b', '&:hover': { bgcolor: '#ff5252' } }}
              >
                보안해제
              </VirtualAccountButton>
              <VirtualAccountButton
                variant="contained"
                sx={{ flex: 1, bgcolor: '#20c997', '&:hover': { bgcolor: '#1cb584' } }}
              >
                생성
              </VirtualAccountButton>
              <VirtualAccountButton
                variant="contained"
                sx={{ flex: 1, bgcolor: '#ff6b6b', '&:hover': { bgcolor: '#ff5252' } }}
              >
                반려
              </VirtualAccountButton>
            </Box>
          </StyledFormControl>

          {/* 비회원에게 송금 정액 */}
          <StyledFormControl>
            <InputLabel>비회원에게 송금 정액 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 비회원에게 송금 정률 */}
          <StyledFormControl>
            <InputLabel>비회원에게 송금 정률(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 회원에게 송금 정액 */}
          <StyledFormControl>
            <InputLabel>회원에게 송금 정액 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 회원에게 송금 정률 */}
          <StyledFormControl>
            <InputLabel>회원에게 송금 정률(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 나의계좌 출금 지급 수수료 정액 */}
          <StyledFormControl>
            <InputLabel>나의계좌 출금 지급 수수료 정액 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 나의계좌 출금 지급 수수료 정률 */}
          <StyledFormControl>
            <InputLabel>나의계좌 출금 지급 수수료 정률(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 어드민 입금 지급 수수료 정액 */}
          <StyledFormControl>
            <InputLabel>어드민 입금 지급 수수료 정액 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 어드민 입금 지급 수수료 정률 */}
          <StyledFormControl>
            <InputLabel>어드민 입금 지급 수수료 정률(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 마스터 입금 지급 수수료 정액 */}
          <StyledFormControl>
            <InputLabel>마스터 입금 지급 수수료 정액 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 마스터 입금 지급 수수료 정률 */}
          <StyledFormControl>
            <InputLabel>마스터 입금 지급 수수료 정률(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 회원간 이체지급 수수료 적용 */}
          <StyledFormControl>
            <InputLabel>회원간 이체지급 수수료 적용 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="적용"
              />
            </Box>
          </StyledFormControl>

          {/* 출금제한 */}
          <StyledFormControl>
            <InputLabel>출금제한(%) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
              />
            </Box>
          </StyledFormControl>

          {/* 요일제한 */}
          <StyledFormControl>
            <InputLabel>요일제한 :</InputLabel>
            <Box sx={{ 
              width: '300px',
              display: 'flex', 
              gap: 0, 
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              <FormControlLabel control={<Checkbox size="small" />} label="월" />
              <FormControlLabel control={<Checkbox size="small" />} label="화" />
              <FormControlLabel control={<Checkbox size="small" />} label="수" />
              <FormControlLabel control={<Checkbox size="small" />} label="목" />
              <FormControlLabel control={<Checkbox size="small" />} label="금" />
              <FormControlLabel control={<Checkbox size="small" checked />} label="토" />
              <FormControlLabel control={<Checkbox size="small" checked />} label="일" />
              <FormControlLabel control={<Checkbox size="small" checked />} label="공휴일" />
            </Box>
          </StyledFormControl>

          {/* 출금 딜레이 */}
          <StyledFormControl>
            <InputLabel>출금 딜레이(초) :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                type="number"
              />
            </Box>
          </StyledFormControl>

          {/* 출금정지 */}
          <StyledFormControl>
            <InputLabel>출금정지 :</InputLabel>
            <Box sx={{ width: '300px' }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label="정지"
              />
            </Box>
          </StyledFormControl>

          {/* 하단 버튼 그룹 */}
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 3 }}>
            <Button 
              variant="contained" 
              color="primary"
              sx={{ height: '40px', padding: '0 24px' }}
            >
              저장
            </Button>
            <Button 
              variant="outlined"
              sx={{ height: '40px', padding: '0 24px' }}
            >
              취소
            </Button>
            <Button 
              variant="contained" 
              color="secondary"
              sx={{ height: '40px', padding: '0 24px' }}
            >
              임시저장
            </Button>
          </Box>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default Jeongsan;
