import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// 메인 함수
const GiganChart = ({ data }) => {
  // 월 레이블 생성
  const generateMonthLabels = () => {
    const months = [];
    const now = new Date();
    for (let i = 11; i >= 0; i--) {
      const d = new Date(now.getFullYear(), now.getMonth() - i);
      months.push(`${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}`);
    }
    return months;
  };

  // 월별 데이터 계산
  const calculateMonthlyData = () => {
    return {
      sales: [
        45000000, 52000000, 48000000, 55000000, 
        58000000, 62000000, 57000000, 63000000,
        65000000, 70000000, 75000000, 80000000
      ],
      cancel: [
        15000000, 18000000, 16000000, 19000000,
        20000000, 22000000, 21000000, 24000000,
        25000000, 28000000, 30000000, 32000000
      ],
      risk: [
        25000000, 28000000, 27000000, 30000000,
        32000000, 35000000, 33000000, 36000000,
        38000000, 40000000, 42000000, 45000000
      ]
    };
  };

  // 옵션 설정
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          color: 'rgba(131, 144, 153, 0.1)',
          borderDash: [5, 5],
          drawBorder: false,
        },
        ticks: {
          callback: (value) => {
            switch(value) {
              case 0: return '0';
              case 20000000: return '2천만';
              case 40000000: return '4천만';
              case 60000000: return '6천만';
              case 80000000: return '8천만';
              default: return '';
            }
          },
          stepSize: 20000000,
          count: 5,
          min: 0,
          max: 80000000,
          color: '#839099',
          font: {
            size: 12,
            family: 'Pretendard',
            weight: 500
          },
          padding: 8
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: '#839099',
          font: {
            size: 11,
            family: 'Pretendard',
            weight: 500
          },
          maxRotation: 45,
          minRotation: 45
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          padding: 15,
          borderRadius: 10,
          font: {
            size: 12,
            family: 'Pretendard',
            weight: 500
          }
        },
        margin: {
          top: 0,
          right: 10
        }
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#839099',
        bodyColor: '#666',
        borderColor: '#fff',
        borderWidth: 1,
        padding: 10,
        boxShadow: '1px 2px 10px rgba(0,0,0,0.2)',
        bodyFont: {
          size: 14,
          family: 'Pretendard'
        },
        titleFont: {
          size: 14,
          family: 'Pretendard'
        },
        callbacks: {
          title: (items) => {
            return `${items[0].label} ${new Date().toLocaleString('ko-KR', { weekday: 'short' })}`;
          },
          label: (context) => {
            const value = context.raw;
            const label = context.dataset.label;
            return `${label}: ${(value / 10000).toFixed(0)}만원`;
          }
        },
        displayColors: true,
        usePointStyle: true,
        pointStyle: 'circle'
      }
    }
  };

  // 차트 데이터 생성
  const chartData = {
    labels: generateMonthLabels(),
    datasets: [
      {
        type: 'bar',
        label: '매출액',
        data: calculateMonthlyData().sales,
        backgroundColor: '#fac858',
        borderColor: '#fac858',
        borderWidth: 0,
        borderRadius: 4,
        barPercentage: 0.5,
        order: 2
      },
      {
        type: 'bar',
        label: '취소액',
        data: calculateMonthlyData().cancel,
        backgroundColor: '#8950fc',
        borderColor: '#8950fc',
        borderWidth: 0,
        borderRadius: 4,
        barPercentage: 0.5,
        order: 2
      },
      {
        type: 'line',
        label: '리스크',
        data: calculateMonthlyData().risk,
        borderColor: '#5cb85c',
        backgroundColor: 'rgba(92, 184, 92, 0.1)',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 3,
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        fill: true,
        tension: 0.4,
        order: 1
      }
    ]
  };

  // 메인 코드
  return (
    <Paper sx={{ 
      p: 2.5,
      mb: 3,
      backgroundColor: '#fff',
      boxShadow: '0 0 20px rgba(0,0,0,0.05)',
      borderRadius: '8px'
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 2
      }}>
        <Typography variant="h6" sx={{ 
          fontSize: '1.1rem',
          fontWeight: 600,
          // fontFamilly: 'Pretendard',
          color: '#252525'
        }}>
          매출 현황
        </Typography>
        <Typography sx={{ 
          fontSize: '0.9rem',
          fontWeight: 500,
          // fontFamilly: 'Pretendard',
          color: '#666'
        }}>
          오늘 날짜: {new Date().toLocaleDateString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })}
        </Typography>
      </Box>
      <Box sx={{ height: 350 }}>
        <Chart type="bar" data={chartData} options={options} />
      </Box>
    </Paper>
  );
};

export default GiganChart;
