import React, { useState } from 'react';
import { 
  Box, 
  TextField,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Notice from '../../Notice/Notice';
import FailHistoryTable from './FailHistoryTable';
import DateRangePicker from '../../Common/DateRangePicker';
import CurrentLocation from '../../Common/CurrentLocation';
import QuickMenu from '../../Common/QuickMenu';

function FailHistory() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchText1, setSearchText1] = useState('');
  const [searchText2, setSearchText2] = useState('');
  const [authType, setAuthType] = useState('전체');
  const [searchConditions, setSearchConditions] = useState(null);

  // 검색 처리 함수
  const handleSearch = () => {
    // 날짜 형식 변환 함수
    const formatDateString = (dateStr) => {
      if (!dateStr) return '';
      return dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    };

    // 검색 조건 설정
    const formattedStartDate = formatDateString(startDate);
    const formattedEndDate = formatDateString(endDate);

    // 검색 조건 객체 생성
    const newSearchConditions = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      searchText1: searchText1.trim(),
      searchText2: searchText2.trim(),
      authType // 인증 타입 추가
    };

    setSearchConditions(newSearchConditions);
  };

  return (
    <Box component="main" sx={{
      flexGrow: 1,
      p: 3,
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: '40px',
      height: 'auto',
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    }}>
      <Box sx={{ width: '100%' }}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="실패내역" />
        
        {/* 검색 섹션 */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          mb: 3,
          flexWrap: 'wrap',
          alignItems: 'center',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '8px'
        }}>
          {/* DateRangePicker */}
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            isSearchSection={true}
          />

          {/* 검색 필드들 */}
          <TextField
            placeholder="MID, LoginID, 상호명 검색"
            size="small"
            value={searchText1}
            onChange={(e) => setSearchText1(e.target.value)}
            sx={{ flexGrow: 1 }}
          />

          <TextField
            placeholder="결과메세지 검색"
            size="small"
            value={searchText2}
            onChange={(e) => setSearchText2(e.target.value)}
            sx={{ width: '200px' }}
          />

          {/* 인증/비인증 라디오 그룹 */}
          <FormControl>
            <RadioGroup
              row
              value={authType}
              onChange={(e) => setAuthType(e.target.value)}
            >
              <FormControlLabel 
                value="전체" 
                control={<Radio size="small" />} 
                label="전체"
                sx={{ mr: 1 }}
              />
              <FormControlLabel 
                value="인증" 
                control={<Radio size="small" />} 
                label="인증"
                sx={{ mr: 1 }}
              />
              <FormControlLabel 
                value="비인증" 
                control={<Radio size="small" />} 
                label="비인증"
              />
            </RadioGroup>
          </FormControl>

          {/* 검색 버튼 */}
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={handleSearch}
            sx={{ minWidth: '100px' }}
          >
            검색
          </Button>
        </Box>

        {/* 테이블 */}
        <FailHistoryTable searchConditions={searchConditions} />
      </Box>

      {/* QuickMenu */}
      <QuickMenu />
    </Box>
  );
}

export default FailHistory;
