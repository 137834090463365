import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

// 실패 내역 테이블 더미 데이터 생성
function FailHistoryTable({ isMobile, filteredData, searchConditions }) {
  const dummyData = [
    {
      pg: 'KSNET',
      mid: 'KS0001234',
      loginId: 'm42845709',
      companyName: '농부고을2',
      transactionDate: '2025-02-06 14:00:00',
      amount: '176,000',
      resultMessage: '카드번호 오류',
      buyerName: '홍길동',
      cardCompany: '삼성카드',
      cardNumber: '5137-9200-****-4575',
      expiryDate: '03/27',
      installment: '일시불',
      paymentMethod: '수기',
      authType: '인증',
      trackId: 'TR678901234'
    },
    {
      pg: 'NICE',
      mid: 'M432109876',
      loginId: 'm76640459',
      companyName: '애플49',
      transactionDate: '2025-02-06 15:30:00',
      amount: '1,000,000',
      resultMessage: '한도 초과입니다',
      buyerName: '김철수',
      cardCompany: '현대카드',
      cardNumber: '4009-7870-****-8206',
      expiryDate: '12/29',
      installment: '3개월',
      paymentMethod: '단말기',
      authType: '비인증',
      trackId: 'TRK2024031900002'
    },
    {
      pg: '갤럭시아',
      mid: 'GX5678901',
      loginId: 'm42845709',
      companyName: '고기진심정육점',
      transactionDate: '2025-02-06 16:15:00',
      amount: '583,000',
      resultMessage: '정상처리되었습니다',
      buyerName: '강동원',
      cardCompany: '농협카드',
      cardNumber: '9411-2350-****-1234',
      expiryDate: '08/25',
      installment: '2개월',
      paymentMethod: '단말기',
      authType: '인증',
      trackId: 'TR567890123'
    },
    {
      pg: 'NICE',
      mid: 'NC0005678',
      loginId: 'test_user2',
      companyName: '테스트상점2',
      transactionDate: '2024-03-19 14:28:15',
      amount: '30,000',
      resultMessage: '한도초과',
      buyerName: '김철수',
      cardCompany: '현대카드',
      cardNumber: '4921-****-****-5678',
      expiryDate: '09/26',
      installment: '3개월',
      paymentMethod: '신용카드',
      authType: '비인증',
      trackId: 'TRK2024031900002'
    },
    // ... 7개 더 추가
  ];

  // 검색 조건에 따른 데이터 필터링
  const getFilteredData = () => {
    if (!searchConditions) return dummyData;

    const { searchText1, searchText2, authType, startDate, endDate } = searchConditions;
    
    return dummyData.filter(item => {
      // 1. 날짜 범위 체크
      if (startDate && endDate) {
        const itemDate = item.transactionDate.split(' ')[0];
        if (itemDate < startDate || itemDate > endDate) return false;
      }

      // 2. MID, LoginID, 상호명 검색
      if (searchText1) {
        const searchLower = searchText1.toLowerCase();
        const searchFields = [
          item.mid,
          item.loginId,
          item.companyName
        ].map(field => (field || '').toLowerCase());
        
        if (!searchFields.some(field => field.includes(searchLower))) return false;
      }

      // 3. 결과메시지 검색
      if (searchText2) {
        const messageLower = item.resultMessage.toLowerCase();
        if (!messageLower.includes(searchText2.toLowerCase())) return false;
      }

      // 4. 인증방식 필터링 (개선된 로직)
      if (authType && authType !== '전체') {
        return item.authType === authType;
      }

      return true;
    });
  };

  // 필터링된 데이터 정렬
  const getSortedData = (filteredData) => {
    if (!searchConditions) {
      // searchConditions가 null일 경우 날짜순으로만 정렬
      return [...filteredData].sort((a, b) => 
        new Date(b.transactionDate) - new Date(a.transactionDate)
      );
    }

    return [...filteredData].sort((a, b) => {
      // 인증 타입에 따른 정렬 (searchConditions가 있고 authType이 '전체'가 아닐 때)
      if (searchConditions.authType && searchConditions.authType !== '전체') {
        if (a.authType === searchConditions.authType && b.authType !== searchConditions.authType) return -1;
        if (a.authType !== searchConditions.authType && b.authType === searchConditions.authType) return 1;
      }
      
      // 날짜 기준 내림차순 정렬 (최신순)
      return new Date(b.transactionDate) - new Date(a.transactionDate);
    });
  };

  // 테이블에 표시할 최종 데이터
  const displayData = getSortedData(getFilteredData());

  // 테이블 열 정의
  const columns = [
    { id: 'no', label: 'No', align: 'center', color: '#1976d2', textColor: '#fff' },
    { id: 'pg', label: 'PG', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'mid', label: 'MID', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'loginId', label: 'Login ID', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'companyName', label: '상호명', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'transactionDate', label: '거래일시', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'amount', label: '금액', align: 'right', color: '#1976d2', textColor: '#fff' },
    { id: 'resultMessage', label: '결과메시지', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'buyerName', label: '구매자명', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'cardCompany', label: '카드사', align: 'left', color: '#03a9f4', textColor: '#fff' },
    { id: 'cardNumber', label: '카드번호', align: 'left', color: '#03a9f4', textColor: '#fff' },
    { id: 'expiryDate', label: '유효기간', align: 'right', color: '#03a9f4', textColor: '#fff' },
    { id: 'installment', label: '할부개월수', align: 'left', color: '#03a9f4', textColor: '#fff' },
    { id: 'paymentMethod', label: '결제수단', align: 'left', color: '#ffffff', textColor: '#000' },
    { id: 'authType', label: '인증방식', align: 'left', color: '#ffffff', textColor: '#000' },
    { id: 'trackId', label: 'trackId', align: 'left', color: '#ffffff', textColor: '#000' }
  ];

  // 메인 코드
  return (
    <TableContainer component={Paper} sx={{ 
      maxHeight: 650,
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      '& .MuiTableCell-root': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '8px 16px',
        fontSize: { xs: '0.813rem', md: '0.875rem' }
      },
      '&::-webkit-scrollbar': {
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{
                  backgroundColor: column.color,
                  color: column.textColor,
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  minWidth: column.id === 'resultMessage' ? 200 : 'auto',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  fontSize: { xs: '0.813rem', md: '0.875rem' }
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayData.map((row, index) => (
            <TableRow 
              key={index}
              hover
              sx={{
                '&:nth-of-type(odd)': {
                  backgroundColor: '#f5f5f5',
                },
                '&:hover': {
                  backgroundColor: '#eeeeee',
                }
              }}
            >
              {columns.map((column) => (
                <TableCell 
                  key={column.id} 
                  align={column.align}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: column.id === 'resultMessage' ? 200 : 'auto',
                  }}
                >
                  {column.id === 'no' ? index + 1 : row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FailHistoryTable;
