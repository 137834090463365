import React from 'react';
import { Box, Button, styled } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AssignmentIcon from '@mui/icons-material/Assignment';

// 박스 컨테이너 스타일
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  padding: '16px',
  backgroundColor: '#fff',
  overflowX: 'auto',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  }
}));

// 버튼 스타일
const StyledButton = styled(Button)(({ theme }) => ({
  whiteSpace: 'nowrap',
  backgroundColor: '#26a69a',
  color: 'white',
  padding: '8px 16px',
  minWidth: 'fit-content',
  fontSize: '0.875rem',
  '&:hover': {
    backgroundColor: '#00897b',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
    fontSize: '1.2rem',
  }
}));

// 정산 다운로드 버튼
const SettlementDownButton = () => {
  const openPopup = (url, title) => {
    const width = 1200;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(
      url, 
      title, 
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
    );
  };

  const handleExcelDownload = () => {
    if(window.confirm('엑셀 파일을 다운로드하시겠습니까?')) {
      alert('엑셀 다운로드가 시작됩니다.');
    }
  };

  const handleWalletMatchingExcel = () => {
    if(window.confirm('월렛매칭용 엑셀 파일을 다운로드하시겠습니까?')) {
      alert('월렛매칭용 엑셀 다운로드가 시작됩니다.');
    }
  };

  const handle740AutoUpdate = () => {
    if(window.confirm('740 미확인 자동반영을 실행하시겠습니까?')) {
      alert('740 미확인 자동반영이 실행되었습니다.');
    }
  };

  const buttons = [
    { 
      icon: <AccountBalanceWalletIcon />, 
      text: '엑셀다운',
      action: handleExcelDownload
    },
    { 
      icon: <PaymentsIcon />, 
      text: '월렛매칭용 엑셀다운',
      action: handleWalletMatchingExcel
    },
    { 
      icon: <ReceiptLongIcon />, 
      text: '월렛계정목록',
      url: '/settlement/wallet-account-list'
    },
    { 
      icon: <AccountBalanceIcon />, 
      text: '이체내역조회',
      url: '/settlement/transfer-history'
    },
    { 
      icon: <DescriptionIcon />, 
      text: '미확인입금조회',
      url: '/settlement/unconfirmed-deposits'
    },
    { 
      icon: <LocalAtmIcon />, 
      text: '740 미확인 자동반영',
      action: handle740AutoUpdate
    },
    { 
      icon: <AssignmentIcon />, 
      text: '월렛 출금대기 리스트',
      url: '/settlement/withdrawal-hold'
    },
  ];

  return (
    <StyledBox>
      {buttons.map((button, index) => (
        <StyledButton
          key={index}
          variant="contained"
          startIcon={button.icon}
          onClick={() => button.action ? button.action() : openPopup(button.url, button.text)}
        >
          {button.text}
        </StyledButton>
      ))}
    </StyledBox>
  );
};

export default SettlementDownButton;
