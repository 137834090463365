import React, { useMemo } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const ResultText = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 데이터 합계 계산
  const totals = useMemo(() => {
    if (!data) return {
      approvalAmount: 0,
      cancelAmount: 0,
      actualSalesAmount: 0,
      feeAmount: 0,
      depositAmount: 0,
      riskAmount: 0
    };

    // 데이터 합계 계산 reduce 함수 사용
    return data.reduce((acc, curr) => ({
      approvalAmount: acc.approvalAmount + curr.approvalAmount,
      cancelAmount: acc.cancelAmount + curr.cancelAmount,
      actualSalesAmount: acc.actualSalesAmount + curr.actualSalesAmount,
      feeAmount: acc.feeAmount + curr.feeAmount,
      depositAmount: acc.depositAmount + curr.depositAmount,
      riskAmount: acc.riskAmount + curr.riskAmount
    }), {
      approvalAmount: 0,
      cancelAmount: 0,
      actualSalesAmount: 0,
      feeAmount: 0,
      depositAmount: 0,
      riskAmount: 0
    });
  }, [data]);

  // 스타일 객체
  const styles = {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
      mb: 2,
      p: 2,
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: '1px solid #e3f2fd',
      justifyContent: { xs: 'flex-start', sm: 'center' },
      alignItems: 'center'
    },
    textGroup: {
      display: 'flex',
      alignItems: 'center',
      minWidth: { xs: '45%', sm: 'auto' },
      '&:not(:last-child)::after': {
        content: isMobile ? '""' : '"|"',
        margin: '0 8px',
        color: '#e0e0e0',
      }
    },
    label: {
      color: '#1976d2',
      fontWeight: 600,
      fontSize: { xs: '0.813rem', sm: '0.875rem' },
    },
    labelBlack: {
      color: '#333',
      fontWeight: 600,
      fontSize: { xs: '0.813rem', sm: '0.875rem' },
    },
    value: {
      color: '#333',
      fontWeight: 500,
      fontSize: { xs: '0.813rem', sm: '0.875rem' },
      ml: 1,
    },
    redValue: {
      color: '#ff3d00',
      fontWeight: 500,
      fontSize: { xs: '0.813rem', sm: '0.875rem' },
      ml: 1,
    }
  };

  // 메인 코드
  return (
    <Box sx={styles.container}>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.label}>승인금액</Typography>
        <Typography sx={styles.value}>{totals.approvalAmount.toLocaleString()}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.label}>취소금액</Typography>
        <Typography sx={styles.redValue}>{totals.cancelAmount.toLocaleString()}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.labelBlack}>합계</Typography>
        <Typography sx={styles.value}>{totals.actualSalesAmount.toLocaleString()}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.label}>수수료 합계(VAT포함)</Typography>
        <Typography sx={styles.value}>{totals.feeAmount.toLocaleString()}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.label}>보증금 합계액</Typography>
        <Typography sx={styles.value}>{totals.depositAmount.toLocaleString()}원</Typography>
      </Box>
      <Box sx={styles.textGroup}>
        <Typography sx={styles.labelBlack}>정산액</Typography>
        <Typography sx={styles.value}>
          {(totals.actualSalesAmount - totals.feeAmount).toLocaleString()}원
        </Typography>
      </Box>
    </Box>
  );
};

export default ResultText;
