import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// 모달 스타일
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '90vw',
    maxWidth: '1400px',
    minHeight: '300px',
    maxHeight: '80vh',
    borderRadius: '8px'
  }
}));

// 모달 헤더 스타일
const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9',
  marginBottom: '16px'
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)({
  padding: '8px 12px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  whiteSpace: 'nowrap',
  '&.header': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    height: '42px'
  },
  '&.body': {
    height: '36px'
  }
});

// 수수료 표시 함수
const formatFeeDisplay = (amount, rate) => {
  if (!amount && !rate) return '-';
  return `${amount?.toLocaleString()}원/${rate}%`;
};

// 메인 함수. 모달이라 닫기, 열기 상태값 받음. 추가로 받아오는 가맹점 이름(해당 값으로 DB에서 불러오기?)
const UserInfoTwo = ({ open, onClose, businessName }) => {
  const mockData = [
    {
      type: '일반',
      businessName: businessName,
      feeRate: 0.22,
      feeAmount: 352,
      paymentRate: 0.3,
      paymentAmount: 159648,
      withholding: '0%',
      pointFee: 1000,
      point: 5000,
      rewardFee: 500,
      reward: 2500,
      bankName: '기업은행',
      accountNumber: '257-122937-04-030'
    },
    {
      type: '마스터',
      businessName: '마스터상점',
      feeRate: 1.485,
      feeAmount: 756,
      paymentRate: 1.5,
      paymentAmount: 258900,
      withholding: '3%',
      pointFee: 2000,
      point: 8000,
      rewardFee: 1500,
      reward: 6000,
      bankName: '국민은행',
      accountNumber: '123-456-789012'
    },
    {
      type: '지사',
      businessName: '지사상점',
      feeRate: 0.5,
      feeAmount: 250,
      paymentRate: 0.8,
      paymentAmount: 149750,
      withholding: '0%',
      pointFee: 1500,
      point: 7500,
      rewardFee: 800,
      reward: 4000,
      bankName: '신한은행',
      accountNumber: '110-353-123456'
    },
    {
      type: '대리점',
      businessName: '대리점상점',
      feeRate: 0.555,
      feeAmount: 450,
      paymentRate: 0.7,
      paymentAmount: 178500,
      withholding: '0%',
      pointFee: 3000,
      point: 12000,
      rewardFee: 1000,
      reward: 5000,
      bankName: '우리은행',
      accountNumber: '1002-456-789123'
    },
    {
      type: '총판',
      businessName: '총판상점',
      feeRate: 1.1,
      feeAmount: 550,
      paymentRate: 1.2,
      paymentAmount: 198000,
      withholding: '2%',
      pointFee: 2500,
      point: 10000,
      rewardFee: 1200,
      reward: 4800,
      bankName: '하나은행',
      accountNumber: '505-123456-78912'
    }
  ];

  // 메인 코드
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <StyledDialogTitle>
        계정별 정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell className="header">구분</StyledTableCell>
                <StyledTableCell className="header">영업자명</StyledTableCell>
                <StyledTableCell className="header">수수료율</StyledTableCell>
                <StyledTableCell className="header">지급금액</StyledTableCell>
                <StyledTableCell className="header">수수료율</StyledTableCell>
                <StyledTableCell className="header">지급금액</StyledTableCell>
                <StyledTableCell className="header">원천공제</StyledTableCell>
                <StyledTableCell className="header">포인트수수료</StyledTableCell>
                <StyledTableCell className="header">포인트</StyledTableCell>
                <StyledTableCell className="header">적립금수수료</StyledTableCell>
                <StyledTableCell className="header">적립금</StyledTableCell>
                <StyledTableCell className="header">은행명</StyledTableCell>
                <StyledTableCell className="header">계좌번호</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mockData.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell className="body">{row.type}</StyledTableCell>
                  <StyledTableCell className="body">{row.businessName}</StyledTableCell>
                  <StyledTableCell className="body">{row.feeRate}%</StyledTableCell>
                  <StyledTableCell className="body">{row.feeAmount?.toLocaleString()}원</StyledTableCell>
                  <StyledTableCell className="body">{row.paymentRate}%</StyledTableCell>
                  <StyledTableCell className="body">{row.paymentAmount?.toLocaleString()}원</StyledTableCell>
                  <StyledTableCell className="body">{row.withholding}</StyledTableCell>
                  <StyledTableCell className="body">{row.pointFee || '-'}</StyledTableCell>
                  <StyledTableCell className="body">{row.point || '-'}</StyledTableCell>
                  <StyledTableCell className="body">{row.rewardFee || '-'}</StyledTableCell>
                  <StyledTableCell className="body">{row.reward || '-'}</StyledTableCell>
                  <StyledTableCell className="body">{row.bankName}</StyledTableCell>
                  <StyledTableCell className="body">{row.accountNumber}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default UserInfoTwo;