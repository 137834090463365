import { makeStyles } from '@mui/styles';

// PG사 통계 컴포넌트 스타일
export const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3)
  },
  cardChart: {
    boxShadow: '0 6px 10px -4px rgba(0,0,0,.15)',
    backgroundColor: '#fff',
    borderRadius: '12px',
    height: '100%'
  },
  cardHeader: {
    padding: theme.spacing(3),
    borderBottom: 'none',
    background: 'transparent'
  },
  cardBody: {
    padding: theme.spacing(2)
  },
  chartContainer: {
    height: 450,
    position: 'relative'
  },
  chartTitle: {
    color: '#252422',
    fontSize: '1.25rem',
    fontWeight: 400,
    marginBottom: theme.spacing(1)
  },
  cardCategory: {
    color: '#9A9A9A',
    fontSize: '0.875rem'
  },
  tableHead: {
    backgroundColor: 'rgba(245,245,245,0.8)'
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(250,250,250,0.4)'
    },
    '&:hover': {
      backgroundColor: 'rgba(245,245,245,0.8)'
    }
  },
  totalRow: {
    backgroundColor: 'rgba(240,240,240,0.9)',
    fontWeight: 500
  },
  tableCell: {
    // fontFamilly: 'Montserrat, sans-serif',
    fontSize: '1.1rem',
    fontWeight: 600,
    letterSpacing: '0.5px'
  }
}));