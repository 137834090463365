import React, { useState } from 'react';
import { Box, Card, Grid, Typography, Table, TableBody, TableCell, 
         TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { useStyles } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

// 인증별 결제금액 컴포넌트  표 + 차트
const AuthStats = () => {
  const classes = useStyles();
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedData, setSortedData] = useState([
    { type: '거래금액', count: 12696, amount: 1741199115, isTotal: true },
    { type: '비인증 거래금액', count: 240, amount: 305344692, isTotal: false },
    { type: '인증 거래금액', count: 11948, amount: 816753743, isTotal: false },
    { type: '구인증 거래금액', count: 508, amount: 619100680, isTotal: false }
  ]);

  const handleSort = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    const sorted = [...sortedData].sort((a, b) => {
      if (a.isTotal) return -1;
      if (b.isTotal) return 1;
      return newDirection === 'asc' ? a.amount - b.amount : b.amount - a.amount;
    });
    setSortDirection(newDirection);
    setSortedData(sorted);
  };

  // 차트 데이터
  const barChartData = {
    labels: ['11월'],
    datasets: [
      {
        label: '결제금액',
        backgroundColor: 'rgba(81, 203, 206, 0.8)',
        data: [1741199115],
        barPercentage: 0.7,
        borderRadius: 5
      },
      {
        label: '비인증 거래금액',
        backgroundColor: 'rgba(251, 198, 88, 0.8)',
        data: [305344692],
        barPercentage: 0.7,
        borderRadius: 5
      },
      {
        label: '인증 거래금액',
        backgroundColor: 'rgba(147, 104, 189, 0.8)',
        data: [816753743],
        barPercentage: 0.7,
        borderRadius: 5
      },
      {
        label: '구인증 거래금액',
        backgroundColor: 'rgba(149, 165, 166, 0.8)',
        data: [619100680],
        barPercentage: 0.7,
        borderRadius: 5
      }
    ]
  };

  const pieChartData = {
    labels: ['구인증', '인증', '비인증'],
    datasets: [{
      data: [619100680, 816753743, 305344692],
      backgroundColor: [
        'rgba(149, 165, 166, 0.8)',
        'rgba(147, 104, 189, 0.8)',
        'rgba(251, 198, 88, 0.8)'
      ],
      borderWidth: 0,
      hoverOffset: 4
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif",
            weight: '500'
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: 12,
        titleFont: {
          size: 13,
          family: "'Noto Sans KR', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 12,
          family: "'Noto Sans KR', sans-serif"
        },
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `${context.dataset.label}: ${value.toLocaleString()}원`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif"
          },
          padding: 10,
          callback: value => value.toLocaleString() + '원'
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif"
          }
        }
      }
    }
  };

  // 차트 옵션
  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '60%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif",
            weight: '500'
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: 12,
        titleFont: {
          size: 13,
          family: "'Noto Sans KR', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 12,
          family: "'Noto Sans KR', sans-serif"
        },
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${percentage}% (${value.toLocaleString()}원)`;
          }
        }
      }
    }
  };

  // 메인 코드
  return (
    <Box className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                인증별 결제금액
              </Typography>
            </Box>
            <TableContainer 
              component={Paper} 
              elevation={0}
              sx={{
                overflowX: 'auto',
                '& .MuiTable-root': {
                  minWidth: '800px'
                },
                '& .MuiTableCell-root': {
                  whiteSpace: 'nowrap'
                }
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">결제건수</TableCell>
                    <TableCell align="center">11월</TableCell>
                    <TableCell align="center">
                      <Box display="flex" alignItems="center" justifyContent="center">
                        합계
                        <IconButton size="small" onClick={handleSort}>
                          {sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, index) => (
                    <TableRow key={index} className={row.isTotal ? classes.totalRow : ''}>
                      <TableCell>{row.type}</TableCell>
                      <TableCell align="center">{row.count.toLocaleString()}</TableCell>
                      <TableCell align="center">{row.amount.toLocaleString()}</TableCell>
                      <TableCell align="center">{row.amount.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                인증별 결제금액
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Bar data={barChartData} options={chartOptions} />
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                인증별 비율
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Pie data={pieChartData} options={pieOptions} />
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthStats;