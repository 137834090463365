import React, { useState, useMemo } from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  TableContainer, 
  Paper,
  Box,
  LinearProgress,
  Tooltip,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PGModal from '../../Common/StatisticsModal/PGModal';

// BonNote 스타일 적용
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px',
      
      '&[data-field="amount"]': {
        height: '40px',
        lineHeight: '40px'
      }
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 워퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  height: 'calc(100vh - 380px)',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#a4c9ef',
    zIndex: 3
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

// 아이콘 버튼 스타일
const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

// 메인 함수
function GiganSalesTables({ searchPanelOpen, searchConditions }) {
  // 더미 데이터 생성
  const dummyData = [
    {
      date: '2024-01-01',
      detail: 'SPC',
      merchantCount: 523,
      approvalCount: 2723,
      cancelCount: 84,
      riskCount: 5,
      totalCount: 2812,
      approvalAmount: 498808000,
      cancelAmount: 4291000,
      totalAmount: 494517000,
      riskAmount: 51560,
      averagePrice: 326789,
      totalFee: 12807,
      deposit: 672595,
      supplyPrice: 740751,
      vat: 6270072,
      feeRate: 2.59,
      paymentRate: 86.16,
      settlementAmount: 183616,
      accountPayment: 147867,
      deductionRate: 2.35,
      reserveRate: 1.79,
      totalDeduction: 183616,
      reserveAmount: 147867,
      cancelRatio: 2.35,
      riskRatio: 1.79
    },
    {
      date: '2024-01-02',
      detail: '갤럭시아',
      merchantCount: 207,
      approvalCount: 3760,
      cancelCount: 1,
      riskCount: 4,
      totalCount: 3765,
      totalAmount: 392411000,
      approvalAmount: 398182000,
      cancelAmount: 2751000,
      riskAmount: 93391,
      averagePrice: 65818,
      totalFee: 565582,
      deposit: 187871,
      supplyPrice: 227628,
      vat: 2175920,
      feeRate: 3.40,
      paymentRate: 88.64,
      settlementAmount: 195916,
      accountPayment: 123591,
      deductionRate: 2.39,
      reserveRate: 2.90,
      totalDeduction: 195916,
      reserveAmount: 123591,
      cancelRatio: 2.45,
      riskRatio: 4.85
    },
    {
      date: '2024-01-03',
      detail: '페이업',
      merchantCount: 700,
      approvalCount: 3052,
      cancelCount: 13,
      riskCount: 13,
      totalCount: 3078,
      totalAmount: 351875000,
      approvalAmount: 358578000,
      cancelAmount: 4620000,
      riskAmount: 91009,
      averagePrice: 499074,
      totalFee: 681837,
      deposit: 294499,
      supplyPrice: 2553902,
      vat: 2531627,
      feeRate: 1.90,
      paymentRate: 86.03,
      settlementAmount: 143062,
      accountPayment: 183190,
      deductionRate: 2.85,
      reserveRate: 4.15,
      totalDeduction: 143062,
      reserveAmount: 183190,
      cancelRatio: 2.85,
      riskRatio: 4.42
    },
    {
      date: '2024-01-04',
      detail: 'KG이니시스',
      merchantCount: 298,
      approvalCount: 1198,
      cancelCount: 66,
      riskCount: 24,
      totalCount: 1288,
      totalAmount: 534874000,
      approvalAmount: 457707000,
      cancelAmount: 5318000,
      riskAmount: 3909,
      averagePrice: 451862,
      totalFee: 478708,
      deposit: 238861,
      supplyPrice: 5274091,
      vat: 723676,
      feeRate: 2.39,
      paymentRate: 93.15,
      settlementAmount: 382861,
      accountPayment: 128015,
      deductionRate: 1.27,
      reserveRate: 2.17,
      totalDeduction: 382861,
      reserveAmount: 128015,
      cancelRatio: 1.27,
      riskRatio: 0.36
    },
    {
      date: '2024-01-05',
      detail: '나이스페이',
      merchantCount: 770,
      approvalCount: 8950,
      cancelCount: 73,
      riskCount: 8,
      totalCount: 9031,
      totalAmount: 194049000,
      approvalAmount: 207066000,
      cancelAmount: 4773000,
      riskAmount: 36479,
      averagePrice: 75417,
      totalFee: 175308,
      deposit: 86408,
      supplyPrice: 3989650,
      vat: 235287,
      feeRate: 3.50,
      paymentRate: 92.53,
      settlementAmount: 302310,
      accountPayment: 177308,
      deductionRate: 5.38,
      reserveRate: 2.93,
      totalDeduction: 302310,
      reserveAmount: 177308,
      cancelRatio: 5.38,
      riskRatio: 0.98
    }
  ];

  // PG Modal 상태 관리
  const [isPGModalOpen, setIsPGModalOpen] = useState(false);
  const [selectedPGData, setSelectedPGData] = useState(null);

  const handlePGDetailClick = (e, detail) => {
    e.stopPropagation();  // 이벤트 전파 중지
    setSelectedPGData(detail);
    setIsPGModalOpen(true);
  };

  // 날짜 필터링된 데이터
  const filteredData = useMemo(() => {
    if (!searchConditions || !searchConditions.startDate || !searchConditions.endDate) {
      return dummyData;
    }

    return dummyData.filter(row => {
      const rowDate = row.date.replace(/-/g, ''); // "2024-01-01" -> "20240101"
      return rowDate >= searchConditions.startDate && rowDate <= searchConditions.endDate;
    });
  }, [searchConditions]);

  // 메인 코드
  return (
    <>
      <Paper sx={{ 
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: 'none',
        border: '1px solid #e9ecef',
        borderRadius: '4px',
        overflow: 'hidden'
      }}>
        <StyledTableWrapper>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="primary-header sticky-header" align="center">날짜</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">상세</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">거래처 수</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">승인건수</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">취소건수</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">리스크건수</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">건수합계</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">승인금액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">취소금액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">합계금액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">리스크 금액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">평균단가</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">수수료합계</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">보증금</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">공급가액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">부가세</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">수수료율(VAT포함)</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">지급율</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">정산금액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">계정지급액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">차감율</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">유보율</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">차감총액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">유보액</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">취소비율</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">리스크비율 그래프</StyledTableCell>
                <StyledTableCell className="primary-header" align="center">취소 비율 그래프</StyledTableCell>
                
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className="sticky-column" align="left">{row.date}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title="PG사 상세정보">
                      <IconButton
                        size="small"
                        onClick={(e) => handlePGDetailClick(e, row.detail)}
                        sx={IconButtonStyle}
                      >
                        <StorefrontIcon sx={{ fontSize: 18, color: '#1976d2' }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.merchantCount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.approvalCount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">
                    <span style={{ color: '#f44336' }}>
                      {row.cancelCount.toLocaleString()}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.riskCount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.totalCount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.approvalAmount.toLocaleString()} / {row.feeRate}%
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <span style={{ color: '#f44336' }}>
                      -{row.cancelAmount.toLocaleString()}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.totalAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.riskAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.averagePrice.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.totalFee.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.deposit.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.supplyPrice.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.vat.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">
                    {(row.totalAmount * (row.paymentRate / 100)).toLocaleString()} / {row.paymentRate}%
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.settlementAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.accountPayment.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.deductionRate}%</StyledTableCell>
                  <StyledTableCell align="right">{row.reserveRate}%</StyledTableCell>
                  <StyledTableCell align="right">{row.totalDeduction.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.reserveAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.cancelRatio}%</StyledTableCell>
                  <StyledTableCell align="right">{row.riskRatio}%</StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title={`취소 비율: ${row.cancelRatio}%`}>
                      <Box sx={{ width: '100%', minWidth: 100 }}>
                        <LinearProgress
                          variant="determinate"
                          value={parseFloat(row.cancelRatio)}
                          sx={{
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: '#e3f2fd',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#1976d2',
                              borderRadius: 5,
                            }
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title={`리스크 비율: ${row.riskRatio}%`}>
                      <Box sx={{ width: '100%', minWidth: 100 }}>
                        <LinearProgress
                          variant="determinate"
                          value={parseFloat(row.riskRatio)}
                          sx={{
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: '#ffebee',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#f44336',
                              borderRadius: 5,
                            }
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableWrapper>
      </Paper>

      {/* PG Modal */}
      <PGModal
        open={isPGModalOpen}
        onClose={() => setIsPGModalOpen(false)}
        pgData={selectedPGData}
      />
    </>
  );
}

export default GiganSalesTables;
