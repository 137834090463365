import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Box 
} from '@mui/material';
import styled from '@emotion/styled';
import ManageRM from '../../Common/SetModal/ManageRM';

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme, color, clickable }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: color || '#495057',
  textAlign: 'center',
  cursor: clickable ? 'pointer' : 'default',
  '&:hover': clickable ? {
    backgroundColor: '#f5f5f5',
    textDecoration: 'underline'
  } : {}
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
}));

// 스타일 정의
const styles = {
  tableContainer: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px',
    padding: '0px',
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip'
    }
  }
};

// 테이블 헤더 데이터
const tableHeaders = [
  { id: '234', label: '234 머천트 잔액:', width: '20%' },
  { id: '740', label: '740 머천트 잔액:', width: '20%' },
  { id: '741', label: '741 머천트 잔액:', width: '20%' },
  { id: '742', label: '742 머천트 잔액:', width: '20%' },
  { id: '999', label: '999 머천트 잔액:', width: '20%' },
];

const OutputTable = () => {
  // 모달 열기 상태
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 선택된 사용자 데이터
  const [selectedMerchant, setSelectedMerchant] = useState(null);

  // 더미 데이터
  const dummyData = {
    '234': '5,567,497',
    '740': '150',
    '741': '9,959,078',
    '742': '0',
    '999': '13,712'
  };

  // 헤더 클릭 함수
  const handleHeaderClick = (merchantId) => {
    setSelectedMerchant(merchantId);
    setIsModalOpen(true);
  };

  // 메인 코드
  return (
    <Box>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  style={{ 
                    backgroundColor: '#f5f5f5',
                    width: header.width,
                    fontWeight: 'normal'
                  }}
                  clickable={true}
                  onClick={() => handleHeaderClick(header.id)}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                >
                  {dummyData[header.id]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <ManageRM 
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`${selectedMerchant}`}
      />
    </Box>
  );
};

export default OutputTable;
