import React, { useState, useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Box
} from '@mui/material';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

// 테이블 열 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '&.purchase': {
    color: '#2196f3',
    textAlign: 'right'
  },
  '&.cancel': {
    color: '#dc3545',
    textAlign: 'right'
  },
  '&.total': {
    color: '#1565c0',
    textAlign: 'right',
    fontWeight: 'bold'
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 워퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#666'
    }
  }
});

// 스타일 정의
const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

// 테이블 헤더 데이터
const tableHeaders = [
  { id: 'no', label: 'No' },
  { id: 'pg', label: 'PG' },
  { id: 'mid', label: 'MID' },
  { id: 'tid', label: 'TID' },
  { id: 'transactionDate', label: '거래일시' },
  { id: 'merchantName', label: '상호명' },
  // 매입 데이터 (파란색)
  { id: 'blueAmount', label: '금액', type: 'purchase' },
  { id: 'blueCount', label: '건수', type: 'purchase' },
  { id: 'blueSettlement', label: '정산금액', type: 'purchase' },
  { id: 'blueSupply', label: '공급가', type: 'purchase' },
  { id: 'blueVat', label: '부가세', type: 'purchase' },
  { id: 'blueTotal', label: '합계', type: 'purchase' },
  // 취소 데이터 (빨간색)
  { id: 'redAmount', label: '금액', type: 'cancel' },
  { id: 'redCount', label: '건수', type: 'cancel' },
  { id: 'redSettlement', label: '정산금액', type: 'cancel' },
  { id: 'redSupply', label: '공급가', type: 'cancel' },
  { id: 'redVat', label: '부가세', type: 'cancel' },
  { id: 'redTotal', label: '합계', type: 'cancel' },
  // 총합 (진한 파란색)
  { id: 'grandTotal', label: '총합', type: 'total' }
];

// 더미 데이터
const dummyData = [
  {
    pg: 'KSNET',
    mid: 'KS0001234',
    tid: 'TR678901234',
    transactionDate: '2025-02-06 15:43:54',
    merchantName: '농부고을2',
    // 매입 데이터
    blueAmount: 1760000,
    blueCount: 5,
    blueSettlement: 1700000,
    blueSupply: 1600000,
    blueVat: 160000,
    blueTotal: 1760000,
    // 취소 데이터
    redAmount: 176000,
    redCount: 1,
    redSettlement: 170000,
    redSupply: 160000,
    redVat: 16000,
    redTotal: 176000,
    // 총합
    grandTotal: 1584000
  },
  {
    pg: 'NICE',
    mid: 'M432109876',
    tid: 'TRK2024031900002',
    transactionDate: '2025-02-06 16:30:22',
    merchantName: '애플49',
    // 매입 데이터
    blueAmount: 2500000,
    blueCount: 8,
    blueSettlement: 2400000,
    blueSupply: 2272727,
    blueVat: 227273,
    blueTotal: 2500000,
    // 취소 데이터
    redAmount: 250000,
    redCount: 2,
    redSettlement: 240000,
    redSupply: 227273,
    redVat: 22727,
    redTotal: 250000,
    // 총합
    grandTotal: 2250000
  },
  {
    pg: 'GALAXIA',
    mid: 'SPC107836',
    tid: 'TRG2024031900003',
    transactionDate: '2025-02-06 17:15:45',
    merchantName: '고기진심정육점',
    // 매입 데이터
    blueAmount: 890000,
    blueCount: 3,
    blueSettlement: 850000,
    blueSupply: 809091,
    blueVat: 80909,
    blueTotal: 890000,
    // 취소 데이터
    redAmount: 89000,
    redCount: 1,
    redSettlement: 85000,
    redSupply: 80909,
    redVat: 8091,
    redTotal: 89000,
    // 총합
    grandTotal: 801000
  },
  {
    pg: 'EtoU',
    mid: 'SPC_112014',
    tid: 'TRE2024031900004',
    transactionDate: '2025-02-06 18:20:33',
    merchantName: '골드스파사우나',
    // 매입 데이터
    blueAmount: 3200000,
    blueCount: 12,
    blueSettlement: 3100000,
    blueSupply: 2909091,
    blueVat: 290909,
    blueTotal: 3200000,
    // 취소 데이터
    redAmount: 320000,
    redCount: 2,
    redSettlement: 310000,
    redSupply: 290909,
    redVat: 29091,
    redTotal: 320000,
    // 총합
    grandTotal: 2880000
  },
  {
    pg: 'Kopay',
    mid: 'KP987654',
    tid: 'TRK2024031900005',
    transactionDate: '2025-02-06 19:45:12',
    merchantName: '일일구세탁',
    // 매입 데이터
    blueAmount: 450000,
    blueCount: 4,
    blueSettlement: 430000,
    blueSupply: 409091,
    blueVat: 40909,
    blueTotal: 450000,
    // 취소 데이터
    redAmount: 45000,
    redCount: 1,
    redSettlement: 43000,
    redSupply: 40909,
    redVat: 4091,
    redTotal: 45000,
    // 총합
    grandTotal: 405000
  }
];

// 메인 코드
const DepositHistoryTable = ({ searchConditions }) => {
  // 검색 조건에 따른 필터링된 데이터
  const filteredData = useMemo(() => {
    if (!searchConditions) return dummyData;

    return dummyData.filter(row => {
      // 날짜 필터링
      if (searchConditions.startDate && searchConditions.endDate) {
        const rowDate = dayjs(row.transactionDate);
        const start = dayjs(searchConditions.startDate);
        const end = dayjs(searchConditions.endDate).endOf('day');
        if (!rowDate.isBetween(start, end, 'day', '[]')) return false;
      }

      // PG사 필터링
      if (searchConditions.pgCompany && searchConditions.pgCompany !== '전체') {
        if (row.pg !== searchConditions.pgCompany) return false;
      }

      // MID/TID/상호명 검색
      if (searchConditions.searchText) {
        const searchLower = searchConditions.searchText.toLowerCase();
        return row.mid.toLowerCase().includes(searchLower) ||
               row.tid.toLowerCase().includes(searchLower) ||
               row.merchantName.toLowerCase().includes(searchLower);
      }

      return true;
    });
  }, [searchConditions, dummyData]);

  return (
    <TableContainer component={StyledTableWrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <StyledTableCell 
                key={header.id}
                className={header.type ? `${header.type}-header` : 'primary-header'}
              >
                {header.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row, index) => (
            <StyledTableRow key={row.tid}>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={`${row.tid}-${header.id}`}
                  className={header.type}
                  align={
                    ['no', 'pg', 'mid', 'tid', 'transactionDate', 'merchantName'].includes(header.id)
                      ? 'center'
                      : 'right'
                  }
                >
                  {typeof row[header.id] === 'number' 
                    ? row[header.id].toLocaleString() 
                    : header.id === 'no' 
                      ? index + 1 
                      : row[header.id]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DepositHistoryTable;
