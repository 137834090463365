import React, { useState, useEffect, useCallback } from 'react';
import { Box, Drawer, IconButton, Button, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import ExcelJS from 'exceljs';

// 공통 컴포넌트 import
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import SearchOptions from './CashdamdangSearch';
import QuickMenu from '../../Common/QuickMenu';
import CashdamdangTables from './CashdamdangTables';
import CashResultTables from '../../CashResultTables';

// 스타일 정의
const styles = {
  contentWrapper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: 'hidden'
  },
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    pl: '50px',
    backgroundColor: '#ffffff',
    transition: 'all 0.3s ease',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)',
    margin: '0', 
    mb: 2,
    padding: '0'
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '280px',
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      borderLeft: '1px solid #e0e0e0',
      height: 'calc(100% - 64px)',
      top: '64px'
    }
  }
};

// 캐시 담당관리 메인 페이지
function CashDamdang() {
  const navigate = useNavigate();

  // 검색 패널 상태
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [searchParams, setSearchParams] = useState({
    startDate: '',
    endDate: '',
    limit: 10
  });
  
  // 테이블 데이터 상태
  const [tableData, setTableData] = useState([]);
  const [getAllMerchantData, setGetAllMerchantData] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 핸들러
  const handleSearchSubmit = (params) => {
    setSearchParams({
      startDate: params.startDate,
      endDate: params.endDate,
      limit: Number(params.limit)
    });
  };

  // 데이터 업데이트 핸들러
  const handleDataUpdate = useCallback(({ data, getAllData }) => {
    setTableData(data);
    setGetAllMerchantData(() => getAllData);
  }, []);

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      // 데이터 존재 여부 체크
      if (!getAllMerchantData || getAllMerchantData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('캐시빌담당관리');

      // 헤더 설정
      worksheet.columns = [
        { header: 'NO', key: 'no', width: 10 },
        { header: '선택', key: 'select', width: 10 },
        { header: '차감율', key: 'deductionRate', width: 10 },
        { header: '충전액', key: 'chargeAmount', width: 12 },
        { header: '충전금', key: 'chargeMoney', width: 12 },
        { header: '적립금', key: 'points', width: 12 },
        { header: '담당 아이디', key: 'managerId', width: 15 },
        { header: '로그인 아이디', key: 'loginId', width: 15 },
        { header: '이름', key: 'name', width: 15 },
        { header: '휴대폰 번호', key: 'phoneNumber', width: 15 },
        { header: '출금상태', key: 'withdrawalStatus', width: 12 },
        { header: '관리', key: 'manage', width: 10 },
        { header: '등록일', key: 'registDate', width: 20 },
        { header: '수정일', key: 'modifyDate', width: 20 }
      ];

      // 스타일 설정
      worksheet.getRow(1).font = { bold: true };
      worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

      // 데이터 추가
      const excelData = getAllMerchantData.map((item, index) => ({
        no: index + 1,
        select: item.property?.value || '0',
        deductionRate: item.deductionRate || '0%',
        chargeAmount: item.chargeAmount || '0',
        chargeMoney: item.chargeMoney || '0',
        points: item.points || '0',
        managerId: item.managerId || '',
        loginId: item.loginId || '',
        name: item.name || '',
        phoneNumber: item.phoneNumber || '',
        withdrawalStatus: item.withdrawalStatus || '',
        manage: '',  // 관리 컬럼은 빈값으로
        registDate: item.registDate || '',
        modifyDate: item.modifyDate || ''
      }));

      worksheet.addRows(excelData);

      // 모든 셀 가운데 정렬
      worksheet.eachRow((row, rowNumber) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
      });

      // 엑셀 파일 다운로드
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `캐시빌담당관리_${new Date().toLocaleDateString()}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: searchPanelOpen && !isMobile ? '280px' : '50px',
    width: '100%',
    transition: 'padding-right 0.3s ease',
    backgroundColor: 'transparent'
  };

  const noticeBoxStyle = {
    ...styles.noticeBox,
    width: 'calc(100% - 0px)',
    transition: 'width 0.3s ease'
  };

  // 담당 등록하기 핸들러 추가
  const handleRegister = () => {
    navigate('/management/cashdamdang/register');
  };

  return (
    <Box sx={styles.contentWrapper}>
      <Box component="main" sx={mainBoxStyle}>
        {/* 공지사항 영역 */}
        <Box sx={{ 
          ...noticeBoxStyle,
          pr: searchPanelOpen && !isMobile ? '80px' : '0px',
          transition: 'padding-right 0.3s ease'
        }}>
          <Notice />
        </Box>

        {/* 현재 위치 */}
        <Box sx={{ 
          ...noticeBoxStyle,
          pr: searchPanelOpen && !isMobile ? '80px' : '0px',
          transition: 'padding-right 0.3s ease'
        }}>
          <CurrentLocation mainTitle="관리" subTitle="캐시빌담당관리" />
        </Box>

        {/* 버튼 그룹 추가 */}
<Box sx={{ 
  display: 'flex', 
  alignItems: 'center',
  mt: 3,
  mb: 2,
  gap: 2,
  pr: searchPanelOpen && !isMobile ? '80px' : '0px',
  transition: 'padding-right 0.3s ease',
  flexWrap: 'wrap'  // 모바일에서는 줄바꿈 허용
}}>
  <CashResultTables />
  <Button
    variant="contained"
    startIcon={<PersonAddIcon />}
    onClick={handleRegister}
    sx={{
      backgroundColor: '#2196f3',
      '&:hover': {
        backgroundColor: '#1976d2'
      },
      fontSize: '0.95rem',
      py: 1,
      px: 3,
      height: 'fit-content',
      alignSelf: 'center'
    }}
  >
    담당 등록하기
  </Button>
</Box>

        {/* 캐시빌담당관리 테이블 */}
        <Box sx={{ 
          mt: 3,
          width: '100%',
          transition: 'width 0.3s ease',
          pr: searchPanelOpen && !isMobile ? '80px' : '0px'
        }}>
          <CashdamdangTables onDataUpdate={handleDataUpdate} />
        </Box>
      </Box>

      {/* 좌측 사이드바 */}
      <QuickMenu />

      {/* 검색 패널 열기 버튼 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            zIndex: 1200,
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearchSubmit}
          onClose={() => setSearchPanelOpen(false)}
          onExcelDownload={handleExcelDownload}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default CashDamdang; 