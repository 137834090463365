import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  IconButton,
  TableCell,
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import { TableContainer, Table } from '@mui/material';
import ExcelJS from 'exceljs';
import SearchIcon from '@mui/icons-material/Search';

// 출금 출금대기 리스트 컴포넌트
const WalletPendingList = () => {
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // 상단 버튼 설정
  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2'
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32'
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2'
    }
  ];

  // 더미 데이터
  const rows = [
    {
      no: 372,
      amount: '1825960',
      bank_name: '기업은행',
      account_no: '01064019977',
      owner_name: '김동열',
      merchant_id: 'm76640459',
      request_time: '2024-12-06 13:11:51',
      status: '입금 건중'
    },
    {
      no: 371,
      amount: '1825960',
      bank_name: '기업은행',
      account_no: '01064019977',
      owner_name: '김동열',
      merchant_id: 'm76640459',
      request_time: '2024-12-06 13:11:00',
      status: '입금 건중'
    }
  ];

  // 테이블 래퍼 스타일 추가
  const StyledTableWrapper = styled(Box)({
    overflow: 'auto',
    position: 'relative',
    maxWidth: '100%',
    '& .sticky-column': {
      position: 'sticky',
      left: 0,
      backgroundColor: '#fff',
      zIndex: 2,
      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '4px',
        background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
      }
    },
    '@media (max-width: 600px)': {
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      '&::-webkit-scrollbar': {
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }
  });

  // 검색 섹션 래퍼 스타일 추가
  const StyledSearchWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '@media (max-width: 600px)': {
      flexDirection: 'row',
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      padding: '8px 0',
      '&::-webkit-scrollbar': {
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }
  });

  // 테이블 셀 스타일 추가
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '4px 8px',
    fontSize: '0.813rem',
    // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
    letterSpacing: '-0.03em',
    borderBottom: '1px solid #e9ecef',
    borderRight: '1px solid #e9ecef',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    '@media (max-width: 600px)': {
      padding: '4px 8px'
    }
  }));

  // 5. 엑셀 다운로드 설정
  const excelDownloadSettings = {
    fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    worksheetName: '출금신청목록',
    fileNamePrefix: 'wallet_pending_list_'
  };

  // 6. 컬럼 매핑 정의
  const columnMapping = {
    'No.': 'no',
    '금액': 'amount',
    '은행명': 'bankName',
    '계좌번호': 'accountNumber',
    '예금주': 'accountHolder',
    '로그인아이디': 'merchantId',
    '출금요청일시': 'requestDate'
  };

  // 7. 엑셀 헤더 정의
  const headers = ['No.', '금액', '은행명', '계좌번호', '예금주', '로그인아이디', '출금요청일시'];

  // 3. 컬럼 정렬 설정
  const columnAlignments = {
    'No.': 'center',
    '금액': 'right',
    '은행명': 'center',
    '계좌번호': 'center',
    '예금주': 'center',
    '로그인아이디': 'center',
    '출금요청일시': 'center'
  };

  // 4. 엑셀 스타일 설정
  const defaultCellStyle = {
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    },
    font: {
      size: 10,
      name: '맑은 고딕'
    }
  };

  const headerCellStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF1976D2' }
    },
    font: {
      size: 10,
      color: { argb: 'FFFFFFFF' },
      bold: true,
      name: '맑은 고딕'
    },
    height: 30
  };

  const defaultRowHeight = 25;

  // 2. 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );

    const getTextWidth = (text) => {
      return Array.from(text).reduce((width, char) => {
        return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
      }, 0);
    };

    const maxWidth = Math.max(
      getTextWidth(header),
      ...data.map(row => getTextWidth(String(row[fieldName] || '')))
    );

    return maxWidth + Math.ceil(maxWidth * 0.2);
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      if (!rows || rows.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(excelDownloadSettings.worksheetName);

      worksheet.columns = headers.map(header => ({
        header,
        key: columnMapping[header],
        width: getMaxColumnWidth(rows, header),
        style: {
          alignment: {
            horizontal: columnAlignments[header],
            vertical: 'middle'
          }
        }
      }));

      worksheet.addRows(rows);

      worksheet.eachRow((row, rowNumber) => {
        row.height = defaultRowHeight;
        row.eachCell(cell => Object.assign(cell, defaultCellStyle));
        
        if (rowNumber === 1) {
          row.height = headerCellStyle.height;
          row.eachCell(cell => Object.assign(cell, headerCellStyle));
        }
      });

      // 엑셀 다운로드 로직
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: excelDownloadSettings.fileType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${excelDownloadSettings.fileNamePrefix}${new Date().toISOString().split('T')[0]}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 메인 코드
  return (
    <Box sx={{ p: 3 }}>
      {/* 헤더 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <AccountBalanceIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          // fontFamilly: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          월렛 출금대기 리스트
        </Typography>
      </Box>

      {/* 검색 및 필터 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <StyledSearchWrapper>
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ 
              flexShrink: 0,
              width: '300px' 
            }}
          />
          {/* 검색 버튼 */}
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={() => {/* 검색 로직 구현 */}}
            sx={{ minWidth: '100px' }}
          >
            검색
          </Button>

          {buttons.map((button, index) => (
            <Tooltip key={index} title={button.label}>
              <IconButton
                sx={{
                  flexShrink: 0,
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#fff',
                  border: `1px solid ${button.color}`,
                  color: button.color,
                  '&:hover': {
                    backgroundColor: `${button.color}10`,
                  }
                }}
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}

          <Tooltip title="엑셀 다운로드">
            <IconButton
              onClick={handleExcelDownload}
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#fff',
                border: '1px solid #2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  backgroundColor: '#2e7d3210',
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </StyledSearchWrapper>
      </Paper>

      {/* 테이블 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table sx={{ 
              minWidth: '1200px',
              tableLayout: 'fixed'
            }}>
              <colgroup>
                <col style={{ width: '80px' }} />  {/* No */}
                <col style={{ width: '120px' }} />  {/* 출금액 */}
                <col style={{ width: '100px' }} />  {/* 은행명 */}
                <col style={{ width: '150px' }} />  {/* 계좌번호 */}
                <col style={{ width: '100px' }} />  {/* 예금주 */}
                <col style={{ width: '120px' }} />  {/* 로그인아이디 */}
                <col style={{ width: '160px' }} />  {/* 출금요청일 */}
                <col style={{ width: '80px' }} />   {/* 알림발송 */}
                <col style={{ width: '80px' }} />   {/* 취소 */}
                <col style={{ width: '80px' }} />   {/* 출금 */}
              </colgroup>
              <thead>
                <tr style={{ backgroundColor: '#f8f9fa' }}>
                  <th style={tableHeaderStyle}>No</th>
                  <th style={tableHeaderStyle}>출금액</th>
                  <th style={tableHeaderStyle}>은행명</th>
                  <th style={tableHeaderStyle}>계좌번호</th>
                  <th style={tableHeaderStyle}>예금주</th>
                  <th style={tableHeaderStyle}>로그인아이디</th>
                  <th style={tableHeaderStyle}>출금요청일</th>
                  <th style={tableHeaderStyle}>알림발송</th>
                  <th style={tableHeaderStyle}>취소</th>
                  <th style={tableHeaderStyle}>출금</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.no}>
                    <td style={tableCellStyle}>{row.no}</td>
                    <td style={tableCellStyle}>{row.amount}</td>
                    <td style={tableCellStyle}>{row.bank_name}</td>
                    <td style={tableCellStyle}>{row.account_no}</td>
                    <td style={tableCellStyle}>{row.owner_name}</td>
                    <td style={tableCellStyle}>{row.merchant_id}</td>
                    <td style={tableCellStyle}>{row.request_time}</td>
                    <td style={tableCellStyle}>
                      <ActionButton
                        title="알림발송"
                        icon={<NotificationsIcon />}
                        color="#51cbce"
                        hoverColor="#34b5b8"
                      />
                    </td>
                    <td style={tableCellStyle}>
                      <ActionButton
                        title="취소"
                        icon={<CancelIcon />}
                        color="#6bd098"
                        hoverColor="#44c47d"
                      />
                    </td>
                    <td style={tableCellStyle}>
                      <ActionButton
                        title="출금"
                        icon={<AccountBalanceWalletIcon />}
                        color="#ef8157"
                        hoverColor="#eb6532"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>
      </Paper>
    </Box>
  );
};

// 스타일 상수
const tableHeaderStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  color: '#344767',
  fontWeight: 600,
  fontSize: '0.75rem',
  textTransform: 'uppercase'
};

const tableCellStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  fontSize: '0.875rem'
};

// 액션 버튼 컴포넌트
const ActionButton = ({ title, icon, color, hoverColor }) => (
  <Tooltip title={title}>
    <IconButton
      size="medium"
      sx={{
        backgroundColor: color,
        color: '#fff',
        width: '36px',
        height: '36px',
        '&:hover': {
          backgroundColor: hoverColor
        }
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: '1.2rem' } })}
    </IconButton>
  </Tooltip>
);

export default WalletPendingList;
