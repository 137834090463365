import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  useMediaQuery,
  useTheme,
  Paper
} from '@mui/material';
import Notice from '../../Notice/Notice'; // 공지사항 컴포넌트
import DateRangePicker from '../../Common/DateRangePicker'; // DateRangePicker 추가
import QuickMenu from '../../Common/QuickMenu'; // 퀵메뉴 추가
import ScSummaryTable from './ScSummaryTable'; // 테이블 컴포넌트 추가
import CurrentLocation from '../../Common/CurrentLocation';
import SearchOptions from '../../Common/SearchOptions';

// 메인 함수
function ScheduledSettlementSummary() {
  // 테마 설정
  const theme = useTheme();
  // 모바일 여부 확인
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // 날짜 상태 관리
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // 스타일 정의
  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',
      mr: '40px',
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentWrapper: {
      width: '100%'
    },
    searchSection: {
      p: 3,
      borderRadius: '12px',
      backgroundColor: '#fff',
      boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
      mb: 3,
      position: 'relative',
      zIndex: 1200
    },
    datePickerWrapper: {
      display: 'flex',
      gap: 2,
      alignItems: 'center',
      flexWrap: 'wrap',
      position: 'relative',
      '& .MuiPopover-root, .MuiPopper-root': {
        zIndex: 1300
      }
    },
    datePickerBox: {
      position: 'relative',
      '& .MuiPopover-root, .MuiPopper-root': {
        zIndex: 1500,
        position: 'fixed',
        transform: 'none !important',
        left: '240px !important'  // 사이드바 너비
      }
    }
  };

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };


  useEffect(() => {
    const handleResize = () => {
      // 모바일 처리 로직
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 메인 코드
  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentWrapper}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="정산집계 내역" />
        
        <Paper sx={styles.searchSection}>
          <Box sx={styles.datePickerWrapper}>
            <Box sx={styles.datePickerBox}>
              <DateRangePicker 
                startDate={startDate} 
                endDate={endDate} 
                onStartDateChange={setStartDate} 
                onEndDateChange={setEndDate}
                isSearchSection={true}
              />
            </Box>
          </Box>
        </Paper>

        <Box sx={{ mt: 2 }}>
          <ScSummaryTable startDate={startDate} endDate={endDate} />
        </Box>
      </Box>

      <QuickMenu />
    </Box>
  );
}

export default ScheduledSettlementSummary;