import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  styled
} from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

// 테이블 열 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    backgroundColor: '#1976d2',
    color: '#ffffff'
  }
}));

// 테이블 텍스트 필드 스타일
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#90a4ae',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#607d8b',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '4px 8px',
    fontSize: '0.813rem',
  },
});

// 더미 데이터
const dummyData = [
  {
    date: '2025-02-07 14:30:00',
    no: 1,
    pg: 'KSNET',
    count: '102',
    sales: '19,142,160',
    inputFee: '',
    calculatedValue: '결과 표시',
    amountInput: '',
    difference: '결과 표시',
    differenceRate: '결과 표시'
  },
  {
    date: '2025-02-06 15:20:00',
    no: 2,
    pg: 'NICE',
    count: '140',
    sales: '10,198,132',
    inputFee: '',
    calculatedValue: '결과 표시',
    amountInput: '',
    difference: '결과 표시',
    differenceRate: '결과 표시'
  },
  {
    date: '2025-02-05 11:45:00',
    no: 3,
    pg: '갤럭시아',
    count: '85',
    sales: '15,567,890',
    inputFee: '',
    calculatedValue: '결과 표시',
    amountInput: '',
    difference: '결과 표시',
    differenceRate: '결과 표시'
  },
  {
    date: '2025-02-04 09:15:00',
    no: 4,
    pg: 'KSNET',
    count: '95',
    sales: '8,765,430',
    inputFee: '',
    calculatedValue: '결과 표시',
    amountInput: '',
    difference: '결과 표시',
    differenceRate: '결과 표시'
  },
  {
    date: '2025-02-03 16:45:00',
    no: 5,
    pg: 'NICE',
    count: '120',
    sales: '12,345,678',
    inputFee: '',
    calculatedValue: '결과 표시',
    amountInput: '',
    difference: '결과 표시',
    differenceRate: '결과 표시'
  }
];

// 메인 함수
const InputValidationTable = ({ searchConditions }) => {
  // 검색 조건에 따른 데이터 필터링
  const filteredData = useMemo(() => {
    if (!searchConditions) return dummyData;

    return dummyData.filter(row => {
      // 날짜 범위 체크
      if (searchConditions.startDate && searchConditions.endDate) {
        const rowDate = dayjs(row.date);
        const start = dayjs(searchConditions.startDate).startOf('day');
        const end = dayjs(searchConditions.endDate).endOf('day');
        
        if (!rowDate.isBetween(start, end, 'day', '[]')) {
          return false;
        }
      }

      // PG사 필터링
      if (searchConditions.pgCompany && searchConditions.pgCompany !== '') {
        if (row.pg !== searchConditions.pgCompany) {
          return false;
        }
      }

      return true;
    }).map((row, index) => ({
      ...row,
      no: index + 1 // 동적으로 NO 재할당
    }));
  }, [searchConditions]);

  return (
    <TableContainer 
      component={Paper} 
      sx={{
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '4px',
        },
      }}
    >
      <Table size="small" sx={{ minWidth: 1000 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>날짜</StyledTableCell>
            <StyledTableCell>NO</StyledTableCell>
            <StyledTableCell>PG구분</StyledTableCell>
            <StyledTableCell align="right">건수합계</StyledTableCell>
            <StyledTableCell align="right">매출합계</StyledTableCell>
            <StyledTableCell>입력수수료</StyledTableCell>
            <StyledTableCell>산출값</StyledTableCell>
            <StyledTableCell>금액입력</StyledTableCell>
            <StyledTableCell>차액</StyledTableCell>
            <StyledTableCell>차액율</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row) => (
            <TableRow 
              key={row.no}
              sx={{ 
                '&:nth-of-type(odd)': { 
                  backgroundColor: '#ffffff',
                },
                '&:nth-of-type(even)': { 
                  backgroundColor: '#f8f9fa',
                },
                '&:hover': {
                  backgroundColor: '#f1f3f5',
                },
              }}
            >
              <StyledTableCell>{row.date}</StyledTableCell>
              <StyledTableCell>{row.no}</StyledTableCell>
              <StyledTableCell>{row.pg}</StyledTableCell>
              <StyledTableCell align="right">{row.count}</StyledTableCell>
              <StyledTableCell align="right">{row.sales}</StyledTableCell>
              <StyledTableCell>
                <TextField
                  size="small"
                  placeholder="값 입력"
                  fullWidth
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '32px',
                      fontSize: '0.813rem'
                    }
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>{row.calculatedValue}</StyledTableCell>
              <StyledTableCell>
                <TextField
                  size="small"
                  placeholder="값 입력"
                  fullWidth
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '32px',
                      fontSize: '0.813rem'
                    }
                  }}
                />
              </StyledTableCell>
              <StyledTableCell>{row.difference}</StyledTableCell>
              <StyledTableCell>{row.differenceRate}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InputValidationTable;
