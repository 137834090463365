import React from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  IconButton,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

// 모달 스타일
const StyledBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  padding: '20px'
});

// 폼 컨트롤 스타일
const StyledFormControl = styled(FormControl)({
  width: '100%',
  marginBottom: '8px'
});

// 메인 함수. 모달이라 닫기, 열기 상태값 받음
const ManageRM = ({ open, onClose, title }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="manage-rm-modal"
    >
      <StyledBox>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {title} - 잔액이체
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <StyledFormControl>
          <Typography variant="body2" sx={{ mb: 0.5 }}>잔액</Typography>
          <TextField
            size="small"
            fullWidth
            value="5,567,497"
            disabled
          />
        </StyledFormControl>

        <StyledFormControl>
          <Typography variant="body2" sx={{ mb: 0.5 }}>발송계좌</Typography>
          <Select
            size="small"
            value="234"
            displayEmpty
          >
            <MenuItem value="234">234</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <Typography variant="body2" sx={{ mb: 0.5 }}>송금액</Typography>
          <TextField
            size="small"
            fullWidth
          />
        </StyledFormControl>

        <StyledFormControl>
          <Typography variant="body2" sx={{ mb: 0.5 }}>예금주</Typography>
          <TextField
            size="small"
            fullWidth
          />
        </StyledFormControl>

        <StyledFormControl>
          <Typography variant="body2" sx={{ mb: 0.5 }}>은행명</Typography>
          <Select
            size="small"
            displayEmpty
          >
            <MenuItem value="" disabled>선택</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <Typography variant="body2" sx={{ mb: 0.5 }}>계좌번호</Typography>
          <TextField
            size="small"
            fullWidth
          />
        </StyledFormControl>

        <StyledFormControl>
          <Typography variant="body2" sx={{ mb: 0.5 }}>수신계좌</Typography>
          <Select
            size="small"
            displayEmpty
          >
            <MenuItem value="" disabled>선택</MenuItem>
          </Select>
        </StyledFormControl>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: '#007bff',
              '&:hover': {
                backgroundColor: '#0056b3'
              }
            }}
          >
            송금하기
          </Button>
        </Box>
      </StyledBox>
    </Modal>
  );
};

export default ManageRM;
