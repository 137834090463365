import React from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  minWidth: 'max-content',
  
  '&.total-column': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '@media (max-width: 600px)': {
    padding: '4px 8px'
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  },
  
  '&:last-child': {
    '& td': {
      fontWeight: 600,
      backgroundColor: '#f8f9fa'
    }
  }
}));

// 테이블 래퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 메인 함수
function GeoraegmBiyul({ searchParams }) {
  // 더미 데이터
  const biyulData = [
    { range: '0 ~ 1만원 이하', amount: 1250000, count: 272, ratio: 18.579 },
    { range: '1만원 초과 ~ 5만원 이하', amount: 3824000, count: 191, ratio: 13.046 },
    { range: '5만원 초과 ~ 10만원 이하', amount: 11256000, count: 277, ratio: 18.921 },
    { range: '10만원 초과 ~ 20만원 이하', amount: 14567000, count: 188, ratio: 12.842 },
    { range: '20만원 초과 ~ 50만원 이하', amount: 25678000, count: 85, ratio: 5.806 },
    { range: '50만원 초과 ~ 100만원 이하', amount: 89456000, count: 133, ratio: 9.085 },
    { range: '100만원 초과 ~ 200만원 이하', amount: 154123000, count: 80, ratio: 5.464 },
    { range: '200만원 초과 ~ 300만원 이하', amount: 234567000, count: 36, ratio: 2.459 },
    { range: '300만원 초과 ~ 500만원 이하', amount: 345678000, count: 41, ratio: 2.801 },
    { range: '500만원 초과 ~ 800만원 이하', amount: 567890000, count: 24, ratio: 1.639 },
    { range: '800만원 초과 ~ 1000만원 이하', amount: 789012000, count: 4, ratio: 0.273 },
    { range: '1000만원 초과 ~ 2000만원 이하', amount: 1234567000, count: 3, ratio: 0.205 },
    { range: '2000만원 초과', amount: 2345678000, count: 2, ratio: 0.137 },
    { range: '총계', amount: 5817546000, count: 1336, ratio: 100 }
  ];

  // 메인 함수
  return (
    <Paper elevation={1} sx={{ p: 3, backgroundColor: '#ffffff', borderRadius: '8px' }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#424242' }}>
        거래금액 비율
      </Typography>
      
      <StyledTableWrapper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="sticky-column">금액구간</StyledTableCell>
                <StyledTableCell align="right" className="primary-header">건수</StyledTableCell>
                <StyledTableCell align="right" className="primary-header">총 거래금액</StyledTableCell>
                <StyledTableCell align="right" className="secondary-header">비율(%)</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {biyulData.map((row) => (
                <StyledTableRow key={row.range}>
                  <StyledTableCell>{row.range}</StyledTableCell>
                  <StyledTableCell align="right" className="total-column">
                    {row.count.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="total-column">
                    {row.amount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.ratio.toFixed(3)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableWrapper>
    </Paper>
  );
}

export default GeoraegmBiyul; 