//현재 사용 영수증 이미지임.
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';
// import receiptLogo from '../../../assets/images/영수증 로고.png';

// 스타일 컴포넌트 정의
const ReceiptContainer = styled(Box)({
  background: 'white',
  width: '100%',
  maxWidth: '330px',
  border: '1px solid #ccc',
  borderRadius: '8px',
  padding: '8px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  margin: '20px auto',
  // fontFamilly: "Pretendard Variable !important",
  '& *': {
    // fontFamilly: "Pretendard Variable !important"
  },
  '& > *:not(:first-child)': {
    marginTop: '4px'
  }
});

// 헤더 스타일 컴포넌트
const ReceiptHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  background: '#0078d7',
  color: 'white',
  padding: '10px',
  borderRadius: '6px 6px 0 0',
  fontSize: '14px',
  fontWeight: 'bold',
  position: 'relative',
  // fontFamilly: "Pretendard Variable !important",
  justifyContent: 'flex-end'
});

// 로고 스타일 컴포넌트. 현재 사용 X
// const Logo = styled(Box)({
//   flex: 1,
//   '& img': {
//     height: '20px',
//     width: 'auto',
//     objectFit: 'contain'
//   }
// });

// 타이틀 스타일 컴포넌트
const Title = styled(Box)({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '14px',
  textAlign: 'center'
});

// 닫기 버튼 스타일 컴포넌트
const CloseButton = styled(Button)({
  background: '#f44336',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '1px 6px',
  fontSize: '12px',
  minWidth: '32px',
  height: '20px',
  cursor: 'pointer',
  '&:hover': {
    background: '#d32f2f'
  }
});

// 각 섹션 스타일 컴포넌트
const Section = styled(Box)({
  marginTop: '10px',
  padding: '10px',
  borderRadius: '4px',
  fontSize: '12px',
  '&.store-info': {
    backgroundColor: '#eaf4ff',
    border: '1px solid #ccc',
    '& table th, & table td': {
      border: '1px solid #ccc'
    }
  }
});

// 섹션 타이틀 스타일 컴포넌트
const SectionTitle = styled(Box)({
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '4px',
  color: '#333',
  paddingLeft: '0',
  marginLeft: '0'
});


const InfoTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  fontSize: '12px',
  '& th, & td': {
    padding: '6px',
    textAlign: 'left'
  },
  '& th': {
    background: '#f9f9f9',
    fontWeight: 'bold'
  }
});

// 하이라이트 섹션 스타일 컴포넌트
const HighlightSection = styled(Section)({
  border: '1px solid #0078d7',
  background: '#eaf4ff',
  '& .info-table th, & .info-table td': {
    background: '#ffffff',
    border: '1px solid #e0e0e0'
  }
});

// 푸터 스타일 컴포넌트
const Footer = styled(Box)({
  background: '#0078d7',
  color: 'white',
  textAlign: 'center',
  marginTop: '10px',
  borderRadius: '4px',
  padding: '10px',
  fontSize: '12px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '& a': {
    color: '#ffffff',
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

// 작은 푸터 스타일 컴포넌트(하단 설명 텍스트)
const FooterSmall = styled(Box)({
  background: '#f9f9f9',
  color: '#555',
  textAlign: 'left',
  marginTop: '5px',
  padding: '10px',
  fontSize: '10px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  wordBreak: 'keep-all',
  lineHeight: '1.4',
  whiteSpace: 'pre-line'
});

// 모바일용 스타일 컴포넌트 수정
const MobileSection = styled(Section)({
  marginTop: '4px',
  padding: '0',
  border: '1px solid #ddd',
  borderRadius: '4px',
  overflow: 'hidden',
  '& > h6': {
    fontSize: '11px',
    margin: '0',
    padding: '4px 6px 4px 12px',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #ddd'
  }
});

const MobileInfoRow = styled(Box)({
  display: 'flex',
  borderBottom: '1px solid #eee',
  '&:last-child': {
    borderBottom: 'none'
  },
  '& .label': {
    flex: '0 0 70px',
    padding: '4px 6px',
    backgroundColor: '#f8f9fa',
    fontSize: '10px',
    color: '#666',
    borderRight: '1px solid #eee'
  },
  '& .value': {
    flex: 1,
    padding: '4px 6px',
    fontSize: '10px',
    color: '#333'
  }
});

// 모바일용 하이라이트 섹션 수정
const MobileHighlightSection = styled(MobileSection)({
  border: '1px solid #0078d7',
  backgroundColor: '#eaf4ff',
  borderRadius: '4px',
  overflow: 'hidden',
  '& > h6': {
    backgroundColor: '#eaf4ff',
    borderBottom: '1px solid #0078d7',
    padding: '4px 6px 4px 12px'
  },
  '& .label': {
    backgroundColor: '#f8f9fa'
  },
  '& .value': {
    backgroundColor: '#ffffff'
  },
  '& > div': {
    backgroundColor: '#eaf4ff'
  }
});

const Bill1 = ({ onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose?.();
  };

  // 모바일용 정보 행 컴포넌트
  // const MobileInfoPair = ({ left, right }) => (
  //   <MobileInfoRow>
  //     <Box>
  //       <span className="label">{left.label}:</span>
  //       <span className="value">{left.value}</span>
  //     </Box>
  //     {right && (
  //       <Box>
  //         <span className="label">{right.label}:</span>
  //         <span className="value">{right.value}</span>
  //       </Box>
  //     )}
  //   </MobileInfoRow>
  // );

  return (
    <Box sx={{ 
      position: 'fixed',
      top: '64px',
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      zIndex: 999,
      overflowY: 'auto',
      padding: '20px'
    }}>
      <Box className="receipt-modal" sx={{
        marginTop: '20px',
        marginBottom: '20px'
      }}>
        <ReceiptContainer sx={{ 
          maxWidth: isMobile ? '280px' : '330px',
          padding: isMobile ? '4px' : '8px'
        }}>
          <ReceiptHeader>
            <Title sx={{ fontSize: isMobile ? '12px' : '14px' }}>온라인 매출전표</Title>
            <CloseButton onClick={handleClose}>닫기</CloseButton>
          </ReceiptHeader>

          {isMobile ? (
            <>
              <MobileSection>
                <SectionTitle>구매정보</SectionTitle>
                <Box>
                  <MobileInfoRow>
                    <span className="label">거래일시</span>
                    <span className="value">2024-12-12 17:10</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">상품명</span>
                    <span className="value">신속개발강의</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">구매자명</span>
                    <span className="value">이민자</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">주문번호</span>
                    <span className="value">1232321</span>
                  </MobileInfoRow>
                </Box>
              </MobileSection>

              <MobileSection>
                <SectionTitle>결제정보</SectionTitle>
                <Box>
                  <MobileInfoRow>
                    <span className="label">결제방법</span>
                    <span className="value">신용카드</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">카드종류</span>
                    <span className="value">비씨카드드</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">카드번호</span>
                    <span className="value">1321-1213-1031</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">할부개월</span>
                    <span className="value">일시불</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">승인번호</span>
                    <span className="value">132131231</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">결제금액</span>
                    <span className="value">44,000원</span>
                  </MobileInfoRow>
                </Box>
              </MobileSection>

              <MobileHighlightSection>
                <SectionTitle>상점정보</SectionTitle>
                <Box>
                  <MobileInfoRow>
                    <span className="label">사업장명</span>
                    <span className="value">초특급개발센터</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">대표자명</span>
                    <span className="value">일론머스크</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">사업자번호</span>
                    <span className="value">124-00-12345</span>
                  </MobileInfoRow>
                  <MobileInfoRow>
                    <span className="label">상점문의</span>
                    <span className="value">1669-2255</span>
                  </MobileInfoRow>
                </Box>
              </MobileHighlightSection>
            </>
          ) : (
            // 데스크톱 버전 (기존 코드 유지)
            <>
              <Section>
                <SectionTitle>구매정보</SectionTitle>
                <InfoTable>
                  <tbody>
                    <tr>
                      <th>거래일시</th>
                      <td>2024-12-12 17:10</td>
                    </tr>
                    <tr>
                      <th>상품명</th>
                      <td>신속개발강의</td>
                    </tr>
                    <tr>
                      <th>구매자명</th>
                      <td>이민자</td>
                    </tr>
                    <tr>
                      <th>주문번호</th>
                      <td>1232321</td>
                    </tr>
                  </tbody>
                </InfoTable>
              </Section>

              <Section>
                <SectionTitle>결제정보</SectionTitle>
                <InfoTable>
                  <tbody>
                    <tr>
                      <th>결제방법</th>
                      <td>신용카드 / 체크카드</td>
                    </tr>
                    <tr>
                      <th>카드종류</th>
                      <td>비씨카드</td>
                    </tr>
                    <tr>
                      <th>카드번호</th>
                      <td>1321-1213-10312</td>
                    </tr>
                    <tr>
                      <th>할부개월</th>
                      <td>일시불 / 12개월</td>
                    </tr>
                    <tr>
                      <th>거래유형</th>
                      <td>승인 / 취소</td>
                    </tr>
                    <tr>
                      <th>승인번호</th>
                      <td>132131231</td>
                    </tr>
                    <tr>
                      <th>결제금액</th>
                      <td>44,000</td>
                    </tr>
                    <tr>
                      <th>공급가</th>
                      <td>40,000</td>
                    </tr>
                    <tr>
                      <th>부가세</th>
                      <td>4,000</td>
                    </tr>
                  </tbody>
                </InfoTable>
              </Section>

              <Section className="store-info">
                <SectionTitle>상점정보</SectionTitle>
                <InfoTable>
                  <tbody>
                    <tr>
                      <th>사업장명</th>
                      <td>초특급개발센터</td>
                    </tr>
                    <tr>
                      <th>대표자명</th>
                      <td>일론머스크</td>
                    </tr>
                    <tr>
                      <th>사업자등록번호</th>
                      <td>124-00-12345</td>
                    </tr>
                    <tr>
                      <th>사업장주소</th>
                      <td>경기도 가평군 복길 직로 999</td>
                    </tr>
                    <tr>
                      <th>상점문의</th>
                      <td>1669-2255</td>
                    </tr>
                  </tbody>
                </InfoTable>
              </Section>
            </>
          )}

          <Footer sx={{ 
            fontSize: isMobile ? '9px' : '12px',
            padding: isMobile ? '6px' : '10px'
          }}>
            신용카드 청구서에는 이지플랫전자결제(주)로 표시됩니다.
            <br />
            This Transaction will be billed by EGFP Inc.
          </Footer>

          <FooterSmall sx={{ 
            fontSize: isMobile ? '8px' : '10px',
            padding: isMobile ? '6px' : '10px',
            lineHeight: isMobile ? '1.2' : '1.4'
          }}>
            * 신용카드 매출전표는 부가가치세법 제32조 2제3항에 의하여 발행되었으며, 
            부가가치세법 제46조에 따라 신용카드매출전표 등을 발급받은 경우에는 
            매입세액 공제가 가능합니다.
          </FooterSmall>
        </ReceiptContainer>
      </Box>
    </Box>
  );
};

export default Bill1;
