import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Paper, 
  Box,
  TextField,
  Button,
  Typography, 
  ToggleButton, 
  ToggleButtonGroup,
} from '@mui/material';
import styled from '@emotion/styled';
import DateRangePicker from '../../Common/DateRangePicker';
import SearchIcon from '@mui/icons-material/Search';

// 테이블 열 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  },

  '&.bg-green': {
    backgroundColor: '#e8f5e9'
  },
  '&.bg-blue': {
    backgroundColor: '#e3f2fd'
  },
  '&.bg-orange': {
    backgroundColor: '#fff3e0'
  },

  '&.status-cell': {
    textAlign: 'center',
    '& .active': { color: '#2185d0' },
    '& .inactive': { color: '#f44336' }
  },

  '&:last-child': {
    borderRight: 'none'
  }
}));

// 테이블 행 스타일 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f9ff'
  }
}));

// 테이블 워퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  border: '1px solid #e9ecef',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  backgroundColor: '#fff',
  
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  }
});

// 상태 칩 스타일
const StatusChip = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  
  &.active {
    background-color: #64b5f6;  // 연한 파란색
    color: #ffffff;
  }
  
  &.inactive {
    background-color: #ef5350;  // 연한 빨간색
    color: #ffffff;
  }

  &.sms-unavailable {
    background-color: #ef5350;  // 연한 붉은색
    color: #ffffff;
  }

  &.sms-available {
    background-color: #fb8c00;  // 연한 주황색
    color: #ffffff;
  }

  &.delete {
    background-color: #ef5350;  // 연한 빨간색
    color: #ffffff;
  }
`;

// 토글 버튼 스타일
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    border: '1px solid #e0e0e0',
    
    '&.Mui-selected': {
      '&.active': {
        backgroundColor: '#64b5f6',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#42a5f5',
        }
      },
      '&.inactive': {
        backgroundColor: '#ef5350',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#e53935',
        }
      },
      '&.delete': {
        backgroundColor: '#ef5350',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#e53935',
        }
      },
      '&.sms-unavailable': {
        backgroundColor: '#ef5350',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#e53935',
        }
      },
      '&.sms-available': {
        backgroundColor: '#fb8c00',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#f57c00',
        }
      }
    }
  }
}));

// 더미데이터 수정 및 확장
const dummyData = [
  {
    no: 1,
    registerDate: '2025-02-06 15:00:00',
    nextPaymentDate: '2025-02-06 15:43:54',
    stopDate: '2024-02-01 14:00:00',
    status: '사용중',
    deleteStatus: '삭제',
    authType: 'SMS',
    pgName: 'KSNET',
    merchantId: 'KS0001234',
    merchantName: '농부고을2',
    registerName: '등록자1',
    amount: '176,000',
    cardNumber: '5137-9200-****-4575',
    validThru: '03/27',
    customerName: '홍길동',
    password: '**',
    phone: '010-0444-2222',
    email: 'aaa@dsdsds',
    smsStatus: '발송하기',
    result: '성공'
  },
  {
    no: 2,
    registerDate: '2025-02-05 11:30:00',
    nextPaymentDate: '2025-02-05 11:43:54',
    stopDate: '-',
    status: '삭제완료',
    deleteStatus: '-',
    authType: 'CARD',
    pgName: 'NICE',
    merchantId: 'M432109876',
    merchantName: '애플49',
    registerName: '등록자2',
    amount: '1,000,000',
    cardNumber: '4009-7870-****-8206',
    validThru: '12/29',
    customerName: '김철수',
    password: '**',
    phone: '010-1111-2222',
    email: 'test2@test.com',
    smsStatus: '발송불가',
    result: '성공'
  },
  {
    no: 3,
    registerDate: '2025-02-04 09:15:00',
    nextPaymentDate: '2025-02-04 09:43:54',
    stopDate: '-',
    status: '사용중',
    deleteStatus: '-',
    authType: 'SMS',
    pgName: '갤럭시아',
    merchantId: 'SPC107836',
    merchantName: '고기진심정육점',
    registerName: '등록자3',
    amount: '583,000',
    cardNumber: '9411-2350-****-1234',
    validThru: '08/25',
    customerName: '강동원',
    password: '**',
    phone: '010-3333-4444',
    email: 'test3@test.com',
    smsStatus: '발송하기',
    result: '성공'
  },
  {
    no: 4,
    registerDate: '2025-02-03 14:20:00',
    nextPaymentDate: '2025-02-03 14:43:54',
    stopDate: '2024-02-01 14:00:00',
    status: '삭제완료',
    deleteStatus: '삭제',
    authType: 'CARD',
    pgName: 'EtoU',
    merchantId: 'SPC_112014',
    merchantName: '골드스파사우나',
    registerName: '등록자1',
    amount: '890,000',
    cardNumber: '5137-9200-****-4575',
    validThru: '03/27',
    customerName: '박박이',
    password: '**',
    phone: '010-5555-6666',
    email: 'test4@test.com',
    smsStatus: '발송불가',
    result: '실패'
  },
  {
    no: 5,
    registerDate: '2025-02-02 16:45:00',
    nextPaymentDate: '2025-02-02 16:43:54',
    stopDate: '-',
    status: '사용중',
    deleteStatus: '-',
    authType: 'SMS',
    pgName: 'Kopay',
    merchantId: 'KP987654',
    merchantName: '일일구세탁',
    registerName: '등록자2',
    amount: '45,000',
    cardNumber: '4009-7870-****-8206',
    validThru: '12/29',
    customerName: '이영희',
    password: '**',
    phone: '010-7777-8888',
    email: 'test5@test.com',
    smsStatus: '발송하기',
    result: '성공'
  },
  {
    no: 6,
    registerDate: '2025-02-01 10:30:00',
    nextPaymentDate: '2025-02-01 10:43:54',
    stopDate: '-',
    status: '사용중',
    deleteStatus: '-',
    authType: 'CARD',
    pgName: 'payup',
    merchantId: 'PU123456',
    merchantName: '골드스파사우나 매점',
    registerName: '등록자3',
    amount: '67,000',
    cardNumber: '9411-2350-****-1234',
    validThru: '08/25',
    customerName: '김연아',
    password: '**',
    phone: '010-9999-0000',
    email: 'test6@test.com',
    smsStatus: '발송하기',
    result: '성공'
  },
  {
    no: 7,
    registerDate: '2025-01-31 13:15:00',
    nextPaymentDate: '2025-01-31 13:43:54',
    stopDate: '2024-02-01 14:00:00',
    status: '삭제완료',
    deleteStatus: '삭제',
    authType: 'SMS',
    pgName: 'AYNIL',
    merchantId: 'AY789012',
    merchantName: '일일구세탁',
    registerName: '등록자1',
    amount: '234,000',
    cardNumber: '5137-9200-****-4575',
    validThru: '03/27',
    customerName: '차범근',
    password: '**',
    phone: '010-2222-3333',
    email: 'test7@test.com',
    smsStatus: '발송불가',
    result: '성공'
  },
  {
    no: 8,
    registerDate: '2025-01-30 09:45:00',
    nextPaymentDate: '2025-01-30 09:43:54',
    stopDate: '2024-02-01 14:00:00',
    status: '삭제완료',
    deleteStatus: '삭제',
    authType: 'CARD',
    pgName: 'KSNET',
    merchantId: 'KS345678',
    merchantName: '애플49',
    registerName: '등록자2',
    amount: '445,000',
    cardNumber: '4009-7870-****-8206',
    validThru: '12/29',
    customerName: '김민재',
    password: '**',
    phone: '010-3333-4444',
    email: 'test8@test.com',
    smsStatus: '발송불가',
    result: '실패'
  },
  {
    no: 9,
    registerDate: '2025-01-29 15:20:00',
    nextPaymentDate: '2025-01-29 15:43:54',
    stopDate: '-',
    status: '사용중',
    deleteStatus: '-',
    authType: 'SMS',
    pgName: 'NICE',
    merchantId: 'NC901234',
    merchantName: '고기진심정육점',
    registerName: '등록자3',
    amount: '128,000',
    cardNumber: '9411-2350-****-1234',
    validThru: '08/25',
    customerName: '손흥민',
    password: '**',
    phone: '010-5555-6666',
    email: 'test9@test.com',
    smsStatus: '발송하기',
    result: '성공'
  },
  {
    no: 10,
    registerDate: '2025-01-28 11:10:00',
    nextPaymentDate: '2025-01-28 11:43:54',
    stopDate: '2024-02-01 14:00:00',
    status: '사용중',
    deleteStatus: '삭제',
    authType: 'CARD',
    pgName: '갤럭시아',
    merchantId: 'GX567890',
    merchantName: '골드스파사우나',
    registerName: '등록자1',
    amount: '920,000',
    cardNumber: '5137-9200-****-4575',
    validThru: '03/27',
    customerName: '황희찬',
    password: '**',
    phone: '010-7777-8888',
    email: 'test10@test.com',
    smsStatus: '발송하기',
    result: '성공'
  }
];

// 필터 그룹 컴포넌트
const FilterGroup = ({ title, children }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 1 
  }}>
    <Typography 
      sx={{ 
        fontSize: '0.875rem', 
        fontWeight: 600,
        color: '#333',
        minWidth: 'max-content'
      }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

// 정기결제 테이블 컴포넌트
function RegularPaymentTable({ filters }) {
  const [dateRange, setDateRange] = useState({
    startDate: '2025-01-01',
    endDate: '2025-12-31'
  });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(dummyData);
  const [localFilters, setLocalFilters] = useState({
    usage: null,
    deletion: null,
    sms: null
  });
  const [searchParams, setSearchParams] = useState({
    dateRange: {
      startDate: '2025-01-01',
      endDate: '2025-12-31'
    },
    searchText: ''
  });

  // DateRangePicker 날짜 변경 핸들러
  const handleStartDateChange = (date) => {
    setDateRange(prev => ({
      ...prev,
      startDate: formatDateString(date)
    }));
  };

  const handleEndDateChange = (date) => {
    setDateRange(prev => ({
      ...prev,
      endDate: formatDateString(date)
    }));
  };

  // YYYYMMDD 형식을 YYYY-MM-DD 형식으로 변환
  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}-${month}-${day}`;
  };

  // 필터 변경 핸들러 - 즉시 적용
  const handleFilterChange = (type, value) => {
    setLocalFilters(prev => ({
      ...prev,
      [type]: prev[type] === value ? null : value
    }));
  };

  // 초기화 핸들러
  const handleReset = () => {
    setLocalFilters({
      usage: null,
      deletion: null,
      sms: null
    });
    setSearchText('');
    setDateRange({
      startDate: '2025-01-01',
      endDate: '2025-12-31'
    });
    setSearchParams({
      dateRange: {
        startDate: '2025-01-01',
        endDate: '2025-12-31'
      },
      searchText: ''
    });
  };

  // 검색 버튼 클릭 핸들러 - 날짜와 검색어만 적용
  const handleSearch = () => {
    setSearchParams({
      dateRange,
      searchText
    });
  };

  // 데이터 필터링 함수
  const filterData = (data) => {
    return data.filter(item => {
      // 1. 날짜 필터링 (검색 버튼으로 적용)
      const registerDate = new Date(item.registerDate.split(' ')[0]);
      const startDate = new Date(searchParams.dateRange.startDate);
      const endDate = new Date(searchParams.dateRange.endDate);
      endDate.setHours(23, 59, 59, 999);

      const isInDateRange = registerDate >= startDate && registerDate <= endDate;

      // 2. 검색어 필터링 (검색 버튼으로 적용)
      const searchLower = searchParams.searchText.toLowerCase();
      const matchesSearch = !searchParams.searchText || Object.values(item).some(value => 
        String(value).toLowerCase().includes(searchLower)
      );

      // 3. 상태 필터링 (즉시 적용)
      const matchesStatus = !localFilters.usage || item.status === localFilters.usage;
      const matchesDelete = !localFilters.deletion || item.deleteStatus === localFilters.deletion;
      const matchesSms = !localFilters.sms || item.smsStatus === localFilters.sms;

      return isInDateRange && matchesSearch && matchesStatus && matchesDelete && matchesSms;
    });
  };

  // searchParams 또는 localFilters가 변경될 때 데이터 필터링
  useEffect(() => {
    const filtered = filterData(dummyData);
    setFilteredData(filtered);
  }, [searchParams, localFilters]);

  return (
    <>
      <Paper sx={{ p: 2, mb: 2, position: 'relative', overflow: 'visible' }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <Box sx={{ position: 'relative', zIndex: 1 }}>
            <DateRangePicker
              startDate={dateRange.startDate.replace(/-/g, '')}
              endDate={dateRange.endDate.replace(/-/g, '')}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              isSearchSection={true}
            />
          </Box>
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ minWidth: '200px' }}
          />

          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={handleSearch}
            sx={{ minWidth: '100px' }}
          >
            검색
          </Button>

          {/* 기존 필터 그룹들 (즉시 적용) */}
          <FilterGroup title="사용구분">
            <ToggleButtonGroup
              value={localFilters.usage}
              exclusive
              onChange={(e, value) => handleFilterChange('usage', value)}
              size="small"
            >
              <StyledToggleButton value="사용중">사용중</StyledToggleButton>
              <StyledToggleButton value="삭제완료">삭제완료</StyledToggleButton>
            </ToggleButtonGroup>
          </FilterGroup>

          <FilterGroup title="삭제여부">
            <ToggleButtonGroup
              value={localFilters.deletion}
              exclusive
              onChange={(e, value) => handleFilterChange('deletion', value)}
              size="small"
            >
              <StyledToggleButton value="삭제">삭제</StyledToggleButton>
            </ToggleButtonGroup>
          </FilterGroup>

          <FilterGroup title="문자발송여부">
            <ToggleButtonGroup
              value={localFilters.sms}
              exclusive
              onChange={(e, value) => handleFilterChange('sms', value)}
              size="small"
            >
              <StyledToggleButton value="발송하기">발송하기</StyledToggleButton>
              <StyledToggleButton value="발송불가">발송불가</StyledToggleButton>
            </ToggleButtonGroup>
          </FilterGroup>
        </Box>
      </Paper>

      {/* 테이블 */}
      <StyledTableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell className="primary-header">No</StyledTableCell>
              <StyledTableCell className="primary-header">등록일시</StyledTableCell>
              <StyledTableCell className="primary-header">다음결제일</StyledTableCell>
              <StyledTableCell className="primary-header">중지일시</StyledTableCell>
              <StyledTableCell className="primary-header">사용구분</StyledTableCell>
              <StyledTableCell className="primary-header">삭제여부</StyledTableCell>
              <StyledTableCell className="primary-header bg-green">문자발송여부</StyledTableCell>
              <StyledTableCell className="primary-header bg-green">응답결과</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">인증타입</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">PG</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">ID</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">상호명</StyledTableCell>
              <StyledTableCell className="primary-header bg-blue">등록명</StyledTableCell>
              <StyledTableCell className="primary-header bg-orange">결제금액</StyledTableCell>
              <StyledTableCell className="primary-header bg-orange">카드번호</StyledTableCell>
              <StyledTableCell className="primary-header bg-orange">유효기간</StyledTableCell>
              <StyledTableCell className="primary-header">고객명</StyledTableCell>
              <StyledTableCell className="primary-header">비밀번호</StyledTableCell>
              <StyledTableCell className="primary-header">휴대폰번호</StyledTableCell>
              <StyledTableCell className="primary-header">이메일</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{row.registerDate}</StyledTableCell>
                <StyledTableCell>{row.nextPaymentDate}</StyledTableCell>
                <StyledTableCell>{row.stopDate}</StyledTableCell>
                <StyledTableCell>
                  <StatusChip className={row.status === '사용중' ? 'active' : 'inactive'}>
                    {row.status}
                  </StatusChip>
                </StyledTableCell>
                <StyledTableCell>
                  {row.deleteStatus === '삭제' ? (
                    <StatusChip className="delete">{row.deleteStatus}</StatusChip>
                  ) : row.deleteStatus}
                </StyledTableCell>
                <StyledTableCell className="bg-green" align="center">
                  <StatusChip className={row.smsStatus === '발송하기' ? 'sms-available' : 'sms-unavailable'}>
                    {row.smsStatus}
                  </StatusChip>
                </StyledTableCell>
                <StyledTableCell className="bg-green" align="center">{row.result}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="center">{row.authType}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="center">{row.pgName}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="center">{row.merchantId}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="left">{row.merchantName}</StyledTableCell>
                <StyledTableCell className="bg-blue" align="center">{row.registerName}</StyledTableCell>
                <StyledTableCell className="bg-orange" align="right">{row.amount}</StyledTableCell>
                <StyledTableCell className="bg-orange" align="center">{row.cardNumber}</StyledTableCell>
                <StyledTableCell className="bg-orange" align="center">{row.validThru}</StyledTableCell>
                <StyledTableCell align="center">{row.customerName}</StyledTableCell>
                <StyledTableCell align="center">{row.password}</StyledTableCell>
                <StyledTableCell align="center">{row.phone}</StyledTableCell>
                <StyledTableCell align="left">{row.email}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>
    </>
  );
}

export default RegularPaymentTable;
