import React, { useState } from "react";
import { Table, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { styled } from '@mui/material/styles';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Box, Switch } from '@mui/material';

// 테이블 스타일
const StyledTable = styled(Table)`
  th, td {
    text-align: center;
    vertical-align: middle !important;
    padding: 12px 8px !important;
  }
  
  th:nth-child(2), 
  td:nth-child(2) {
    text-align: left;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

// 메뉴 On/Off 컴포넌트
function MenuOnOff() {
  const [menuItems, setMenuItems] = useState([
    { no: 1, name: "결제내역", defaultMaster: true, defaultBranch: true, defaultSub: true },
    { no: 2, name: "정기결제 내역", defaultMaster: true, defaultBranch: true, defaultSub: true },
    { no: 3, name: "실패내역", defaultMaster: true, defaultBranch: false, defaultSub: false },
    { no: 4, name: "결제내역 집계", defaultMaster: true, defaultBranch: true, defaultSub: true },
    { no: 5, name: "세금계산서", defaultMaster: true, defaultBranch: true, defaultSub: true },
    { no: 6, name: "취소요청 내역", defaultMaster: true, defaultBranch: false, defaultSub: true },
    { no: 7, name: "취소완료 내역", defaultMaster: false, defaultBranch: false, defaultSub: false },
    { no: 8, name: "입/취소 활동등록", defaultMaster: false, defaultBranch: false, defaultSub: false },
    { no: 9, name: "송금하기", defaultMaster: false, defaultBranch: false, defaultSub: false },
    { no: 10, name: "정산 달력", defaultMaster: false, defaultBranch: false, defaultSub: false },
  ]);

  // 스위치 변경 핸들러
  const handleSwitchChange = (itemNo, type, value) => {
    const updatedItems = menuItems.map(item => {
      if (item.no === itemNo) {
        return { ...item, [type]: value }; // 해당 항목의 상태 업데이트
      }
      return item;
    });
    setMenuItems(updatedItems); // 상태 업데이트
    console.log(`Item ${itemNo} ${type} changed to: ${value}`);
  };

  // 메인 코드
  return (
    <div className="content">
      <Card>
        <CardHeader>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ToggleOnIcon sx={{ color: '#2e7d32', fontSize: 28 }} />
            <CardTitle tag="h4">메뉴 On/Off 설정</CardTitle>
          </Box>
        </CardHeader>
        <CardBody>
          <StyledTable responsive>
            <thead className="text-primary">
              <tr>
                <th style={{width: '5%'}}>No</th>
                <th style={{width: '25%'}}>메뉴</th>
                <th style={{width: '23%'}}>마스터 ON/OFF</th>
                <th style={{width: '23%'}}>지사 ON/OFF</th>
                <th style={{width: '24%'}}>총판이하 ON/OFF</th>
              </tr>
            </thead>
            <tbody>
              {menuItems.map((item) => (
                <tr key={item.no}>
                  <td>{item.no}</td>
                  <td>{item.name}</td>
                  <td>
                    <Switch 
                      checked={item.defaultMaster}
                      onChange={(e) => handleSwitchChange(item.no, 'defaultMaster', e.target.checked)}
                    />
                  </td>
                  <td>
                    <Switch 
                      checked={item.defaultBranch}
                      onChange={(e) => handleSwitchChange(item.no, 'defaultBranch', e.target.checked)}
                    />
                  </td>
                  <td>
                    <Switch 
                      checked={item.defaultSub}
                      onChange={(e) => handleSwitchChange(item.no, 'defaultSub', e.target.checked)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </CardBody>
      </Card>
    </div>
  );
}

export default MenuOnOff;