import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
  IconButton,
  Tooltip,
  TablePagination,
  Typography,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import OptionButton from '../../Common/ManagementData/OptionButton';
import MerchantStatusIcons from '../../Common/MerchantStatusIcons';
import BlockIcon from '@mui/icons-material/Block';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MerchantIcons from '../../Common/MerchantIcons';
import MerchantModalOne from '../../Common/ManagementData/MerchantModalOne';
import MerchantInput from './MerchantInput/MerchantInput';

// 스타일 컴포넌트
const StyledTableWrapper = styled(Box)({
  overflowX: 'auto',
  width: '100%',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
  }
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: '#1976d2',
    color: '#ffffff',
    textAlign: 'center',
    height: '32px',
    padding: '4px 8px',

    // 헤더 체크박스 셀 스타일
    '& .MuiCheckbox-root': {
      color: '#ffffff',
      padding: '4px',
      '&.Mui-checked': {
        color: '#ffffff',
      },
      '&.MuiCheckbox-indeterminate': {
        color: '#ffffff',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
      }
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(even)': {
    backgroundColor: '#f8f9fa',
  },
  '&:hover': {
    backgroundColor: '#e3f2fd',
  },
  '& td': {
    height: '32px',
  }
});

// 테이블 페이지네이션 스타일
const StyledTablePagination = styled(TablePagination)({
  '.MuiTablePagination-select': {
    fontSize: '0.813rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.813rem'
  }
});

// 더미 데이터 생성 함수
const generateDummyData = (count = 10) => {
  const pgTypes = ['KCP', 'NICE페이먼츠', 'KG이니시스'];
  return Array(count).fill(null).map((_, index) => ({
    id: index + 1,
    pg: pgTypes[Math.floor(Math.random() * pgTypes.length)],
    businessType: ['본사', '일반'][Math.floor(Math.random() * 2)],
    replacementAccount: `ACC${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
    loginId: `merchant${String(index + 1).padStart(3, '0')}`,
    merchantName: `가맹점${index + 1}`,
    mid: `MID${String(Math.floor(Math.random() * 10000)).padStart(4, '0')}`,
    tid: `TID${String(Math.floor(Math.random() * 10000)).padStart(4, '0')}`,
    businessStatus: ['사업자', '개인'][Math.floor(Math.random() * 2)],
    taxType: ['과세', '원천'][Math.floor(Math.random() * 2)],
    paymentMethod: ['신용카드', '계좌이체', '가상계좌'][Math.floor(Math.random() * 3)],
    authMethod: ['휴대폰', '카드', '공인인증서'][Math.floor(Math.random() * 3)],
    cancelAuth: ['Y', 'N'][Math.floor(Math.random() * 2)],
    settlement: ['자동', '수동'][Math.floor(Math.random() * 2)],
    wallet: ['사용', '미사용'][Math.floor(Math.random() * 2)],
    withdrawalDelay: `${Math.floor(Math.random() * 7)}일`,
    settlementStatus: ['정상', '보류'][Math.floor(Math.random() * 2)],
    performanceSms: ['Y', 'N'][Math.floor(Math.random() * 2)],
    feeRate: (Math.random() * 3 + 1).toFixed(2),
    depositRate: (Math.random() * 10).toFixed(1),
    point: ['적립', '미적립'][Math.floor(Math.random() * 2)],
    limitPerCase: `${Math.floor(Math.random() * 1000000)}원`,
    installment: ['가능', '불가능'][Math.floor(Math.random() * 2)],
    frequencyLimit: `${Math.floor(Math.random() * 100)}/일`,
    walletFixed: `${Math.floor(Math.random() * 10000)}원`,
    walletRate: `${(Math.random() * 5).toFixed(1)}%`,
    walletLimit: `${Math.floor(Math.random() * 1000000)}원`,
    sales1: `영업${Math.floor(Math.random() * 100)}`,
    sales2: `영업${Math.floor(Math.random() * 100)}`,
    sales3: `영업${Math.floor(Math.random() * 100)}`,
    agency: `대리점${Math.floor(Math.random() * 100)}`,
    retail: `리테일${Math.floor(Math.random() * 100)}`,
    branch: `지사${Math.floor(Math.random() * 100)}`,
    distributor: `총판${Math.floor(Math.random() * 100)}`,
    master: `마스터${Math.floor(Math.random() * 100)}`,
    registDate: new Date(Date.now() - Math.floor(Math.random() * 10000000000)).toISOString().split('T')[0],
    modifyDate: new Date(Date.now() - Math.floor(Math.random() * 1000000000)).toISOString().split('T')[0],
    workerId: `admin${String(Math.floor(Math.random() * 100)).padStart(2, '0')}`
  }));
};

// 테이블 헤더 정의
const columns = [
  { id: 'select', label: '선택', width: 40 },
  { id: 'no', label: 'No', width: 40 },
  { id: 'detail', label: '상세', width: 40 },
  { id: 'pg', label: 'PG', width: 80 },
  { id: 'businessType', label: '본사/일반', width: 70 },
  { id: 'replacementAccount', label: '치환계정', width: 80 },
  { id: 'loginId', label: '로그인 아이디', width: 100 },
  { id: 'merchantName', label: '가맹점명', width: 120 },
  { id: 'mid', label: 'MID', width: 80 },
  { id: 'tid', label: 'TID', width: 80 },
  { id: 'businessStatus', label: '사업자/개인', width: 80 },
  { id: 'taxType', label: '과세/원천', width: 70 },
  { id: 'paymentMethod', label: '결제수단', width: 80 },
  { id: 'authMethod', label: '인증방식', width: 80 },
  { id: 'cancelAuth', label: '취소권한', width: 70 },
  { id: 'settlement', label: '정산', width: 60 },
  { id: 'wallet', label: '월렛', width: 60 },
  { id: 'withdrawalDelay', label: '출금딜레이', width: 80 },
  { id: 'settlementStatus', label: '정산상태', width: 70 },
  { id: 'performanceSms', label: '실적문자', width: 70 },
  { id: 'feeRate', label: '수수료율', width: 70 },
  { id: 'depositRate', label: '보증금율', width: 70 },
  { id: 'point', label: '포인트', width: 60 },
  { id: 'limitPerCase', label: '건한도', width: 80 },
  { id: 'installment', label: '할부', width: 60 },
  { id: 'frequencyLimit', label: '횟수제한', width: 80 },
  { id: 'walletFixed', label: '월렛정액', width: 80 },
  { id: 'walletRate', label: '월렛정률', width: 80 },
  { id: 'walletLimit', label: '월렛제한', width: 80 },
  { id: 'sales1', label: '영업1', width: 80 },
  { id: 'sales2', label: '영업2', width: 80 },
  { id: 'sales3', label: '영업3', width: 80 },
  { id: 'agency', label: '대리점', width: 80 },
  { id: 'retail', label: '리테일', width: 80 },
  { id: 'branch', label: '지사', width: 80 },
  { id: 'distributor', label: '총판', width: 80 },
  { id: 'master', label: '마스터', width: 80 },
  { id: 'registDate', label: '등록일', width: 80 },
  { id: 'modifyDate', label: '수정일', width: 80 },
  { id: 'workerId', label: '작업자 아이디', width: 100 }
];

// 상태값 관리 및 데이터 업데이트 함수
function MerchantManageTables({ onDataUpdate }) {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  // getAllMerchantData 함수 추가
  const getAllMerchantData = useCallback(() => {
    return merchants;
  }, [merchants]);

  useEffect(() => {
    const merchantData = generateDummyData(100);
    setMerchants(merchantData);
  }, []);

  // 데이터가 변경될 때마다 상위 컴포넌트에 전달
  useEffect(() => {
    if (onDataUpdate && merchants.length > 0) {
      onDataUpdate({
        data: merchants,
        getAllData: getAllMerchantData
      });
    }
  }, [merchants, getAllMerchantData, onDataUpdate]);

  // 전체 선택 함수
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedMerchants(merchants.map(merchant => merchant.id));
    } else {
      setSelectedMerchants([]);
    }
  };

  // 선택 함수
  const handleSelect = (id) => {
    setSelectedMerchants(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  // 페이지 변경 함수
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // 옵션 클릭 함수
  const handleOptionClick = (optionType, selectedItems) => {
    switch(optionType) {
      case '수정':
        if (selectedItems.length === 1) {
          const selectedMerchant = merchants.find(m => m.id === selectedItems[0]);
          setSelectedMerchant(selectedMerchant);
          setEditModalOpen(true);
        }
        break;
      case '삭제':
        console.log('삭제 처리:', selectedItems);
        break;
      case '복사':
        if (selectedItems.length === 1) {
          console.log('복사 처리:', selectedItems);
        }
        break;
    }
  };

  // 상세 클릭 함수
  const handleDetailClick = (merchant) => {
    setSelectedMerchant(merchant);
    setModalOpen(true);
  };

  // 메인 코드
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ 
        display: 'flex',
        gap: 4,
        backgroundColor: '#f8f9fa',
        padding: '24px',
        borderRadius: '8px',
        mb: 3,
        width: '100%'
      }}>
        <Box sx={{ 
          display: 'flex',
          gap: 3,
          alignItems: 'flex-start'
        }}>
          <MerchantIcons onIconClick={(value) => console.log('Icon clicked:', value)} />
          <Box sx={{ borderLeft: '2px solid #ddd' }} />
          <OptionButton 
            onOptionClick={handleOptionClick}
            selectedItems={selectedMerchants}
          />
        </Box>
      </Box>

      <StyledTableWrapper>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  style={{ minWidth: column.width }}
                >
                  {column.id === 'select' ? (
                    <Checkbox
                      checked={selectedMerchants.length === merchants.length}
                      indeterminate={selectedMerchants.length > 0 && selectedMerchants.length < merchants.length}
                      onChange={handleSelectAll}
                    />
                  ) : (
                    column.label
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {merchants
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((merchant) => (
                <StyledTableRow key={merchant.id}>
                  <StyledTableCell>
                    <Checkbox
                      checked={selectedMerchants.includes(merchant.id)}
                      onChange={() => handleSelect(merchant.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{merchant.id}</StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="상세보기">
                      <IconButton 
                        size="small"
                        onClick={() => handleDetailClick(merchant)}
                      >
                        <VisibilityIcon sx={{ fontSize: 18, color: '#2185d0' }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>{merchant.pg}</StyledTableCell>
                  <StyledTableCell>{merchant.businessType}</StyledTableCell>
                  <StyledTableCell>{merchant.replacementAccount}</StyledTableCell>
                  <StyledTableCell>{merchant.loginId}</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {merchant.merchantName}
                      <MerchantStatusIcons statuses={['block', 'sms', 'recurring']} />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>{merchant.mid}</StyledTableCell>
                  <StyledTableCell>{merchant.tid}</StyledTableCell>
                  <StyledTableCell>{merchant.businessStatus}</StyledTableCell>
                  <StyledTableCell>{merchant.taxType}</StyledTableCell>
                  <StyledTableCell>{merchant.paymentMethod}</StyledTableCell>
                  <StyledTableCell>{merchant.authMethod}</StyledTableCell>
                  <StyledTableCell>{merchant.cancelAuth}</StyledTableCell>
                  <StyledTableCell>{merchant.settlement}</StyledTableCell>
                  <StyledTableCell>{merchant.wallet}</StyledTableCell>
                  <StyledTableCell>{merchant.withdrawalDelay}</StyledTableCell>
                  <StyledTableCell>{merchant.settlementStatus}</StyledTableCell>
                  <StyledTableCell>{merchant.performanceSms}</StyledTableCell>
                  <StyledTableCell>{merchant.feeRate}</StyledTableCell>
                  <StyledTableCell>{merchant.depositRate}%</StyledTableCell>
                  <StyledTableCell>{merchant.point}</StyledTableCell>
                  <StyledTableCell>{merchant.limitPerCase}</StyledTableCell>
                  <StyledTableCell>{merchant.installment}</StyledTableCell>
                  <StyledTableCell>{merchant.frequencyLimit}</StyledTableCell>
                  <StyledTableCell>{merchant.walletFixed}</StyledTableCell>
                  <StyledTableCell>{merchant.walletRate}</StyledTableCell>
                  <StyledTableCell>{merchant.walletLimit}</StyledTableCell>
                  <StyledTableCell>{merchant.sales1}</StyledTableCell>
                  <StyledTableCell>{merchant.sales2}</StyledTableCell>
                  <StyledTableCell>{merchant.sales3}</StyledTableCell>
                  <StyledTableCell>{merchant.agency}</StyledTableCell>
                  <StyledTableCell>{merchant.retail}</StyledTableCell>
                  <StyledTableCell>{merchant.branch}</StyledTableCell>
                  <StyledTableCell>{merchant.distributor}</StyledTableCell>
                  <StyledTableCell>{merchant.master}</StyledTableCell>
                  <StyledTableCell>{merchant.registDate}</StyledTableCell>
                  <StyledTableCell>{merchant.modifyDate}</StyledTableCell>
                  <StyledTableCell>{merchant.workerId}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>
      <StyledTablePagination
        component="div"
        count={merchants.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
        labelDisplayedRows={({ from, to, count }) => 
          `전체 ${count}건 중 ${from}-${to}건`
        }
      />
      <MerchantModalOne
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        merchantData={selectedMerchant}
      />
      {editModalOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'background.paper',
            zIndex: 1300,
          }}
        >
          <Box sx={{ height: '100%' }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              p: 2,
              borderBottom: '1px solid #e0e0e0'
            }}>
              <Button 
                onClick={() => setEditModalOpen(false)}
                variant="outlined"
                size="small"
              >
                닫기
              </Button>
            </Box>
            <MerchantInput merchantData={selectedMerchant} />
          </Box>
        </Box>
      )}
    </Paper>
  );
}

export default MerchantManageTables;
