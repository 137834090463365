import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, Typography, Button, Card, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, 
  IconButton, Tooltip, Paper, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Grid
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import DownloadIcon from '@mui/icons-material/Download';
import ExcelJS from 'exceljs';

// 스타일 컴포넌트
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
  height: 'calc(100vh - 280px)'
}));

const StyledTableContainer = styled(TableContainer)({
  height: 'calc(100vh - 380px)',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

// 테이블 셀 스타일
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  textAlign: 'center',

  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    backgroundColor: '#f7f7f7'
  }
}));

// 테이블 로우 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: '#f5f5f5'
  }
}));

// 초기값 세팅
function PGProviderSettings() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newSetting, setNewSetting] = useState({
    mid: '',
    pg: '',
    pay_flag: '',
    admin_fee: '',
    senior_fee: '',
    key: '',
    inv: '',
    admin: '',
    insertdate: ''
  });
  const [rows, setRows] = useState([
    {
      no: 1,
      mid: 'M230001',
      pg: 'KG이니시스',
      pay_flag: 'Y',
      admin_fee: '3.4',
      senior_fee: '0.2',
      key: 'ST1234567890',
      inv: 'IV1234567890',
      admin: 'admin1',
      insertdate: '2024-01-01 12:00:00'
    },
    {
      no: 2,
      mid: 'M230002',
      pg: '나이스페이',
      pay_flag: 'Y',
      admin_fee: '3.2',
      senior_fee: '0.3',
      key: 'ST9876543210',
      inv: 'IV9876543210',
      admin: 'admin2',
      insertdate: '2024-01-02 14:30:00'
    },
    {
      no: 3,
      mid: 'M230003',
      pg: 'KG모빌리언스',
      pay_flag: 'N',
      admin_fee: '3.6',
      senior_fee: '0.1',
      key: 'ST5432167890',
      inv: 'IV5432167890',
      admin: 'admin1',
      insertdate: '2024-01-03 09:15:00'
    }
  ]);

  const columns = [
    { id: 'No', label: 'No', sortable: true },
    { id: 'MID', label: 'MID', sortable: true },
    { id: 'PG', label: 'PG', sortable: true },
    { id: 'PAY_FLAG', label: 'PAY_FLAG', sortable: true },
    { id: 'ADMIN_FEE', label: 'ADMIN_FEE', sortable: true },
    { id: 'SENIOR_FEE', label: 'SENIOR_FEE', sortable: true },
    { id: 'KEY', label: 'KEY', sortable: true },
    { id: 'INV', label: 'INV', sortable: true },
    { id: 'ADMIN', label: 'ADMIN', sortable: true },
    { id: 'INSERTDATE', label: 'INSERTDATE', sortable: true },
    { id: 'delete', label: '삭제', sortable: false }
  ];

  const [selectedPG, setSelectedPG] = useState('');
  const [filteredData, setFilteredData] = useState(rows);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      console.log("파일이 선택되었습니다:", file.name);
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCancelFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    fileInputRef.current.value = '';
  };

  // 컬럼 매핑 정의
  const columnMapping = {
    'No': 'no',
    'MID': 'mid',
    'PG': 'pg',
    'PAY_FLAG': 'pay_flag',
    'ADMIN_FEE': 'admin_fee',
    'SENIOR_FEE': 'senior_fee',
    'KEY': 'key',
    'INV': 'inv',
    'ADMIN': 'admin',
    'INSERTDATE': 'insertdate'
  };

  // 엑셀 다운로드 설정
  const excelDownloadSettings = {
    fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    worksheetName: 'PG사별 세팅',
    fileNamePrefix: 'PG사별세팅_'
  };

  const handlePGSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedPG(selectedValue);
    
    if (selectedValue === '') {
      setFilteredData(rows);
    } else {
      const filtered = rows.filter(row => row.PG === selectedValue);
      setFilteredData(filtered);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...filteredData].sort((a, b) => {
      if (['No', 'ADMIN_FEE', 'SENIOR_FEE'].includes(property)) {
        return isAsc 
          ? parseFloat(b[property]) - parseFloat(a[property])
          : parseFloat(a[property]) - parseFloat(b[property]);
      }
      return isAsc
        ? b[property].localeCompare(a[property])
        : a[property].localeCompare(b[property]);
    });

    setFilteredData(sortedData);
  };

  // 모달 핸들러
  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setNewSetting({
      mid: '',
      pg: '',
      pay_flag: '',
      admin_fee: '',
      senior_fee: '',
      key: '',
      inv: '',
      admin: '',
      insertdate: ''
    });
  };

  const handleAddSubmit = () => {
    console.log('새로운 PG 세팅:', newSetting);
    handleAddClose();
  };

  // 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );
    const getTextWidth = (text) => {
      return Array.from(text).reduce((width, char) => {
        return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
      }, 0);
    };
    const maxWidth = Math.max(
      getTextWidth(header),
      ...data.map(row => getTextWidth(String(row[fieldName] || '')))
    );
    return maxWidth + Math.ceil(maxWidth * 0.2);
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    try {
      if (!filteredData || filteredData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(excelDownloadSettings.worksheetName);

      // 기본 셀 스타일
      const defaultCellStyle = {
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        },
        font: {
          size: 10,
          name: '맑은 고딕'
        }
      };

      // 헤더 셀 스타일
      const headerCellStyle = {
        ...defaultCellStyle,
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF1976D2' }
        },
        font: {
          size: 10,
          color: { argb: 'FFFFFFFF' },
          bold: true,
          name: '맑은 고딕'
        }
      };

      // 헤더 추가
      const headers = Object.keys(columnMapping);
      worksheet.addRow(headers);

      // 헤더 스타일 적용
      worksheet.getRow(1).height = 30;
      worksheet.getRow(1).eachCell((cell) => {
        Object.assign(cell, headerCellStyle);
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      // 데이터 추가
      filteredData.forEach(row => {
        const rowData = headers.map(header => row[columnMapping[header]]);
        worksheet.addRow(rowData);
      });

      // 데이터 행 스타일 적용
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          row.height = 25;
          row.eachCell(cell => {
            Object.assign(cell, defaultCellStyle);
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          });
        }
      });

      // 컬럼 너비 설정
      headers.forEach((header, index) => {
        const column = worksheet.getColumn(index + 1);
        column.width = getMaxColumnWidth(filteredData, header);
      });

      // 파일 다운로드
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: excelDownloadSettings.fileType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${excelDownloadSettings.fileNamePrefix}${new Date().toISOString().split('T')[0]}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('엑셀 다운로드 오류:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 상단 버튼 설정
  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2',
      onClick: () => {/* Copy 로직 */}
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32',
      onClick: handleExcelDownload
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2',
      onClick: () => {/* Print 로직 */}
    }
  ];

  // 메인 코드
  return (
    <>
      <Box sx={{ p: 3 }}>
        <Paper elevation={3}>
          <Box sx={{ 
            p: 2, 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #e0e0e0'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccountBalanceIcon sx={{ color: '#ed6c02' }} />
              <Typography variant="h6">PG사별 세팅</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                accept="image/*"
              />
              {selectedFile && (
                <Box sx={{ 
                  position: 'relative',
                  width: '40px',
                  height: '40px'
                }}>
                  <img 
                    src={previewUrl}
                    alt="preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '4px'
                    }}
                  />
                  <IconButton
                    onClick={handleCancelFile}
                    sx={{
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#fff',
                      border: '1px solid #d32f2f',
                      color: '#d32f2f',
                      padding: '2px',
                      '&:hover': {
                        backgroundColor: '#d32f2f10',
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} size="xs" />
                  </IconButton>
                </Box>
              )}
              <Tooltip title="파일 선택">
                <IconButton
                  onClick={handleFileButtonClick}
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #1976d2',
                    color: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1976d210',
                    }
                  }}
                >
                  <UploadFileIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="엑셀 양식 다운로드">
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#e8f5e9',
                    }
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleAddClick}
              >
                등록하기
              </Button>
            </Box>
          </Box>

          <Box sx={{ p: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              gap: 1,
              mb: 2 
            }}>
              {buttons.map((button, index) => (
                <Tooltip key={index} title={button.label}>
                  <IconButton
                    onClick={button.onClick}
                    sx={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: `1px solid ${button.color}`,
                      color: button.color,
                      '&:hover': {
                        backgroundColor: `${button.color}10`,
                      }
                    }}
                  >
                    {button.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </Box>

            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell>MID</StyledTableCell>
                    <StyledTableCell>PG</StyledTableCell>
                    <StyledTableCell>PAY_FLAG</StyledTableCell>
                    <StyledTableCell>ADMIN_FEE</StyledTableCell>
                    <StyledTableCell>SENIOR_FEE</StyledTableCell>
                    <StyledTableCell>KEY</StyledTableCell>
                    <StyledTableCell>INV</StyledTableCell>
                    <StyledTableCell>ADMIN</StyledTableCell>
                    <StyledTableCell>INSERTDATE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.no}</StyledTableCell>
                      <StyledTableCell>{row.mid}</StyledTableCell>
                      <StyledTableCell>{row.pg}</StyledTableCell>
                      <StyledTableCell>{row.pay_flag}</StyledTableCell>
                      <StyledTableCell>{row.admin_fee}</StyledTableCell>
                      <StyledTableCell>{row.senior_fee}</StyledTableCell>
                      <StyledTableCell>{row.key}</StyledTableCell>
                      <StyledTableCell>{row.inv}</StyledTableCell>
                      <StyledTableCell>{row.admin}</StyledTableCell>
                      <StyledTableCell>{row.insertdate}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>

      {/* PG사 세팅 추가 모달 */}
      <Dialog 
        open={addModalOpen} 
        onClose={handleAddClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: '800px',
            minHeight: '550px'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#252631',
          padding: '16px 24px'
        }}>
          세트값 관리
        </DialogTitle>
        <DialogContent sx={{ 
          mt: 3,  // 상단 여백 증가
          p: 3,
          '& .MuiFormControl-root': {
            minHeight: '75px'  // 필드 간격 증가
          }
        }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <br/>
              <TextField
                fullWidth
                size="small"
                label="MID"
                value={newSetting.mid}
                onChange={(e) => setNewSetting({...newSetting, mid: e.target.value})}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <br/>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ backgroundColor: 'white', px: 1, mt: -0.5 }}>PG</InputLabel>
                <Select
                  value={newSetting.pg}
                  label="PG"
                  onChange={(e) => setNewSetting({...newSetting, pg: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value="SPC">SPC</MenuItem>
                  {/* Add other PG options */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel sx={{ backgroundColor: 'white', px: 1, mt: -0.5 }}>PAY_FLAG</InputLabel>
                <Select
                  value={newSetting.pay_flag}
                  label="PAY_FLAG"
                  onChange={(e) => setNewSetting({...newSetting, pay_flag: e.target.value})}
                  sx={{ height: '40px' }}
                >
                  <MenuItem value="구인증">구인증</MenuItem>
                  {/* Add other PAY_FLAG options */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="ADMIN_FEE"
                value={newSetting.admin_fee}
                onChange={(e) => setNewSetting({...newSetting, admin_fee: e.target.value})}
                sx={{ 
                  '& .MuiInputBase-root': { height: '40px' },
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="SENIOR_FEE"
                value={newSetting.senior_fee}
                onChange={(e) => setNewSetting({...newSetting, senior_fee: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="KEY"
                value={newSetting.key}
                onChange={(e) => setNewSetting({...newSetting, key: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="INV"
                value={newSetting.inv}
                onChange={(e) => setNewSetting({...newSetting, inv: e.target.value})}
                sx={{
                  '& .MuiInputLabel-root': { 
                    backgroundColor: 'white',
                    px: 1,
                    mt: -0.5
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2,
          borderTop: '1px solid #e0e0e0'
        }}>
          <Button 
            onClick={handleAddClose}
            variant="outlined" 
            color="inherit"
            sx={{ mr: 1, minWidth: '80px', height: '36px' }}
          >
            닫기
          </Button>
          <Button 
            onClick={handleAddSubmit}
            variant="contained" 
            color="primary"
            sx={{ minWidth: '80px', height: '36px' }}
          >
            적용
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PGProviderSettings;
