import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

// 취소 옵션 컴포넌트(아이콘 + 텍스트) case문 따라 조건 처리
const CancelOptions = ({ onCancelOption, selectedItems = [] }) => {
  const handleCancelOption = (optionType) => {
    console.log(`선택된 항목 수: ${selectedItems.length}, 클릭한 버튼: ${optionType}`);
    
    switch(optionType) {
      case '강제취소':
        if (selectedItems.length === 0) {
          alert('취소할 항목을 선택해주세요.');
          return;
        }
        break;
      
      case 'RM':
        if (selectedItems.length === 0) {
          alert('RM 문의할 항목을 선택해주세요.');
          return;
        }
        if (selectedItems.length > 1) {
          alert('RM 문의는 한 건만 선택 가능합니다.');
          return;
        }
        break;
      
      case '상계':
        if (selectedItems.length === 0) {
          alert('상계 처리할 항목을 선택해주세요.');
          return;
        }
        if (selectedItems.length > 1) {
          alert('상계 처리를 위해 한 건만 선택해주세요.');
          return;
        }
        break;
    }
    
    onCancelOption?.(optionType, selectedItems);
  };

  const buttons = [
    { 
      label: '강제취소', 
      icon: <CancelIcon />,
      color: '#dc3545',
      onClick: () => handleCancelOption('강제취소')
    },
    { 
      label: 'RM', 
      icon: <SupervisorAccountIcon />,
      color: '#ffa000',
      onClick: () => handleCancelOption('RM')
    },
    { 
      label: '상계', 
      icon: <CompareArrowsIcon />,
      color: '#2196f3',
      onClick: () => handleCancelOption('상계')
    }
  ];

  return (
    <Box>
      <Typography 
        variant="subtitle1" 
        sx={{ 
          fontSize: '0.875rem', 
          fontWeight: 600,
          color: '#495057',
          mb: 1.5,
          pl: '4px'
        }}
      >
        취소 옵션
      </Typography>
      <Box sx={{ 
        display: 'flex',
        gap: 1
      }}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            startIcon={button.icon}
            onClick={button.onClick}
            sx={{
              bgcolor: '#fff',
              color: button.color,
              border: `1px solid ${button.color}`,
              fontSize: '0.75rem',
              height: '32px',
              padding: '4px 12px',
              minWidth: 'auto',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: button.color,
                color: '#fff',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
              },
              '& .MuiButton-startIcon': {
                marginRight: '4px',
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem'
                }
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default CancelOptions;