import React, { useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow 
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

// BonNote PC버전 스타일 컴포넌트 적용
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px',
      
      '&[data-field="amount"]': {
        height: '40px',
        lineHeight: '40px'
      }
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 래퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#a4c9ef',
    zIndex: 3
  }
});

// 차트 래퍼 스타일 컴포넌트 추가
const ChartWrapper = styled(Box)({
  width: '100%',
  mt: 3,
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 메인 함수
function IlbyeolData({ searchDates }) {
  // 더미 데이터 값 범위 조정
  const dummyData = {
    '거래건수': [150000, -20000, 130000],
    '01일': [5000000, -5000000, 45000000],
    '02일': [4800000, -400000, 4400000],
    '03일': [5200000, -600000, 4600000],
    '04일': [21000000, -1800000, 19200000],
    '05일': [2800000, -250000, 2550000],
    '06일': [1900000, -120000, 1780000],
    '07일': [2300000, -220000, 2080000],
    '08일': [2700000, -240000, 2460000],
    '09일': [2400000, -200000, 2200000],
    '10일': [2900000, -260000, 2640000],
    '11일': [2600000, -230000, 2370000],
    '12일': [2200000, -190000, 2010000],
    '13일': [2000000, -170000, 1830000],
    '14일': [2400000, -210000, 2190000],
    '15일': [2800000, -240000, 2560000],
    '16일': [3100000, -280000, 2820000],
    '17일': [2700000, -230000, 2470000],
    '18일': [2500000, -220000, 2280000],
    '19일': [2300000, -200000, 2100000],
    '20일': [2600000, -230000, 2370000],
    '21일': [2900000, -260000, 2640000],
    '22일': [2700000, -240000, 2460000],
    '23일': [2400000, -210000, 2190000],
    '24일': [2200000, -190000, 2010000],
    '25일': [2500000, -220000, 2280000],
    '26일': [2800000, -250000, 2550000],
    '27일': [3000000, -270000, 2730000],
    '28일': [2600000, -230000, 2370000]
  };

  // 필터링된 데이터 계산 (표용)
  const filteredData = useMemo(() => {
    const entries = Object.entries(dummyData);
    const startDate = dayjs(searchDates.startDate);
    const endDate = dayjs(searchDates.endDate);

    const filteredEntries = entries.reduce((acc, [key, value]) => {
      if (key === '거래건수') {
        acc[key] = value;  // 거래건수는 항상 포함
        return acc;
      }

      const day = parseInt(key);
      const currentDate = startDate.date(day);

      if (currentDate.isBetween(startDate, endDate, 'day', '[]')) {
        acc[key] = value;
      }

      return acc;
    }, {});

    return filteredEntries;
  }, [searchDates, dummyData]);

  // 차트용 데이터 (일별 데이터만)
  const chartData = useMemo(() => {
    const dailyData = Object.entries(filteredData)
      .filter(([key]) => key !== '거래건수')
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    return dailyData;
  }, [filteredData]);

  // 차트 옵션 수정
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    legend: {
      data: ['결제금액', '취소금액', '실 매출금액'],
      top: 0
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: Object.keys(chartData),  // 차트용 데이터 사용
      axisLabel: {
        rotate: 45
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => {
          return (value / 1000000).toFixed(1) + 'M';
        }
      }
    },
    series: [
      {
        name: '결제금액',
        type: 'line',
        data: Object.values(chartData).map(values => values[0]),  // 차트용 데이터 사용
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#5470c6'
        },
        lineStyle: {
          width: 2
        },
        smooth: true
      },
      {
        name: '취소금액',
        type: 'line',
        data: Object.values(chartData).map(values => Math.abs(values[1])),  // 차트용 데이터 사용
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#ff9933'
        },
        lineStyle: {
          width: 2
        },
        smooth: true
      },
      {
        name: '실 매출금액',
        type: 'line',
        data: Object.values(chartData).map(values => values[2]),  // 차트용 데이터 사용
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#36c6d3'
        },
        lineStyle: {
          width: 2
        },
        smooth: true
      }
    ]
  };

  // 선택된 기간의 일별 합계 계산
  const periodTotal = useMemo(() => {
    const dailyData = Object.entries(filteredData)
      .filter(([key]) => key !== '거래건수')
      .map(([_, values]) => values);

    if (dailyData.length === 0) return [0, 0, 0];

    return dailyData.reduce(
      (acc, curr) => [
        acc[0] + curr[0], // 결제금액 합계
        acc[1] + curr[1], // 취소금액 합계
        acc[2] + curr[2]  // 실 매출금액 합계
      ],
      [0, 0, 0]
    );
  }, [filteredData]);

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>일별 결제금액</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="primary-header"></StyledTableCell>
                  <StyledTableCell className="primary-header">합계</StyledTableCell>
                  <StyledTableCell className="primary-header">거래건수</StyledTableCell>
                  {Object.keys(filteredData)
                    .filter(key => key !== '거래건수')
                    .map((day) => (
                      <StyledTableCell key={day} className="secondary-header">
                        {day}
                      </StyledTableCell>
                    ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">결제금액</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {periodTotal[0].toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>
                    {dummyData['거래건수'][0].toLocaleString()}
                  </StyledTableCell>
                  {Object.entries(filteredData)
                    .filter(([key]) => key !== '거래건수')
                    .map(([_, values], index) => (
                      <StyledTableCell key={index} sx={{ textAlign: 'right' }}>
                        {values[0].toLocaleString()}
                      </StyledTableCell>
                    ))}
                </StyledTableRow>
                <StyledTableRow className="negative-amount">
                  <StyledTableCell className="sticky-column">취소금액</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {periodTotal[1].toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>
                    {dummyData['거래건수'][1].toLocaleString()}
                  </StyledTableCell>
                  {Object.entries(filteredData)
                    .filter(([key]) => key !== '거래건수')
                    .map(([_, values], index) => (
                      <StyledTableCell key={index} sx={{ textAlign: 'right' }}>
                        {values[1].toLocaleString()}
                      </StyledTableCell>
                    ))}
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column" sx={{ fontWeight: 'bold' }}>실 매출금액</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {periodTotal[2].toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {dummyData['거래건수'][2].toLocaleString()}
                  </StyledTableCell>
                  {Object.entries(filteredData)
                    .filter(([key]) => key !== '거래건수')
                    .map(([_, values], index) => (
                      <StyledTableCell key={index} sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {values[2].toLocaleString()}
                      </StyledTableCell>
                    ))}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>

        <ChartWrapper>
          <Box sx={{ minWidth: window.innerWidth <= 600 ? '1200px' : 'auto' }}>
            <ReactECharts option={option} style={{ height: '400px' }} />
          </Box>
        </ChartWrapper>
      </Box>
    </Paper>
  );
}

export default IlbyeolData;
