import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  Button
} from '@mui/material';
import DnsIcon from '@mui/icons-material/Dns';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import ExcelJS from 'exceljs';
import SearchIcon from '@mui/icons-material/Search';

const ServerResources = () => {
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [searchText, setSearchText] = useState('');

  // 더미 데이터
  const [rows] = useState([
    {
      no: 7045,
      server_ip: '49.247.25.17',
      total_memory: '7.76',
      used_memory: '1.00',
      available_memory: '6.76',
      cpu_usage: '0.11',
      total_disk: '24.05',
      used_disk: '5.87',
      network_rx: '6256482537',
      network_tx: '2517687237',
      check_time: '2024-12-06 14:36:11'
    },
    {
      no: 7044,
      server_ip: '49.247.25.17',
      total_memory: '7.76',
      used_memory: '0.94',
      available_memory: '6.82',
      cpu_usage: '0.11',
      total_disk: '24.05',
      used_disk: '5.87',
      network_rx: '6254399113',
      network_tx: '2516712076',
      check_time: '2024-12-06 13:36:11'
    },
    // ... 더 많은 더미 데이터 추가 가능
  ]);

// 엑셀 다운로드 핸들러
const handleExcelDownload = async () => {
  try {
    if (!rows || rows.length === 0) {
      alert('다운로드할 데이터가 없습니다.');
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(excelDownloadSettings.worksheetName);

    worksheet.columns = headers.map(header => ({
      header,
      width: getMaxColumnWidth(rows, header),
      style: { 
        alignment: { 
          horizontal: columnAlignments[header],
          vertical: 'middle'
        }
      }
    }));

    worksheet.addRows(rows);

    worksheet.eachRow((row, rowNumber) => {
      row.height = defaultRowHeight;
      row.eachCell(cell => Object.assign(cell, defaultCellStyle));
      
      if (rowNumber === 1) {
        row.height = headerCellStyle.height;
        row.eachCell(cell => Object.assign(cell, headerCellStyle));
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: excelDownloadSettings.fileType });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${excelDownloadSettings.fileNamePrefix}${new Date().toISOString().split('T')[0]}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);

  } catch (error) {
    console.error('엑셀 다운로드 오류:', error);
    alert('엑셀 다운로드 중 오류가 발생했습니다.');
  }
};

  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2',
      onClick: () => console.log('Copy clicked')
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32',
      onClick: handleExcelDownload
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2',
      onClick: () => console.log('Print clicked')
    }
  ];

  // 테이블 스타일 추가
  const StyledTableWrapper = styled(Box)({
    overflow: 'auto',
    position: 'relative',
    maxWidth: '100%',
    '& .sticky-column': {
      position: 'sticky',
      left: 0,
      backgroundColor: '#fff',
      zIndex: 2,
      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '4px',
        background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
      }
    },
    '@media (max-width: 600px)': {
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      '&::-webkit-scrollbar': {
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }
  });

  // 테이블 셀 스타일 추가
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '4px 8px',
    fontSize: '0.813rem',
    // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
    letterSpacing: '-0.03em',
    borderBottom: '1px solid #e9ecef',
    borderRight: '1px solid #e9ecef',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    '@media (max-width: 600px)': {
      padding: '4px 8px'
    }
  }));

  // 정렬 핸들러 추가
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = React.useMemo(() => {
    const comparator = (a, b) => {
      if (order === 'asc') {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    };
    return [...rows].sort(comparator);
  }, [rows, order, orderBy]);

  // 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );

    const getTextWidth = (text) => {
      return Array.from(text).reduce((width, char) => {
        return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
      }, 0);
    };

    const maxWidth = Math.max(
      getTextWidth(header),
      ...data.map(row => getTextWidth(String(row[fieldName] || '')))
    );

    return maxWidth + Math.ceil(maxWidth * 0.2);
  };

  // 컬럼 정렬 설정
  const columnAlignments = {
    'No': 'center',
    '서버 IP': 'center',
    '총 메모리': 'right',
    '사용 중 메모리': 'right',
    '사용 가능한 메모리': 'right',
    'CPU 사용률': 'right',
    '총 디스크 크기': 'right',
    '사용중 디스크': 'right',
    '네트워크 전송 바이트': 'right',
    '네트워크 수신 바이트': 'right',
    '수집 시간': 'center'
  };

  // 엑셀 스타일 설정
  const defaultCellStyle = {
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    },
    font: { 
      size: 10, 
      name: '맑은 고딕' 
    }
  };

  const headerCellStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF1976D2' }
    },
    font: {
      size: 10,
      color: { argb: 'FFFFFFFF' },
      bold: true,
      name: '맑은 고딕'
    },
    height: 30
  };

  const defaultRowHeight = 25;

  // 엑셀 다운로드 설정
  const excelDownloadSettings = {
    fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    worksheetName: '서버 리소스 정보',
    fileNamePrefix: '서버_리소스_정보_'
  };

  // 컬럼 매핑 정의
  const columnMapping = {
    'No': 'no',
    '서버 IP': 'server_ip',
    '총 메모리': 'total_memory',
    '사용 중 메모리': 'used_memory',
    '사용 가능한 메모리': 'available_memory',
    'CPU 사용률': 'cpu_usage',
    '총 디스크 크기': 'total_disk',
    '사용중 디스크': 'used_disk',
    '네트워크 전송 바이트': 'network_tx',
    '네트워크 수신 바이트': 'network_rx',
    '수집 시간': 'check_time'
  };

  // 엑셀 헤더 정의
  const headers = [
    'No', '서버 IP', '총 메모리', '사용 중 메모리', '사용 가능한 메모리',
    'CPU 사용률', '총 디스크 크기', '사용중 디스크', 
    '네트워크 전송 바이트', '네트워크 수신 바이트', '수집 시간'
  ];

  

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3}>
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DnsIcon sx={{ color: '#00acc1' }} />
            <Typography variant="h6">서버 리소스 정보</Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            mb: 2,
            alignItems: 'center'
          }}>
            <TextField
              size="small"
              placeholder="검색어를 입력하세요"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ 
                minWidth: '200px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '40px'
                }
              }}
            />
            {/* 검색 버튼 */}
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={() => {/* 검색 로직 구현 */}}
            sx={{ minWidth: '100px' }}
          >
            검색
          </Button>
            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.label}>
                <IconButton
                  onClick={button.onClick}
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>

          <StyledTableWrapper>
            <TableContainer sx={{ 
              width: '100%',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              '&::-webkit-scrollbar': {
                height: '8px'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#555'
                }
              }
            }}>
              <Table sx={{ 
                minWidth: '1200px',
                tableLayout: 'fixed'
              }}>
                <colgroup>
                  <col style={{ width: '70px' }} />  {/* No */}
                  <col style={{ width: '120px' }} />  {/* 서버 IP */}
                  <col style={{ width: '100px' }} />  {/* 총 메모리 */}
                  <col style={{ width: '120px' }} />  {/* 사용 중 메모리 */}
                  <col style={{ width: '140px' }} />  {/* 사용 가능한 메모리 */}
                  <col style={{ width: '100px' }} />  {/* CPU 사용률 */}
                  <col style={{ width: '120px' }} />  {/* 총 디스크 크기 */}
                  <col style={{ width: '120px' }} />  {/* 사용중 디스크 */}
                  <col style={{ width: '150px' }} />  {/* 네트워크 전송 바이트 */}
                  <col style={{ width: '150px' }} />  {/* 네트워크 수신 바이트 */}
                  <col style={{ width: '160px' }} />  {/* 수집 시간 */}
                </colgroup>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                    <StyledTableCell align="center">
                      <TableSortLabel
                        active={orderBy === 'no'}
                        direction={orderBy === 'no' ? order : 'asc'}
                        onClick={() => handleRequestSort('no')}
                      >
                        No
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="center">서버 IP</StyledTableCell>
                    <StyledTableCell align="center">총 메모리</StyledTableCell>
                    <StyledTableCell align="center">사용 중 메모리</StyledTableCell>
                    <StyledTableCell align="center">사용 가능한 메모리</StyledTableCell>
                    <StyledTableCell align="center">CPU 사용률</StyledTableCell>
                    <StyledTableCell align="center">총 디스크 크기</StyledTableCell>
                    <StyledTableCell align="center">사용중 디스크</StyledTableCell>
                    <StyledTableCell align="center">네트워크 전송 바이트</StyledTableCell>
                    <StyledTableCell align="center">네트워크 수신 바이트</StyledTableCell>
                    <StyledTableCell align="center">
                      <TableSortLabel
                        active={orderBy === 'check_time'}
                        direction={orderBy === 'check_time' ? order : 'asc'}
                        onClick={() => handleRequestSort('check_time')}
                      >
                        수집 시간
                      </TableSortLabel>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRows.map((row) => (
                    <TableRow key={row.no}>
                      <StyledTableCell align="center">{row.no}</StyledTableCell>
                      <StyledTableCell align="center">{row.server_ip}</StyledTableCell>
                      <StyledTableCell align="right">{row.total_memory}</StyledTableCell>
                      <StyledTableCell align="right">{row.used_memory}</StyledTableCell>
                      <StyledTableCell align="right">{row.available_memory}</StyledTableCell>
                      <StyledTableCell align="right">{row.cpu_usage}</StyledTableCell>
                      <StyledTableCell align="right">{row.total_disk}</StyledTableCell>
                      <StyledTableCell align="right">{row.used_disk}</StyledTableCell>
                      <StyledTableCell align="right">{row.network_rx}</StyledTableCell>
                      <StyledTableCell align="right">{row.network_tx}</StyledTableCell>
                      <StyledTableCell align="center">{row.check_time}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledTableWrapper>
        </Box>
      </Paper>
    </Box>
  );
};

export default ServerResources;
