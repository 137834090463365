import React, { useState, useEffect } from 'react';
import { Box, Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import QuickMenu from '../../Common/QuickMenu';
import SearchOptions from '../../Common/SearchOptions';
import Notice from '../../Notice/Notice';
import ResultText from '../../ResultText';
import ResultTable from '../../ResultTable';
import TaxITTable from './TaxITTable';
import CurrentLocation from '../../Common/CurrentLocation';

// 메인 함수
function TaxIT() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchPanelOpen, setSearchPanelOpen] = useState(!isMobile);
  const [searchConditions, setSearchConditions] = useState({
    searchText: '',      // 가맹점명, 에이전시명 검색
    taxType: '',         // 과세/비과세
    pgCompany: '',       // PG사
    businessType: '',    // 사업자/개인
    authType: ''         // 인증방식
  });

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
    setSearchConditions({
      searchText: searchParams.searchText || '',
      taxType: searchParams.taxType || '',
      pgCompany: searchParams.pgCompany || '',
      businessType: searchParams.businessType || '',
      authType: searchParams.authType || ''
    });
  };

  // 스타일 정의
  const styles = {
    mainBox: { 
      flexGrow: 1, 
      p: 3, 
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto', 
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    },
    contentBox: {
      width: '100%'
    },
    noticeBox: { 
      width: '100%',
      mb: 2
    },
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  // 메인 코드
  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={styles.contentBox}>
        <Notice />
        <CurrentLocation mainTitle="정산" subTitle="세금계 전산매체" />
        <ResultText />
        <ResultTable />
        <TaxITTable searchConditions={searchConditions} />
      </Box>

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
          showDatePicker={false}  // 날짜 검색 비활성화
        />
      </Drawer>
    </Box>
  );
}

export default TaxIT;
