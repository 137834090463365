import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow 
} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';

// BonNote 스타일 컴포넌트 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 워퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// 메인 함수
function WolData() {
  // 더미 데이터 생성 (6개월치)
  const dummyData = {
    거래건수: [17157, 292, 16865],
    '합계': [8853227419, -257829015, 9111056434],
    '01월': [1033106140, -21781743, 1054887883],
    '02월': [1618073021, -77653569, 1695726590],
    '03월': [1430456789, -31245678, 1461702467],
    '04월': [1550789012, -42567890, 1593356902],
    '05월': [1780234567, -38901234, 1819135801],
    '06월': [1440567890, -45678901, 1486246791],
    
  };

  // 차트 옵션
  const chartOption = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#fff',
      borderWidth: 1,
      borderColor: '#fff',
      textStyle: {
        color: '#666',
        fontSize: 11
      },
      padding: 10,
      formatter: function(params) {
        return `${params[0].name}<br/>` +
          params.map(param => {
            return `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>` +
            `${param.seriesName}: <span style="font-weight:bold">${param.value.toLocaleString()}원</span>`
          }).join('<br/>');
      }
    },
    legend: {
      data: ['결제금액', '취소금액', '실 매출금액'],
      bottom: 0,
      icon: 'circle',
      itemGap: 30,
      textStyle: {
        color: '#666666',
        fontSize: 12
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '60px',
      top: '20px',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['01월', '02월', '03월', '04월', '05월', '06월'],
      axisLine: {
        lineStyle: {
          color: '#eaeaea'
        }
      },
      axisLabel: {
        color: '#666',
        fontSize: 11
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false
      },
      axisLabel: {
        color: '#666',
        fontSize: 11,
        formatter: (value) => {
          if (value === 0) return '0';
          return (value / 100000000) + '억';
        }
      },
      splitLine: {
        lineStyle: {
          color: '#eaeaea',
          type: 'dashed'
        }
      }
    },
    series: [
      {
        name: '결제금액',
        type: 'line',
        symbol: 'circle',
        symbolSize: 8,
        data: [
          dummyData['01월'][2],
          dummyData['02월'][2],
          dummyData['03월'][2],
          dummyData['04월'][2],
          dummyData['05월'][2],
          dummyData['06월'][2]
        ],
        itemStyle: {
          color: '#6387f9'
        },
        lineStyle: {
          width: 2
        }
      },
      {
        name: '취소금액',
        type: 'line',
        symbol: 'circle',
        symbolSize: 8,
        data: [
          Math.abs(dummyData['01월'][1]),
          Math.abs(dummyData['02월'][1]),
          Math.abs(dummyData['03월'][1]),
          Math.abs(dummyData['04월'][1]),
          Math.abs(dummyData['05월'][1]),
          Math.abs(dummyData['06월'][1])
        ],
        itemStyle: {
          color: '#ff9933'
        },
        lineStyle: {
          width: 2
        }
      },
      {
        name: '실 매출금액',
        type: 'line',
        symbol: 'circle',
        symbolSize: 8,
        data: [
          dummyData['01월'][0],
          dummyData['02월'][0],
          dummyData['03월'][0],
          dummyData['04월'][0],
          dummyData['05월'][0],
          dummyData['06월'][0]
        ],
        itemStyle: {
          color: '#36c6d3'
        },
        lineStyle: {
          width: 2
        }
      }
    ]
  };

  // 메인 코드
  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>월별 결제금액</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <StyledTableWrapper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column"></StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">거래건수</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">합계</StyledTableCell>
                  {Object.keys(dummyData).slice(2).map((key) => (
                    <StyledTableCell key={key} align="right" className="secondary-header">
                      {key}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column">실 매출금액</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">
                    {dummyData['거래건수'][0].toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">
                    {dummyData['합계'][0].toLocaleString()}
                  </StyledTableCell>
                  {Object.entries(dummyData).slice(2).map(([key, values]) => (
                    <StyledTableCell key={key} align="right" className="secondary-header">
                      {values[0].toLocaleString()}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
                <StyledTableRow className="negative-amount">
                  <StyledTableCell className="sticky-column">취소금액</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">
                    {dummyData['거래건수'][1].toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="primary-header">
                    {dummyData['합계'][1].toLocaleString()}
                  </StyledTableCell>
                  {Object.entries(dummyData).slice(2).map(([key, values]) => (
                    <StyledTableCell key={key} align="right" className="secondary-header">
                      {values[1].toLocaleString()}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className="sticky-column" sx={{ fontWeight: 'bold' }}>결제금액</StyledTableCell>
                  <StyledTableCell align="right" className="primary-header" sx={{ fontWeight: 'bold' }}>
                    {dummyData['거래건수'][2].toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right" className="primary-header" sx={{ fontWeight: 'bold' }}>
                    {dummyData['합계'][2].toLocaleString()}
                  </StyledTableCell>
                  {Object.entries(dummyData).slice(2).map(([key, values]) => (
                    <StyledTableCell key={key} align="right" className="secondary-header" sx={{ fontWeight: 'bold' }}>
                      {values[2].toLocaleString()}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTableWrapper>

        {/* 차트 영역 */}
        <Box sx={{ width: '100%', mt: 3 }}>
          <ReactECharts 
            option={chartOption}
            style={{ height: '300px' }}
            className="echarts-for-react boot-chart"
          />
        </Box>
      </Box>
    </Paper>
  );
}

export default WolData;
