import { makeStyles } from '@mui/styles';

// 사업자 통계 컴포넌트 스타일
export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 6px 10px rgba(0,0,0,.08)',
    transition: 'transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease',
    '&:hover': {
      transform: 'translateY(-3px)',
      boxShadow: '0 12px 20px -10px rgba(0, 0, 0, 0.15)'
    }
  },
  cardChart: {
    extend: 'card',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    padding: '20px 20px 0',
  },
  cardBody: {
    flex: 1,
    padding: '20px',
    minHeight: '300px'
  },
  chartContainer: {
    position: 'relative',
    height: '100%',
    minHeight: '300px'
  },
  chartTitle: {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: '#2c2c2c',
    margin: 0,
    marginBottom: '8px'
  },
  chartSubtitle: {
    color: '#9A9A9A',
    fontSize: '0.9em',
    marginBottom: '20px'
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      padding: '12px 16px',
      fontSize: '0.875rem',
      // fontFamilly: "'Noto Sans KR', sans-serif",
    }
  },
  totalRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& .MuiTableCell-root': {
      fontWeight: 700
    }
  }
}));
