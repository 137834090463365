import React, { useState, useEffect } from 'react';
import { Box, Drawer, IconButton, Tooltip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Notice from '../../Notice/Notice';
import SearchOptions from '../../Common/SearchOptions';
import ResultText from '../../ResultText';
import ResultTable from '../../ResultTable';
import PayMentAggregationTable from './PayMentAggregationTable';
import QuickMenu from '../../Common/QuickMenu';
import CurrentLocation from '../../Common/CurrentLocation';
import ExcelJS from 'exceljs';

// 결제내역 집계 메인 함수
function PaymentAggregation() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [tableData, setTableData] = useState([]);
  
  // 검색 조건 상태 확장
  const [searchConditions, setSearchConditions] = useState({
    startDate: '',
    endDate: '',
    searchText: '',
    selectedOption: '',
    excludeOption: false,
    selectedToggleOptions: [],
    activeFilters: [],
    sourceType: '',
    businessType: '',
    taxType: '',
    depositType: '',
    recurringType: '',
    duplicateType: '',
    amountSortType: '',
    walletType: '',
    limit: 10
  });

  // 사이즈감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 핸들러 수정
  const handleSearch = (searchParams) => {
    console.group('Search Parameters Received');
    console.log('Date Range:', { startDate: searchParams.startDate, endDate: searchParams.endDate });
    console.log('Search Text:', searchParams.searchText);
    console.log('Selected Options:', searchParams.selectedToggleOptions);
    console.log('Active Filters:', searchParams.activeFilters);
    console.log('Other Parameters:', {
      sourceType: searchParams.sourceType,
      businessType: searchParams.businessType,
      taxType: searchParams.taxType,
      depositType: searchParams.depositType,
      recurringType: searchParams.recurringType,
      duplicateType: searchParams.duplicateType,
      amountSortType: searchParams.amountSortType,
      walletType: searchParams.walletType
    });
    console.groupEnd();

    setSearchConditions(searchParams);
  };

  // 컬럼 매핑 정의
  const columnMapping = {
    '계정상태': 'accountStatus',
    '아이디': 'userId',
    '상호': 'businessName',
    'PG/경과일': 'pgDaysElapsed',
    '승인금액': 'approvalAmount',
    '취소금액': 'cancelAmount',
    '합계': 'totalAmount',
    '수수료': 'feeAmount',
    '지급액': 'paymentAmount',
    '유보액': 'reserveAmount',
    '결제수단': 'paymentMethod',
    '인증방식': 'authMethod',
    '할부개월': 'installment',
    '개인/세금': 'taxType',
    '정산주기': 'settlementCycle',
    '정액/정률': 'feeType',
    '리스크금액': 'riskAmount',
    '환입금액': 'refundAmount',
    '보증금액': 'depositAmount'
  };

  // 컬럼 너비 계산 함수
  const getMaxColumnWidth = (data, header) => {
    const fieldName = columnMapping[header];
    const maxDataLength = Math.max(
      header.length,
      ...data.map(row => {
        const value = String(row[fieldName] || '');
        return value.length;
      })
    );
    const getTextWidth = (text) => {
      return Array.from(text).reduce((width, char) => {
        return width + (/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char) ? 2.5 : 1);
      }, 0);
    };
    const maxWidth = Math.max(
      getTextWidth(header),
      ...data.map(row => getTextWidth(String(row[fieldName] || '')))
    );
    return maxWidth + Math.ceil(maxWidth * 0.2);
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('결제내역 집계');

    // 스타일 정의
    const defaultCellStyle = {
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      font: {
        size: 10,
        name: '맑은 고딕'
      }
    };

    const headerCellStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF1976D2' }
      },
      font: {
        size: 10,
        color: { argb: 'FFFFFFFF' },
        bold: true,
        name: '맑은 고딕'
      }
    };

    // 헤더 정의
    const headers = [
      'No', '가맹점명', '승인금액', '취소금액', '매출합계', '승인/취소건수',
      '평균단가', '수수료 (%)', '정산금(%)', 'RISK건수', '지사명',
      '지급액(%)', '유보액(%)', '원차감(%)', '환입금(%)', '미수금(%)',
      '보증금(%)', 'PG', 'MID', 'TID'
    ];

    worksheet.addRow(headers);

    // 헤더 스타일 적용
    worksheet.getRow(1).height = 30;
    headers.forEach((_, index) => {
      const cell = worksheet.getCell(1, index + 1);
      Object.assign(cell, headerCellStyle);
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // PayMentAggregationTable의 데이터를 사용하여 행 추가
    const data = tableData; // 테이블 데이터 사용
    data.forEach((row) => {
      const rowData = [
        row.no,
        row.merchantName,
        row.approvalAmount,
        row.cancelAmount,
        row.totalSales.replace(/\s*\([^)]*\)/g, ''), // 건수 제거
        row.transactionCount,
        row.averageAmount,
        row.feeRate,
        row.settlementRate,
        row.riskCount,
        row.branchName,
        row.paymentRate,
        row.reserveRate,
        row.deductionRate,
        row.refundRate,
        row.unpaidRate,
        row.depositRate,
        row.pgName,
        row.mid,
        row.tid
      ];
      worksheet.addRow(rowData);
    });

    // 데이터 행 스타일 적용
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        row.height = 25;
        row.eachCell((cell) => {
          Object.assign(cell, defaultCellStyle);
          // 숫자 데이터의 경우 오른쪽 정렬
          const columnIndex = cell.col - 1;
          if ([2, 3, 4, 6, 7, 8, 11, 12, 13, 14, 15, 16].includes(columnIndex)) {
            cell.alignment = { vertical: 'middle', horizontal: 'right' };
          } else {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          }
        });
      }
    });

    // 컬럼 너비 자동 조정
    worksheet.columns.forEach((column) => {
      column.width = 15;
    });

    // 파일 다운로드
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `결제내역집계_${new Date().toISOString().split('T')[0]}.xlsx`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  // 메인 코드
  return (
    <Box component="main" sx={{
      flexGrow: 1,
      p: 3,
      mt: '5px',
      ml: '40px',  // QuickMenu 기준
      mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
      height: 'auto',
      overflowY: 'auto',
      position: 'relative',
      transition: 'margin 0.3s ease',
      '@media (max-width: 960px)': {
        mx: '10px',
        pl: '10px',
        pr: '10px'
      }
    }}>
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            borderRadius: '4px 0 0 4px',
            boxShadow: '-2px 0 4px rgba(0,0,0,0.1)',
            zIndex: 1000,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: '280px',
            boxSizing: 'border-box',
            mt: '64px',
            height: 'calc(100% - 64px)',
            border: 'none',
            backgroundColor: 'transparent',
            zIndex: 1100
          }
        }}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          onExcelDownload={handleExcelDownload}
          isMobile={isMobile}
        />
      </Drawer>

      <Box sx={{ width: '100%' }}>
        <Notice />
        <CurrentLocation mainTitle="결제" subTitle="결제내역 집계" />
        <ResultText />
        <ResultTable />
        <PayMentAggregationTable 
          searchPanelOpen={searchPanelOpen}
          searchConditions={searchConditions}
          onDataUpdate={setTableData}
        />
      </Box>

      <QuickMenu />
    </Box>
  );
}

export default PaymentAggregation;