import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from '@emotion/styled';

// 버튼 래퍼 스타일 컴포넌트
const ButtonWrapper = styled(Box)({
  display: 'flex',
  gap: '16px',
  '@media (max-width: 600px)': {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 매입현황 데이터, 매입취소 데이터 컴포넌트(아이콘 + 텍스트)
const InOutOptions = ({ onIconClick, hideTitle }) => {
  const buttons = [
    {
      label: '매입현황 데이터',
      icon: <PaymentsIcon />,
      value: 'purchase',
      color: '#2196f3'
    },
    {
      label: '매입취소 데이터',
      icon: <CancelIcon />,
      value: 'cancel',
      color: '#dc3545'
    }
  ];

  // 메인 코드
  return (
    <ButtonWrapper>
      {buttons.map((button, index) => (
        <Button
          key={index}
          variant="contained"
          startIcon={button.icon}
          onClick={() => onIconClick?.(button.value)}
          sx={{
            bgcolor: '#fff',
            color: button.color,
            border: `1px solid ${button.color}`,
            fontSize: '0.875rem',
            height: '40px',
            padding: '8px 16px',
            minWidth: '120px',
            boxShadow: 'none',
            whiteSpace: 'nowrap',
            flexShrink: 0,
            '&:hover': {
              bgcolor: `${button.color}10`,
              border: `1px solid ${button.color}`,
              boxShadow: 'none'
            },
            '& .MuiButton-startIcon': {
              marginRight: '8px',
              '& .MuiSvgIcon-root': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          {button.label}
        </Button>
      ))}
    </ButtonWrapper>
  );
};

export default InOutOptions;
