import React, { useState, useEffect, useMemo } from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  TableContainer, 
  Paper,
  Box,
  LinearProgress,
  Tooltip,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PGModal from '../../Common/StatisticsModal/PGModal';

// BonNote 스타일 적용
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '.negative-amount &': {
    backgroundColor: '#fff3f0'
  },
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#03a9f4',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&.negative-amount': {
    backgroundColor: '#ffebee',
    '& td': {
      color: '#495057'
    }
  },
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 워퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  height: 'calc(100vh - 380px)',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#1976d2',
    color: '#ffffff',
    zIndex: 3
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
    '&:hover': {
      background: '#666'
    }
  }
});

const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

// dummyData를 컴포넌트 외부로 이동
export const dummyData = [
  {
    branch: '오성',
    merchantName: '오성상사',
    businessNumber: '505-81-87535',
    residentNumber: '770312-2',
    phone: '010-4878-0051',
    email: 'eoqk2000@naver.com',
    approvalCount: 5,
    cancelCount: 0,
    actualSalesCount: 5,
    riskCount: 0,
    approvalAmount: 29760000,
    cancelAmount: 0,
    actualSalesAmount: 29760000,
    feeAmount: 1440384,
    depositAmount: 0,
    riskAmount: 0,
    riskRatio: 1.2
  },
  {
    branch: '강남',
    merchantName: '(주)케이지모빌리언스',
    businessNumber: '660-86-00863',
    residentNumber: '600721-1',
    phone: '010-2008-9670',
    email: 'elbio@chamc.co.kr',
    approvalCount: 12,
    cancelCount: 2,
    actualSalesCount: 10,
    riskCount: 1,
    approvalAmount: 11096360,
    cancelAmount: -976479,
    actualSalesAmount: 10119881,
    feeAmount: 976479,
    depositAmount: 100000,
    riskAmount: 50000,
    riskRatio: 4.85
  },
  {
    branch: '부산',
    merchantName: '(주)페이업',
    businessNumber: '460723-2',
    residentNumber: '840906-1',
    phone: '010-4647-8836',
    email: 'jlee1644337@daum.net',
    approvalCount: 8,
    cancelCount: 1,
    actualSalesCount: 7,
    riskCount: 2,
    approvalAmount: 5260000,
    cancelAmount: -289300,
    actualSalesAmount: 4970700,
    feeAmount: 289300,
    depositAmount: 50000,
    riskAmount: 75000,
    riskRatio: 3.8
  },
  {
    branch: '대구',
    merchantName: '나이스정보통신',
    businessNumber: '525-22-01664',
    residentNumber: '800512-1',
    phone: '010-3140-1141',
    email: 'nice@naver.com',
    approvalCount: 15,
    cancelCount: 3,
    actualSalesCount: 12,
    riskCount: 0,
    approvalAmount: 4690000,
    cancelAmount: -281423,
    actualSalesAmount: 4408577,
    feeAmount: 281423,
    depositAmount: 200000,
    riskAmount: 0,
    riskRatio: 0.8
  },
  {
    branch: '인천',
    merchantName: '(주)스마트로',
    businessNumber: '215-81-91827',
    residentNumber: '880214-1',
    phone: '010-5567-8892',
    email: 'smartro@smartro.co.kr',
    approvalCount: 7,
    cancelCount: 0,
    actualSalesCount: 7,
    riskCount: 1,
    approvalAmount: 8750000,
    cancelAmount: 0,
    actualSalesAmount: 8750000,
    feeAmount: 437500,
    depositAmount: 150000,
    riskAmount: 25000,
    riskRatio: 2.1
  },
  {
    branch: '광주',
    merchantName: '코리아결제시스템',
    businessNumber: '312-86-42195',
    residentNumber: '910628-2',
    phone: '010-2234-5678',
    email: 'kps@kps.co.kr',
    approvalCount: 10,
    cancelCount: 0,
    actualSalesCount: 10,
    riskCount: 0,
    approvalAmount: 6320000,
    cancelAmount: 0,
    actualSalesAmount: 6320000,
    feeAmount: 316000,
    depositAmount: 100000,
    riskAmount: 0,
    riskRatio: 0.5
  },
  {
    branch: '대전',
    merchantName: '한국사이버결제',
    businessNumber: '118-81-49324',
    residentNumber: '850417-1',
    phone: '010-9988-7766',
    email: 'kcp@kcp.co.kr',
    approvalCount: 6,
    cancelCount: 0,
    actualSalesCount: 6,
    riskCount: 1,
    approvalAmount: 3890000,
    cancelAmount: 0,
    actualSalesAmount: 3890000,
    feeAmount: 194500,
    depositAmount: 80000,
    riskAmount: 15000,
    riskRatio: 1.8
  },
  {
    branch: '울산',
    merchantName: '(주)다날',
    businessNumber: '425-87-00453',
    residentNumber: '790905-2',
    phone: '010-3344-5566',
    email: 'danal@danal.co.kr',
    approvalCount: 9,
    cancelCount: 2,
    actualSalesCount: 7,
    riskCount: 1,
    approvalAmount: 7150000,
    cancelAmount: -520000,
    actualSalesAmount: 6630000,
    feeAmount: 357500,
    depositAmount: 120000,
    riskAmount: 30000,
    riskRatio: 2.5
  }
];

// 메인 함수
function GamengSalesTables({ searchPanelOpen, searchConditions }) {
  const [isPGModalOpen, setIsPGModalOpen] = useState(false);
  const [selectedPGData, setSelectedPGData] = useState(null);

  // 검색 조건에 따른 데이터 필터링
  const filteredData = useMemo(() => {
    if (!searchConditions || !searchConditions.searchText) {
      return dummyData;
    }

    const searchLower = searchConditions.searchText.toLowerCase();
    return dummyData.filter(row => {
      return (
        row.branch.toLowerCase().includes(searchLower) ||      // 지사
        row.merchantName.toLowerCase().includes(searchLower)   // 가맹점명
      );
    });
  }, [searchConditions]);

  // PG 상세 클릭 핸들러
  const handlePGDetailClick = (e, detail) => {
    e.stopPropagation();  // 이벤트 전파 중지
    setSelectedPGData(detail);
    setIsPGModalOpen(true);
  };

  // 메인 코드
  return (
    <>
      <Paper sx={{ 
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: 'none',
        border: '1px solid #e9ecef',
        borderRadius: '4px',
        overflow: 'hidden'
      }}>
        <StyledTableWrapper>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell className="primary-header sticky-header">지사</StyledTableCell>
                <StyledTableCell className="primary-header">가맹점명</StyledTableCell>
                <StyledTableCell className="primary-header">사업자등록번호</StyledTableCell>
                <StyledTableCell className="primary-header">주민번호</StyledTableCell>
                <StyledTableCell className="primary-header">연락처</StyledTableCell>
                <StyledTableCell className="primary-header">이메일</StyledTableCell>
                <StyledTableCell className="primary-header">승인건수</StyledTableCell>
                <StyledTableCell className="primary-header">취소건수</StyledTableCell>
                <StyledTableCell className="primary-header">실매출건수</StyledTableCell>
                <StyledTableCell className="primary-header">리스크건수</StyledTableCell>
                <StyledTableCell className="primary-header">승인금액</StyledTableCell>
                <StyledTableCell className="primary-header">취소금액</StyledTableCell>
                <StyledTableCell className="primary-header">실매출금액</StyledTableCell>
                <StyledTableCell className="primary-header">수수료금액</StyledTableCell>
                <StyledTableCell className="primary-header">보증금액</StyledTableCell>
                <StyledTableCell className="primary-header">리스크금액</StyledTableCell>
                <StyledTableCell className="primary-header">리스크비율</StyledTableCell>
                <StyledTableCell className="primary-header">리스크비율 그래프</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <StyledTableRow 
                  key={index}
                  className={row.cancelAmount < 0 ? 'negative-amount' : ''}
                >
                  <StyledTableCell className="sticky-column">{row.branch}</StyledTableCell>
                  <StyledTableCell>{row.merchantName}</StyledTableCell>
                  <StyledTableCell align="center">{row.businessNumber}</StyledTableCell>
                  <StyledTableCell align="center">{row.residentNumber}</StyledTableCell>
                  <StyledTableCell align="center">{row.phone}</StyledTableCell>
                  <StyledTableCell align="left">{row.email}</StyledTableCell>
                  <StyledTableCell align="right">{row.approvalCount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right" style={{ color: row.cancelCount > 0 ? '#f44336' : 'inherit' }}>
                    {row.cancelCount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.actualSalesCount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.riskCount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.approvalAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right" style={{ color: row.cancelAmount < 0 ? '#f44336' : 'inherit' }}>
                    {row.cancelAmount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.actualSalesAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.feeAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.depositAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.riskAmount.toLocaleString()}</StyledTableCell>
                  <StyledTableCell align="right">{row.riskRatio}%</StyledTableCell>
                  <StyledTableCell align="center">
                    <Box sx={{ width: '100%', minWidth: 100 }}>
                      <LinearProgress
                        variant="determinate"
                        value={parseFloat(row.riskRatio)}
                        sx={{
                          height: 10,
                          borderRadius: 5,
                          backgroundColor: '#ffebee',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: '#f44336',
                            borderRadius: 5,
                          }
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableWrapper>
      </Paper>

      {/* PG Modal */}
      <PGModal
        open={isPGModalOpen}
        onClose={() => setIsPGModalOpen(false)}
        pgData={selectedPGData}
      />
    </>
  );
}

export default GamengSalesTables;
