import React, { useState } from "react";
import { Table, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { styled } from '@mui/material/styles';
import DeveloperBoardOffIcon from '@mui/icons-material/DeveloperBoardOff';
import { Box, Switch } from '@mui/material';

// 테이블 스타일
const StyledTable = styled(Table)`
  th, td {
    text-align: center;
    vertical-align: middle !important;
    padding: 12px 8px !important;
  }
  
  th:nth-child(2), 
  td:nth-child(2) {
    text-align: left;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

function SubSystemOnOff() {
  const [menuItems, setMenuItems] = useState([
    { 
      no: 1, 
      domain: "cash2check.com",
      permissions: {
        정산내역: true,
        그룹관리: false,
        가맹관리: false,
        계정관리: false
      }
    },
    { 
      no: 2, 
      domain: "ondapay.kr",
      permissions: {
        정산내역: true,
        그룹관리: true,
        가맹관리: true,
        계정관리: true
      }
    }
  ]);

  // 스위치 변경 핸들러
  const handleSwitchChange = (itemNo, key, value) => {
    const updatedItems = menuItems.map(item => {
      if (item.no === itemNo) {
        return {
          ...item,
          permissions: {
            ...item.permissions,
            [key]: value // 해당 권한의 상태 업데이트
          }
        };
      }
      return item;
    });
    setMenuItems(updatedItems); // 상태 업데이트
    console.log(`Domain ${itemNo} ${key} changed to: ${value}`);
  };

  // 메인 코드
  return (
    <div className="content">
      <Card>
        <CardHeader>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DeveloperBoardOffIcon sx={{ color: '#2e7d32', fontSize: 28 }} />
            <CardTitle tag="h4">서브전산 메뉴 On/Off 설정</CardTitle>
          </Box>
        </CardHeader>
        <CardBody>
          <StyledTable responsive>
            <thead className="text-primary">
              <tr>
                <th style={{width: '5%'}}>No</th>
                <th style={{width: '15%'}}>도메인</th>
                <th>정산내역 ON/OFF</th>
                <th>그룹관리 ON/OFF</th>
                <th>가맹관리 ON/OFF</th>
                <th>계정관리 ON/OFF</th>
              </tr>
            </thead>
            <tbody>
              {menuItems.map((item) => (
                <tr key={item.no}>
                  <td>{item.no}</td>
                  <td>{item.domain}</td>
                  {Object.entries(item.permissions).map(([key, value]) => (
                    <td key={key}>
                      <Switch 
                        checked={value}
                        onChange={(e) => handleSwitchChange(item.no, key, e.target.checked)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </CardBody>
      </Card>
    </div>
  );
}

export default SubSystemOnOff;