import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// 메인 함수. 링크 이동만 처리
const OptionButton = ({ onOptionClick, selectedItems = [] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const handleOptionClick = (optionType) => {
    console.log(`선택된 항목 수: ${selectedItems.length}, 클릭한 버튼: ${optionType}`);
    
    if (selectedItems.length === 0) {
      alert('항목을 선택해주세요.');
      return;
    }

    if ((optionType === '수정' || optionType === '복사') && selectedItems.length > 1) {
      alert(`${optionType}은 한 건만 선택 가능합니다.`);
      return;
    }

    if (optionType === '수정') {
      const currentPath = location.pathname;
      
      if (currentPath.includes('/management/cashbill/manager')) {
        navigate('/management/cashdamdang/input', { 
          state: { selectedItems, mode: 'edit' } 
        });
      }
      else if (currentPath.includes('/management/cashdamdang')) {
        navigate('/management/cashdamdang/register', { 
          state: { selectedItems, mode: 'edit' } 
        });
      }
      else if (currentPath.includes('/management/account')) {
        navigate('/management/account/register', { 
          state: { selectedItems, mode: 'edit' } 
        });
      } 
      else if (currentPath.includes('/management/merchant')) {
        navigate('/management/merchant/register', { 
          state: { selectedItems, mode: 'edit' } 
        });
      }
      else if (currentPath.includes('/management/cashuser/manager')) {
        navigate('/management/cashuser/register', { 
          state: { selectedItems, mode: 'edit' } 
        });
      }
    }
    
    onOptionClick?.(optionType, selectedItems);
  };

  const buttons = [
    { 
      label: '수정', 
      icon: <EditIcon />,
      color: '#ffa000',
      onClick: () => handleOptionClick('수정')
    },
    { 
      label: '삭제', 
      icon: <DeleteIcon />,
      color: '#dc3545',
      onClick: () => handleOptionClick('삭제')
    },
    { 
      label: '복사', 
      icon: <ContentCopyIcon />,
      color: '#4caf50',
      onClick: () => handleOptionClick('복사')
    }
  ];

  return (
    <Box>
      <Typography 
        variant="subtitle1" 
        sx={{ 
          fontSize: '0.875rem', 
          fontWeight: 600,
          color: '#495057',
          mb: 1.5,
          pl: '4px'
        }}
      >
        관리 옵션
      </Typography>
      <Box sx={{ 
        display: 'flex',
        gap: 1
      }}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            startIcon={button.icon}
            onClick={button.onClick}
            sx={{
              bgcolor: '#fff',
              color: button.color,
              border: `1px solid ${button.color}`,
              fontSize: '0.75rem',
              height: '32px',
              padding: '4px 12px',
              minWidth: 'auto',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: button.color,
                color: '#fff',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
              },
              '& .MuiButton-startIcon': {
                marginRight: '4px',
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem'
                }
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default OptionButton;
