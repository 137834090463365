import React, { useState, useMemo } from 'react';
import { 
  Box, 
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Modal,
  Typography,
  Tooltip
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import MerchantStatusIcons from '../../Common/MerchantStatusIcons';
import CancelOptions from '../../Common/CancelOptions';
import MerchantIcons from '../../Common/MerchantIcons';
import RMquestion from '../../Common/RMquestion';
import SetOff from '../../Common/SetOff';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

// dayjs에 isBetween 플러그인 추가
dayjs.extend(isBetween);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 테이블 워퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px'
  }
});

function ComplaintDetailsTables({ 
  searchPanelOpen, 
  setSearchPanelOpen, 
  selectedRows, 
  setSelectedRows,
  searchConditions
}) {
  // 상태값 정의
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [isRMModalOpen, setIsRMModalOpen] = useState(false);
  const [isSetOffModalOpen, setIsSetOffModalOpen] = useState(false);
  const [selectedMerchantData, setSelectedMerchantData] = useState(null);
  const [dummyData, setDummyData] = useState([
    {
      id: 1,
      offsetRefund: false,
      customerName: '홍길동',
      phoneNumber: '010-0444-2222',
      approvalAmount: '332,111',
      approvalNo: '65934468',
      paymentDate: '2025-02-06 10:30:00',
      cancelDate: '2025-02-06 10:35:00',
      hasAttachments: 'Y',
      pgType: 'KSNET',
      merchantTid: 'TR678901234',
      merchantName: '네오알파시스템즈',
      branchName: '개인/원천/N오평욱B 49',
      registerTime: '2025-02-06 11:04:54',
      complaintType: '결제취소요청',
      completionStatus: '완료',
      responseDeadline: '2024-03-25',
      turnCount: '1',
      paymentMethod: '신용카드',
      userId: 'm48780067',
      complaintContent: '환급, 취소지연/ 취소요청',
      responseContent: '확인 후 처리하겠습니다.',
      registrant: '테스트1'
    },
    {
      id: 2,
      customerName: '김철수',
      phoneNumber: '010-1111-3333',
      approvalAmount: '35,000',
      approvalNo: '65908892',
      paymentDate: '2025-02-05 14:35:00',
      cancelDate: '2025-02-05 14:38:00',
      hasAttachments: 'Y',
      pgType: 'NICE',
      merchantTid: 'TRK2024031900002',
      merchantName: '샤론정보통신',
      branchName: '개인/원천/1테스트 김말자 대리점66 원천',
      registerTime: '2025-02-05 14:40:00',
      complaintType: '환불요청',
      completionStatus: '미완료',
      responseDeadline: '2024-03-26',
      turnCount: '2',
      paymentMethod: '간편결제',
      userId: 'm98019007',
      complaintContent: '가맹점 연락불가',
      responseContent: '확인중입니다.',
      registrant: '테스트2'
    },
    {
      id: 3,
      customerName: '강동원',
      phoneNumber: '010-2222-4444',
      approvalAmount: '35,000',
      approvalNo: '65934470',
      paymentDate: '2025-02-04 11:20:00',
      cancelDate: '2025-02-04 11:23:00',
      hasAttachments: 'N',
      pgType: 'EtoU',
      merchantTid: 'TR678901235',
      merchantName: '골드스파사우나',
      branchName: '사업자/원천/N네오 윤병원',
      registerTime: '2025-02-04 11:25:00',
      complaintType: '오류신고',
      completionStatus: '완료',
      responseDeadline: '2024-03-27',
      turnCount: '1',
      paymentMethod: '계좌이체',
      userId: 'm42845709',
      complaintContent: '본인 미사용 / 취소요청',
      responseContent: '처리완료되었습니다.',
      registrant: '테스트3'
    },
    {
      id: 4,
      customerName: '박박이',
      phoneNumber: '010-3333-5555',
      approvalAmount: '35,000',
      approvalNo: '65934471',
      paymentDate: '2025-02-03 09:10:00',
      cancelDate: '2025-02-03 09:13:00',
      hasAttachments: 'N',
      pgType: 'AYNIL',
      merchantTid: 'TR678901236',
      merchantName: '일일구세탁',
      branchName: '개인/원천/1테스트 김말자 대리점66 원천',  // SearchOptions.js의 branch 옵션값과 일치
      registerTime: '2025-02-03 09:15:00',
      complaintType: '결제취소요청',
      completionStatus: '미완료',
      responseDeadline: '2024-03-28',
      turnCount: '3',
      paymentMethod: '가상계좌',
      userId: 'm76640459',
      complaintContent: '환급, 취소지연',
      responseContent: '가맹점 확인중입니다.',
      registrant: '테스트1'
    },
    {
      id: 5,
      customerName: '이순신',
      phoneNumber: '010-4444-6666',
      approvalAmount: '35,000',
      approvalNo: '65934472',
      paymentDate: '2025-02-02 16:15:00',
      cancelDate: '2025-02-02 16:18:00',
      hasAttachments: 'N',
      pgType: 'Kopay',
      merchantTid: 'TR678901237',
      merchantName: '일일구세탁',
      branchName: '사업자/원천/N네오 윤병원',
      registerTime: '2025-02-02 16:20:00',
      complaintType: '환불요청',
      completionStatus: '완료',
      responseDeadline: '2024-03-29',
      turnCount: '2',
      paymentMethod: '신용카드',
      userId: 'm42845709',
      complaintContent: '가맹점 연락불가',
      responseContent: '처리완료했습니다.',
      registrant: '테스트2'
    }
  ]);

  // 그 다음에 tableHeaders 정의
  const tableHeaders = [
    { id: 'checkbox', label: <Checkbox />, color: '#bbdefb', width: '60px' },
    { id: 'no', label: 'No', color: '#42a5f5', width: '50px' },
    { id: 'offsetRefund', label: '상계환입', color: '#42a5f5', width: '80px' },
    { id: 'complaintType', label: '민원종류', color: '#42a5f5', width: '100px' },
    { id: 'completionStatus', label: '완료여부', color: '#42a5f5', width: '80px' },
    { id: 'responseDeadline', label: '회신기한', color: '#64b5f6', width: '100px' },
    { id: 'turnCount', label: '회차수', color: '#64b5f6', width: '60px' },
    { id: 'pgType', label: 'PG구분', color: '#64b5f6', width: '100px' },
    { id: 'paymentMethod', label: '결제수단', color: '#64b5f6', width: '80px' },
    { id: 'userId', label: '로그인ID', color: '#a5d6a7', width: '100px' },
    { id: 'merchantTid', label: '가맹점TID', color: '#a5d6a7', width: '120px' },
    { id: 'merchantName', label: '가맹점명', color: '#a5d6a7', width: '150px' },
    { id: 'branchName', label: '지사명', color: '#a5d6a7', width: '150px' },
    { id: 'complaintContent', label: '민원내용', color: '#ef9a9a', width: '200px' },
    { id: 'responseContent', label: '답변내용', color: '#ef9a9a', width: '200px' },
    { id: 'registerTime', label: '등록시간', color: '#ef9a9a', width: '150px' },
    { id: 'registrant', label: '등록자', color: '#ef9a9a', width: '80px' },
    { id: 'customerName', label: '고객명', color: '#ef9a9a', width: '100px' },
    { id: 'phoneNumber', label: '휴대폰번호', color: '#ef9a9a', width: '120px' },
    { id: 'approvalAmount', label: '승인액', color: '#ef9a9a', width: '100px' },
    { id: 'approvalNo', label: '승인번호', color: '#ef9a9a', width: '100px' },
    { id: 'paymentDate', label: '결제일자', color: '#ef9a9a', width: '150px' },
    { id: 'cancelDate', label: '취소일자', color: '#ef9a9a', width: '150px' },
    { id: 'hasAttachments', label: '첨부파일', color: '#ef9a9a', width: '80px' }
  ];

  // 체크박스 변경 함수
  const handleCheckboxChange = (checked, rowId) => {
    console.log('Checkbox clicked:', { checked, rowId });
    console.log('Current selectedRows:', selectedRows);
    
    if (checked) {
      setSelectedRows(prev => {
        const newSelection = [...prev, rowId];
        console.log('New selectedRows after adding:', newSelection);
        return newSelection;
      });
    } else {
      setSelectedRows(prev => {
        const newSelection = prev.filter(id => id !== rowId);
        console.log('New selectedRows after removing:', newSelection);
        return newSelection;
      });
    }
  };

  // 상계환입 체크박스 변경 핸들러
  const handleOffsetRefundChange = (checked, rowId) => {
    setDummyData(prev => prev.map(row => 
      row.id === rowId ? { ...row, offsetRefund: checked } : row
    ));
  };

  // 사용자 아이콘 클릭 함수
  const handleUserIconClick = (userData) => {
    setSelectedUserData(userData);
    setModalOpen(true);
  };

  // RM 문의 클릭 함수
  const handleRMClick = () => {
    if (selectedRows.length > 1) {
      alert('RM 문의는 한 건만 선택 가능합니다.');
      return;
    }
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }
    
    const selectedRow = dummyData.find(row => row.id === selectedRows[0]);
    setSelectedMerchantData(selectedRow);
    setIsRMModalOpen(true);
  };

  // 상계 클릭 함수
  const handleSetOffClick = () => {
    if (selectedRows.length > 1) {
      alert('상계 처리를 위해 한 건만 선택해주세요.');
      return;
    }
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }
    
    setIsSetOffModalOpen(true);
  };

  // 텍스트 길이를 제한하는 유틸리티 함수
  const truncateText = (text, maxLength = 20) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  // 필터링된 데이터 계산
  const filteredData = useMemo(() => {
    console.log('ComplaintDetailsTables - 현재 검색 조건:', searchConditions);
    if (!searchConditions) return dummyData;

    return dummyData.filter(row => {
      // 날짜 필터
      if (searchConditions.startDate && searchConditions.endDate) {
        const rowDate = dayjs(row.registerTime);
        const startDate = dayjs(searchConditions.startDate);
        const endDate = dayjs(searchConditions.endDate).endOf('day');
        if (rowDate.isBefore(startDate) || rowDate.isAfter(endDate)) {
          return false;
        }
      }

      // 텍스트 검색 (MID, TID, 가맹점명)
      if (searchConditions.searchText) {
        const searchText = searchConditions.searchText.toLowerCase();
        const searchFields = [
          row.merchantTid,
          row.merchantName,
          row.userId
        ].map(field => (field || '').toLowerCase());
        
        if (!searchFields.some(field => field.includes(searchText))) return false;
      }

      // 토글 옵션 처리
      if (searchConditions.selectedToggleOptions?.length > 0) {
        for (const option of searchConditions.selectedToggleOptions) {
          switch (option.parentOption) {
            case 'pgCompany':
              if (row.pgType?.toLowerCase() !== option.label.toLowerCase()) return false;
              break;
            case 'complaintType':
              if (row.complaintType?.toLowerCase() !== option.label.toLowerCase()) return false;
              break;
            case 'completionStatus':
              if (row.completionStatus?.toLowerCase() !== option.label.toLowerCase()) return false;
              break;
            // 필요한 다른 토글 옵션들 처리
          }
        }
      }

      // 원천구분 필터
      if (searchConditions.sourceType) {
        const branchSourceType = row.branchName?.split('/')[1]?.trim();
        if (!branchSourceType || branchSourceType.toLowerCase() !== searchConditions.sourceType.toLowerCase()) {
          return false;
        }
      }

      // 사업자구분 필터
      if (searchConditions.businessType) {
        const branchBusinessType = row.branchName?.split('/')[0]?.trim();
        if (!branchBusinessType || branchBusinessType.toLowerCase() !== searchConditions.businessType.toLowerCase()) {
          return false;
        }
      }

      // PG사 필터
      if (searchConditions.pgCompany && 
          row.pgType.toLowerCase() !== searchConditions.pgCompany.toLowerCase()) {
        return false;
      }

      // 민원종류 필터
      if (searchConditions.complaintType && 
          row.complaintType.toLowerCase() !== searchConditions.complaintType.toLowerCase()) {
        return false;
      }

      // 완료여부 필터
      if (searchConditions.completionStatus && 
          row.completionStatus.toLowerCase() !== searchConditions.completionStatus.toLowerCase()) {
        return false;
      }

      return true;
    });
  }, [searchConditions, dummyData]);

  // 전체 체크박스 핸들러
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = filteredData.map(row => row.id);
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };

  // 메인 코드
  return (
    <>
      <Box 
        className="cancel-options-section"
        sx={{ 
          display: 'flex',
          gap: 4,
          backgroundColor: '#f8f9fa',
          padding: '20px',
          borderRadius: '8px',
          mb: 3,
          width: '100%',
         
          '@media (max-width: 600px)': {
            overflowX: 'auto',
            WebkitOverflowScrolling: 'touch',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.2)',
              borderRadius: '3px'
            }
          }
        }}
      >
        <Box sx={{ 
          display: 'flex',
          gap: 2,
          '@media (max-width: 600px)': {
            minWidth: 'max-content'
          }
        }}>
          <CancelOptions 
            selectedItems={selectedRows}
            onCancelOption={(optionType) => {
              switch(optionType) {
                case 'RM':
                  handleRMClick();
                  break;
                case '상계':
                  handleSetOffClick();
                  break;
                case '강제취소':
                  console.log('취소 클릭');
                  break;
              }
            }}
          />
          <Box sx={{ borderLeft: '1px solid #ddd' }} />
          <MerchantIcons />
        </Box>
      </Box>

      <TableContainer component={StyledTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  align="center"
                  className="primary-header"
                  style={{ 
                    backgroundColor: header.color,
                    width: header.width
                  }}
                >
                  {header.id === 'checkbox' ? (
                    <Checkbox
                      size="small"
                      checked={filteredData.length > 0 && selectedRows.length === filteredData.length}
                      indeterminate={selectedRows.length > 0 && selectedRows.length < filteredData.length}
                      onChange={handleSelectAll}
                    />
                  ) : (
                    header.label
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  <Checkbox
                    size="small"
                    checked={selectedRows.includes(row.id)}
                    onChange={(e) => handleCheckboxChange(e.target.checked, row.id)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">
                  <Checkbox
                    size="small"
                    checked={row.offsetRefund || false}
                    onChange={(e) => handleOffsetRefundChange(e.target.checked, row.id)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{row.complaintType}</StyledTableCell>
                <StyledTableCell align="center">{row.completionStatus}</StyledTableCell>
                <StyledTableCell align="center">{row.responseDeadline}</StyledTableCell>
                <StyledTableCell align="center">{row.turnCount}</StyledTableCell>
                <StyledTableCell align="center">{row.pgType}</StyledTableCell>
                <StyledTableCell align="center">{row.paymentMethod}</StyledTableCell>
                <StyledTableCell align="center">{row.userId}</StyledTableCell>
                <StyledTableCell align="center">{row.merchantTid}</StyledTableCell>
                <StyledTableCell align="center">{row.merchantName}</StyledTableCell>
                <StyledTableCell align="center">{row.branchName}</StyledTableCell>
                <StyledTableCell align="left">{row.complaintContent}</StyledTableCell>
                <StyledTableCell align="left">{row.responseContent}</StyledTableCell>
                <StyledTableCell align="center">{row.registerTime}</StyledTableCell>
                <StyledTableCell align="center">{row.registrant}</StyledTableCell>
                <StyledTableCell align="center">{row.customerName}</StyledTableCell>
                <StyledTableCell align="center">{row.phoneNumber}</StyledTableCell>
                <StyledTableCell align="right">{row.approvalAmount}</StyledTableCell>
                <StyledTableCell align="center">{row.approvalNo}</StyledTableCell>
                <StyledTableCell align="center">{row.paymentDate}</StyledTableCell>
                <StyledTableCell align="center">{row.cancelDate || '-'}</StyledTableCell>
                <StyledTableCell align="center">{row.hasAttachments ? 'Y' : 'N'}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 1,
          width: '400px',
          maxWidth: '90vw'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            사용자 정보
          </Typography>
          {selectedUserData && (
            <>
              <Typography>고객명: {selectedUserData.customerName}</Typography>
              <Typography>휴대폰 번호: {selectedUserData.phoneNumber}</Typography>
              <Typography>승인액: {selectedUserData.approvalAmount}</Typography>
              <Typography>승인번호: {selectedUserData.approvalNo}</Typography>
              <Typography>결제일자: {selectedUserData.paymentDate}</Typography>
              <Typography>취소일자: {selectedUserData.cancelDate || '-'}</Typography>
              <Typography>첨부파일: {selectedUserData.hasAttachments ? '있음' : '없음'}</Typography>
            </>
          )}
        </Box>
      </Modal> */}

      <RMquestion
        open={isRMModalOpen}
        onClose={() => setIsRMModalOpen(false)}
        data={selectedMerchantData}
      />

      <Modal
        open={isSetOffModalOpen}
        onClose={() => setIsSetOffModalOpen(false)}
        aria-labelledby="setoff-modal-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiModal-backdrop': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <Box sx={{ 
          width: '95%',
          maxWidth: '1300px',
          minWidth: '1000px',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          height: '90vh',
          overflow: 'hidden'
        }}>
          <SetOff onClose={() => setIsSetOffModalOpen(false)} />
        </Box>
      </Modal>
    </>
  );
}

export default ComplaintDetailsTables;
