//헤더에서  열리는 우측 사용자 정보 패널
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import StorefrontIcon from '@mui/icons-material/Storefront'; // 가맹점 아이콘
import StarsIcon from '@mui/icons-material/Stars'; // 계급 아이콘으로 변경
import { faGear, faUser, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// 메인 함수
function UserInfoPanel({ open, onClose }) {
  const navigate = useNavigate();

  // 메인 코드
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: 320,
          boxSizing: 'border-box',
          background: '#ffffff',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <Box sx={{ 
        p: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {/* 타이틀 영역 */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2,
          pb: 2,
          borderBottom: '1px solid #e0e0e0'
        }}>
          <Typography variant="h6" sx={{ 
            color: '#34495e', 
            fontWeight: 600
          }}>
            사용자 정보
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        
        {/* 사용자 정보 영역 */}
        <Box sx={{ 
          py: 2, 
          px: 2, 
          mb: 2,
          backgroundColor: '#fff',
          border: '1px solid #e0e0e0',
          borderRadius: '4px'
        }}>
          {/* 로그인 ID - 한 줄로 정렬 */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            mb: 2 
          }}>
            <PersonIcon sx={{ mr: 1, color: '#666666' }} />
            <Typography variant="body1" sx={{ color: '#666666', mr: 1 }}>
              로그인 ID:
            </Typography>
            <Typography variant="body1" sx={{ color: '#000000' }}>
              localhostID
            </Typography>
          </Box>

          {/* 이전 로그인 이력 - 두 줄로 정렬 */}
          <Box sx={{ display: 'flex' }}>
            <AccessTimeIcon sx={{ mr: 1, color: '#666666', mt: '3px' }} />
            <Box>
              <Typography variant="body1" sx={{ color: '#666666' }}>
                이전 로그인 이력
              </Typography>
              <Typography variant="body1" sx={{ color: '#000000', mt: 0.5 }}>
                2025-01-24 09:30:00
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* 메뉴 목록 */}
        <List>
          <MenuItem onClick={() => {
            navigate('/admin');
            onClose();
          }}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faShieldHalved} style={{ fontSize: '20px', color: '#666' }} />
            </ListItemIcon>
            <ListItemText>관리자 페이지</ListItemText>
          </MenuItem>
          
          <Divider sx={{ opacity: 0.6 }} />
          
          <ListItem button>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="프로필 설정(미구현)" />
          </ListItem>
          
          <ListItem button>
            <ListItemIcon>
              <SecurityIcon />
            </ListItemIcon>
            <ListItemText primary="보안 설정(미구현)" />
          </ListItem>
          
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="환경 설정(미구현)" />
          </ListItem>
          
          <Divider sx={{ opacity: 0.6 }} />
          
          <ListItem button>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
            onClick={() => {
              navigate('/');
              onClose();
            }}
            primary="로그아웃" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}

export default UserInfoPanel;