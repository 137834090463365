import React, { useState, useEffect } from 'react';
import { Box, Drawer, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import QuickMenu from '../../Common/QuickMenu';
import SearchOptions from '../../Common/SearchOptions';
import CancelRequestTables from './CancelRequestTables';
import DefaultTables from './DefaultTables';
import Notice from '../../Notice/Notice';
import CurrentLocation from '../../Common/CurrentLocation';
import ExcelJS from 'exceljs';

function CancelRequestDetails() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [selectedApprovals, setSelectedApprovals] = useState([]);

  // 검색 상태값 정의
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState('2025-02-01');
  const [endDate, setEndDate] = useState('2025-02-28');
  const [searchConditions, setSearchConditions] = useState(null);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (checked, approvalNo) => {
    if (checked) {
      setSelectedApprovals(prev => [...prev, approvalNo]);
    } else {
      setSelectedApprovals(prev => prev.filter(no => no !== approvalNo));
    }
  };

  // 검색 처리 함수
  const handleSearch = (searchParams) => {
    setSearchConditions({
      startDate: searchParams.startDate,
      endDate: searchParams.endDate,
      searchText: searchParams.searchText,
      pgCompany: searchParams.pgCompany,
      installment: searchParams.installment
    });
  };

  // 엑셀 다운로드 핸들러
  const handleExcelDownload = async (excelData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('취소요청내역');

    // 스타일 정의
    const headerStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF1976D2' }
      },
      font: {
        color: { argb: 'FFFFFFFF' },
        bold: true,
        size: 11
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center'
      }
    };

    // 헤더 추가
    worksheet.addRow(excelData.headers);
    worksheet.getRow(1).eachCell(cell => {
      cell.style = headerStyle;
    });

    // 데이터 추가
    excelData.data.forEach(row => {
      worksheet.addRow(row);
    });

    // 열 너비 자동 조정
    worksheet.columns.forEach(column => {
      column.width = Math.max(
        15,
        ...worksheet.getColumn(column.number).values
          .map(v => String(v).length)
      );
    });

    // 셀 스타일 적용
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) { // 데이터 행
        row.eachCell((cell, colNumber) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: ['refundAmount', 'amount', 'deposit', 'depositAmount', 'accountBalance', 'deductionAmount']
              .includes(excelData.headers[colNumber - 1])
              ? 'right'
              : 'center'
          };
        });
      }
    });

    // 파일 다운로드
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { 
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `취소요청내역_${new Date().toISOString().split('T')[0]}.xlsx`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: '5px',
    ml: '40px',  // QuickMenu 기준
    mr: searchPanelOpen ? '280px' : '40px',  // SearchPanel 기준
    height: 'auto',
    overflowY: 'auto',
    position: 'relative',
    transition: 'margin 0.2s',
    '@media (max-width: 960px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  };

  // 스타일 정의
  const styles = {
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  // 메인 코드
  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 패널 열기 버튼 - 패널이 닫혔을 때만 표시 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            zIndex: 1199  // Drawer의 zIndex보다 1 작게 설정
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Notice />
      <CurrentLocation mainTitle="결제" subTitle="취소요청 내역" />
      
      <Box sx={{ mb: '20px' }} />
      
      <CancelRequestTables 
        searchConditions={searchConditions}
        onCheckboxChange={handleCheckboxChange}
        selectedApprovals={selectedApprovals}
      />
      
      <Box sx={{ mb: '20px' }} />
      
      <DefaultTables 
        data-testid="default-tables"
        onExcelDownload={handleExcelDownload}
      />

      <QuickMenu />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          onExcelDownload={() => {
            const defaultTablesRef = document.querySelector('[data-testid="default-tables"]');
            if (defaultTablesRef) {
              const excelData = defaultTablesRef.prepareExcelData();
              handleExcelDownload(excelData);
            }
          }}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default CancelRequestDetails; 