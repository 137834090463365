import { makeStyles } from '@mui/styles';

// 인증별 결제금액 컴포넌트 스타일
export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  card: {
    borderRadius: '12px',
    boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
    backgroundColor: '#FFFFFF',
    color: '#252422',
    marginBottom: '20px',
    position: 'relative',
    border: '0 none',
    transition: 'transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease'
  },
  cardChart: {
    extend: 'card',
    height: '400px'
  },
  cardHeader: {
    padding: '15px 15px 0',
    background: 'transparent',
    position: 'relative'
  },
  cardBody: {
    padding: '15px',
    height: 'calc(100% - 100px)'
  },
  chartContainer: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  chartTitle: {
    color: '#252422',
    fontSize: '1.1em',
    fontWeight: 600,
    marginBottom: '5px'
  },
  chartSubtitle: {
    color: '#9A9A9A',
    fontSize: '0.9em',
    marginBottom: '15px'
  },
  totalRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    fontWeight: 'bold',
    '& td': {
      fontWeight: 'bold'
    }
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      padding: '12px 16px',
      fontSize: '0.875rem',
      // fontFamilly: "'Noto Sans KR', sans-serif",
      borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      fontWeight: 600,
      backgroundColor: 'rgba(0, 0, 0, 0.03)'
    }
  },
  sortButton: {
    padding: 4,
    marginLeft: 4,
    '& svg': {
      fontSize: '1.1rem'
    }
  }
}));
