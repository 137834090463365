import React from 'react';
import { Typography } from '@mui/material';
import { settlementData } from './SetSummaryTables';

const OffsetAmount = () => {
  const { basicStats = {} } = settlementData || {};

  const formatNumber = (number) => {
    return (number || 0).toLocaleString();
  };

  // 상계금액(총합) 출력 (추가  팝업)
  return (
    <>
      <Typography 
        sx={{
          textAlign: 'center',
          fontSize: { xs: '1.2rem', md: '1.5rem' },
          fontWeight: 'bold',
          color: '#1976d2',
          mb: 1,
        }}
      >
        상계금액(총합) {formatNumber(basicStats.offsetAmount)}원
      </Typography>
      <Typography 
        sx={{
          textAlign: 'center',
          fontSize: { xs: '1.0rem', md: '1.2rem' },
          color: '#555',
          mb: 3,
        }}
      >
        정산예정 정상산출 기준일 : 2023-11-01
      </Typography>
    </>
  );
};

export default OffsetAmount;
