import React, { useState, useEffect } from 'react';
import { 
  Box,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import QuickMenu from '../../Common/QuickMenu';
import DateRangePicker from '../../Common/DateRangePicker';
import Notice from '../../Notice/Notice';
import SearchTable from './SearchTable';
import CurrentLocation from '../../Common/CurrentLocation';
import { styled } from '@mui/material/styles';

// 스타일드 컴포넌트 추가
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#fff'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

// 검색 섹션 래퍼 스타일 추가
const StyledSearchWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  '@media (max-width: 600px)': {
    flexDirection: 'row',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    padding: '8px 0',
    '&::-webkit-scrollbar': {
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '3px'
    }
  }
});

// 테이블 래퍼 스타일 수정
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 300px)',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px'
  },
  '@media (max-width: 600px)': {
    maxHeight: 'none',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch'
  }
});

const mainBoxStyle = {
  flexGrow: 1,
  p: 3,
  mt: '5px',
  ml: '40px',  // QuickMenu 기준
  mr: '40px',  // SearchPanel 없음
  height: 'auto',
  overflowY: 'auto',
  position: 'relative',
  '@media (max-width: 960px)': {
    mx: '10px',
    pl: '10px',
    pr: '10px'
  }
};

// 메인 코드
function CancelRefundRegistration() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [searchTableOpen, setSearchTableOpen] = useState(false);

  const tableHeaders = [
    { id: 'no', label: 'No', width: '60px' },
    { id: 'actions', label: '액션', width: '160px' },
    { id: 'pg', label: '거래구분', width: '100px' },
    { id: 'amount', label: '거래금액(원금, 수수료, 수수료금액)', width: '200px' },
    { id: 'refundAmount', label: '환입금액', width: '100px' },
    { id: 'transactionDate', label: '거래일시', width: '120px' },
    { id: 'paymentType', label: '취소여부', width: '80px' },
    { id: 'approvalNo', label: '승인번호', width: '100px' }
  ];

  // 더미 데이터 확장
  const dummyData = [
    {
      id: 1,
      no: '1',
      actions: ['취소등록', '환입등록'],
      pg: 'Galaxia',
      amount: '54,781 (59,000/6.5/4,219)',
      refundAmount: '0',
      transactionDate: '20241209002127',
      paymentType: 'PAY',
      approvalNo: '30021713'
    },
    {
      id: 2,
      no: '2',
      actions: ['취소등록', '환입등록'],
      pg: 'KG이니시스',
      amount: '92,500 (100,000/7.5/7,500)',
      refundAmount: '0',
      transactionDate: '20241208235959',
      paymentType: 'PAY',
      approvalNo: '30021714'
    },
    {
      id: 3,
      no: '3',
      actions: ['취소등록', '환입등록'],
      pg: 'NICE',
      amount: '27,750 (30,000/7.5/2,250)',
      refundAmount: '0',
      transactionDate: '20241208235930',
      paymentType: 'PAY',
      approvalNo: '30021715'
    },
    {
      id: 4,
      no: '4',
      actions: ['취소등록', '환입등록'],
      pg: 'KSNET',
      amount: '46,250 (50,000/7.5/3,750)',
      refundAmount: '0',
      transactionDate: '20241208235900',
      paymentType: 'PAY',
      approvalNo: '30021716'
    },
    {
      id: 5,
      no: '5',
      actions: ['취소등록', '환입등록'],
      pg: 'Smartro',
      amount: '185,000 (200,000/7.5/15,000)',
      refundAmount: '0',
      transactionDate: '20241208235830',
      paymentType: 'PAY',
      approvalNo: '30021717'
    },
    {
      id: 5,
      no: '5',
      actions: ['취소등록', '환입등록'],
      pg: 'Smartro',
      amount: '185,000 (200,000/7.5/15,000)',
      refundAmount: '0',
      transactionDate: '20241208235830',
      paymentType: 'PAY',
      approvalNo: '30021717'
    },
    {
      id: 5,
      no: '5',
      actions: ['취소등록', '환입등록'],
      pg: 'Smartro',
      amount: '185,000 (200,000/7.5/15,000)',
      refundAmount: '0',
      transactionDate: '20241208235830',
      paymentType: 'PAY',
      approvalNo: '30021717'
    },
    {
      id: 5,
      no: '5',
      actions: ['취소등록', '환입등록'],
      pg: 'Smartro',
      amount: '185,000 (200,000/7.5/15,000)',
      refundAmount: '0',
      transactionDate: '20241208235830',
      paymentType: 'PAY',
      approvalNo: '30021717'
    },
    {
      id: 5,
      no: '5',
      actions: ['취소등록', '환입등록'],
      pg: 'Smartro',
      amount: '185,000 (200,000/7.5/15,000)',
      refundAmount: '0',
      transactionDate: '20241208235830',
      paymentType: 'PAY',
      approvalNo: '30021717'
    },
    {
      id: 5,
      no: '5',
      actions: ['취소등록', '환입등록'],
      pg: 'Smartro',
      amount: '185,000 (200,000/7.5/15,000)',
      refundAmount: '0',
      transactionDate: '20241208235830',
      paymentType: 'PAY',
      approvalNo: '30021717'
    },
    {
      id: 5,
      no: '5',
      actions: ['취소등록', '환입등록'],
      pg: 'Smartro',
      amount: '185,000 (200,000/7.5/15,000)',
      refundAmount: '0',
      transactionDate: '20241208235830',
      paymentType: 'PAY',
      approvalNo: '30021717'
    }
  ];

  // 사이즈 감지 함수
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 검색 히스토리 버튼 컴포넌트
  const SearchHistoryButton = () => {
    return (
      <Button
        variant="contained"
        startIcon={<HistoryIcon />}
        onClick={() => setSearchTableOpen(true)}
        sx={{
          bgcolor: '#fff',
          color: '#1976d2',
          border: '1px solid #1976d2',
          fontSize: '0.875rem',
          height: '36px',
          padding: '6px 16px',
          minWidth: 'auto',
          boxShadow: 'none',
          ml: 1,
          '&:hover': {
            bgcolor: '#1976d210',
            border: '1px solid #1976d2',
            boxShadow: 'none'
          }
        }}
      >
        내역조회
      </Button>
    );
  };

  // 메인 코드
  return (
    <Box component="main" sx={mainBoxStyle}>
      <Notice />
      <CurrentLocation mainTitle="결제" subTitle="임의취소 환입등록" />
      
      <Box sx={{ mb: '5px' }} />
      
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: { xs: 1, md: 2 },
        mb: 2,
        backgroundColor: '#fff',
        padding: { xs: '8px', md: '16px' },
        borderRadius: '4px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
        position: 'relative',
        zIndex: 1,
      }}>
        <StyledSearchWrapper>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            flexShrink: 0,
            position: 'relative',
            '& .MuiPopover-root': {
              zIndex: 99999
            },
            '& .MuiPopper-root': {
              zIndex: 99999
            },
            '& .MuiPaper-root': {
              zIndex: 99999,
              position: 'relative'
            },
            '& .MuiPickersPopper-root': {
              zIndex: 99999
            }
          }}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              isSearchSection={true}
            />
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <SearchHistoryButton />
          </Box>
        </StyledSearchWrapper>
      </Box>

      <TableContainer component={StyledTableWrapper} sx={{ 
        position: 'relative',
        zIndex: 0  // 테이블 전체의 z-index를 가장 낮게 설정
      }}>
        <Table stickyHeader sx={{ minWidth: '1000px', tableLayout: 'fixed' }}>
          <colgroup>
            <col style={{ width: '60px' }} />  {/* No */}
            <col style={{ width: '160px' }} /> {/* 액션 */}
            <col style={{ width: '100px' }} /> {/* 거래구분 */}
            <col style={{ width: '200px' }} /> {/* 거래금액 */}
            <col style={{ width: '100px' }} /> {/* 환입금액 */}
            <col style={{ width: '120px' }} /> {/* 거래일시 */}
            <col style={{ width: '80px' }} />  {/* 취소여부 */}
            <col style={{ width: '100px' }} /> {/* 승인번호 */}
          </colgroup>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.id}
                  align="center"
                  className="primary-header"
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.id} hover>
                <StyledTableCell align="center">{row.no}</StyledTableCell>
                <StyledTableCell align="center">
                  <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                    {row.actions.map((action, index) => (
                      <Button
                        key={index}
                        variant="contained"
                        size="small"
                        sx={{ 
                          backgroundColor: index === 0 ? '#4caf50' : '#00bcd4',
                          fontSize: '0.75rem',
                          minWidth: 'unset',
                          padding: '4px 8px',
                          '&:hover': {
                            backgroundColor: index === 0 ? '#388e3c' : '#00838f'
                          }
                        }}
                      >
                        {action}
                      </Button>
                    ))}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">{row.pg}</StyledTableCell>
                <StyledTableCell align="right">{row.amount}</StyledTableCell>
                <StyledTableCell align="right">{row.refundAmount}</StyledTableCell>
                <StyledTableCell align="center">{row.transactionDate}</StyledTableCell>
                <StyledTableCell align="center">{row.paymentType}</StyledTableCell>
                <StyledTableCell align="center">{row.approvalNo}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <QuickMenu />

      <SearchTable 
        open={searchTableOpen}
        onClose={() => setSearchTableOpen(false)}
      />
    </Box>
  );
}

export default CancelRefundRegistration; 