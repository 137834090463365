import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  Typography, 
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';



const ConfigurationandSettings = () => {
  // 첫 번째 섹션 상태
  const [bannerSlideInterval, setBannerSlideInterval] = useState('2200');
  const [loginDuration, setLoginDuration] = useState('10');
  const [smsAccount, setSmsAccount] = useState('T24823');
  const [smsAmount, setSmsAmount] = useState('990');

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  // 두 번째 섹션 상태
  const [loginAuth, setLoginAuth] = useState(true);
  const [userPhoneAuth, setUserPhoneAuth] = useState(false);
  const [subSystemAuth, setSubSystemAuth] = useState(true);

  // 세 번째 섹션 상태 (서버 오류 발생시 수신번호들)
  const [phoneNumbers, setPhoneNumbers] = useState({
    phone1: '01075599753',
    phone2: '01071179805',
    phone3: '01041179805',
    phone4: '01086653011',
    phone5: '01064793763'
  });
  
  // 메인 코드
  return (
    <Card sx={{ mt: 4, borderRadius: '12px', boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)' }}>
      <Box sx={{ p: 3 }}>
        {/* 제목 */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <SettingsSuggestIcon sx={{ color: '#9c27b0' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            설정 및 세팅
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* 첫 번째 섹션 - 왼쪽 */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>기본 설정</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  size="small"
                  label="배너슬라이드 주기(ms)"
                  value={bannerSlideInterval}
                  onChange={(e) => setBannerSlideInterval(e.target.value)}
                />
                <TextField
                  size="small"
                  label="유저 페이지 로그인 유지시간(분)"
                  value={loginDuration}
                  onChange={(e) => setLoginDuration(e.target.value)}
                />
                <TextField
                  size="small"
                  label="실적문자 세팅계정"
                  value={smsAccount}
                  onChange={(e) => setSmsAccount(e.target.value)}
                />
                <TextField
                  size="small"
                  label="실적문자 금액"
                  value={smsAmount}
                  onChange={(e) => setSmsAmount(e.target.value)}
                />
              </Box>
            </Box>
          </Grid>

          {/* 첫 번째 섹션 - 오른쪽 */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>인증 설정</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControlLabel
                  control={<Switch checked={loginAuth} onChange={(e) => setLoginAuth(e.target.checked)} />}
                  label="로그인 인증여부"
                />
                <FormControlLabel
                  control={<Switch checked={userPhoneAuth} onChange={(e) => setUserPhoneAuth(e.target.checked)} />}
                  label="유저 페이지 등록된 번호 인증"
                />
                <FormControlLabel
                  control={<Switch checked={subSystemAuth} onChange={(e) => setSubSystemAuth(e.target.checked)} />}
                  label="서브전산 로그인 인증"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>유저페이지 출금가능시간 설정</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <FormControl size="medium">
                  <InputLabel>시작 시간</InputLabel>
                  <Select
                    label="시작 시간"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    sx={{ minWidth: 120 }}
                  >
                    {[...Array(25).keys()].map((hour) => (
                      <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="medium">
                  <InputLabel>종료 시간</InputLabel>
                  <Select
                    label="종료 시간"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    sx={{ minWidth: 120 }}
                  >
                    {[...Array(25).keys()].map((hour) => (
                      <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid>

          {/* 두 번째 섹션 */}
          <Grid item xs={12}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>서버 오류 발생시 수신번호</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {[1, 2, 3, 4, 5].map((num) => (
                  <TextField
                    key={num}
                    size="small"
                    label={`서버 오류 발생시 수신번호 ${num}`}
                    value={phoneNumbers[`phone${num}`]}
                    onChange={(e) => setPhoneNumbers({...phoneNumbers, [`phone${num}`]: e.target.value})}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* 적용 버튼 */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button 
            variant="contained"
            sx={{ 
              bgcolor: '#51cbce',
              '&:hover': {
                bgcolor: '#45b0b3'
              }
            }}
          >
            적용
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default ConfigurationandSettings;
