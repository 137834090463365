import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableContainer, 
  TableCell,
  Box,
  Paper 
} from '@mui/material';
import styled from '@emotion/styled';

// 스타일드 컴포넌트 정의
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '4px 8px',
  fontSize: '0.813rem',
  // fontFamilly: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '28px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    padding: '4px 8px',
    
    '&.primary-header': {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '4px 8px'
    }
  }
}));

// 테이블 행 스타일
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '28px',
  backgroundColor: '#ffffff',
  
  '&:hover': {
    '& td': {
      color: '#000000',
    },
    backgroundColor: 'inherit'
  }
}));

// 테이블 래퍼 스타일
const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  }
});

// 더미데이터 (5개)
const dummyData = [
  {
    no: 1,
    registrationDate: '2025-02-06 15:39:44',
    modificationDate: '2025-02-06 15:39:44',
    elapsedDays: '2024-03-01 14:00:00',
    merchantName: '네오알파시스템즈',
    userId: 'm42845709',
    paymentMethod: '계좌이체',
    authType: 'SMS',
    sales: '김종각 확인용',
    settlementStatus: '정산가능',
    walletStatus: '사용중',
    pgName: 'Galaxia',
    merchantId: 'galaxia_auto_175_20241227000001'
  },
  {
    no: 2,
    registrationDate: '2025-02-05 14:40:00',
    modificationDate: '2025-02-05 14:40:00',
    elapsedDays: '2024-02-28 15:00:33',
    merchantName: '샤론정보통신',
    userId: 'm98019007',
    paymentMethod: '가상계좌',
    authType: 'CARD',
    sales: '대림 김영성',
    settlementStatus: '출금금지',
    walletStatus: '조회용',
    pgName: 'Payup',
    merchantId: 'galaxia_auto_174_20241227000001'
  },
  {
    no: 3,
    registrationDate: '2025-02-04 11:30:22',
    modificationDate: '2025-02-04 11:30:22',
    elapsedDays: '2024-02-27 09:15:40',
    merchantName: '고기진심정육점',
    userId: 'm76640459',
    paymentMethod: '신용카드',
    authType: '인증',
    sales: '시흥 김철성',
    settlementStatus: '정산가능',
    walletStatus: '사용중',
    pgName: 'KSNET',
    merchantId: 'KS0001234'
  },
  {
    no: 4,
    registrationDate: '2025-02-03 09:15:30',
    modificationDate: '2025-02-03 09:15:30',
    elapsedDays: '2024-02-26 16:20:15',
    merchantName: '골드스파사우나',
    userId: 'm42845709',
    paymentMethod: '계좌이체',
    authType: 'SMS',
    sales: '이안 파트너스',
    settlementStatus: '출금금지',
    walletStatus: '조회용',
    pgName: 'NICE',
    merchantId: 'M432109876'
  },
  {
    no: 5,
    registrationDate: '2025-02-02 16:45:10',
    modificationDate: '2025-02-02 16:45:10',
    elapsedDays: '2024-02-25 13:10:55',
    merchantName: '일일구세탁',
    userId: 'm76640459',
    paymentMethod: '가상계좌',
    authType: 'CARD',
    sales: '권오현',
    settlementStatus: '정산가능',
    walletStatus: '사용중',
    pgName: 'EtoU',
    merchantId: 'SPC107836'
  }
];

function NonMerchantTable({ searchConditions }) {
  const [filteredData, setFilteredData] = useState(dummyData);

  // 검색 조건에 따른 필터링
  useEffect(() => {
    if (!searchConditions) {
      setFilteredData(dummyData);
      return;
    }

    const { searchText, walletStatus, startDate, endDate } = searchConditions;

    const filtered = dummyData.filter(item => {
      // 1. 월렛상태 필터링
      const matchesStatus = walletStatus === 'all' || item.walletStatus === walletStatus;

      // 2. 텍스트 검색 (아이디, MID, 가맹점명)
      const searchLower = searchText?.toLowerCase() || '';
      const matchesSearch = !searchText || 
        item.userId.toLowerCase().includes(searchLower) ||
        item.merchantId.toLowerCase().includes(searchLower) ||
        item.merchantName.toLowerCase().includes(searchLower);

      // 3. 날짜 필터링 (YYYY-MM-DD 형식으로 변환)
      const itemDate = new Date(item.registrationDate);
      const start = startDate ? new Date(`${startDate.slice(0,4)}-${startDate.slice(4,6)}-${startDate.slice(6,8)}`) : null;
      const end = endDate ? new Date(`${endDate.slice(0,4)}-${endDate.slice(4,6)}-${endDate.slice(6,8)} 23:59:59`) : null;
      const matchesDate = (!start || itemDate >= start) && (!end || itemDate <= end);

      return matchesStatus && matchesSearch && matchesDate;
    });

    setFilteredData(filtered);
  }, [searchConditions]);

  return (
    <Paper sx={{ width: 'calc(100% - 20px)', margin: '0 auto', overflow: 'hidden' }}>
      <StyledTableWrapper>
        <TableContainer sx={{ 
          maxHeight: '65vh',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px'
          }
        }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell className="primary-header">번호</StyledTableCell>
                <StyledTableCell className="primary-header">등록일</StyledTableCell>
                <StyledTableCell className="primary-header">수정일</StyledTableCell>
                <StyledTableCell className="primary-header">경과일</StyledTableCell>
                <StyledTableCell className="primary-header">가맹점명</StyledTableCell>
                <StyledTableCell className="primary-header">아이디</StyledTableCell>
                <StyledTableCell className="primary-header">결제수단</StyledTableCell>
                <StyledTableCell className="primary-header">인증방식</StyledTableCell>
                <StyledTableCell className="primary-header">영업</StyledTableCell>
                <StyledTableCell className="primary-header">정산상태</StyledTableCell>
                <StyledTableCell className="primary-header">월렛상태</StyledTableCell>
                <StyledTableCell className="primary-header">PG</StyledTableCell>
                <StyledTableCell className="primary-header">MID</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row) => (
                <StyledTableRow key={row.no}>
                  <StyledTableCell align="center">{row.no}</StyledTableCell>
                  <StyledTableCell align="center">{row.registrationDate}</StyledTableCell>
                  <StyledTableCell align="center">{row.modificationDate}</StyledTableCell>
                  <StyledTableCell align="center">{row.elapsedDays}</StyledTableCell>
                  <StyledTableCell align="center">{row.merchantName}</StyledTableCell>
                  <StyledTableCell align="center">{row.userId}</StyledTableCell>
                  <StyledTableCell align="center">{row.paymentMethod}</StyledTableCell>
                  <StyledTableCell align="center">{row.authType}</StyledTableCell>
                  <StyledTableCell align="center">{row.sales}</StyledTableCell>
                  <StyledTableCell align="center">{row.settlementStatus}</StyledTableCell>
                  <StyledTableCell align="center">{row.walletStatus}</StyledTableCell>
                  <StyledTableCell align="center">{row.pgName}</StyledTableCell>
                  <StyledTableCell align="center">{row.merchantId}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableWrapper>
    </Paper>
  );
}

export default NonMerchantTable;